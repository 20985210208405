import { FC, useMemo } from "react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import moment from "moment";
import type { AxiosError } from "axios";
import TextArea from "antd/lib/input/TextArea";
import { css } from "@linaria/core";
import tw from "twin.macro";

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Typography
} from "antd";
import { useDefaultQuery } from "@hooks";
import type { EditContractFormProps } from "@components/organisms/EditContractForm/props";
import { EditOutlined } from "@ant-design/icons";

import type { Currency } from "@components/types/models/Currency";
import {
  CONTRACT_TYPE,
  defineContractType
} from "@components/types/models/Contract";
import type { FormValues } from "@components/types/models/Contract";

import { contractApi } from "@api/contractApi";

import { CATEGORIES } from "@components/constants/AdminCategories";

import type { Category } from "./libs/types/Category";

const scopeOfWorks = [
  { enName: "Goods", ruName: "Товары", id: 1 },
  { enName: "Services", ruName: "Услуги", id: 2 },
  { enName: "Works", ruName: "Работы", id: 3 }
];

const { Option } = Select;
const { Text, Paragraph } = Typography;

const DATE_FORMAT = "YYYY-MM-DD";
const ADDON_BEFORE_VALUE = "C";

export const EditContractForm: FC<EditContractFormProps> = ({
  contract,
  refetchContractsList
}) => {
  const { t } = useTranslation();
  const [, i18n] = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const contractTypeTitle =
    i18n.language === "en"
      ? defineContractType(contract?.contractType).enName
      : defineContractType(contract?.contractType).ruName;

  const handleContractAddonBefore = useMemo(() => {
    if (contract.contractNumber[0] === ADDON_BEFORE_VALUE) {
      return contract.contractNumber.substring(1);
    }
    return contract.contractNumber;
  }, [contract.contractNumber]);

  const {
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue,
    watch
  } = useForm<Pick<FormValues, "contractNumber" | "kcCategory">>({
    defaultValues: {
      contractNumber: handleContractAddonBefore,
      kcCategory: contract.kcCategory?.id
    }
  });

  const { data: currencyList } = useDefaultQuery<readonly Currency[]>(
    "getCurrencyList",
    async () => contractApi.getCurrencyList().then((res) => res.data)
  );

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };
  const handleCloseModal = (): void => {
    setIsModalVisible(false);
  };

  const handleCategoryAndAreaChange = (value: number): void => {
    setValue("kcCategory", value);
  };

  const handleContractNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target;
    setValue("contractNumber", value);
  };

  const mutation = useMutation(
    async (values: Pick<FormValues, "contractNumber" | "kcCategory">) =>
      contractApi.updateContract(contract.id, values),
    {
      onSuccess() {
        notification.success({
          message: t("contracts.successUpdate")
        });
        handleCloseModal();
        refetchContractsList();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: "contractNumber" | "kcCategory";
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        }
      }
    }
  );

  const handleModalOkClick = () => {
    clearErrors();
    const values = getValues();
    const mutatedValues = {
      ...values,
      contractNumber: ADDON_BEFORE_VALUE + values.contractNumber
    };
    mutation.mutate(mutatedValues);
  };

  return (
    <>
      <Button
        type="primary"
        title={t("edit")}
        shape="circle"
        icon={<EditOutlined />}
        onClick={handleOpenModal}
      />

      <Modal
        destroyOnClose
        visible={isModalVisible}
        title={t("contractors.editContract")}
        cancelText={t("cancel")}
        okText={t("save")}
        width={1000}
        confirmLoading={mutation.isLoading}
        closable={false}
        onOk={handleModalOkClick}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical">
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                required
                label={t("contracts.contractNumber")}
                validateStatus={errors.contractNumber && "error"}
                help={errors.contractNumber?.message}
              >
                <Input
                  addonBefore={ADDON_BEFORE_VALUE}
                  placeholder={"1234567"}
                  value={watch("contractNumber")}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                    handleContractNumberChange(evt)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                required
                label={t("contracts.kcCategoryAndArea")}
                validateStatus={errors.kcCategory && "error"}
                help={errors.kcCategory?.message}
              >
                <Select
                  value={watch("kcCategory")}
                  onChange={handleCategoryAndAreaChange}
                >
                  {CATEGORIES.map((category: Category) => (
                    <Option key={category.key} value={category.key}>
                      {category.title}
                    </Option>
                  ))}
                  {CATEGORIES.map(
                    (category: { readonly children?: readonly Category[] }) =>
                      category.children?.map((child) => (
                        <Option key={child.key} value={child.key}>
                          {child.title}
                        </Option>
                      ))
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("contracts.contractType")}>
                <Input readOnly disabled value={contractTypeTitle} />
              </Form.Item>
            </Col>
          </Row>
          {contract?.masterAgreement && (
            <Form.Item label={t("contracts.masterAgreement")}>
              <Input
                readOnly
                disabled
                value={contract?.masterAgreement.contractNumber}
              />
            </Form.Item>
          )}
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label={t("contracts.startDate")}>
                <DatePicker
                  disabled
                  style={{ width: "100%" }}
                  format={DATE_FORMAT}
                  value={moment(contract?.startDate)}
                />
              </Form.Item>
            </Col>
            {contract?.lastApprovedAmendment?.amount && (
              <Col span={8}>
                <Form.Item label={t("contracts.amount")}>
                  <Input
                    disabled
                    type="number"
                    value={contract.lastApprovedAmendment.amount}
                  />
                </Form.Item>
              </Col>
            )}
            {contract?.lastApprovedAmendment?.currencyId && (
              <Col span={8}>
                <Form.Item label={t("contracts.currency")}>
                  <Select
                    disabled
                    showSearch
                    value={contract.lastApprovedAmendment.currencyId}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {currencyList
                      ?.filter((currency) => currency.enabled)
                      ?.map((availableCurrency) => (
                        <Option
                          key={availableCurrency.id}
                          value={availableCurrency.id}
                        >
                          <span>{availableCurrency.code}</span>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item label={t("contracts.endDate")}>
                <DatePicker
                  disabled
                  style={{ width: "100%" }}
                  format={DATE_FORMAT}
                  value={moment(contract.endDate)}
                />
              </Form.Item>
            </Col>
            {contract?.lastApprovedAmendment?.kcTarget && (
              <Col span={8}>
                <Form.Item label={t("contracts.kcTarget")}>
                  <Input
                    disabled
                    value={contract.lastApprovedAmendment.kcTarget}
                    type="number"
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          {contract?.scopes &&
            contract?.contractType !== CONTRACT_TYPE.MASTER_AGREEMENT && (
              <Form.Item label={t("contracts.scopeOfWorks")}>
                <Checkbox.Group
                  disabled
                  value={contract.scopes}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {scopeOfWorks.map((item, index) => (
                    <Checkbox key={index} value={item.id}>
                      {i18n.language === "en" ? item.enName : item.ruName}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
          {contract?.lastApprovedAmendment?.detailsOnScopeWork && (
            <Form.Item label={t("contracts.detailsOnScopeWork")}>
              <TextArea
                disabled
                value={contract.lastApprovedAmendment.detailsOnScopeWork}
              />
            </Form.Item>
          )}
          {!!contract?.lastApprovedAmendment?.history?.length && (
            <Form.Item label={t("contracts.comment")}>
              <TextArea
                disabled
                value={
                  contract.lastApprovedAmendment.history[
                    contract.lastApprovedAmendment.length - 1
                  ]?.comment
                }
              />
            </Form.Item>
          )}
          {contract?.lastApprovedAmendment?.kcPlanFile && (
            <Row>
              <Text>
                <Text
                  className={css`
                    ${tw`block mb-2`}
                  `}
                >
                  {t("contracts.attachedFile")}
                </Text>
                <Paragraph>
                  {contract.lastApprovedAmendment.kcPlanFile}
                </Paragraph>
              </Text>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  );
};
