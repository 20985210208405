import axios from "axios";

import { notification } from "antd";

import { HttpResponseStatusCodes } from "@components/types/HttpResponseStatusCodes";

import type { History } from "history";

export type PaginatedResponse<T> = {
  readonly currentPage: number;
  readonly pageSize: number;
  readonly totalItems: number;
  readonly results: readonly T[];
};

export type FakeAxiosResponse<T> = {
  readonly data: T;
};

axios.defaults.baseURL =
  process.env.REACT_APP_CT_API_URL ?? "https://localhost:5001";

axios.interceptors.request.use((config) => {
  const lang = localStorage.getItem("KC_PORTAL_LNG");
  return {
    ...config,
    headers: {
      ...config.headers,
      "Accept-Language": lang
    }
  };
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("KC_PORTAL_USER_TOKEN");

  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`
      }
    };
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorStatus = error?.response?.status;

    if (errorStatus === HttpResponseStatusCodes.Unauthorized) {
      localStorage.removeItem("KC_PORTAL_USER_TOKEN");
    }

    return Promise.reject(error);
  }
);

const interceptHttpErrors = (history: History<unknown>): void => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const errorStatus = error?.response?.status;
      if (errorStatus >= HttpResponseStatusCodes.InternalServerError) {
        notification.error({
          message: `Error Status: ${error.response.status}`,
          description: error.response.data?.Message
        });
      } else if (errorStatus === HttpResponseStatusCodes.Unauthorized) {
        notification.error({
          message: `Error Status: ${error.response.status}`,
          description: error.response.data?.Message
        });
        localStorage.removeItem("KC_PORTAL_USER_TOKEN");
        history.push("/login");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else if (Number.isNaN(errorStatus)) {
        notification.error({
          message: `Something went wrong. Try again.`
        });
      }
      return Promise.reject(error);
    }
  );
};

export { interceptHttpErrors };
