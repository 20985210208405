import { FC, useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Col, Divider, Row, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { STATUS } from "@components/types/models/Statuses";

import { UserContext } from "@contexts/userContext";

import type { TimelineProps } from "./props";

const { Title } = Typography;
const GRAND_TOTAL = 0;
const COUNT_MONTH_AS_TOTAL = 0;

export const Timeline: FC<TimelineProps> = ({
  forecastStatus,
  selectedMonth,
  setSelectedMonth,
  setSelectedYear,
  selectedYear,
  period,
  lastYear,
  firstYear
}) => {
  const { isAdmin, isContractor } = useContext(UserContext);
  const { t } = useTranslation();

  const [isAnnual, setIsAnnual] = useState(false);

  const handleNextYearClick = (): void => {
    setSelectedYear(selectedYear === lastYear ? GRAND_TOTAL : selectedYear + 1);
  };

  const handlePrevYearClick = (): void => {
    setSelectedYear(selectedYear === GRAND_TOTAL ? lastYear : selectedYear - 1);
  };

  const handleMonthClick = useCallback(
    (id: number) => () => {
      setSelectedMonth(id);
    },
    [setSelectedMonth]
  );

  useEffect(() => {
    if (selectedYear > moment().year() && forecastStatus !== STATUS.PENDING) {
      setIsAnnual(true);
    } else {
      setIsAnnual(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    if (
      (forecastStatus !== STATUS.PENDING && isAdmin) ||
      ((forecastStatus === STATUS.PENDING ||
        forecastStatus === STATUS.APPROVED) &&
        isContractor)
    ) {
      setSelectedYear(GRAND_TOTAL);
    } else if (firstYear > moment().year()) {
      setSelectedYear(firstYear);
    } else if (lastYear < moment().year()) {
      setSelectedYear(lastYear);
    } else {
      setSelectedYear(moment().year());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  useEffect(() => {
    const lastMonthInPeriod =
      period?.[selectedYear]?.[period?.[selectedYear]?.length - 1];
    const firstMonthInPeriod = period?.[selectedYear]?.[0];
    const currentMonth = moment().month();
    const currentYear = moment().year();

    if (isAnnual) {
      setSelectedMonth(COUNT_MONTH_AS_TOTAL);
    } else if (firstYear <= currentYear && lastYear >= currentYear) {
      if (period?.[selectedYear]?.includes(currentMonth)) {
        setSelectedMonth(currentMonth);
      } else {
        setSelectedMonth(lastMonthInPeriod ?? 0);
      }
    } else if (firstYear > currentYear) {
      setSelectedMonth(firstMonthInPeriod ?? COUNT_MONTH_AS_TOTAL);
    } else if (lastYear < currentYear) {
      setSelectedMonth(lastMonthInPeriod ?? 0);
    } else {
      setSelectedMonth(COUNT_MONTH_AS_TOTAL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, period, isAnnual]);

  return (
    <Row align="middle">
      <Col span={5}>
        <Row align="middle" justify="space-between">
          <Button
            type="link"
            icon={<LeftOutlined />}
            disabled={selectedYear === firstYear}
            onClick={handlePrevYearClick}
          />
          <Title
            className={css`
              ${tw`uppercase! text-primary! mb-0!`}
            `}
            level={4}
          >
            {selectedYear === GRAND_TOTAL
              ? t("forecasts.grandTotal")
              : selectedYear}
          </Title>
          <Button
            type="link"
            icon={<RightOutlined />}
            disabled={selectedYear === GRAND_TOTAL}
            onClick={handleNextYearClick}
          />
        </Row>
      </Col>
      {selectedYear !== GRAND_TOTAL && (
        <Col span={19}>
          <Col style={{ marginLeft: "24px" }}>
            {selectedYear > moment().year() && (
              <Row
                gutter={6}
                className={css`
                  ${tw`mb-2`}
                `}
              >
                <Col>
                  <Button
                    type={isAnnual ? "default" : "primary"}
                    onClick={() => setIsAnnual(false)}
                  >
                    {t("months.monthly")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type={isAnnual ? "primary" : "default"}
                    onClick={() => setIsAnnual(true)}
                  >
                    {t("months.annual")}
                  </Button>
                </Col>
              </Row>
            )}

            <Row gutter={6}>
              {!isAnnual &&
                period?.[selectedYear]?.map((month: number, index: number) => (
                  <Col key={index}>
                    <Button
                      type={selectedMonth === month ? "primary" : "default"}
                      className={css`
                        ${tw`mb-2`}
                      `}
                      onClick={handleMonthClick(month)}
                    >
                      {t(`months.${month}`)}
                    </Button>
                  </Col>
                ))}

              {selectedYear <= moment().year() && (
                <Button
                  type={selectedMonth === 0 ? "primary" : "default"}
                  onClick={handleMonthClick(0)}
                >
                  {t(`months.${0}`)}
                </Button>
              )}
            </Row>
          </Col>
        </Col>
      )}
      <Divider />
    </Row>
  );
};
