import type { FC } from "react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { css } from "@linaria/core";
import moment, { locale } from "moment";
import tw from "twin.macro";

import { ArrowUpOutlined } from "@ant-design/icons";
import { Row, Table, Tag, Typography } from "antd";

import type { Actual } from "@components/types/models/Actuals";
import { ActualSortType } from "@components/types/models/Actuals";
import {
  defineContractType,
  defineScopeOfWorks
} from "@components/types/models/Contract";
import { defineStatus } from "@components/types/models/Statuses";

import type { ActualsTableProps } from "./props";

export const ActualsTable: FC<ActualsTableProps> = ({
  actualsList,
  isLoading,
  pagination,
  sortType,
  setSortType
}) => {
  const { t, i18n } = useTranslation();
  const { Column } = Table;
  const { Text } = Typography;
  const maxRotateDegree = 180;
  const [rotate, setRotate] = useState(maxRotateDegree);

  locale(i18n.language === "en" ? "en" : "ru");
  const getActualVersion = (
    version: number,
    month: number,
    year: number
  ): string =>
    `${moment()
      .month(month - 1)
      .locale(i18n.language)
      .format("MMMM")} ${year} (v${version})`;

  const handleSortButtonClick = (): void => {
    if (sortType === ActualSortType.Ascending) {
      setSortType(ActualSortType.Descending);
      setRotate(maxRotateDegree);
    } else if (sortType === ActualSortType.Descending) {
      setSortType(ActualSortType.Ascending);
      setRotate(0);
    }
  };

  return (
    <Table
      locale={{ emptyText: t("noData") }}
      loading={isLoading}
      dataSource={actualsList}
      pagination={pagination}
      scroll={{ x: 800 }}
    >
      <Column
        key="period"
        width={250}
        title={() => (
          <Row
            align="middle"
            className={css`
              ${tw`cursor-pointer`}
            `}
            onClick={handleSortButtonClick}
          >
            <Text
              className={css`
                ${tw`mr-2 cursor-pointer`}
              `}
            >
              {sortType === ActualSortType.Ascending
                ? t("actuals.periodAscending")
                : t("actuals.periodDescending")}
            </Text>
            <ArrowUpOutlined
              style={{
                transition: "transform 0.3s linear",
                transform: `rotate(${rotate}deg)`
              }}
            />
          </Row>
        )}
        render={(actual: Actual) => (
          <Link
            to={`/contractor/actual/details?contractId=${actual.contractId}&period=${actual.year}-${actual.month}`}
          >
            {getActualVersion(actual.version, actual.month, actual.year)}
          </Link>
        )}
      />
      <Column
        key="contractNumber"
        title={t("contracts.contractNumber")}
        dataIndex="contractNumber"
      />
      <Column
        key="status"
        title={t("status")}
        dataIndex="status"
        render={(status: number) => (
          <Tag color={defineStatus(status).color}>
            {i18n.language === "en"
              ? defineStatus(status).enName
              : defineStatus(status).ruName}
          </Tag>
        )}
      />
      <Column
        key="contractType"
        title={t("contracts.contractType")}
        dataIndex="contractType"
        render={(contractType: number) =>
          i18n.language === "en"
            ? defineContractType(contractType).enName
            : defineContractType(contractType).ruName
        }
      />
      <Column
        key="scopeOfWorks"
        title={t("scopeOfWork")}
        dataIndex="scopeOfWorks"
        render={(scope: readonly number[]) =>
          scope.map((item: number, index) => (
            <Text key={index}>
              {(index ? ", " : "") +
                (i18n.language === "en"
                  ? defineScopeOfWorks(item).enName
                  : defineScopeOfWorks(item).ruName)}
            </Text>
          ))
        }
      />
      <Column
        key="submissionDate"
        title={t("actuals.submissionDate")}
        dataIndex="submissionDate"
        render={(submissionDate?: string) =>
          submissionDate
            ? moment(submissionDate).format("YYYY-MM-DD HH:mm:ss")
            : "-"
        }
      />
    </Table>
  );
};
