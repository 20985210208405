import type { FC } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next/";

import { EditOutlined, TeamOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

import { PERMISSIONS } from "@components/types/models/Permissions";

import { UserContext } from "@contexts/userContext";
import { isUserHasPermission } from "@utils/permissionHelper";

import type { ContractorActionsProps } from "./props";

export const ContractorActions: FC<ContractorActionsProps> = ({
  item,
  handleShowEditModal
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isDisable = !isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACTOR_EDIT
  );

  return (
    <Space size="middle">
      <Button
        title={t("members.members")}
        type="link"
        shape="circle"
        icon={<TeamOutlined />}
        href={`/admin/contractor/${item.id}`}
      />
      <Button
        title={t("edit")}
        type="primary"
        shape="circle"
        disabled={isDisable}
        icon={<EditOutlined />}
        onClick={handleShowEditModal(item)}
      />
    </Space>
  );
};
