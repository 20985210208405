export const contracts = [
  {
    title: "Типы контракта и статусы",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Для просмотра подробной информации о контракте нажмите на &quot;Номер контракта&quot; в блоке &quot;Контракты&quot; детальной страницы Подрядчика.</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Существуют следующие </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>виды контрактов</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в системе:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Отдельное соглашение</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Генеральное соглашение</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Дополнительное соглашение</span></span></span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Контракты имеют 4 типа статусов:</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Черновик</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> - когда контракт создается руководителем KC, но еще не обновлен Подрядчиком</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>На рассмотрении</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> - когда Подрядчик обновил детали контракта и отправил на рассмотрение Руководителю КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Отклонен</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> - когда Руководитель КС отклоняет контракт после рассмотрения деталей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Утвержден</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> - когда Руководитель KC подтверждает все детали контракта. Утвержденные контракты позволяют Подрядчикам начать процесс создания и отправки на соглашение прогнозов, фактических показателей и G1-C форм</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Помимо уведомлений по электронной почте, отправляемых каждому назначенному Руководителю KC, система дополнительно отображает количество ожидающих рассмотрения контрактов, прогнозов, фактических показателей или форм G1-C.</span></span></span></p>

`
  },
  {
    title: "Как добавить контракт?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+ Добавить контракт&quot;на детальной странице выбранного подрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/GkAUE1216E3tRYnwnFj3WRp9SA80AhE76Dx3e98a3BV_JgPDd7ICDoeCx3_iaEXYsaqPCxXhx310IiAjCHDQyKu31hahqLn4IfD-qoDDMqafdMN7Cv1D5pFiy-2ysAjrIJhbvwnKmO1ORfC0P-v2WemPnzcg0owjYBOnTBVfFKj6NzivqSmQkKKbDQ" style="height:593px; width:596px" /></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:&nbsp;</strong></span></span></span></p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Для добавления типа дополнительного соглашения необходимо сначала создать и утвердить генеральное соглашение (обязательно)</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Дополнительные соглашения также можно добавить нажав кнопку &quot;+ Добавить доп. соглашение&quot; на детальной странице утвержденного генерального соглашения</span></span></span></li>
</ul>
`
  },
  {
    title: "Как отредактировать контракты?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики и откройте таблицу </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Контракты</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Редактировать&quot;под столбцом Действия для выбранного контракта подрядчика (Все статусы включительно)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обновите номер контракта или Категорию и область КС для выбранного контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как удалить контракты?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики и откройте таблицу </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Контракты</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Удалить&quot;под столбцом Действия для выбранного контракта подрядчика (Все статусы включительно)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите удаление нажав на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как отклонить контракты?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики и откройте таблицу </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Контракты</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт со статусом &quot;На рассмотрении&quot;в списке Контракты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отклонить&quot;на детальной странице выбранного контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий (причина отклонения)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/GHFf7isejoE7JYlGtSq4OvDGlqbeumYQW79FRbU3-u-mwAkEj8rPJTUV0C0gohUi6xxeeZlNcsdiXPTL6FgHTp7Z_yVuoCfU_Hv9-BWbYPIzW7YieQSENrRalxBJNPyiEY8HzQ9JOhc7BpcOBswVouAlvvaukpjnac-t_nHsHnlmo0kADr7zRKENGQ" style="height:357px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как утвердить контракты?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики и откройте таблицу </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Контракты</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт со статусом &quot;На рассмотрении&quot;в списке Контракты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Утвердить&quot;на детальной странице выбранного контракта</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/toCsnCZL82sG4juXmxgeWY2KFx2LSdLQQHxWb6fmugOSe5nci93fCLhXN6OdkCbNU4tU5o0Juwkmq4WzNyR8n9y8ezfd7Dj3HtRelVbq8QrUANvAaSGKdNnTbN-KgwK_Bmp7od6fLFn3fATLYyual1jLAfDxaqmfZtmN1Zair0L-EoSI6w3znqSoJw" style="height:357px; width:624px" /></span></span></span></p>
`
  }
];
