import { enMigrations } from "@modules/Migrations/i18n/en";

import { enActuals } from "./actuals";
import { enCommon } from "./common";
import { enContractors } from "./contractors";
import { enContracts } from "./contracts";
import { enCountriesAndCities } from "./countries-and-cities";
import { enExchangeRates } from "./exchange-rates";
import { enForecasts } from "./forecasts";
import { enGlossary } from "./glossary";
import { enInvoices } from "./invoices";
import { enLogin } from "./login";
import { enMembers } from "./members";
import { enMeta } from "./meta";
import { enMonths } from "./months";
import { enPermissions } from "./permissions";
import { enPowerBi } from "./powerBi";
import { enProfile } from "./profile";
import { enReports } from "./reports";
import { ruSubcontractors } from "./subcontractors";
import { enUserguides } from "./userguides";

export const enTranslation = {
  translation: {
    ...enCommon,
    contractors: enContractors,
    subcontractors: ruSubcontractors,
    login: enLogin,
    meta: enMeta,
    members: enMembers,
    profile: enProfile,
    permissions: enPermissions,
    contracts: enContracts,
    forecasts: enForecasts,
    actuals: enActuals,
    months: enMonths,
    exchangeRates: enExchangeRates,
    reports: enReports,
    powerBi: enPowerBi,
    invoices: enInvoices,
    glossary: enGlossary,
    userguides: enUserguides,
    migrations: enMigrations,
    countriesAndCities: enCountriesAndCities
  }
};
