export const gettingStarted = [
  {
    title: "Как получить доступ к информации учетной записи?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Войдите в систему</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на метку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>KC PORTAL</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в левом углу горизонтального меню ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на свое </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>ИМЯ</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в правом углу горизонтального меню</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/hdzksPAcsARjWsSDS4mqWkJSL6n7P1UAxB5OVMS4UBdl9Y1CFHmpKTvJzJHoryGcgFUNcNnUkETOxXaR50qzffaGUhquRmeDBG83X9zF1GXdCVfEFZLOeHBypgGUkiUAkbQ0-emtW2J_thl0wqhRrNaw8wVh-aJUbIPTl7gW6Yd5LkYVehdIPF2NvA" style="height:111px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как изменить пароль?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Войдите в систему</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на метку KC PORTAL в левом углу горизонтального меню ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на свое ИМЯ в правом углу горизонтального меню</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Изменить пароль&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> на странице учетной записи</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите текущий пароль, новый пароль и подтверждение нового пароля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Поменять&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/SZBDwrpelOOIwA8PAqkysJ6roJ36SXOelNbaV0tToZhiNiVqhzW4NBuTNUHmPrdG3MNQe161oLPlv7rLlLl0HtRTd1Nx5KlOuNveU1vv-q9mFCm7PHlAwz8WHcwhJRZBtIDuWwe9-e0Hz3_L5GA-eBON_NwFXCBfMJWHoK3_FXLGDD3ZEZ0l7fGcZA" style="height:232px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как попасть на страницу информации о компании",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Войдите в систему</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/PfeT_JVsgbYJ_VIztYu9Flk8dIySJO60j-pkvTJE4NRNOFsoWfpwxprn3du-sfxqnHwHSRiA2PGNaOU-l4415hc7PadToTmiSR8tWwT1xzPn1fGh1-YyEMHJc4f7b9UgWxXrAxMvBwbcKQBUqbsH4_sTUKs74NLYhzpirNAUHRYkGQhFwccxrQhyWA" style="height:181px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как пригласить сотрудников в систему?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Пригласить сотрудника&quot; на странице сотрудники</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Пригласить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/Q5U4JZcB_gDoMDMQPCdRzcv2F-YBNbvcOnKEjC6n1jp93hpSRy2pBZ4C4FhLj7J_Mlopg7H6VzUmPV72WMJgBnEZyX0tYqR1Smkrl1uiK9eRMpqeSnrw4SwP59tMLuP2m7zEKxSpIGdqkBz6b9wQ2ckS-VzeYi70M5PACK2La1lUKDiP06sQwt5lEg" style="height:403px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как отредактировать профиль субподрядчика?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Пролистайте вниз страницу сотрудники до блока &quot;Субподрядчики&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Редактировать&quot; под столбцом Действия для выбранного субподрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/p5d-lPw2VEgFWp3gInM_FlkBIoSF5a8jK28o4DVBgFWhRTnxdBKCLj_DCDP37XehN0K5IZv1PQck10lAfQwSh6Y7QlcCPeOqlNPPAbe17DdKvF1oSQnnDwiAfsSOjk_Q2e_xi5Uhb6mHy9hQa1raD8MSEbXlx8pp7PU-Ubmw56HBvJpcb0DfGIQcQQ" style="height:283px; width:624px" /></span></span></span></p>
`
  }
];
