import React from "react";
import { useTranslation } from "react-i18next";

import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

import type { LoginButtonProps } from "./props";

const loginButton = css`
  ${tw`border-white!	hover:bg-opacity-10`}
`;

export const LoginButton: React.FC<LoginButtonProps> = ({ withIcon }) => {
  const [t] = useTranslation();
  return (
    <Button className={loginButton} type="primary">
      {withIcon && <UserOutlined />}
      {t("login.loginButton")}
    </Button>
  );
};
