import type { FC } from "react";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useMutation } from "react-query";

import axios from "axios";
import type { AxiosError } from "axios";

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Typography
} from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import type { FormValues } from "@components/types/models/Member";

import type { InviteContractorMemberFormProps } from "./props";

export const InviteContractorMemberForm: FC<
  InviteContractorMemberFormProps
> = ({ contractorId, refetchMembersList, isDisable }) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    watch,
    reset,
    clearErrors,
    control
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      position: "",
      phoneNumber: "",
      email: ""
    }
  });

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (): void => {
    reset();
    setIsModalOpen(false);
  };

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLInputElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const mutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any) =>
      axios.post(`/api/contractor/${contractorId}/member/invite`, values),
    {
      onSuccess: (_, values: FormValues) => {
        refetchMembersList();
        handleCloseModal();
        notification.success({
          message: `${values.email} ${t("members.inviteSuccess")}`
        });
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              readonly name: any;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("email", { message: errData.message });
        }
      }
    }
  );

  const handleModalOkClick = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      const phoneNumber = values.phoneNumber?.replace(/\D/gu, "");
      const formValues = {
        ...values,
        phoneNumber
      };
      const data = Object.fromEntries(
        Object.entries(formValues).filter(([_, v]) => v !== "")
      );
      mutation.mutate(data);
    })();
  }, [handleSubmit, mutation, clearErrors]);

  return (
    <>
      <Button
        key="1"
        type="primary"
        icon={<UserAddOutlined />}
        disabled={isDisable}
        onClick={handleOpenModal}
      >
        {t("members.inviteMember")}
      </Button>
      <Modal
        visible={isModalOpen}
        title={t("members.inviteMember")}
        cancelText={t("cancel")}
        okText={t("members.invite")}
        width={1000}
        confirmLoading={mutation.isLoading}
        closable={false}
        onOk={handleModalOkClick}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical">
          <Title level={5}>{t("members.memberInformation")}</Title>
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.firstName?.message && "error"}
                help={errors.firstName?.message}
                label={t("members.firstName")}
              >
                <Input
                  value={watch("firstName")}
                  onChange={handleInputChange("firstName")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.lastName?.message && "error"}
                help={errors.lastName?.message}
                label={t("members.lastName")}
              >
                <Input
                  value={watch("lastName")}
                  onChange={handleInputChange("lastName")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.position?.message && "error"}
                help={errors.position?.message}
                label={t("members.jobTitle")}
              >
                <Input
                  value={watch("position")}
                  onChange={handleInputChange("position")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={16}>
              <Form.Item
                validateStatus={errors.email?.message && "error"}
                help={errors.email?.message}
                label={t("email")}
              >
                <Input
                  value={watch("email")}
                  onChange={handleInputChange("email")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.phoneNumber?.message && "error"}
                help={errors.phoneNumber?.message}
                label={t("members.phoneNumber")}
                labelCol={{ span: 24 }}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { onChange: handleChange, value } }) => (
                    <InputMask
                      mask="+7(999)999 99 99"
                      value={value}
                      onChange={handleChange}
                    >
                      {(inputProps: unknown) => (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <Input {...inputProps} type="tel" className="input" />
                      )}
                    </InputMask>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
