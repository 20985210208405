import { FC, useEffect } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { Button, Col, Form, Input, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { stringArrToNumberArr } from "@utils/helper";

import type { SearchSectionProps } from "./props";

export const SearchSection: FC<SearchSectionProps> = ({
  categories,
  filters,
  isLoading,
  onSearchQuery
}) => {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useForm();

  useEffect(() => {
    setValue("bin", filters?.bin);
    setValue("name", filters?.name);
    setValue("categories", stringArrToNumberArr(filters?.categories));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnFinish = useCallback(() => {
    const vals = getValues();

    onSearchQuery({
      bin: vals.bin ? vals.bin : global.undefined,
      name: vals.name ? vals.name : global.undefined,
      categories: vals.categories
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchQuery]);

  return (
    <Form layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item label={t("bin")}>
            <Input
              value={watch("bin")}
              onChange={(e) => setValue("bin", e.currentTarget.value)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("contractors.contractorName")}>
            <Input
              value={watch("name")}
              onChange={(e) => setValue("name", e.currentTarget.value)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("contractors.kcCategoryAndArea")}>
            <Select
              loading={!categories?.length}
              options={categories?.map((cat) => ({
                label: cat.name,
                value: cat.id
              }))}
              mode="multiple"
              maxTagCount="responsive"
              value={watch("categories")}
              onChange={(value) => setValue("categories", value)}
            />
          </Form.Item>
        </Col>
        <Col
          span={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
            loading={isLoading}
          >
            {t("applyFilters")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
