import type { FC } from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { Button, Form, Input, Space, Spin } from "antd";

import type { Currency } from "@components/types/models/Currency";
import type {
  ExchangeRateType,
  UpdateExchangeRatePayloadType,
  UpdateExchangeRateValidationErrorsType
} from "@components/types/models/ExchangeRates";

import type { ValidateErrorEntity } from "rc-field-form/lib/interface";

import { ExchangeRatePeriodPicker } from "./ExchangeRatePeriodPicker";

type EditExchangeRatesFormProps = {
  readonly currencies: readonly Currency[];
  readonly onSubmit: (values: UpdateExchangeRatePayloadType) => void;
  readonly onSubmitFailed?: (errorInfo: ValidateErrorEntity) => void;
  readonly errors?: readonly UpdateExchangeRateValidationErrorsType[];
  readonly onCancel: () => void;
  readonly loading?: boolean;
  readonly onPeriodChange: (period: string) => void;
  readonly exchangeRates: readonly ExchangeRateType[];
  readonly exchangeRatesLoading: boolean;
  readonly period: string;
};

type CurrencyCodeMap = { readonly [key: string]: string };

export const EditExchangeRatesForm: FC<EditExchangeRatesFormProps> = ({
  currencies,
  onSubmit: handleSubmit,
  onSubmitFailed: handleSubmitFailed,
  onCancel: handleCancel,
  period,
  onPeriodChange,
  exchangeRates,
  exchangeRatesLoading,
  loading,
  errors
}) => {
  const [form] = Form.useForm<UpdateExchangeRatePayloadType>();
  const [t] = useTranslation();

  const getCurrencyRate = (
    rates: readonly ExchangeRateType[],
    currencyId: number
  ): number =>
    rates.find((rate) => rate.currencyDto.id === currencyId)?.rate ?? 0;

  useEffect(() => {
    form.setFields([
      {
        name: "exchangeRates",
        value: currencies.map((currency) => ({
          currencyId: currency.id,
          rate: getCurrencyRate(exchangeRates, currency.id)
        }))
      },
      { name: "period", value: period }
    ]);
  }, [exchangeRates, period, currencies, form]);

  const initialValues = useMemo(
    () => ({
      period,
      exchangeRates: currencies.map((currency) => ({
        currencyId: currency.id,
        rate: getCurrencyRate(exchangeRates, currency.id)
      }))
    }),
    [currencies, exchangeRates, period]
  );

  const currencyCodeMap: CurrencyCodeMap = useMemo(
    () =>
      currencies.reduce(
        (total, currency) => ({
          ...total,
          [currency.id]: currency.code
        }),
        {}
      ),
    [currencies]
  );

  const handlePeriodChange = (newPeriod: string): void => {
    onPeriodChange(newPeriod);
    form.setFields([{ name: "period", value: newPeriod }]);
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      initialValues={initialValues}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
    >
      <Form.Item name="period" label={t("exchangeRates.date")}>
        <Space>
          <ExchangeRatePeriodPicker
            value={moment(form.getFieldValue("period") || period).format()}
            onChange={handlePeriodChange}
          />
        </Space>
      </Form.Item>
      <Spin spinning={exchangeRatesLoading}>
        <Form.List name="exchangeRates">
          {(fields) =>
            fields.map(({ key, ...field }) => {
              const error = errors?.find(
                (validationError) =>
                  validationError.name ===
                  `exchangeRates[${field.fieldKey}].Rate`
              );
              const hasError =
                error?.description ||
                form.getFieldError(["exchangeRates", key, "rate"]).length > 0;

              return (
                <Form.Item
                  key={[key, "rate"].join("-")}
                  {...field}
                  required
                  label={
                    currencyCodeMap[
                      form.getFieldValue([`exchangeRates`, key, "currencyId"])
                    ]
                  }
                  name={[field.name, "rate"]}
                  fieldKey={[field.fieldKey, "rate"]}
                  rules={[
                    {
                      required: true,
                      message: t("exchangeRates.rateValidationMessage")
                    }
                  ]}
                  validateStatus={hasError ? "error" : ""}
                  help={error?.description}
                >
                  <Input placeholder={t("exchangeRates.rateInput")} />
                </Form.Item>
              );
            })
          }
        </Form.List>
      </Spin>

      <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
        <Space>
          <Button type="default" htmlType="reset" onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("submit")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
