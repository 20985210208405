import i18n from "@i18n/i18n";

const defaultSliceIndex = 20;

export const truncateText = (
  text: string,
  sliceIndex: number = defaultSliceIndex
): string =>
  text.length > sliceIndex && i18n.language === "ru"
    ? `${text.slice(0, sliceIndex)}...`
    : text;
