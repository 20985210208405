import type { FC } from "react";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next/";

import { styled } from "@linaria/react";
import tw from "twin.macro";

import { AppBadge } from "@components/atoms/AppBadge";
import { ForecastsTable } from "@components/pages/admin/Forecasts/libs/components/ForecastsTable";
import { Tabs } from "antd";

import { ForecastApprovalStatus } from "@components/types/models/Forecast";

import { UserContext } from "@contexts/userContext";

import type { ForecastsListTabsProps } from "./props";

const { TabPane } = Tabs;

const TabsBase = styled(Tabs)`
  .ant-tabs-nav {
    &::before {
      ${tw`border-b`}
      border-color: #fafafa;
    }
  }
`;

export const ForecastsListTabs: FC<ForecastsListTabsProps> = ({
  forecastsList,
  isLoading,
  onSetPage,
  sort,
  onSetSort,
  children,
  ...rest
}) => {
  const { t } = useTranslation();
  const { notices } = useContext(UserContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, react-hooks/exhaustive-deps
  const handleSort = useCallback(onSetSort!, [onSetSort]);

  return (
    <TabsBase {...rest}>
      <TabPane key={ForecastApprovalStatus.Undefined} tab={t("all")}>
        <ForecastsTable
          forecastsList={forecastsList?.results}
          isLoading={isLoading}
          pagination={{
            total: forecastsList?.totalItems,
            current: forecastsList?.currentPage,
            pageSize: forecastsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Pending}
        tab={
          <AppBadge count={notices?.forecasts}>
            {t("forecasts.opts.status.pending")}
          </AppBadge>
        }
      >
        <ForecastsTable
          forecastsList={forecastsList?.results}
          isLoading={isLoading}
          pagination={{
            total: forecastsList?.totalItems,
            current: forecastsList?.currentPage,
            pageSize: forecastsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Approved}
        tab={t("forecasts.opts.status.approved")}
      >
        <ForecastsTable
          forecastsList={forecastsList?.results}
          isLoading={isLoading}
          pagination={{
            total: forecastsList?.totalItems,
            current: forecastsList?.currentPage,
            pageSize: forecastsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Rejected}
        tab={t("forecasts.opts.status.rejected")}
      >
        <ForecastsTable
          forecastsList={forecastsList?.results}
          isLoading={isLoading}
          pagination={{
            total: forecastsList?.totalItems,
            current: forecastsList?.currentPage,
            pageSize: forecastsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Overdue}
        tab={t("forecasts.opts.status.overdue")}
      >
        <ForecastsTable
          forecastsList={forecastsList?.results}
          isLoading={isLoading}
          pagination={{
            total: forecastsList?.totalItems,
            current: forecastsList?.currentPage,
            pageSize: forecastsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      {children}
    </TabsBase>
  );
};
