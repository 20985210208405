export const actuals = [
  {
    title: "How to create Actuals?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Your contract was approved by KC Lead</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> There are three types of contracts &ndash; Standalone, Sub-agreement, Master agreement (each with different sections of information to fill in)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Press on &quot;Submit new actuals&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select your Approved contract from the Contract drop-down list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select submission period (Not submitted)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill in all required fields separated by sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/vf40kDbSPozDTANCPNw50hetCTZaVrtyzmri69qpSdhbz2eM_IdNkeH07IsaLtV0NTJ4SvCFFDbC08PLtiDpWURJ6FsrPMGr72AjWUw8SVcjAuj_K3u8BIKHNceIMedIcW83k-z_fVefK8g9L0RI0XAsjdzyQyDthwX9HghLsPpWPcCSg4O1aHrang" style="height:553px; width:593px" /></span></span></span></p>
`
  },
  {
    title: "How to Add Goods, Works, Services?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services section on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+&quot; button next to Goods/Works/Services description field</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter your Good/Work/Service name in the Name field</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find your good/work/service in the Goods/Works/Services description drop-down list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+Add goods/works/services&quot; button if you need to add more</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/PRnMJ-iOoQiq6BAWdpXat-Qd1fnO-eMUueQGgk-vtk9ObL1XoqtKuBMzxno0GP36JLlpVUHIZBVSC3Czf0p27b9I_iEyYhZHNDr4oaQoT1kUKEhNSXSGER9leBuq0cpmg0IAZoXEJ7JXi4FL3HFG3NjD33Sggpiak_-_ko3tAk9-GH769woAcyI5hw" style="height:445px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to delete Goods/Works/Services?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Goods/Works/Services were created</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select and Open DRAFT/REJECTED Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services sections on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find created Good/Work/Service and scroll to the right</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;X&quot; button to delete not needed Goods/Works/Services entries</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/mktUuH2SmqkqR6RMayl9TlUx8dQJ0MkEvNRSbDKCOsxZxTU5xXvhsvYToeFDU0_ewZzH4Htnbvj9v9f-Ddl6M5sUmVk66Tq2CfUAE00LM6dIKnlLgpGD0VWWcN8vG6c1ZVAqlhRXLveblvcGRxl_XD1OWgHraQLR7Nwb0LEVgfih1rKqEyabZSAiWQ" style="height:183px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Add Company?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services section on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+&quot; button next to Company name description field:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter Company name</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Country from drop-down list</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select City from drop-down list</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter BIN (if Kazakhstan is selected as a Country)</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter Details on scope of work</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find your Company name in the drop-down list</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/xhaozFwDaLfEBAHQ8d6UmEdOBv6KrA31_S_YRgW6aE2C4Pg_Yd6RRU5Zw1iJ9kZEoFBciOQgTgh5etqbIfP60hrrlTVMODsuPeIEZ2fgQIv74slOVSCrqucW9tC5x_zL7nulH_76wRyZ8ElTq2lfsrR4J963jDN4FlekLoZ2es2va4VeBfarijVyAA" style="height:435px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to add CT-KZ certificate?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods section on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+&quot; button next to CT-KZ certificate # field:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter Certificate number</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select certificate Expiration date</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Add certificate (You may upload following formats only .pdf .jpg .jpeg .png)</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm Certificate Validity</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select good from Good name drop-down list OR</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Add good to the certificate by clicking on &quot;+&quot; button placed next to good name field</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter CT-KZ% for your added goods</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+Add row&quot; button to add more goods to the certificate</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find your added certificate in the CT-KZ certificate# drop-down list</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/Us5eU-JxinTkxwfPkBhLF1ykgfItBFiupMkUDX2vQsoN_oa1azpEIqPkpFiXIK0JpKdj5Aqih1FpvCAStfd-eDbYz6N5UR7qjnHHFVbyk00AvFRYVkotJm97jTeYQhbpF3Ai16TZfElDww8d7gQM9vVMgMe5lvlBC4ND53CzpxJhkS6Jt28-HqNL9A" style="height:471px; width:597px" /></span></span></span></p>
`
  },
  {
    title: "How to download CT-KZ certificate?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> CT-KZ certificate was added to the system</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select and Open DRAFT/REJECTED/APPROVED/PENDING Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods sections on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find your Good entry and scroll to the right till CT-KZ certificate # field</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;download&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/b3nJgRPUDLhjzxZjq4R7CGRTXkoV38M9gepauqfVaoSnenIZpXd7C1ZeBz7DlnCfEdrXtUPPfLkkMuFQQnlK540C07LqG9asxDLatwOUWbGsO65hg7aiSA12JnQCJn6jRrDvOjEnJUKlzz1_QgKwKBcmRan2fLSKLuzsDyvJnrKtafJInroc6vFDqg" style="height:147px; width:624px" /></span></span></span></p>
`
  },
  {
    title:
      "How to Pre-populate Goods/Works/Services/Jobs and Nationalization data?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Actuals (with filled GWS &amp; Jobs and Nationalization data) for earlier period should be Approved</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services/Jobs and Nationalization sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Pre-populate&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/eiwjRlQumGcjFp22LMz6b7CiNp0VW6QOw239uhqguKq2pBUF5tAGrAVeF2oTBo4V7WnjhD5lCbZl5hwdRRT5lfPSxUNtqunO0mJAjkx_3bHKP3nUmlAzycundFNnKz_XasWZorVE5KYpAK4Vfg9yaL7OlZ2_6eWXE7Sty3d0skUdTwXxJv0_jCnPtw" style="height:85px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to add Jobs and Nationalization data?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Jobs and Nationalization data can be added to Standalone and Master agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Jobs and Nationalization section on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Configure cities&quot; button (more than one city can be added):</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select country from Country drop-down list</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select city from City from drop-down list</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Apply&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Attach file if needed by clicking on &quot;Attach file&quot; button (Attachments up to 3 files)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save and go to total&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/mW-TrWdk232dhVgipdLaW_o5oWiYwhTl9yoStp5HZqP7gp_Uxv6mU9Iu7l0OoJnMiJAB5vXde-8vrUAb3G8s1lpmgxdQRljQtkkmlgqKtZ5fyU-MvgpgMILrSA5BqjgobcGJJwLQ_7ryG-8ReBOs4PEm3HyS52snYfZSv7FeVGDFtqENYO_DcI6CIw" style="height:550px; width:569px" /></span></span></span></p>
`
  },
  {
    title: "How to add Training data?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Training data can be added to Standalone and Master agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Training section on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save and go to the next section&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to add Technology Transfer, Legacy and Success Stories?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note: </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Technology Transfer, Legacy, Success stories can be added to Standalone and Master agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on Add Technology Transfer/Legacy/Success Stories button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand sections on Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter required information into the text box</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Attach file if needed by clicking on &quot;Attach file&quot; button (Attachments up to 3 files)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Delete added sections if not needed by clicking on &quot;Delete section&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save and go to the next section&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/yl4nuXoVtExP9andnecgpuuVBzU8jPbmnQrOBSLzOQpFTBkOllBo_SYltWCbtji_1l9xhjDZoItOUmDMgx9YyDYZ909HpGRSLrF_ZmfHukDrQCGw3Lez2wRqQD_L5Pv0sPiynbd8DYZXZlyEwuHVd6jMIIBE0-QbhLyGnLftitzIzRrC3RnzMUsLSw" style="height:277px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to leave comments in Actuals Sections?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> There are three types of contracts &ndash; Standalone, Sub-agreement, Master agreement (each with different sections of information to fill in)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expands Goods, Works, Services, Jobs and Nationalization, Training, Technology Transfer, Legacy and Success Stories sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Leave comment&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter your comment into the text box</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to submit Actuals?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: There are three types of contracts &ndash; Standalone, Sub-agreement, Master agreement (each with different sections of information to fill in)</span></span></span></p>

<p>&nbsp;</p>

<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Create new Actuals by clicking on &quot;submit new actuals&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields separated by sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to update Approved Actuals?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select APPROVED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update&quot; button on APPROVED Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to Restore Actuals previous version?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select APPROVED actuals from the Actuals list and click on &quot;Update&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT (version &gt; 1) from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Restore previous version&quot; button on DRAFT Actuals detailed page (version &gt;1)</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/rF8QrjmJTvtgPNJ75p5kKn9VFYYAiYrRZKQQo3MT_T1P0hRcY47EnShs9hwoUbvhR2WWJXrX0UNG5_X3RGwigS2HHZzlLVanHMo4Bpvi5wRuzhdk0fcebyqfIkHssEO_OTY9kWWpczpTJU200mS9rBMS2p6aXy_MH8CCwuRwCpOMPvCO_1xjQFTRTA" style="height:109px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to update Rejected Actuals?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Open REJECTED Actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
`
  }
];

export default actuals;
