import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row, Typography } from "antd";

export const Home: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  return (
    <div className="guest-pages-bg">
      <Row justify="center" align="middle" style={{ paddingTop: "20vh" }}>
        <Col span={8}>
          <Title style={{ color: "white" }}>Kazakhstani Content Portal</Title>
        </Col>
      </Row>
    </div>
  );
};
