/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Col, Input, Row, Tooltip } from "antd";
import i18n from "@i18n/i18n";
import type {
  ForecastJobsData,
  ForecastJobsDataItem,
  MouCategorySubCategoryItem
} from "@components/organisms/ForecastJobsForm/props";

import { truncateText } from "@utils/truncate";

import type { AnnualTotal } from "./props";
import type { ForecastJobsPerYearProps } from "./props";

const defaultValue = {
  engineering: {},
  supervisorAndForeman: {},
  administration: {},
  craft: {},
  heavyEquipmentOperator: {},
  total: {}
};

const UNSET_MONTH = 0;
const TOTAL_MOU_CATEGORY = "total";

export const ForecastJobsPerYear: FC<ForecastJobsPerYearProps> = ({
  currentPeriod,
  mouCategoryRowHeaders,
  initialData,
  currentMouCategory,
  mouCategories,
  onSave,
  isEditable,
  refetchJobs
}) => {
  const [periodJobsData, setPeriodJobsData] =
    useState<ForecastJobsData>(defaultValue);
  const [annualData, setAnnualData] = useState<AnnualTotal>({});

  const { t } = useTranslation();

  const sliceCount = 20;
  const jobsPositionRowHeadersIndex = 4;
  const graduateSectionRowHeadersIndex = -2;
  const jobsPositionRowHeaders = mouCategoryRowHeaders.slice(
    0,
    jobsPositionRowHeadersIndex
  );
  const graduateSectionRowHeaders = mouCategoryRowHeaders.slice(
    graduateSectionRowHeadersIndex
  );

  const isAnnualMouCategory = currentMouCategory === TOTAL_MOU_CATEGORY;

  const setJobsData = (): void => {
    // TODO
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputJobsData: any = {};

    mouCategories?.forEach((mouCategory) => {
      const currentPeriodData = initialData
        ?.filter(
          (item: ForecastJobsDataItem) =>
            item.year === currentPeriod?.year &&
            item.month === UNSET_MONTH &&
            item.mouCategory === mouCategory.value
        )
        ?.reduce((acc: Record<string, number>, currentValue) => {
          Object.entries(currentValue).forEach(
            ([key, value]) =>
              (acc[key] = acc[key] ? acc[key] + Number(value) : Number(value))
          );

          return acc;
        }, {});

      outputJobsData[mouCategory.title] = {
        ...outputJobsData[mouCategory.title],
        [currentPeriod.year]: {
          year: currentPeriod.year,
          kzNationals:
            typeof currentPeriodData?.kzNationals === "number"
              ? currentPeriodData.kzNationals
              : "",
          nonKzNationals:
            typeof currentPeriodData?.nonKzNationals === "number"
              ? currentPeriodData.nonKzNationals
              : "",
          plannedToNationalize:
            typeof currentPeriodData?.plannedToNationalize === "number"
              ? currentPeriodData.plannedToNationalize
              : "",
          newGradInternships:
            typeof currentPeriodData?.newGradInternships === "number"
              ? currentPeriodData.newGradInternships
              : "",
          newGradJobs:
            typeof currentPeriodData?.newGradJobs === "number"
              ? currentPeriodData.newGradJobs
              : "",
          nationalizationPositions: currentPeriodData?.nationalizationPositions
            ? currentPeriodData.nationalizationPositions
            : [],
          mouCategory: mouCategory.value
        }
      };
    });

    setPeriodJobsData(outputJobsData);
    refetchJobs();
  };

  const setJobsValue = ({
    value,
    headerTitle,
    year
  }: {
    readonly value: string;
    readonly headerTitle: string;
    readonly year: number;
  }): void => {
    const updatedList = { ...periodJobsData };

    updatedList[currentMouCategory] = {
      ...updatedList[currentMouCategory],
      [year]: {
        ...updatedList[currentMouCategory][year],
        [headerTitle]: value
      }
    };

    setPeriodJobsData(updatedList);
  };

  const setAnnualTotal = (): void => {
    const filteredRows: readonly MouCategorySubCategoryItem[] =
      mouCategoryRowHeaders.filter((item) => item.title !== TOTAL_MOU_CATEGORY);

    // TODO
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const annualTotal: any = {};

    mouCategories?.forEach((mouCategory) => {
      filteredRows.forEach((itemRow) => {
        annualTotal[currentPeriod.year] = {
          ...annualTotal[currentPeriod.year],
          [itemRow.title]:
            Number(annualTotal?.[currentPeriod.year]?.[itemRow.title] || 0) +
            Number(
              periodJobsData?.[mouCategory.title]?.[currentPeriod.year]?.[
                itemRow.title
              ] || 0
            )
        };
      });
    });

    setAnnualData(annualTotal);
  };

  const getTotalPositionsTotal = (year: number): number => {
    const currentMonth = isAnnualMouCategory
      ? annualData[year]
      : periodJobsData[currentMouCategory]?.[year];

    return (
      Number(currentMonth?.kzNationals) + Number(currentMonth?.nonKzNationals)
    );
  };

  const handleOnSave = useCallback(
    (jobsData: ForecastJobsData) => () => {
      onSave(jobsData);
    },
    [onSave]
  );

  const handleSetJobsValue = useCallback(
    (payload: { readonly headerTitle: string; readonly year: number }) =>
      (event: React.FormEvent<HTMLInputElement>) => {
        const outputValue = {
          ...payload,
          value: event.currentTarget.value
        };

        setJobsValue(outputValue);
      },
    [setJobsValue]
  );

  const saveText = useMemo(() => {
    if (!isEditable) {
      if (currentMouCategory === "heavyEquipmentOperator") {
        return t("forecasts.proceedAndGoToTheNextSection");
      }
      return t("forecasts.proceedAndGoToTheNextCategory");
    }

    if (currentMouCategory === "heavyEquipmentOperator") {
      return t("forecasts.saveAndGoToTheNextSection");
    }

    return t("forecasts.saveAndGoToTheNextCategory");
  }, [currentMouCategory, i18n.language]);

  useEffect(() => {
    setJobsData();
  }, [currentPeriod?.year, initialData]);

  useEffect(() => {
    if (isAnnualMouCategory) {
      setAnnualTotal();
    }
  }, [periodJobsData]);

  useEffect(() => {
    setJobsData();
  }, [currentMouCategory]);

  return (
    <Row
      className={css`
        ${tw`space-y-4`}
      `}
    >
      <Col span={24}>
        <Row>
          <Col
            span={4}
            className={css`
              ${tw`font-bold`}
            `}
          >
            {t("forecasts.jobsPositions")}
          </Col>
          <Col span={20}>
            <Row
              className={css`
                ${tw`space-x-6`}
              `}
            >
              <div
                key={currentPeriod?.year}
                className={css`
                  ${tw`flex-1 flex justify-center font-bold capitalize`}
                  min-width: 50px;
                  max-width: 50px;
                  color: #9e9e9e;
                `}
              >
                {currentPeriod?.year}
              </div>
            </Row>
          </Col>
        </Row>

        <Row
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {jobsPositionRowHeaders.map(
            (header: MouCategorySubCategoryItem, index: number) => (
              <React.Fragment key={index}>
                <Col
                  span={4}
                  className={css`
                    ${tw`flex items-center`}
                  `}
                >
                  <Tooltip title={header.text}>
                    {truncateText(header.text ?? "", sliceCount)}
                  </Tooltip>
                </Col>
                <Col span={20}>
                  <Row
                    className={css`
                      ${tw`space-x-6`}
                    `}
                  >
                    <div
                      key={currentPeriod?.year}
                      className={css`
                        ${tw`flex-1 flex justify-center items-center`}
                        min-width: 50px;
                        max-width: 50px;
                      `}
                    >
                      <Row>
                        {header.title === TOTAL_MOU_CATEGORY ? (
                          <span>
                            {getTotalPositionsTotal(
                              Number(currentPeriod?.year)
                            )}
                          </span>
                        ) : (
                          <Input
                            type="number"
                            className={css`
                              ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                              width: 100%;
                              border: 1px solid #e2e2e2;
                            `}
                            value={
                              isAnnualMouCategory
                                ? annualData?.[currentPeriod.year]?.[
                                    header.title
                                  ]
                                : periodJobsData?.[currentMouCategory]?.[
                                    currentPeriod?.year
                                  ]?.[header.title]
                            }
                            disabled={isAnnualMouCategory || !isEditable}
                            onChange={handleSetJobsValue({
                              headerTitle: header.title ?? "",
                              year: Number(currentPeriod.year)
                            })}
                          />
                        )}
                      </Row>
                    </div>
                  </Row>
                </Col>
              </React.Fragment>
            )
          )}
        </Row>
      </Col>

      <Col span={24}>
        <Row>
          <Col
            span={4}
            className={css`
              ${tw`font-bold`}
            `}
          >
            {t("forecasts.graduateSection")}
          </Col>
          <Col span={20}>
            <Row
              className={css`
                ${tw`space-x-6`}
              `}
            >
              <div
                key={currentPeriod.year}
                className={css`
                  ${tw`flex-1 flex justify-center font-bold capitalize`}
                  min-width: 50px;
                  max-width: 50px;
                  color: #9e9e9e;
                `}
              >
                {Number(currentPeriod.year)}
              </div>
            </Row>
          </Col>
        </Row>

        <Row
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {graduateSectionRowHeaders.map(
            (header: MouCategorySubCategoryItem, index: number) => (
              <React.Fragment key={index}>
                <Col
                  span={4}
                  className={css`
                    ${tw`flex items-center`}
                  `}
                >
                  <Tooltip title={header.text}>
                    {truncateText(header.text ?? "", sliceCount)}
                  </Tooltip>
                </Col>
                <Col span={20}>
                  <Row
                    className={css`
                      ${tw`space-x-6`}
                    `}
                  >
                    <div
                      key={Number(currentPeriod.year)}
                      className={css`
                        ${tw`flex-1 flex justify-center items-center`}
                        min-width: 50px;
                        max-width: 50px;
                      `}
                    >
                      {header.title === TOTAL_MOU_CATEGORY ? (
                        <span>
                          {getTotalPositionsTotal(Number(currentPeriod.year))}
                        </span>
                      ) : (
                        <Input
                          type="number"
                          className={css`
                            ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                            width: 100%;
                            border: 1px solid #e2e2e2;
                          `}
                          value={
                            isAnnualMouCategory
                              ? annualData?.[currentPeriod.year]?.[header.title]
                              : periodJobsData?.[currentMouCategory]?.[
                                  Number(currentPeriod.year)
                                ]?.[header?.title]
                          }
                          disabled={!isEditable}
                          onChange={handleSetJobsValue({
                            headerTitle: header.title ?? "",
                            year: Number(currentPeriod.year)
                          })}
                        />
                      )}
                    </div>
                  </Row>
                </Col>
              </React.Fragment>
            )
          )}
        </Row>
      </Col>
      <Col
        span={24}
        className={css`
          ${tw`mt-4 flex justify-end`}
        `}
      >
        <Button
          className="secondary-button"
          onClick={handleOnSave(periodJobsData)}
        >
          {saveText}
        </Button>
      </Col>
    </Row>
  );
};
