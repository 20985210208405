export const ruReports = {
  reports: "Отчеты",
  dashboards: "Дэшборды",
  datadump: "Скачать исходные данные",
  datadumpTitle: "Все отчеты",
  datadumpSubtitle: "Скачать исходные данные",
  datadumpName: "Название файла",
  datadumpDate: "Дата создания",
  // DatadumpDate: "Дата создания",
  datadumpGenerate: "Сгенерировать файл с исходными данными",
  datadumpGenerateError:
    "При генерации файла возникла ошибка, пожалуйста попробуйте ещё раз",
  status: "Статус",
  datadumpSuccess: "Генерация датадампа может длиться до 1 часа",
  datadumpAlert:
    "Файлы с исходными данными содержат только рассмотренные и утвержденные данные",
  countriesAndCities: "Страны и города",
  countryInEng: "Страна на английском",
  countryInRus: "Страна на русском",
  cityInEng: "Город на английском",
  cityInRus: "Город на русском",
  region: "Область"
};
