import type { FC } from "react";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { differenceInHours, parseISO } from "date-fns";

import {
  DeleteOutlined,
  EditOutlined,
  PoweroffOutlined,
  RedoOutlined
} from "@ant-design/icons";
import { Button, Col, Popconfirm, Space, Tooltip } from "antd";

import type { AdminMember } from "@components/types/models/AdminMember";
import type { Member } from "@components/types/models/Member";
import { MemberStatus } from "@components/types/models/Member";

import { UserContext } from "@contexts/userContext";

import type { ContractorMembersTableProps } from "./props";
import { MemberTable } from "./Table";

export const ContractorMembersTable: FC<ContractorMembersTableProps> = ({
  membersList,
  isMembersListLoading,
  deleteMemberMutation,
  resendInviteMutation,
  deleteMemberInviteMutation,
  onEditClick,
  disableActionButton,
  activateMemberMutation,
  contractorName
}) => {
  const { user } = useContext(UserContext);

  const { t } = useTranslation();
  const activeMembers = membersList.filter(
    (member: AdminMember) => member.status === MemberStatus.Active
  );

  const inactiveMembers = membersList.filter(
    (member: AdminMember) => member.status === MemberStatus.Inactive
  );

  const invitedMembers = membersList.filter(
    (member: AdminMember) => member.status === MemberStatus.Invited
  );

  const handleActivateMember = useCallback(
    (am: AdminMember) => () => {
      activateMemberMutation.mutate(am.userId);
    },
    [activateMemberMutation]
  );

  const handleDeleteInvite = useCallback(
    (email) => () => {
      deleteMemberInviteMutation.mutate(email);
    },
    [deleteMemberInviteMutation]
  );

  const invitationExpirationDeadline = 24;

  const handleDeleteMember = useCallback(
    (userId: number) => () => {
      deleteMemberMutation.mutate(userId);
    },
    [deleteMemberMutation]
  );

  const handleResendInvite = useCallback(
    (email: string) => () => {
      resendInviteMutation.mutate(email);
    },
    [resendInviteMutation]
  );

  const handleEditClick = useCallback(
    (member: Member) => {
      onEditClick?.(member);
    },
    [onEditClick]
  );

  return (
    <Col span={24}>
      <MemberTable
        title={t("members.activeMembers")}
        subtitle={`${t("members.membersSum")}: ${activeMembers.length}`}
        members={activeMembers}
        isLoading={isMembersListLoading}
        rowKey="userId"
        renderActions={(am: AdminMember) => (
          <Space size="middle">
            <Button
              title={t("edit")}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              disabled={user?.id === am.userId || disableActionButton}
              // eslint-disable-next-line react/jsx-handler-names
              onClick={() => {
                handleEditClick(am);
              }}
            />

            <Popconfirm
              title={t("members.confirmContractorMemberDelete", {
                contractorName,
                memberName: am.firstName
              })}
              cancelText={t("cancel")}
              okText={t("yes")}
              placement="topLeft"
              disabled={
                deleteMemberMutation.isLoading ||
                user?.id === am.userId ||
                disableActionButton
              }
              onConfirm={handleDeleteMember(am.userId)}
            >
              <Button
                danger
                title={t("delete")}
                shape="circle"
                disabled={user?.id === am.userId || disableActionButton}
                loading={
                  deleteMemberMutation.isLoading &&
                  deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />
      <MemberTable
        title={t("members.inactiveMembers")}
        subtitle={`${t("members.membersSum")}: ${inactiveMembers.length}`}
        members={inactiveMembers}
        isLoading={isMembersListLoading}
        rowKey="userId"
        renderActions={(am) => (
          <Space size="middle">
            <Button
              title={t("members.activate")}
              type="primary"
              shape="circle"
              icon={<PoweroffOutlined />}
              loading={false}
              disabled={disableActionButton}
              onClick={handleActivateMember(am)}
            />

            <Popconfirm
              title={t("members.confirmContractorMemberDelete", {
                contractorName,
                memberName: am.firstName
              })}
              placement="topLeft"
              cancelText={t("cancel")}
              okText={t("yes")}
              disabled={deleteMemberMutation.isLoading || disableActionButton}
              onConfirm={handleDeleteMember(am.userId)}
            >
              <Button
                danger
                title={t("delete")}
                shape="circle"
                disabled={deleteMemberMutation.isLoading || disableActionButton}
                loading={
                  deleteMemberMutation.isLoading &&
                  deleteMemberMutation.variables === am.userId
                }
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        )}
      />
      <MemberTable
        title={t("members.invitedMembers")}
        subtitle={`${t("members.membersSum")}: ${invitedMembers.length}`}
        members={invitedMembers}
        isLoading={isMembersListLoading}
        rowKey="userId"
        renderActions={(am) => {
          const inviteDate = parseISO(am.invitedAt);
          const hourDiff = differenceInHours(Date.now(), inviteDate);
          const isInviteExpired = hourDiff >= invitationExpirationDeadline;
          const tooltipTitle = isInviteExpired
            ? t("members.resendPossible")
            : t("members.previousInviteNotExpired");

          return (
            <Space size="middle">
              <Tooltip title={disableActionButton ? null : tooltipTitle}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<RedoOutlined />}
                  disabled={
                    !isInviteExpired ||
                    resendInviteMutation.isLoading ||
                    disableActionButton
                  }
                  loading={
                    resendInviteMutation.isLoading &&
                    resendInviteMutation.variables === am.email
                  }
                  onClick={handleResendInvite(am.email)}
                />
              </Tooltip>

              <Popconfirm
                title={`${t("members.confirmDeleteInvite")} ${am.email} ?`}
                cancelText={t("cancel")}
                okText={t("yes")}
                disabled={
                  deleteMemberInviteMutation.isLoading || disableActionButton
                }
                onConfirm={handleDeleteInvite(am.email)}
              >
                <Button
                  danger
                  title={t("delete")}
                  shape="circle"
                  disabled={
                    deleteMemberInviteMutation.isLoading || disableActionButton
                  }
                  loading={
                    deleteMemberInviteMutation.isLoading &&
                    deleteMemberInviteMutation.variables === am.email
                  }
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        }}
      />
    </Col>
  );
};
