import { useCallback, useRef } from "react";

const timeoutAmount = 1000;
type IdleCheckerProps = {
  readonly idleTimeOutSeconds: number;
  readonly onTimeOut: () => void;
};

function setIdleCheck({ idleTimeOutSeconds, onTimeOut }: IdleCheckerProps) {
  return () => {
    const idleStartTime = localStorage.getItem("KC_PORTAL_IDLE_START_TIME");
    const elapsedIdleTimeSeconds = Math.round(
      (Date.now() - Number(idleStartTime)) / timeoutAmount
    );
    if (elapsedIdleTimeSeconds > idleTimeOutSeconds) {
      onTimeOut();
      localStorage.removeItem("KC_PORTAL_IDLE_START_TIME");
    }
  };
}

const idleReset = (): void => {
  localStorage.setItem("KC_PORTAL_IDLE_START_TIME", Date.now().toString());
};

type UseIdleCheckerProps = IdleCheckerProps & {
  readonly checkEvery: number;
};
export const useIdleChecker = ({
  checkEvery,
  ...idleCheckerProps
}: UseIdleCheckerProps): {
  readonly subscribeToIdleChecker: () => () => void;
} => {
  const isIdleReseting = useRef<boolean>(false);
  const timeout = useRef<number | undefined>();

  const tryIdleReset = useCallback(() => {
    if (!isIdleReseting.current) {
      isIdleReseting.current = true;

      timeout.current = window.setTimeout(() => {
        idleReset();
        isIdleReseting.current = false;
      }, timeoutAmount);
    }
  }, []);

  return {
    subscribeToIdleChecker: () => {
      idleReset();

      const idleCheckerId = window.setInterval(
        setIdleCheck(idleCheckerProps),
        checkEvery
      );

      window.addEventListener("mousemove", tryIdleReset);
      window.addEventListener("mousedown", tryIdleReset);
      window.addEventListener("keypress", tryIdleReset);
      window.addEventListener("scroll", tryIdleReset);

      return () => {
        window.clearInterval(idleCheckerId);
        window.clearTimeout(timeout.current);

        window.removeEventListener("mousemove", tryIdleReset);
        window.removeEventListener("mousedown", tryIdleReset);
        window.removeEventListener("keypress", tryIdleReset);
        window.removeEventListener("scroll", tryIdleReset);
      };
    }
  };
};
