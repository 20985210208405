export const ruUserguides = {
  user_guide: "Руководство пользователя",
  general: "Общее",
  contractors: "Подрядчики",
  contracts: "Контракты",
  forecasts: "Прогнозы",
  actuals: "Фактические показатели",
  forecasts_general: "General forecasts help",
  forecasts_create: "Forecasts creation",
  forecasts_fillout: "Forecasts fillout",
  g1c: "G1-C Формы",
  dashboards: "Дашборды"
};
