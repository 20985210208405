import axios from "axios";

import type { PowerBiParams } from "@components/types/models/PowerBiParams";

const getPowerBiParams = async (
  workspaceId: string,
  reportId: string
): Promise<PowerBiParams> =>
  axios
    .get<PowerBiParams>(
      `api/power-bi-reports/${workspaceId}/report/${reportId}`
    )
    .then((res) => res.data);

const getTcoPowerBiDashboard = async (): Promise<PowerBiParams> =>
  axios
    .get<PowerBiParams>(`api/power-bi-reports/for-tco`)
    .then((res) => res.data);

const getContractorPowerBiDashboard = async (): Promise<PowerBiParams> =>
  axios
    .get<PowerBiParams>(`api/power-bi-reports/for-contractor`)
    .then((res) => res.data);

export {
  getPowerBiParams,
  getTcoPowerBiDashboard,
  getContractorPowerBiDashboard
};
