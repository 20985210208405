import type { FC } from "react";
import { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router";

import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import type { Moment } from "moment";
import moment from "moment";
import tw from "twin.macro";

import { CommentCard } from "@components/organisms/CommentCard";
import { InvoiceCard } from "@components/organisms/InvoiceCard";
import { InvoiceGwsForm } from "@components/organisms/InvoiceGwsForm";
import { InvoicePreviewModal } from "@components/organisms/InvoicePreviewModal";
import { useDefaultQuery } from "@hooks";
import { Button, Card, Col, DatePicker, message, Row, Tabs } from "antd";

import { SCOPE_OF_WORK } from "@components/types/models/Contract";
import { SectionType } from "@components/types/models/Forecast";
import { STATUS } from "@components/types/models/Statuses";

import { invoiceApi } from "@api/invoiceApi";

import { UserRoles } from "@contexts/types/UserRoles";
import { UserContext } from "@contexts/userContext";

type PageParams = {
  readonly id: string;
};
export const InvoiceView: FC = () => {
  const { id } = useParams<PageParams>();
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const defaultSubmissionDate = 3;
  const { userRole } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(
    SCOPE_OF_WORK.GOODS | SCOPE_OF_WORK.WORKS
  );
  const [dueDate, setDueDate] = useState<Moment>(
    moment(new Date(), "YYYY-MM-DD").add(defaultSubmissionDate, "days")
  );
  const disableDate = (current: Moment): boolean =>
    current < moment().endOf("day");
  const { setError } = useForm();

  const {
    data: invoiceData,
    refetch: refetchInvoiceData,
    isLoading: isInvoiceDataLoading
  } = useDefaultQuery(
    ["getInvoice", id],
    async () => invoiceApi.getInvoiceById(id).then((res) => res.data),
    (response) => {
      const [firstScope] = response.contract.scopes;

      setActiveTab(firstScope);
    }
  );

  const isEditable =
    invoiceData?.status !== STATUS.PENDING &&
    invoiceData?.status !== STATUS.APPROVED &&
    userRole !== UserRoles.Admin;

  const onProceed = (): void => {
    if (
      activeTab === SCOPE_OF_WORK.GOODS &&
      invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.WORKS)
    ) {
      setActiveTab(SCOPE_OF_WORK.WORKS);
      return;
    }
    setActiveTab(SCOPE_OF_WORK.SERVICES);

    if (activeTab === SCOPE_OF_WORK.WORKS) {
      setActiveTab(SCOPE_OF_WORK.SERVICES);
    }
  };
  const handleTabChange = (activeKey: string): void => {
    setActiveTab(Number(activeKey));
  };

  const handleDueDateChange = useCallback((date) => {
    setDueDate(date);
  }, []);

  const { mutate: submitInvoiceMutation, isLoading: isSubmitLoading } =
    useMutation(async () => invoiceApi.submitInvoice(id, dueDate.toJSON()), {
      onSuccess() {
        void message.success("G1-C form successfully submitted");
        setIsModalVisible(false);
        void refetchInvoiceData();
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              void message.error(error.description);
            }
          );
        } else {
          setError("error", { message: errData.message });
        }
      }
    });

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };
  const onModalClose = (): void => {
    setIsModalVisible(false);
  };

  return (
    <>
      <InvoiceCard
        invoiceData={invoiceData}
        refetchInvoiceData={refetchInvoiceData}
        isInvoiceDataLoading={isInvoiceDataLoading}
        isInvoiceEditable={isEditable}
        cancelUrl="/contractor/payment-application/all"
        onPreviewInvoicePdf={handleOpenModal}
      />
      <Card
        className={css`
          ${tw`mt-8`}
        `}
        loading={isInvoiceDataLoading}
      >
        <Tabs activeKey={`${activeTab}`} onChange={handleTabChange}>
          {invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.GOODS) && (
            <TabPane key={SCOPE_OF_WORK.GOODS} tab={t("contracts.goods")}>
              <InvoiceGwsForm
                getGwsNamesListUrl={`/api/actual/gws/good-positions/${invoiceData.contractId}`}
                gwsColumnTitle={t("invoices.goodName")}
                addRowButtonTitle={t("forecasts.addGoods")}
                kcShareColumnTitle={t("forecasts.CTKZ")}
                addGwsMutationUrl={`/api/invoices/${id}/goods`}
                submissionSuccessMessage={t("invoices.goodsSectionUpdated")}
                handleProceed={onProceed}
                getGwsListUrl={`/api/invoices/${id}/goods`}
                activeTab={activeTab}
                invoiceStartDate={invoiceData.startDate}
                invoiceEndDate={invoiceData.endDate}
                isEditable={isEditable}
                currency={
                  invoiceData.contract.lastApprovedAmendment.currency.code
                }
              />
              <CommentCard
                isInvoice
                id={Number(id)}
                status={invoiceData.status}
                sectionType={SectionType.Good}
              />
            </TabPane>
          )}
          {invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.WORKS) && (
            <TabPane key={SCOPE_OF_WORK.WORKS} tab={t("contracts.works")}>
              <InvoiceGwsForm
                getGwsNamesListUrl={`/api/actual/gws/work-positions/${invoiceData.contractId}`}
                gwsColumnTitle={t("invoices.workName")}
                addRowButtonTitle={t("forecasts.addWork")}
                kcShareColumnTitle={t("forecasts.staffPercent")}
                addGwsMutationUrl={`/api/invoices/${id}/works`}
                submissionSuccessMessage={t("invoices.worksSectionUpdated")}
                handleProceed={onProceed}
                getGwsListUrl={`/api/invoices/${id}/works`}
                activeTab={activeTab}
                invoiceStartDate={invoiceData.startDate}
                invoiceEndDate={invoiceData.endDate}
                isEditable={isEditable}
                currency={
                  invoiceData.contract.lastApprovedAmendment.currency.code
                }
              />
              <CommentCard
                isInvoice
                id={Number(id)}
                status={invoiceData.status}
                sectionType={SectionType.Work}
              />
            </TabPane>
          )}
          {invoiceData?.contract.scopes.includes(SCOPE_OF_WORK.SERVICES) && (
            <TabPane key={SCOPE_OF_WORK.SERVICES} tab={t("contracts.services")}>
              <InvoiceGwsForm
                getGwsNamesListUrl={`/api/actual/gws/service-positions/${invoiceData.contractId}`}
                gwsColumnTitle={t("invoices.serviceName")}
                addRowButtonTitle={t("forecasts.addService")}
                kcShareColumnTitle={t("forecasts.staffPercent")}
                addGwsMutationUrl={`/api/invoices/${id}/services`}
                submissionSuccessMessage={t("invoices.servicesSectionUpdated")}
                handleProceed={onProceed}
                getGwsListUrl={`/api/invoices/${id}/services`}
                activeTab={activeTab}
                invoiceStartDate={invoiceData.startDate}
                invoiceEndDate={invoiceData.endDate}
                isEditable={isEditable}
                currency={
                  invoiceData.contract.lastApprovedAmendment.currency.code
                }
              />
              <CommentCard
                isInvoice
                id={Number(id)}
                status={invoiceData.status}
                sectionType={SectionType.Service}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>

      {isEditable && (
        <Row style={{ marginTop: "24px" }} justify="space-between">
          <Col span={4}>
            <DatePicker
              showNow={false}
              placeholder={t("forecasts.dueDate")}
              size="large"
              value={dueDate}
              style={{ width: "100%" }}
              disabledDate={disableDate}
              format="YYYY-MM-DD"
              onChange={handleDueDateChange}
            />
          </Col>
          <Button type="primary" onClick={handleOpenModal}>
            {t("invoices.preview")}
          </Button>
        </Row>
      )}

      <InvoicePreviewModal
        invoiceId={id}
        handleSubmitClick={submitInvoiceMutation}
        isModalVisible={isModalVisible}
        isSubmitLoading={isSubmitLoading}
        handleModalClick={onModalClose}
        invoiceName={invoiceData?.identityNumber}
      />
    </>
  );
};
