import type { FC } from "react";
import { useContext } from "react";

import { ContractsTable } from "@components/organisms/ContractsTable";
import { useDefaultQuery } from "@hooks";

import { contractApi } from "@api/contractApi";

import { UserContext } from "@contexts/userContext";

export const ContractsList: FC = () => {
  const { user } = useContext(UserContext);

  const {
    isLoading: isContractsListLoading,
    data: contractsList,
    refetch
  } = useDefaultQuery("getContractsList", async () =>
    contractApi
      .getContractsList(Number(user?.userRoles[0].entityId))
      .then((res) => res.data)
  );

  return (
    <ContractsTable
      isLoading={isContractsListLoading}
      refetch={refetch}
      contractsList={contractsList || []}
      linkToContract="/contractor/contract/"
    />
  );
};
