import type { FC } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { ErrorMessage } from "@hookform/error-message";
import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import axios from "axios";
import tw from "twin.macro";

import {
  ApartmentOutlined,
  LaptopOutlined,
  MailOutlined,
  NotificationOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined
} from "@ant-design/icons";
import { TagRounded } from "@components/atoms/TagRounded";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  notification,
  Row,
  Typography
} from "antd";

import { HttpResponseStatusCodes } from "@components/types/HttpResponseStatusCodes";
import { USER_ROLES } from "@components/types/models/Roles";
import type { User } from "@components/types/models/User";

import { defineUserRole, UserContext } from "@contexts/userContext";

const { Text } = Typography;

export const Profile: FC = () => {
  const { t } = useTranslation();
  const { user, userRole, setUser, isAdmin } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    handleSubmit,
    setError,
    formState: { errors },
    register,
    reset,
    clearErrors
  } = useForm();

  const handleCloseModal = (): void => {
    reset();
    setIsModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("KC_PORTAL_USER_TOKEN");
    if (token) {
      void axios.get<User>("/api/auth/me").then((res) => {
        setUser(res.data);
      });
    }
  }, [setUser]);

  const mutation = useMutation(
    async (values: unknown) =>
      axios.post("/api/auth/change-password", values).then((res) => res.data),
    {
      onSuccess: () => {
        handleCloseModal();
        notification.success({
          message: t("profile.passwordChangeSuccess")
        });
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.status === HttpResponseStatusCodes.BadRequest) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: "email" | "password";
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        }
        setError("errorMessage", { message: errData.message });
      }
    }
  );

  const handleModalOkClick = useCallback(() => {
    clearErrors();
    void handleSubmit((values): void => {
      mutation.mutate(values);
    })();
  }, [handleSubmit, clearErrors, mutation]);

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };
  const userName = `${user?.firstName} ${user?.lastName}(${userRole})`;

  return (
    <>
      <Card>
        <Row
          className={css`
            ${tw`my-6`}
          `}
        >
          <Col
            span={12}
            className={css`
              ${tw`text-left`}
            `}
          >
            <Card.Meta
              title={t("profile.accountInfo")}
              description={t("profile.personalInformation")}
            />
          </Col>
          <Col
            span={12}
            className={css`
              ${tw`text-right`}
            `}
          >
            {!user?.userRoles
              .map(({ role }) => role)
              .includes(USER_ROLES.ADMINISTRATOR) && (
              <Button type="primary" onClick={handleOpenModal}>
                {t("profile.changePassword")}
              </Button>
            )}
          </Col>
        </Row>
        <List itemLayout="vertical">
          <List.Item>
            <List.Item.Meta
              avatar={<UserOutlined />}
              description={userName}
              title={t("profile.name")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<MailOutlined />}
              description={user?.email}
              title={t("email")}
            />
          </List.Item>

          <List.Item>
            <List.Item.Meta
              avatar={<LaptopOutlined />}
              description={user?.position}
              title={t("members.jobTitle")}
            />
          </List.Item>
          {isAdmin && (
            <>
              <List.Item>
                <List.Item.Meta
                  avatar={<TeamOutlined />}
                  title={t("profile.role")}
                  description={user?.userRoles.map((role, index: number) => (
                    <Text key={index} type="secondary">
                      {t(`${defineUserRole(role.role)}`)}
                    </Text>
                  ))}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<PieChartOutlined />}
                  title={t("members.categoryAndArea")}
                  description={user?.kcCategories.map(
                    (category, index: number) => (
                      <TagRounded key={index}>{category.name}</TagRounded>
                    )
                  )}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<ApartmentOutlined />}
                  title={t("accessLevel")}
                  description={user?.permissions.map((permission, index) => (
                    // eslint-disable-next-line @typescript-eslint/no-base-to-string
                    <TagRounded key={index}>
                      {t(`permissions.${permission}`)}
                    </TagRounded>
                  ))}
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<NotificationOutlined />}
                  description={user?.receiveNotification ? t("yes") : t("no")}
                  title={t("notifications")}
                />
              </List.Item>
            </>
          )}
        </List>
      </Card>
      <Modal
        visible={isModalOpen}
        title={t("profile.changePassword")}
        cancelText={t("cancel")}
        okText={t("profile.change")}
        confirmLoading={mutation.isLoading}
        onOk={handleModalOkClick}
        onCancel={handleCloseModal}
        closable={false}
      >
        <Form>
          <ErrorMessage
            errors={errors}
            name="errorMessage"
            render={({ message }) => <Text type="danger">{message}</Text>}
          />
          <Form.Item
            label={t("profile.currentPassword")}
            labelCol={{ span: 24 }}
            validateStatus={errors.oldPassword?.message && "error"}
            help={errors.oldPassword?.message}
          >
            <Input.Password {...register("oldPassword")} />
          </Form.Item>

          <Form.Item
            label={t("profile.newPassword")}
            labelCol={{ span: 24 }}
            validateStatus={errors.newPassword?.message && "error"}
            help={errors.newPassword?.message}
          >
            <Input.Password {...register("newPassword")} />
          </Form.Item>
          <Form.Item
            label={t("profile.confirmNewPassword")}
            labelCol={{ span: 24 }}
            validateStatus={errors.newPasswordConfirmation?.message && "error"}
            help={errors.newPasswordConfirmation?.message}
          >
            <Input.Password {...register("newPasswordConfirmation")} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
