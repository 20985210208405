import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { Button, Col, Form, Input, Row, Space } from "antd";

import type { FilterSubcontractorFormProps } from "./props";

export const FilterSubcontractorForm: FC<FilterSubcontractorFormProps> = ({
  isLoading,
  handleFilters,
  clearFilters
}) => {
  const { t } = useTranslation();

  const {
    formState: { errors },
    setValue,
    getValues,
    watch,
    clearErrors,
    reset
  } = useForm();

  const handleFormSubmit = () => {
    const values = getValues() as { bin?: string; name?: string };
    clearErrors();
    handleFilters(values);
  };

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement>,
    name: string
  ) => {
    setValue(name, e.currentTarget.value);
  };

  const handleResetForm = () => {
    reset();
    clearFilters();
  };

  return (
    <Row gutter={8}>
      <Form
        layout="inline"
        style={{ width: "100%" }}
        onFinish={handleFormSubmit}
        onReset={handleResetForm}
      >
        <Col span={10}>
          <Form.Item
            label={t("subcontractors.subcontractor")}
            validateStatus={errors.name && "error"}
            help={errors.name?.message}
          >
            <Input
              value={watch("name")}
              onChange={(evt) => handleInputChange(evt, "name")}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={t("bin")}
            validateStatus={errors.bin && "error"}
            help={errors.bin?.message}
          >
            <Input
              value={watch("bin")}
              maxLength={12}
              onChange={(evt) => handleInputChange(evt, "bin")}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Space size={24}>
            <Button type="primary" loading={isLoading} htmlType="submit">
              {t("applyFilters")}
            </Button>
            <Button loading={isLoading} htmlType="reset">
              {t("clear")}
            </Button>
          </Space>
        </Col>
      </Form>
    </Row>
  );
};
