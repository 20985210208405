export const gettingStarted = [
  {
    title: "Как получить доступ к информации учетной записи?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Войдите в систему</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на метку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>KC PORTAL</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в левом углу горизонтального меню ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на свое </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>ИМЯ</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в правом углу горизонтального меню</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/Jq1S-lr1bF0deAVVItha0BDcqarhLMde11RtD4r7yLpbM-QnDLUUiC-BK6nUYX_dOsi5-uYH_KVVm11UFgs-HYXJDf5sLzxqCIfg-c0eti-at81uwXY6iRYfgmU8kWSOZlUYE7db5OC1VB1Pm_tiFOqtNC_7VSrgBfULPvmdnvJoteLaDiWhcJE_zA" style="height:108px; width:624px" /></span></span></span></p>`
  },
  {
    title: "Как пригласить сотрудников в систему?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите кнопку &quot;Пригласить сотрудника&quot; на странице Сотрудники</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все обязательные поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите кнопку &quot;Пригласить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/zdZOzftIBWQ3CEG-hnZwtBi10aNtTdPPlLd-_rpEJyq2FWm15_pVpdgEF7sydTqbSLRyHxoUIpnbIX-FVdhip738fkhqEFVtGpPU7msdqkIjC2jPjM901mgBD6H1QkMEib0khRI84DenEX_JGQ9nPoZXXC9f221NZHKWwTUJAm9hD4tItC0nSncwXQ" style="height:65px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как изменить сведения об учетной записи активного сотрудника?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите сотрудника из списка активных сотрудников</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите кнопку &quot;Редактировать&quot; в столбце &quot;Действия&quot; на странице Сотрудники</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/Yr7NWlowpQx8u1bQ4FYBmFoN-WVMma3MpE9oYaxtYkq3Rnq-LqzlULHhUWnGR9iO4dcELHsXOPLnRlEGBQ1lC4Z01WWv5FSFAgXq7PZjCIYCyPLGHGtoAN5nB3ASzHi7HA5AIYmoMzbhOVXuErsUvSjUe6bFvHiiA1uC6HQCMfWB4wUPmdKGW8sMTw" style="height:184px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как активировать неактивных сотрудников?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите сотрудника из списка неактивных сотрудников</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Активировать&quot; в столбце &quot;Действия&quot; на странице Сотрудники</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/hepKvs_0SWsEvu_uMJWnHXLt6XnWels-y87fL9UT9xu6_WUMXdTg3w5LsBK0LrGig8L5tiQhlh2wKlnFcBYv8DlX4wbi7YBr7t3LcEtV_WR_aKzaV-B7VjEtGrUq5VRO2au5MwYwr__3pAqqofNCbzkCugqAR8hTHW3c5-FICrKlMQKqragyWJx2kw" style="height:204px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как повторно отправить приглашение приглашенным сотрудникам?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите сотрудника из списка приглашенных сотрудников</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Повторная отправка&quot;в столбце &quot;Действия&quot;на странице Сотрудники</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/FY0N9Vhw7ZZ4hyCV6k9gkON7eVmhWXflHeXqNiGAxLAK3Z_khY3Stz3STKv5-glFAGIowF8etAleUvNOJCC4jEBEgoEsr85FcJmXNbNGVGEwlfb7STcqQQ8_GEClkq1v0M5ReBkFydtZsA8e_DpaZjG76J-4hPMGKx5x2WtatLngtlQtR8yLrCmxvQ" style="height:165px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как удалить сотрудников?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Сотрудники&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите сотрудника из списка сотрудников</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Удалить&quot; в столбце &quot;Действия&quot; на странице Сотрудники</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите удаление нажатием на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/nEX85tsDt-kGiLOOiws-QNTlknMsk-VW6ln4MWpUP7tMIYbZb3vpnvg3eA7mvPbK5V_nSRUEgaiVsrGY9zTp69Mtay02jTYthks4yQX4AmLER_c_Wd_EXMJ4NPr6GIQoG6b48M8FyJR-vULV2DJ1T6X0iEZ30f31N_F_ovCwdmkSRv8NmP-LqZmr-w" style="height:104px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как обновить код валют?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Настройки&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Код валют&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> из выпадающего меню</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить&quot; на странице Код Валют</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите дату обновлению кода валюты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обновите необходимые коды валют</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/fO_bjpQAQVeTVQfwAeRWwWf15jJtINBxKQTsZ8hyVztPTn_FVMlnO8hgYXgyRM_RxiDRjZddahsAW3hytT5KYMMNkCBvYLFJ3RNGnu_z_jwUKejbJLdhRN7P69cGPxnmjpkB-Abf2AtySfv1xl5Qazc3pjyB9lXflNsHuFfxSlxo2XG3q_RDVlmuig" style="height:535px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить валюту?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Настройки&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Код валют&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">из выпадающего меню</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить валюту&quot;на странице Код Валют</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите валюту из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/TCj5Rq2tTRN75EzE-5C3BTdavBxxQ4vZbXVsCgX58ah3w5iH369z45xp0085UjhdklIJf5ikLy4VGoH-6qdrikOGT-2yqPLDQ7YuQiAUGxmiLwCv25Dzb1h9k-rQid_L9fqMqDn-aWFfWd7Ej6VP5dkFSV6fQ36Dt5HmOkrGapLn7XXAYMNRCuYOjw" style="height:309px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как скачать шаблон миграции данных?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Настройки&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Миграция&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Новая миграция&quot;на странице Миграции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите тип даты для миграции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Скачать шаблон&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/NvVSFrcdMYXMnDUwB9AG82bMu1kannIO6aKG5a3N70tqQ9u7xRSrHUzo85AUn3k2hCX7tk2G5-yIwnIj5nzJmyjS0AQRJ3sDRndjCSfBwoqD-IBceoFMShNsbuY94qRlcinHf0rZM9X4ZUvJbupNlUMRqQfn2_fUVS01KEA6fI4FvyK7A0PE0YESiw" style="height:351px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как мигрировать данные",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Настройки&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Миграция&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Новая миграция&quot;на странице Миграции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите тип даты для миграции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Загрузить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите файл для миграции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Открыть&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/ZMysLR7ehwtfoc5KYETk_zds9hqhDG4uTqq5Hy0AyQN4VD4ohjF6KzBJ0Z_3gbej-iuwj6-MNB95gdusFnB41DaOzuPVFW9Lb06GegX369W0zBZoboi2lp45HqFcxIZ5SbN1L2rbCsU8xmuvpfKGCgu-gFTdXMHRgcXTRvsO8LTsGHbSLoETfCeFCg" style="height:408px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить страну в Страны и Города системы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Настройки&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Страны и города&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить новую страну&quot;на странице Страны и Города</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/dw0vyIvenOiSOkYVL574cJO-4waoVmH0zjk78B9SaDicLWdYT-yGXtNYnT_rqvrggm2_OTALHwgI_DZUc8y1poFstpNPUKNkgxu4u1jvMcKCPoSnG4y6YSO9sW9HvglTRjOKrgG6mW0R24Hho5OwEXgpHv0aUx66e2qBGyPrjhya7qEk5GWBVxDHwQ" style="height:276px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить город в Страны и Города системы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Настройки&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Страны и города&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить новый город&quot;на странице Страны и Города</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите страну в выпадающем списке Страна</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите регион (если Казахстан выбрана как страна)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/q1-sv8I3_LE0iA5Juyx3pwS9WMjqGV84PpeRQ63KmQY1x-xw0jlE6deftNTSVhROQK-qktzlt4M-7uBa6p67lxxDuZ1HhqHae9VVyNjrwmm0ok_jOSBFaV5rNsQmKiMsFMAZXnofE1nG-bJvaeZYQJu0gE2bWujRwCvLgY_BjejLNHzZdik9hb342g" style="height:449px; width:624px" /></span></span></span></p>
`
  }
];
