import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Col, DatePicker, Form, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { actualStatuses } from "@utils/statuses";

import { ActualsStatusSelector } from "./ActualsStatusSelector";
import type { SearchQueryType } from "./libs/types/SearchQueryType";
import type { SearchSectionProps } from "./props";

export const SearchSection: FC<SearchSectionProps> = ({ onSearchQuery }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  useEffect(() => {
    onSearchQuery(searchQuery);
  }, [onSearchQuery, searchQuery]);

  const handleOnFinish = useCallback(
    (vals: { readonly actualsStatus: number; readonly period: string }) => {
      setSearchQuery({
        status: vals.actualsStatus,
        year: vals.period ? moment(vals.period).get("year") : global.undefined,
        month: vals.period
          ? moment(vals.period).get("month") + 1
          : global.undefined
      });
    },
    []
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item name="actualsStatus" label={t("contracts.status")}>
            <ActualsStatusSelector statuses={actualStatuses} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="period" label={t("actuals.submissionPeriod")}>
            <DatePicker
              picker="month"
              format="YYYY-MM-01"
              className={css`
                ${tw`w-full`}
              `}
            />
          </Form.Item>
        </Col>
        <Col span={3} style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {t("forecasts.applyFilters")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
