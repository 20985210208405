/* eslint-disable @typescript-eslint/naming-convention */
import type { User } from "@components/types/models/User";

import type { City, Country } from "./CountryCity";

export enum ForecastApprovalStatus {
  Undefined = 0,
  Pending = 1,
  Rejected = 2,
  Approved = 3,
  Draft = 4,
  Overdue = 5
}

export enum MOU_CATEGORY {
  ENGINEERING = 1,
  SUPERVISOR_AND_FOREMAN = 2,
  ADMINISTRATION = 3,
  CRAFT = 4,
  HEAVY_EQUIPMENT_OPERATOR = 5
}

export enum ForecastSorting {
  ByContractorNameAsc = 1,
  ByContractorNameDesc = 2,
  ByForecastCreatedDateAsc = 3,
  ByForecastCreatedDateDesc = 4,
  ByForecastSubmissionDateAsc = 5,
  ByForecastSubmissionDateDesc = 6,
  ByContractorCreateDateAsc = 7,
  ByContractorCreateDateDesc = 8,
  ByLastApprovedContractAmendmentAsc = 9,
  ByLastApprovedContractAmendmentDesc = 10,
  ByLastForecastUpdatedAtAsc = 11,
  ByLastForecastUpdatedAtDesc = 12
}

export type ForecastType = {
  readonly id: number;
  readonly approvalStatus: ForecastApprovalStatus;
  readonly contractAmount: number;
  readonly contractEndDate: string;
  readonly contractNumber: string;
  readonly contractStartDate: string;
  readonly contractType: number;
  readonly detailsOnScopeOfWork: string;
  readonly forecastVersion: number;
  readonly kcCategory: {
    readonly id: number;
    readonly name: string;
  };
  readonly kcTarget: number;
  readonly masterAgreementNumber: string;
  readonly scopes: readonly number[];
  readonly currencyDto: {
    readonly code: string;
    readonly id: number;
    readonly enabled: boolean;
  };
  // TODO: agree with backend to add this field to admin contractor response
  readonly contractId?: number;
  readonly contractorName?: string;
  readonly unresolvedComments: ReadonlyArray<{
    readonly comment: string;
    readonly commentId: number;
    readonly createdAt: Date;
    readonly leavedByUserFullName?: string;
    readonly leavedByUserId: number;
    readonly sectionType: number;
  }>;
  readonly submittedAt?: string;
  readonly submittedBy?: User;
  readonly reviewedAt?: string;
  readonly reviewedBy?: User;
  readonly dueDate?: string;
  readonly createdBy: {
    readonly createdAt: string;
    readonly firstName: string;
  };
};

export type ForecastGoodType = {
  readonly recordId: number;
  readonly year: number;
  readonly month: number;
  readonly forecastValue: number;
  readonly forecastKcValue: number;
  readonly forecastKcShare: number;
  readonly company: {
    readonly id: number;
    readonly name: string;
    readonly country: Country;
    readonly city: City;
    readonly bin: string;
    readonly detailOnScopeOfWork: string;
    readonly contractualRelations: {
      readonly relationType: 1;
      readonly contractorId: number;
      readonly companyId: number;
    };
  };
  readonly kcGood: {
    readonly id: number;
    readonly title: string;
  };
  readonly goodsQuantity: number;
  readonly unitsOfMeasure: {
    readonly id: number;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly title: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly title_En: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    readonly title_Ru: string;
  };
};

export type ForecastWorkType = {
  readonly recordId: number;
  readonly year: number;
  readonly month: number;
  readonly forecastValue: number;
  readonly forecastKcShare: number;
  readonly company: {
    readonly id: number;
    readonly name: string;
    readonly country: Country;
    readonly city: City;
    readonly bin: string;
    readonly detailOnScopeOfWork: string;
    readonly contractualRelations?: {
      readonly relationType: number;
      readonly contractorId: number;
      readonly companyId: number;
    };
  };
  readonly kcWork?: {
    readonly id: number;
    readonly title: string;
  };
};
export type ForecastServiceType = {
  readonly recordId: number;
  readonly year: number;
  readonly month: number;
  readonly forecastValue: number;
  readonly forecastKcShare: number;
  readonly company: {
    readonly id: number;
    readonly name: string;
    readonly country: Country;
    readonly city: City;
    readonly bin: string;
    readonly detailOnScopeOfWork: string;
    readonly contractualRelations: {
      readonly relationType: number;
      readonly contractorId: number;
      readonly companyId: number;
    };
  };
  readonly kcService?: {
    readonly id: number;
    readonly title: string;
  };
};

export type GoodWorkServiceType = {
  readonly id: number;
  readonly title: string;
};

export type CompanyType = {
  readonly bin: string;
  readonly city?: number;
  readonly contractualRelations?: number;
  readonly country?: number;
  readonly detailOnScopeOfWork?: string;
  readonly id: number;
  readonly name: string;
};

export type RelationshipType = {
  readonly value: number;
  readonly label: string;
};

export type ForecastComment = {
  readonly comment: string;
  readonly commentId: number;
  readonly createdAt: string;
  readonly leavedByUserFullName: string;
  readonly leavedByUserId?: number;
  readonly user: User;
  readonly createdByUser: {
    readonly firstName: string;
    readonly lastName: string;
    readonly userRoles: ReadonlyArray<{
      readonly role: number;
      readonly userId: number;
      readonly entityId: number | null;
    }>;
  };
};

export enum SectionType {
  Undefined = 0,
  Good = 1,
  Work = 2,
  Service = 3,
  JobAndNationalization = 4,
  Training = 5,
  TechnologyTransfer = 6,
  Legacy = 7,
  SuccessStories = 8
}

export type ForecastsListResponse = {
  readonly currentPage: number;
  readonly pageSize: number;
  readonly totalItems: number;
  readonly results: readonly ForecastType[];
};

export enum ContractSummaryStatus {
  Undefined = 0,
  Approved = 1,
  NotSubmitted = 2
}

export function defineContractSummaryStatus(status?: ContractSummaryStatus): {
  readonly enName: string;
  readonly ruName: string;
} {
  switch (status) {
    case ContractSummaryStatus.Approved:
      return {
        enName: "Approved",
        ruName: "Утвержден"
      };
    case ContractSummaryStatus.NotSubmitted:
      return {
        enName: "Not submitted",
        ruName: "Не предоставлен"
      };
    default:
      return {
        enName: "Undefined",
        ruName: "Undefined"
      };
  }
}

export type ForecastSubAgreement = {
  readonly subAgreementNumber: string;
  readonly scopeOfWork: readonly number[];
  readonly status: number;
  readonly forecastValueOriginCurrency: number;
  readonly forecastValueInUsd: number;
  readonly currencyDto?: {
    readonly id: number;
    readonly code: string;
    readonly enabled: boolean;
  };
  readonly forecastKcValueOriginCurrency: number;
  readonly forecastKcValueInUsd: number;
  readonly forecastKcShare: number;
};
