import React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@linaria/react";
import tw from "twin.macro";

import { Button } from "antd";

import type { LogoutButtonProps } from "./props";

const LogoutButtonBase = styled(Button)`
  ${tw`border-white`}
`;

export const LogoutButton: React.FC<LogoutButtonProps> = ({ ...rest }) => {
  const [t] = useTranslation();
  return (
    <LogoutButtonBase type="primary" {...rest}>
      {t("login.logoutButton")}
    </LogoutButtonBase>
  );
};
