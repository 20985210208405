import { useState } from "react";

import { Spin } from "antd";

type PdfViewerProps = {
  readonly data: {
    readonly data: Blob;
  };
};

export const PdfViewer: React.FC<PdfViewerProps> = ({ data }) => {
  const [pdfBase64, setPdfBase64] = useState<string>();

  const reader = new FileReader();
  reader.readAsDataURL(data.data);
  reader.onloadend = function () {
    const base64data = reader.result;
    setPdfBase64(base64data as string);
  };

  return (
    <Spin spinning={pdfBase64 === undefined}>
      {pdfBase64 && (
        <embed style={{ width: "100%", height: "500px" }} src={pdfBase64} />
      )}
    </Spin>
  );
};
