import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Select, Space } from "antd";

import type { Currency } from "@components/types/models/Currency";

import type { ValidateErrorEntity } from "rc-field-form/lib/interface";

const { Option } = Select;

type FormValuesTypes = { readonly codes: readonly string[] };

type AddCurrencyFormProps = {
  readonly currencies: readonly Currency[];
  readonly currenciesLoading?: boolean;
  readonly onSubmit: (values: FormValuesTypes) => void;
  readonly onSubmitFailed?: (errorInfo: ValidateErrorEntity) => void;
  readonly onCancel: () => void;
  readonly loading?: boolean;
};

export const AddCurrencyForm: FC<AddCurrencyFormProps> = ({
  currencies,
  currenciesLoading,
  onSubmit: handleSubmit,
  onCancel: handleCancel,
  onSubmitFailed: handleSubmitFailed,
  loading
}) => {
  const [form] = Form.useForm<FormValuesTypes>();
  const [t] = useTranslation();

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 18 }}
      initialValues={{ remember: true }}
      autoComplete="off"
      onFinish={handleSubmit}
      onFinishFailed={handleSubmitFailed}
    >
      <Form.Item
        name="codes"
        label={t("exchangeRates.code")}
        rules={[{ required: true, message: t("exchangeRates.codeRequired") }]}
      >
        <Select
          allowClear
          mode="multiple"
          placeholder={t("exchangeRates.codePlaceholder")}
          defaultValue={[]}
          loading={currenciesLoading}
        >
          {currencies.map((currency) => (
            <Option key={currency.id} value={currency.code}>
              {currency.code}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4, span: 8 }}>
        <Space>
          <Button type="default" htmlType="reset" onClick={handleCancel}>
            {t("cancel")}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("add")}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
