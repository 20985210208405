import type { FC } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import moment from "moment";

import { SearchOutlined } from "@ant-design/icons";
import type { Category } from "@components/organisms/NewContractForm/libs/types/Category";
import { CategorySelector } from "@components/pages/admin/Forecasts/libs/components/CategorySelector";
import { ContractorSelector } from "@components/pages/admin/Forecasts/libs/components/ContractorSelector";
import { InvoicesStatusSelector } from "@components/pages/contractor/Invoice/libs/SearchSection/InvoicesStatusSelector";
import { Button, Col, DatePicker, Form, Input, Row, Skeleton } from "antd";

import { ContractsSelector } from "../ContractsSelector";

import { contractorApi } from "@api/contractorApi";
import { contractApi } from "@api/contractApi";

import { invoiceStatuses } from "@utils/statuses";
import { CATEGORIES } from "@components/constants/AdminCategories";
import { stringArrToNumberArr } from "@utils/helper";

import type { SearchSectionProps } from "./props";

const DATE_FORMAT = "YYYY-MM-DD";

export const SearchSection: FC<SearchSectionProps> = ({
  filters,
  isLoading,
  onSearchQuery
}) => {
  const { t } = useTranslation();

  const lastElementSliceIndex = -1;

  const { watch, setValue, getValues } = useForm();

  useEffect(() => {
    setValue("invoiceNumber", filters?.invoiceNumber);
    setValue("contractors", stringArrToNumberArr(filters?.contractors));
    setValue("contracts", stringArrToNumberArr(filters?.contracts));
    setValue("kcCategories", stringArrToNumberArr(filters?.kcCategories));
    setValue(
      "dateFrom",
      filters?.dateFrom ? moment(filters?.dateFrom) : undefined
    );
    setValue("dateTo", filters?.dateTo ? moment(filters?.dateTo) : undefined);
    setValue("status", stringArrToNumberArr(filters?.status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnFinish = useCallback(() => {
    const vals = getValues();

    onSearchQuery({
      invoiceNumber: vals.invoiceNumber,
      contractors: vals.contractors,
      contracts: vals.contracts,
      kcCategories: vals.kcCategories,
      dateFrom: vals.dateFrom
        ? moment(vals.dateFrom).format(DATE_FORMAT)
        : undefined,
      dateTo: vals.dateTo ? moment(vals.dateTo).format(DATE_FORMAT) : undefined,
      status: vals.status
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contractorsList = useQuery(
    "getContractors",
    async () => contractorApi.getContractors().then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const contractList = useQuery(
    "getContracts",
    async () => contractApi.getAllContracts().then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const categoriesList = useMemo<readonly Category[]>(
    () =>
      CATEGORIES.reduce<readonly Category[]>(
        (prev, cat) =>
          cat.children ? prev.concat(cat.children) : [...prev, cat],
        []
      ),
    []
  );

  return (
    <Form layout="vertical" onFinish={handleOnFinish}>
      {contractorsList.isLoading || contractList.isLoading ? (
        <Skeleton active paragraph={{ rows: 2 }} />
      ) : (
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label={t("contractors.contractors")}>
              <ContractorSelector
                contractors={contractorsList.data}
                value={watch("contractors")}
                onChange={(value) => setValue("contractors", value)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t("contractors.contracts")}>
              <ContractsSelector
                contracts={contractList.data}
                value={watch("contracts")}
                onChange={(value) => setValue("contracts", value)}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label={t("members.categoryAndArea")}>
              <CategorySelector
                categories={categoriesList}
                value={watch("kcCategories")}
                onChange={(value) => setValue("kcCategories", value)}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label={t("invoices.invoiceNumber")}>
              <Input
                value={watch("invoiceNumber")}
                onChange={(e) => setValue("invoiceNumber", e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label={t("dateFrom")}>
              <DatePicker
                allowClear
                style={{ width: "100%" }}
                value={watch("dateFrom")}
                onChange={(value) => setValue("dateFrom", value)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t("dateTo")}>
              <DatePicker
                allowClear
                style={{ width: "100%" }}
                value={watch("dateTo")}
                onChange={(value) => setValue("dateTo", value)}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t("contracts.status")}>
              <InvoicesStatusSelector
                statuses={invoiceStatuses.slice(0, lastElementSliceIndex)}
                value={watch("status")}
                onChange={(value) => setValue("status", value)}
              />
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              loading={isLoading}
            >
              {t("forecasts.applyFilters")}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};
