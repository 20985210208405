import type { FC } from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { css } from "@linaria/core";
import axios from "axios";
import { format, parseISO } from "date-fns";
import tw from "twin.macro";

import { MessageFilled } from "@ant-design/icons";
import { useDefaultQuery } from "@hooks";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Space,
  Typography
} from "antd";

import type { ForecastComment } from "@components/types/models/Forecast";
import { STATUS } from "@components/types/models/Statuses";

import { actualApi } from "@api/actualApi";
import { forecastApi } from "@api/forecastApi";
import { invoiceApi } from "@api/invoiceApi";

import { UserRoles } from "@contexts/types/UserRoles";
import { defineUserRole, UserContext } from "@contexts/userContext";

import type { CommentCardProps } from "./props";

export const CommentCard: FC<CommentCardProps> = ({
  id,
  sectionType,
  status,
  isActual,
  isInvoice,
  styles
}) => {
  const [isShowTextInput, setIsShowTextInput] = useState(false);
  const { Text, Title } = Typography;
  const { userRole } = useContext(UserContext);
  const { TextArea } = Input;
  const { t } = useTranslation();
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  const handleOpenTextInput = (): void => {
    setIsShowTextInput(true);
  };
  const handleCloseTextInput = (): void => {
    setIsShowTextInput(false);
  };

  const {
    data: comments,
    refetch: refetchComments,
    isLoading
  } = useDefaultQuery(["getComments", sectionType, id], async () => {
    if (isActual) {
      return (
        id &&
        sectionType &&
        actualApi.getActualComments(id, sectionType).then((res) => res.data)
      );
    } else if (isInvoice) {
      return (
        id &&
        sectionType &&
        invoiceApi.getInvoiceComments(id, sectionType).then((res) => res.data)
      );
    }
    return (
      id &&
      sectionType &&
      forecastApi.getForecastComments(id, sectionType).then((res) => res.data)
    );
  });

  const addCommentMutation = useMutation(
    async (values: { readonly comment: string }) => {
      if (isActual) {
        return axios
          .post("/api/comments/actual", {
            ...values,
            sectionType,
            actualId: id
          })
          .then((res) => res.data);
      } else if (isInvoice) {
        return axios
          .post(`/api/invoices/${id}/comment`, { ...values, sectionType })
          .then((res) => res.data);
      }
      return axios
        .post("/api/comments/forecast", {
          ...values,
          sectionType,
          forecastId: id
        })
        .then((res) => res.data);
    },
    {
      onSuccess: () => {
        setIsShowTextInput(false);
        void message.success(t("saved"));
        void refetchComments();
      }
    }
  );

  useEffect(() => {
    void refetchComments();
  }, [sectionType, refetchComments]);

  const isContractCommentCardEditable =
    userRole === UserRoles.Contractor &&
    status !== STATUS.PENDING &&
    status !== STATUS.APPROVED;

  const isAdminCommentCardEditable =
    userRole === UserRoles.Admin && status !== STATUS.APPROVED;

  const handleFormSubmit = useCallback(() => {
    void handleSubmit((values: { readonly comment: string }) => {
      addCommentMutation.mutate(values);
    })();
  }, [handleSubmit, addCommentMutation]);

  const handleTextareaChange = useCallback(
    (name) => (e: React.FormEvent<HTMLTextAreaElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const dateFormat = "yyyy-MM-dd HH:mm";

  return (
    <Card style={styles} bordered={false} loading={isLoading}>
      <Divider />
      <Row align="middle">
        <Title level={5}>
          <MessageFilled style={{ marginRight: "12px" }} />
          {t("forecasts.comments")}
        </Title>
      </Row>
      {comments?.length > 0 ? (
        <Space direction="vertical">
          {comments?.map((comment: ForecastComment, index: number) => {
            const authorRole = isInvoice
              ? `[${defineUserRole(comment.createdByUser.userRoles[0]?.role)}]`
              : `[${defineUserRole(comment.user.userRoles[0]?.role)}]`;
            const author = isInvoice
              ? `${comment.createdByUser.firstName} ${comment.createdByUser.lastName}`
              : `${comment.leavedByUserFullName} ${authorRole}`;

            return (
              <Space
                key={index}
                size="small"
                direction="vertical"
                className={css`
                  ${tw`bg-blue-50 rounded-lg p-2`}
                `}
              >
                <Text
                  className={css`
                    ${tw`whitespace-pre-wrap`}
                  `}
                >
                  {comment.comment}
                </Text>
                <Space size="small">
                  {t("by")}
                  <Text strong>{author}</Text>
                  {t("at")}
                  <Text strong>
                    {format(parseISO(comment.createdAt), dateFormat)}
                  </Text>
                </Space>
              </Space>
            );
          })}
        </Space>
      ) : (
        <Text>{t("forecasts.noCommentsYet")}</Text>
      )}
      {isShowTextInput ? (
        <Form onFinish={handleFormSubmit}>
          <Row>
            <Form.Item
              style={{ width: "100%" }}
              rules={[{ required: true, message: t("requiredField") }]}
              validateStatus={errors.comment && "error"}
              help={errors.comment?.message}
              name="comment"
            >
              <TextArea
                placeholder={t("forecasts.commentToTheSection")}
                style={{ marginTop: "24px" }}
                value={watch("comment")}
                maxLength={255}
                onChange={handleTextareaChange("comment")}
              />
            </Form.Item>
          </Row>
          <Row gutter={8}>
            <Col md={24}>
              <br />
              <Form.Item noStyle>
                <Row justify="end">
                  <Col>
                    <Space align="end">
                      <Button htmlType="button" onClick={handleCloseTextInput}>
                        {t("cancel")}
                      </Button>
                      <Button
                        htmlType="submit"
                        loading={addCommentMutation.isLoading}
                        className="secondary-button"
                      >
                        {t("save")}
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Row justify="end">
          {(isContractCommentCardEditable || isAdminCommentCardEditable) && (
            <Button className="secondary-button" onClick={handleOpenTextInput}>
              {t("forecasts.leaveComment")}
            </Button>
          )}
        </Row>
      )}
    </Card>
  );
};
