import type { FC } from "react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { VersionSelectorProps } from "@components/atoms/VersionSelector/props";
import { Select } from "antd";

import type { Contract as ContractType } from "@components/types/models/Contract";
import { defineStatus } from "@components/types/models/Statuses";

const { Option } = Select;

export const VersionSelector: FC<VersionSelectorProps> = ({
  contractData,
  amendments,
  onVersionSelectChange
}) => {
  const { t, i18n } = useTranslation();
  const defaultVersionOptionValue =
    i18n.language === "en" ? "Version 1 - DRAFT" : "Версия 1 - ЧЕРНОВИК";

  const getContractVersionTitle = (
    value?:
      | ContractType
      | { readonly version: number; readonly contractStatus: number }
  ): string =>
    `${t("contracts.version")} ${value?.version} - ${
      i18n.language === "en"
        ? defineStatus(value?.contractStatus).enName
        : defineStatus(value?.contractStatus).ruName
    }`;

  const handleVersionSelectChange = useCallback(onVersionSelectChange, [
    onVersionSelectChange
  ]);

  return (
    <Select
      style={{ width: "max-content" }}
      placeholder={getContractVersionTitle(contractData)}
      onChange={handleVersionSelectChange}
    >
      {amendments && amendments.length > 0 ? (
        amendments.map(
          (
            amendment: {
              readonly version: number;
              readonly contractStatus: number;
            },
            index: number
          ) => (
            <Option key={index} value={`${amendment.version}`}>
              {getContractVersionTitle(amendment)}
            </Option>
          )
        )
      ) : (
        <Option value={1}> {defaultVersionOptionValue}</Option>
      )}
    </Select>
  );
};
