export const ruMigrations = {
  menuTitle: "Миграция",
  title: "Миграция исторических данных",
  uploadMigration: "Новая миграция",
  filename: "Имя файла",
  dataType: "Тип данных",
  username: "Имя пользователя",
  date: "Дата и время",
  status: "Статус",
  statusCodes: {
    "0": "–",
    "1": "В процессе",
    "2": "Завершен",
    "3": "Ожидание",
    "4": "Ошибка"
  },
  typeCodes: {
    "0": "–",
    "1": "Метрики",
    "2": "Контракты",
    "3": "Контракторы",
    "4": "Количество сотрудников и национализация",
    "5": "Обучение"
  },
  uploadLimitAlertTitle:
    "Пожалуйста проверьте корректность данных до загрузки, загруженные данные не могут быть изменены или удалены.",
  uploadLimitAlertDescription:
    "Для загрузки большого количества данных используйте несколько файлов, максимальный разрешенный размер 1МБ.",
  selectType: "Выберите тип данных",
  downloadTemplate: "Скачать шаблон",
  upload: "Загрузить",
  errors: "Ошибки миграции"
};
