export const enMigrations = {
  menuTitle: "Migration",
  title: "Historical data migration",
  uploadMigration: "New migration",
  filename: "File name",
  dataType: "Data type",
  username: "User name",
  date: "Date and time",
  status: "Status",
  statusCodes: {
    "0": "Undefined",
    "1": "In Progress",
    "2": "Completed",
    "3": "Pending",
    "4": "Failed"
  },
  typeCodes: {
    "0": "Undefined",
    "1": "Metrics",
    "2": "Contracts",
    "3": "Contractors",
    "4": "Jobs and Nationalization",
    "5": "Training"
  },
  uploadLimitAlertTitle:
    "Please be noted that data must be checked before uploading, once the data is uploaded it cannot be changed or deleted.",
  uploadLimitAlertDescription:
    "In order the data to be uploaded accurate and complete please split big amounts of data into several files, size limit is 1MB.",
  selectType: "Please select data type",
  downloadTemplate: "Download template",
  upload: "Upload",
  errors: "Migration errors"
};
