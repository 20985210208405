import { MigrationType } from "@modules/Migrations/types/MigrationTypes";

export const MIGRATIONS_TYPE_LIST: ReadonlyArray<{
  readonly value: number;
}> = [
  { value: MigrationType.Undefined },
  { value: MigrationType.Metrics },
  { value: MigrationType.Contracts },
  { value: MigrationType.Contractors },
  { value: MigrationType.JobsAndNationalization },
  { value: MigrationType.Training }
];
