export type Subcontractor = {
  readonly id: number;
  readonly name: string;
  readonly type: number;
  readonly address: string;
  readonly countryId: number;
  readonly detailOnScopeOfWork?: string;
  readonly reviewed?: boolean;

  readonly country: {
    readonly id: number;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly nameEn: string;
    readonly nameRu: string;
    readonly name: string;
    readonly cities: ReadonlyArray<{
      readonly id: number;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly countryId: number;
      readonly nameEn: string;
      readonly nameRu: string;
      readonly name: string;
      readonly regionNameEn: string;
    }>;
  };

  readonly cityId: number;

  readonly city: {
    readonly id: number;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly countryId: number;
    readonly nameEn: string;
    readonly nameRu: string;
    readonly name: string;
    readonly regionNameEn: string;
  };

  readonly bin: string;
  readonly phone: string;
  readonly supervisorEmail: string;
  readonly parentContractorId: number;

  readonly contracts: ReadonlyArray<{
    readonly id: number;
    readonly contractorNumber: string;
  }>;

  readonly contractualRelations: {
    readonly relationType: number;
    readonly contractorId: number;
    readonly companyId: number;
  };
};

export type SubcontractorDetails = {
  readonly id: number;
  readonly name: string;
  readonly type: number;
  readonly address: string;
  readonly countryId: number;

  readonly country: {
    readonly id: number;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly nameEn: string;
    readonly nameRu: string;
    readonly name: string;
    readonly cities: ReadonlyArray<{
      readonly id: number;
      readonly createdAt: string;
      readonly updatedAt: string;
      readonly countryId: number;
      readonly nameEn: string;
      readonly nameRu: string;
      readonly name: string;
      readonly regionNameEn: string;
    }>;
  };

  readonly cityId: number;

  readonly city: {
    readonly id: number;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly countryId: number;
    readonly nameEn: string;
    readonly nameRu: string;
    readonly name: string;
    readonly regionNameEn: string;
  };

  readonly bin: string;
  readonly phone: string;
  readonly supervisorEmail: string;
  readonly parentContractorId: number;

  readonly contracts: ReadonlyArray<{
    readonly id: number;
    readonly contractNumber: string;
  }>;
};

export type UpdateSubcontractorPayload = {
  readonly name: string;
  readonly address: string;
  readonly cityId: number;
  readonly phone: string;
  readonly contractIds: readonly number[];
};

export type CreateSubcontractorPayload = {
  readonly name: string;
  readonly address: string;
  readonly cityId: number;
  readonly phone: string;
  readonly contractIds: readonly number[];
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly position: string;
  readonly permissions: readonly number[];
};

export enum RelationType {
  Undefined = 0,
  Subcontractor = 1,
  AffiliatedCompany = 2,
  Supplier = 3,
  PrimeContractor = 4,
  Contractor = 5
}
