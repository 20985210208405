import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { QueryFunctionContext } from "react-query";
import { useQuery } from "react-query";

import { Typography } from "antd";

import { InvoiceSorting } from "@components/types/models/Invoices";

import { adminApi } from "@api/adminApi";

import { usePagination } from "@hooks/usePagination";

import { InvoicesTable } from "./libs/components/InvoicesTable";
import { SearchSection } from "./libs/components/SearchSection";
import type { SearchQueryType } from "./libs/components/SearchSection/libs/types/SearchQueryType";

const { Title } = Typography;

const PAGE_SIZE = 20;

export const InvoicesList: FC = () => {
  const { t } = useTranslation();

  const { page, pagination, setPagination, setPage } = usePagination<
    SearchQueryType,
    InvoiceSorting
  >();

  const {
    data: invoicesList,
    isFetching: isInvoiceListLoading,
    refetch: refetchInvoicesList
  } = useQuery(
    [
      "getAdminInvoices",
      {
        searchQuery: pagination.filterParams,
        sort: pagination.sortParams
      }
    ],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [
          string,
          {
            readonly searchQuery: SearchQueryType | undefined;
            readonly sort?: InvoiceSorting;
          }
        ]
      >
    ) =>
      adminApi
        .getAdminInvoices(
          page,
          PAGE_SIZE,
          pagination.sortParams,
          searchQueryCallback.queryKey[1].searchQuery?.status,
          searchQueryCallback.queryKey[1].searchQuery?.kcCategories,
          searchQueryCallback.queryKey[1].searchQuery?.invoiceNumber,
          searchQueryCallback.queryKey[1].searchQuery?.contracts,
          searchQueryCallback.queryKey[1].searchQuery?.contractors,
          searchQueryCallback.queryKey[1].searchQuery?.dateFrom,
          searchQueryCallback.queryKey[1].searchQuery?.dateTo
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  useEffect(() => {
    void refetchInvoicesList();
  }, [
    page,
    pagination.tabParams,
    pagination.sortParams,
    pagination.filterParams,
    refetchInvoicesList
  ]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      sortParams: prevState.sortParams || InvoiceSorting.SubmissionDateAsc
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchQuery = (searchParams?: SearchQueryType) => {
    setPagination((prevState) => ({
      ...prevState,
      filterParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSort = (searchParams?: InvoiceSorting) => {
    setPagination((prevState) => ({
      ...prevState,
      sortParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    void refetchInvoicesList();
  }, [page, refetchInvoicesList]);

  return (
    <>
      <Title>{t("invoices.g1cForms")}</Title>
      <SearchSection
        filters={pagination.filterParams}
        isLoading={isInvoiceListLoading}
        onSearchQuery={handleSearchQuery}
      />
      <InvoicesTable
        invoicesList={invoicesList?.results ?? []}
        isLoading={isInvoiceListLoading}
        refetchInvoicesList={refetchInvoicesList}
        pagination={{
          onChange: (p: number) => {
            setPage(p);
          },
          showSizeChanger: false,
          total: invoicesList?.totalItems,
          current: invoicesList?.currentPage,
          pageSize: invoicesList?.pageSize
        }}
        sort={Number(pagination.sortParams)}
        onSetSort={handleSort}
      />
    </>
  );
};
