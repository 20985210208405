import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { css } from "@linaria/core";
import moment from "moment";
import tw from "twin.macro";

import { ArrowUpOutlined } from "@ant-design/icons";
import { Row, Table, Tag, Typography } from "antd";
import { LinkWithQuery } from "@components/atoms/LinkWithQuery";

import type { Contract } from "@components/types/models/Contract";
import { defineContractType } from "@components/types/models/Contract";
import type { Invoice } from "@components/types/models/Invoices";
import { InvoiceSorting } from "@components/types/models/Invoices";
import type { STATUS } from "@components/types/models/Statuses";
import { defineStatus } from "@components/types/models/Statuses";

import { useSort } from "@hooks/useSort";

import type { InvoicesTableProps } from "./props";

const sortList = [
  {
    title: "submittedAt",
    asc: InvoiceSorting.SubmissionDateAsc,
    desc: InvoiceSorting.SubmissionDateDesc
  }
];

export const InvoicesTable: FC<InvoicesTableProps> = ({
  invoicesList,
  isLoading,
  pagination,
  sort,
  onSetSort
}) => {
  const { t, i18n } = useTranslation();
  const { Column } = Table;
  const { Text } = Typography;

  const { handleSort, rotate } = useSort({ sortList, defaultSortValue: sort });

  const handleChange = useCallback(
    (title: string) => (_: unknown) => {
      const outputSortValue = handleSort(title);
      onSetSort?.(outputSortValue);
    },
    [onSetSort, handleSort]
  );

  return (
    <Table
      locale={{ emptyText: t("noData") }}
      loading={isLoading}
      dataSource={invoicesList}
      pagination={pagination}
      scroll={{ x: 800 }}
      rowKey="id"
    >
      <Column
        key="period"
        width={150}
        title={t("invoices.invoiceNumber")}
        render={(invoice: Invoice) => (
          <LinkWithQuery to={`/admin/payment-application/${invoice.id}`}>
            {invoice.identityNumber ? invoice.identityNumber : "-"}
          </LinkWithQuery>
        )}
      />
      <Column
        key="contractNumber"
        width={150}
        title={t("contracts.contractNumber")}
        dataIndex="contract"
        render={(contract: Contract) => contract.contractNumber}
      />
      <Column
        key="contractType"
        title={t("contracts.contractType")}
        dataIndex="contract"
        render={(contract: Contract) =>
          i18n.language === "en"
            ? defineContractType(contract.contractType).enName
            : defineContractType(contract.contractType).ruName
        }
      />
      <Column
        key="contractor"
        title={t("contractor")}
        dataIndex="contract"
        render={(contract?: Contract) => contract?.contractor.name}
      />
      <Column
        key="kcCategoryAndArea"
        title={t("contracts.kcCategoryAndArea")}
        dataIndex="contract"
        render={(contract?: Contract) => contract?.kcCategory.name}
      />
      <Column
        key="startDate"
        title={t("invoices.g1cDateFrom")}
        dataIndex="startDate"
        render={(startDate?: string) =>
          startDate ? moment(startDate).format("YYYY-MM-DD") : "-"
        }
      />
      <Column
        key="endDate"
        title={t("invoices.g1cDateTo")}
        dataIndex="endDate"
        render={(endDate?: string) =>
          endDate ? moment(endDate).format("YYYY-MM-DD") : "-"
        }
      />
      <Column
        key="submissionDate"
        title={() => (
          <Row
            className={css`
              ${tw`cursor-pointer`}
            `}
            justify="space-between"
            align="middle"
            onClick={handleChange("submittedAt")}
          >
            <Text>{t("actuals.submissionDate")}</Text>
            {sort &&
              [
                InvoiceSorting.SubmissionDateAsc,
                InvoiceSorting.SubmissionDateDesc
              ].includes(sort) && (
                <ArrowUpOutlined
                  style={{
                    transition: "transform 0.3s linear",
                    transform: `rotate(${rotate}deg)`
                  }}
                />
              )}
          </Row>
        )}
        dataIndex="submittedAt"
        width={175}
        render={(submittedAt?: string) =>
          submittedAt ? moment(submittedAt).format("YYYY-MM-DD HH:mm:ss") : "-"
        }
      />
      <Column
        key="status"
        title={t("contracts.status")}
        dataIndex="status"
        render={(status: STATUS) => (
          <Tag color={defineStatus(status).color}>
            {i18n.language === "en"
              ? defineStatus(status).enName
              : defineStatus(status).ruName}
          </Tag>
        )}
      />
    </Table>
  );
};
