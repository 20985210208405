export const contracts = [
  {
    title: "Contract types and statuses",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">To see the contract details, press on &quot;Contract number&quot; in the Contracts block of the Contractor&rsquo;s detailed page.</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">System has the following </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contract Types</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">:</span></span></span></p>

<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Stand-alone</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Master-agreement</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Sub-agreement</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Contracts have 4 types of statuses:</span></span></span></p>

<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Draft</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ⎼ when contract is created by KC Lead but not yet updated by Contractor</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pending</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ⎼ when Contractor updates contract details and submits for a review</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Rejected</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ⎼ when KC Lead rejects the contract after reviewing it</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Approved</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ⎼ when KC Lead agrees with all contract details. Approved contracts allow Contractors to start the Forecasts, Actuals and G1-C forms submission process</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Besides the email notifications sent to each assigned KC Lead, the system displays the number of &lsquo;Pending&rsquo; contracts, forecasts, actuals or g1-c forms waiting for review&nbsp;</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/Fs4AIOAsgrclKMRXb5qVa5v8bVad-YvxhS-s5V6oXzkr0tCjW2rf2PHeUnx_Ex_fOed_q4e35wdlQ2bnprsXr1cgp0IIC9PSzF7EhCN2Y_XWsZC5QgXV4IBlbGElvbVE_I-xVkwWE1gVs2glb7j1U6tvQYMRHQ_SDZhmF_C9_nx3oK-JiDZrHLeq7Q" style="height:72px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to add Contracts?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:6.999999999999999pt"><span style="font-family:'Times New Roman'"><span style="color:#000000">&nbsp;</span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+ Add contract&quot; button on Contractor&rsquo;s detailed page (</span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts block</strong></span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:6.999999999999999pt"><span style="font-family:'Times New Roman'"><span style="color:#000000">&nbsp;</span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">Fill out required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/fmE-Fl_gwUdwsQPO727VqXvJuinvdRvIqhUYLQImQZjb5CgBSY0a9-PnhGTZFRW7JU_UrMPHgNavj8mB_djUQs8ge-GrFkF3ysFOxFRfB9rJfpGgggZcbdz55uuUqt0F0d3uIZw3zlRvahdg6idpBOGKThrmnY-eACwtbpNk60MwCIlj-TGDFWDd3A" style="height:345px; width:624px" /></span></span></span></p>

<p><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span></p>

<ul>
\t<li style="list-style-type:disc"><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">In order to add a sub-agreement type of contract, you </span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"><strong>must have</strong></span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"> your </span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Master agreement</strong></span></span></span><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000"> created and approved first (required)</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:10pt"><span style="font-family:Arial"><span style="color:#000000">Sub-agreements can also be added by clicking on the &quot;+Add sub-agreement&quot; button on Approved Master agreement&rsquo;s detailed page</span></span></span></li>
</ul>
`
  },
  {
    title: "How to edit Contract details?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list and open </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> block</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Edit&quot; button under Actions column for selected contract (ALL STATUSES)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Update Contract number or KC Category and Area of the contract</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/WHEy8tihyjfEjBTDfUonxGr1hESl9s4gpwnJSUDxXDQooDrCYPPid8_YtBTxYuump9TqVhnbzWRyDRP6nxOxwWq9fOabmwPI032CpJX1e_aE37FepKgvrXb4qVGtrrZ8WtfBJ6qmtXKC2ntP6dp6FRXTTh9NYVZUENdBSBUNExDyLrwE1DWNcsRnRw" style="height:484px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to delete Contracts?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list and open </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> block</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Delete&quot; button under Actions column for selected contract (ALL STATUSES)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm your action by clicking on &quot;Yes&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/4dFnoPJAdWCMZRdLcpXO9jTETJJBd_n_KglFKwA8JU-Nwuxicm4EIdRwm7e0HkLPtf86_umSpNdtTuE_FxMpv30WQulBWZLEGGQ2uG797EtxpckFa5bizgP3bq_oDtNVKemXiELmLgRAWJ6ZL5Hl-VZPSGxjz39x4m3T7-GNb9JAW3lUNueJujOokg" style="height:252px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Reject contracts?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list and open </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> block</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; contract from the Contracts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Reject&quot; button on Pending contract&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (reasoning of the rejection)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Yes&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/aeTEL6IKTAXxldK8ndvR5RQ4a3cgGlDmEeosHDkl0hBQLNAA6ihxcbmVeWoiqVqouCt4Gmo7w2doG2f44BGLXZLj5TWBb1A9R5X8laCOgkoCGab_lI-HGIAvebkZV97Ju6A7jpYBJFkmLu2OIa80B9CeEMPnTl2OaOa5fCyhHAuXKKSD5PpM1jkiTA" style="height:323px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Approve contracts?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list and open </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> block</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; contract from the Contracts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Approve&quot; button on Pending contract&rsquo;s detailed page</span></span></span></li>
</ol>
`
  }
];
