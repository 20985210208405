import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { css } from "@linaria/core";
import tw from "twin.macro";
import { format, parseISO } from "date-fns";

import { Badge, Button, Space, Table, Tooltip } from "antd";
import { AppTable } from "@components/molecules/AppTable";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { Subcontractor } from "@components/types/models/Subcontractor";

import { defineRelationType } from "@components/constants/ContractualRelations";
import { UserContext } from "@contexts/userContext";

import type { SubcontractorsTableProps } from "./props";

const { Column } = Table;
const DATE_FORMAT = "d/MM/Y";
const MAX_TEXT_LENGTH = 40;

export const SubcontractorsTable: FC<SubcontractorsTableProps> = ({
  subcontractorsList,
  isLoading,
  total,
  pagination,
  onOpenClick,
  contractorId,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const { isAdmin } = useContext(UserContext);

  const handleSliceText = (text: string) => {
    if (text.length > MAX_TEXT_LENGTH) {
      return text.slice(0, MAX_TEXT_LENGTH) + "...";
    }
    return text;
  };

  return (
    <AppTable
      className={css`
        ${tw`mt-8`}
      `}
      isLoading={isLoading}
      dataSource={subcontractorsList}
      subtitle={`${t("members.subcontractorsSum")}: ${total}`}
      pagination={pagination}
      rowKey="name"
      {...rest}
    >
      <Column
        title={t("profile.name")}
        dataIndex="name"
        render={(name, sub: Subcontractor) => (
          <Space>
            {!sub.reviewed && isAdmin && (
              <Tooltip
                title={t("subcontractors.profile.toBeReviewed")}
                color="gold"
              >
                <Badge count={1} offset={[5, -15]} status="warning" />
              </Tooltip>
            )}
            <Link to={`${contractorId}/subcontractor/${sub.id}`}>
              {name ?? "-"}
            </Link>
          </Space>
        )}
      />
      <Column title={t("bin")} dataIndex="bin" render={(bin) => bin ?? "-"} />
      <Column
        title={t("country")}
        dataIndex="country"
        render={(country) =>
          (i18n.language === "en" ? country?.nameEn : country?.nameRu) ?? "-"
        }
      />
      <Column
        title={t("city")}
        dataIndex="city"
        render={(city) =>
          (i18n.language === "en" ? city?.nameEn : city?.nameRu) ?? "-"
        }
      />
      <Column
        title={
          <Space>
            {t("contractors.details")}
            <Tooltip title={t("subcontractors.detailsTooltip")}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        }
        dataIndex="detailOnScopeOfWork"
        render={(detailOnScopeOfWork) =>
          detailOnScopeOfWork ? handleSliceText(detailOnScopeOfWork) : "-"
        }
      />
      <Column
        title={t("forecasts.contractualRelationship")}
        dataIndex="contractualRelations"
        render={(contractualRelation) => {
          if (contractualRelation) {
            return i18n.language === "en"
              ? defineRelationType(contractualRelation?.relationType).enName
              : defineRelationType(contractualRelation?.relationType).ruName;
          }
          return "-";
        }}
      />
      <Column
        title={t("updatedAt")}
        dataIndex="updatedAt"
        render={(date) => (date ? format(parseISO(date), DATE_FORMAT) : "-")}
        align="center"
      />
      {onOpenClick && (
        <Column
          title={t("actions")}
          dataIndex="actions"
          align="center"
          width={"7%"}
          render={(_, sub: Subcontractor) => (
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                onOpenClick(sub);
              }}
            />
          )}
        />
      )}
    </AppTable>
  );
};
