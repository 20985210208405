import type { AxiosResponse } from "axios";
import axios from "axios";

import type { DatadumpRecord } from "@components/types/models/Datadump";

const getAdminDumps = async (): Promise<
  AxiosResponse<readonly DatadumpRecord[]>
> => axios.get<readonly DatadumpRecord[]>("/api/reports/actual-metrics/dumps");

const generateAdminDump = async (): Promise<AxiosResponse<unknown>> =>
  axios.post<unknown>("/api/reports/actual-metrics/dumps");

const getContractorDumps = async (
  contractorId: number
): Promise<AxiosResponse<readonly DatadumpRecord[]>> =>
  axios.get<readonly DatadumpRecord[]>(
    `/api/reports/actual-metrics/dumps/contractors/${contractorId}`
  );

const generateContractorDump = async (
  contractorId: number
): Promise<AxiosResponse<unknown>> =>
  axios.post<unknown>(
    `/api/reports/actual-metrics/dumps/contractors/${contractorId}`
  );

export {
  generateAdminDump,
  generateContractorDump,
  getAdminDumps,
  getContractorDumps
};
