import { FC } from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { css } from "@linaria/core";
import tw from "twin.macro";

import { Card, Col, List, Row, Typography } from "antd";
import { useDefaultQuery } from "@hooks";
import { SubcontractorsTable } from "@components/organisms/SubcontractorsTable";
import { InviteContractorMemberForm } from "@components/organisms/InviteContractorMemberForm";
import { ContractorMembersTable } from "@components/organisms/ContractorMembersTable";
import {
  EnvironmentOutlined,
  FlagOutlined,
  FormOutlined,
  HomeOutlined,
  PhoneOutlined
} from "@ant-design/icons";
import { EditSubcontractorModal } from "@components/organisms/EditSubcontractorModal";
import { FilterSubcontractorForm } from "@components/organisms/FilterSubcontractorsForm";

import { Subcontractor } from "@components/types/models/Subcontractor";

import { contractorApi } from "@api/contractorApi";

import { formatPhoneNumber } from "@utils/phoneNumberFormatter";
import { UserContext } from "@contexts/userContext";

const { Title } = Typography;
const PAGE_SIZE = 10;

export const ContractorMembersList: FC = () => {
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const [page, setPage] = useState(1);

  const [isEditSubcontractorModalOpen, setEditSubcontractorModalOpen] =
    useState<boolean>(false);
  const [selectedSubcontractor, setSelectedSubcontractor] =
    useState<Subcontractor | null>(null);

  const [filters, setFilters] = useState<{
    bin?: string;
    name?: string;
  } | null>(null);

  const contractorId = user?.userRoles[0].entityId;

  const { data: contractor, isLoading: isContractorLoading } = useDefaultQuery(
    "getAllCategories",
    async () =>
      contractorApi.getContractorData(contractorId).then((res) => res.data)
  );
  const members = useDefaultQuery("getContractorMembers", async () =>
    contractorApi.getContractorMembers(contractorId).then((res) => res.data)
  );

  const {
    data: subcontractors,
    isFetching: isSubcontractorsFetching,
    refetch: refetchSubcontractors
  } = useQuery(
    "getSubcontractors",
    async () =>
      contractorApi
        .getContractorSubcontractors(
          page,
          PAGE_SIZE,
          contractorId,
          filters?.bin,
          filters?.name
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  const handleOpenEditModal = (subcontractor: Subcontractor): void => {
    setSelectedSubcontractor(subcontractor);
    setEditSubcontractorModalOpen(true);
  };

  const handleFilters = ({ bin, name }: { bin?: string; name?: string }) => {
    setFilters({
      bin,
      name
    });
  };

  useEffect(() => {
    refetchSubcontractors();
  }, [page, refetchSubcontractors]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      refetchSubcontractors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <Title>{t("members.informationAboutCompany")}</Title>
      <Card title={contractor?.name} loading={isContractorLoading}>
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<FlagOutlined />}
              title={contractor?.bin ?? "-"}
              description={t("contractors.bin")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<FlagOutlined />}
              title={
                i18n.language === "en"
                  ? contractor?.country?.nameEn
                  : contractor?.country?.nameRu
              }
              description={t("contractors.country")}
            />
          </List.Item>

          <List.Item>
            <List.Item.Meta
              avatar={<HomeOutlined />}
              title={
                i18n.language === "en"
                  ? contractor?.city?.nameEn
                  : contractor?.city?.nameRu
              }
              description={t("contractors.city")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<EnvironmentOutlined />}
              title={contractor?.address ?? "-"}
              description={t("contractors.address")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<PhoneOutlined />}
              title={
                contractor?.phone ? formatPhoneNumber(contractor?.phone) : "-"
              }
              description={t("contractors.phoneNumber")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<FormOutlined />}
              title={t(
                `contractors.contractorTypes.${contractor?.contractorType}`
              )}
              description={t(`contractors.contractorType`)}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<FormOutlined />}
              title={contractor?.parentCompanyName ?? "-"}
              description={t("contractors.parentCompanyName")}
            />
          </List.Item>
        </List>
      </Card>
      <Row
        className={css`
          ${tw`my-8`}
        `}
      >
        <Col
          span={12}
          className={css`
            ${tw`text-left`}
          `}
        >
          <Title>{t("members.members")}</Title>
        </Col>
        <Col
          span={12}
          className={css`
            ${tw`text-right`}
          `}
        >
          <InviteContractorMemberForm
            contractorId={contractorId}
            refetchMembersList={members.refetch}
          />
        </Col>
      </Row>
      <ContractorMembersTable
        membersList={members.data ?? []}
        isMembersListLoading={members.isLoading}
        contractorName={contractor?.name}
      />
      <Title
        className={css`
          ${tw`my-8`}
        `}
      >
        {t("contractors.subcontractors")}
      </Title>
      <FilterSubcontractorForm
        handleFilters={handleFilters}
        clearFilters={() => setFilters(null)}
        isLoading={isSubcontractorsFetching}
      />
      <SubcontractorsTable
        total={subcontractors?.totalItems}
        isLoading={isSubcontractorsFetching}
        subcontractorsList={subcontractors?.results ?? []}
        pagination={{
          showSizeChanger: false,
          onChange: (p: number) => {
            setPage(p);
          },
          total: subcontractors?.totalItems,
          current: subcontractors?.currentPage,
          pageSize: subcontractors?.pageSize
        }}
        onOpenClick={handleOpenEditModal}
        contractorId={Number(contractorId)}
      />
      {selectedSubcontractor && (
        <EditSubcontractorModal
          selectedSubcontractor={selectedSubcontractor}
          isModalOpen={isEditSubcontractorModalOpen}
          onCloseClick={() => setEditSubcontractorModalOpen(false)}
          refetchSubcontractors={refetchSubcontractors}
          contractorId={Number(contractorId)}
        />
      )}
    </>
  );
};
