import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "antd";

import type { ExchangeRateType } from "@components/types/models/ExchangeRates";

import type { ColumnsType } from "antd/lib/table";

type ExchangeRateTableProps = {
  readonly data?: readonly ExchangeRateType[];
  readonly loading?: boolean;
};

export const ExchangeRateTable: FC<ExchangeRateTableProps> = ({
  data,
  loading
}) => {
  const { t, i18n } = useTranslation();
  const getTranslatedColumns = useCallback(
    (): ColumnsType<ExchangeRateType> => [
      {
        title: t("exchangeRates.code"),
        dataIndex: ["currencyDto", "code"]
      },
      {
        title: t("exchangeRates.rate"),
        dataIndex: "rate"
      }
    ],
    [t]
  );

  const [columns, setColumns] = useState(getTranslatedColumns());

  useEffect(() => {
    setColumns(getTranslatedColumns());
  }, [t, i18n.language, getTranslatedColumns]);

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={false}
    />
  );
};
