import type {
  Category,
  CategoryParsed
} from "@components/types/models/Category";

const parseCategories = (
  categories: readonly Category[]
): readonly CategoryParsed[] =>
  categories.map((cat) => ({
    ...cat,
    nameRu: cat.name_ru,
    nameEn: cat.name_en,
    parentId: cat.parent_id
  }));

export const objectParsers = {
  parseCategories
};
