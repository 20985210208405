import type { FC } from "react";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link, useHistory } from "react-router-dom";

import axios from "axios";
import type { AxiosError } from "axios";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography
} from "antd";

import type { AppNotification } from "@components/types/models/AppNotification";

import { ADMIN_PERMISSION_OPTION_LIST } from "@components/constants/AdminPermissions";

import { KcCategoriesCheckboxGroup } from "./KcCategoriesCheckboxGroup";
import { PermissionCheckboxGroup } from "./PermissionCheckboxGroup";

export const InviteMember: FC = () => {
  const { t, i18n } = useTranslation();
  const [kcCategories, setKcCategories] = useState([]);

  const kcCategoryIds = kcCategories.filter((id) => id !== 0);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    clearErrors,
    setError,
    watch
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      position: "",
      permissions: [],
      notificationIds: [],
      email: "",
      phoneNumber: "",
      error: ""
    }
  });

  const { Title } = Typography;
  const history = useHistory();

  // TODO replace with API call
  const notifications = [
    {
      id: 1,
      nameEn: "Actual review expired ",
      nameRu: "Время рассмотрения отчета истекло"
    },
    {
      id: 2,
      nameEn: "Forecast review expired ",
      nameRu: "Время рассмотрения прогноза истекло"
    },
    {
      id: 3,
      nameEn: "Contact Amendment Approved",
      nameRu: "Правка контракта одобрена"
    }
  ];

  const mutation = useMutation(
    async (values: unknown) => axios.post("/api/admin/member/invite", values),
    {
      onSuccess: (_, values: any) => {
        notification.success({
          message: `${values.firstName} ${values.lastName} ${t(
            "members.inviteSuccess"
          )}`
        });
        history.push("/admin/member");
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name:
                | "email"
                | "firstName"
                | "lastName"
                | "permissions"
                | "position";
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("email", { message: errData.message });
        }
      }
    }
  );

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLInputElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );
  const handleCheckboxChange = useCallback(
    (name) => (value: unknown) => {
      setValue(name, value);
    },
    [setValue]
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values): void => {
      mutation.mutate({ ...values, kcCategoryIds });
    })();
  }, [handleSubmit, clearErrors, mutation, kcCategoryIds]);

  return (
    <Row align="middle" justify="center" style={{ width: "100%" }}>
      <Col span={18}>
        <Card title={t("members.inviteMember")}>
          <Form onFinish={handleFormSubmit}>
            <Row gutter={8}>
              <Col span={8}>
                <Form.Item
                  validateStatus={errors.firstName?.message && "error"}
                  help={errors.firstName?.message}
                >
                  <Input
                    placeholder={t("members.firstName")}
                    {...register("firstName")}
                    onChange={handleInputChange("firstName")}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  validateStatus={errors.lastName?.message && "error"}
                  help={errors.lastName?.message}
                >
                  <Input
                    placeholder={t("members.lastName")}
                    {...register("lastName")}
                    onChange={handleInputChange("lastName")}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  validateStatus={errors.position?.message && "error"}
                  help={errors.position?.message}
                >
                  <Input
                    {...register("position")}
                    placeholder={t("members.jobTitle")}
                    onChange={handleInputChange("position")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Col span={8}>
              <Form.Item
                validateStatus={errors.email?.message && "error"}
                help={errors.email?.message}
              >
                <Input
                  placeholder="Email"
                  {...register("email")}
                  onChange={handleInputChange("email")}
                />
              </Form.Item>
            </Col>
            <Title level={4}>{t("members.accessLevel")}</Title>
            <PermissionCheckboxGroup
              options={ADMIN_PERMISSION_OPTION_LIST}
              value={watch("permissions")}
              onChange={handleCheckboxChange("permissions")}
            />
            <Title level={4}>{t("members.notifications")}</Title>

            <Checkbox.Group
              style={{ marginBottom: "24px" }}
              {...register("notificationIds")}
              onChange={handleCheckboxChange("notificationIds")}
            >
              <Row style={{ rowGap: "8px" }}>
                {notifications.map((i: AppNotification) => (
                  <Col key={i.id} xs={12}>
                    <Checkbox value={i.id}>
                      {i18n.language === "en" ? i.nameEn : i.nameRu}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
            <Title level={4}>{t("members.categoryAndArea")}</Title>
            <KcCategoriesCheckboxGroup
              value={kcCategories}
              setValue={setKcCategories}
            />
            <Form.Item>
              <Row justify="end" align="middle">
                <Link to="/admin/member">{t("members.back")}</Link>
                <Button
                  htmlType="submit"
                  type="primary"
                  style={{ marginLeft: "24px" }}
                  loading={mutation.isLoading}
                >
                  {t("members.invite")}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
