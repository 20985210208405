export const contractors = [
  {
    title: "How to register Contractors?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Register contractor&quot; button on contractors page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Register&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/ajg7ntmgpYGAARlR0qzAaoNr52ETHVDe2L4lywUM0jq_bhbAlxUrhIVN0sFCHeQvkPDmbZRSRTpOWQQmsf3C-rGlaNdTIDyejkUnjFtLeqm4Kwe8t0OeAQhlg5dHswuos_c-t-KXDqt9u9WkvUmIuZpMKFXEZHnDxvs6x7Cxwbdry_6EkjaMr1nv2A" style="height:405px; width:624px" /></span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Contractor&#39;s detailed page can be accessed through Forecasts, Actuals and G1-C Forms. While reviewing the forecasts, Actuals and G1-C Forms, KC Lead has an option of directly opening the Contractor&rsquo;s page to check the company details.</span></span></span></p>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/A-bR2QRHaHrIfTZg8pB2wU6hlBjLThG7mlTZXB6jtjKUg10_cci0ZNnzw7f6YzaGWu7EBnLhvwhmSqclsmjR4jDUNDVbKdOLiDWzAjKOVyk7Zh1c2K3rXgws85W1-lqaQq1Ylro3W8hjSirJyBdbC8dEztDuia0dns_xiMIQ3GBlWgVaGWzMJLCvug" style="height:108px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to edit Contractors Information?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Edit&quot; button under Actions column</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>

<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/XieoGD6JPz42hMfDJJt_DCErG-ZCPLfHDCYSuo6KhpSYeINzs_uBFUy6BWUZPZr71AzZOYl7rP5EgtUlJp4hgLsC3IivbK0t_j9s5lXbOmPzQsrPhZ9iDBmHuzEQ857YK6L5EP9M2oSQRST86n623yxJoBHJmaV_OlFIvfSGCVWqGAmLYsliaEUJFg" style="height:137px; width:600px" /></span></span></span></p>
`
  },
  {
    title: "How to delete Contractors?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contractors</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contractor from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Scroll down to the bottom of the Contractor&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Delete Contractor&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm your request by clicking on &quot;Yes&quot; button</span></span></span></li>
</ol>

<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/OXtL6cHVF_oEe7OcxYEmRXZVSJUeHvvtwCFndxYZEXaY0YY5w-bzFAxy7II1Uj3f9pi2PHsdN5QES0UsuadhO4qX1M8a93sGkhc7dTxF8sCNuE6uLEFMHmNV91x066zfPNpYzVOfjB63y4X--AsdUSBNrVjfG0Rj12yeuTQHUVBDmA5u1Zvfi5GgZg" style="height:399px; width:541px" /></span></span></span></p>
`
  }
];
