import type { FC } from "react";
import { useTranslation } from "react-i18next/";

import { Select } from "antd";

import type { InvoicesStatusSelectorProps } from "./props";

export const InvoicesStatusSelector: FC<InvoicesStatusSelectorProps> = ({
  statuses,
  ...rest
}) => {
  const { i18n } = useTranslation();
  return (
    <Select
      options={statuses?.map((c) => ({
        value: c.id,
        label: i18n.language === "en" ? c.enName : c.ruName
      }))}
      mode="multiple"
      maxTagCount="responsive"
      {...rest}
    />
  );
};
