import type { FC } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import type { Moment } from "moment";

import { Button, Col, DatePicker, Form, Modal, Row, Typography } from "antd";

import type { AdminRejectModalProps } from "./props";

export const AdminRejectModal: FC<AdminRejectModalProps> = ({
  isModalOpen,
  onClose,
  rejectActualMutation
}) => {
  const { Paragraph } = Typography;
  const disableDate = (current: Moment): boolean =>
    current < moment().endOf("day");
  const defaultSubmissionDate = 3;
  const [dueDate, setDueDate] = useState<Moment>(
    moment(new Date(), "YYYY-MM-DD").add(defaultSubmissionDate, "days")
  );

  const { t } = useTranslation();

  const handleDateChange = useCallback((date) => {
    setDueDate(date);
  }, []);

  const handleClose = (): void => {
    onClose?.();
  };

  const handleReject = useCallback(() => {
    rejectActualMutation?.mutate(dueDate.toJSON());
  }, [dueDate, rejectActualMutation]);

  return (
    <Modal
      visible={isModalOpen}
      title={t("actuals.rejectActual")}
      closable={false}
      width={800}
      footer={
        <Row justify="space-between">
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button
            type="primary"
            loading={rejectActualMutation.isLoading}
            onClick={handleReject}
          >
            {t("yes")}
          </Button>
        </Row>
      }
    >
      <Paragraph>{t("actuals.setDueDate")}</Paragraph>
      <Col span={6}>
        <Form.Item>
          <DatePicker
            showNow={false}
            size="large"
            value={dueDate}
            style={{ width: "100%" }}
            disabledDate={disableDate}
            format="YYYY-MM-DD"
            allowClear={false}
            onChange={handleDateChange}
          />
        </Form.Item>
      </Col>
    </Modal>
  );
};
