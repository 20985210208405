export const forecasts = [
  {
    title: "О прогнозах",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Руководителям KC присваиваются определенные контракты в системе (на основе Категории и области KC), так, когда подрядчики отправляют на рассмотрение прогнозы контракта, только назначенные Руководители KC получают уведомление по электронной почте</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подрядчики создают и отправляют на рассмотрение прогнозы по каждому имеющемуся у них контракту. Один прогноз содержит планы на весь период контракта, подрядчики заполняют свои планы расходов на каждый месяц/год контракта. В зависимости от объема работ по конкретному контракту прогнозы содержат различные разделы:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Отдельное соглашение:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Товары/Работы/Услуги; Итого товары, работы, услуги; Количество сотрудников и национализация; Обучение; Передача технологии; Наследие</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Дополнительное соглашение:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Товары/Работы/Услуги; Итого товары, работы, услуги;</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Генеральное соглашение:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Итого товары, работы, услуги; Количество сотрудников и национализация; Обучение; Передача технологии; Наследие</span></span></span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подрядчики могут иметь только один прогноз по одному контракту, который может быть обновлен и иметь несколько версий, последняя утвержденная версия считается действительной и используется в дальнейших расчетах в системе. Предыдущие версии прогноза также хранятся в системе и могут быть просмотрены путем переключения между версиями на детальной странице прогноза.</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Прогнозы имеют </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>четыре статуса</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в системе:</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Прогнозы, представленные Подрядчиком и еще не рассмотренные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;На рассмотрении&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Прогнозы, представленные Подрядчиком и утвержденные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Утвержден&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Прогнозы, представленные Подрядчиком и отклоненные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Отклонен&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подрядчики, которые не представили свои прогнозы в течение 30 дней после утверждения контракта, считаются </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Просрочен&quot;</strong></span></span></span></li>
</ol>
`
  },
  {
    title: "Как отклонить прогнозы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;На рассмотрении&quot;со списка прогнозов</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Рассмотрите детали предоставленного подрядчиками прогноза по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий нажав на кнопку &quot;Оставить комментарий&quot;(Обязательно: в секции, которую необходимо исправить)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отклонить&quot; на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Укажите срок предоставления изменений прогноза для подрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите отклонение нажатием на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как утвердить прогнозы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;На рассмотрении&quot;со списка прогнозов</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Рассмотрите детали предоставленного подрядчиками прогноза по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий нажав на кнопку &quot;Оставить комментарий&quot;(Необязательно: в секции, которую необходимо исправить)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Утвердить&quot; на детальной странице выбранного прогноза</span></span></span></li>
</ol>
`
  }
];
