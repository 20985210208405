import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { RcFile } from "antd/lib/upload";

import {
  Button,
  Card,
  Radio,
  RadioChangeEvent,
  Space,
  Spin,
  Typography,
  Upload
} from "antd";
import { DownloadFileButton } from "@components/atoms/DownloadFileButton";

import { MigrationType } from "../types/MigrationTypes";

import { MIGRATIONS_TYPE_LIST } from "@components/constants/Migrations";

type MigrationUploadFormProps = {
  onFileUpload: (migrationType: MigrationType, formData: FormData) => void;
  isLoading: boolean;
};

export const MigrationUploadForm: React.FC<MigrationUploadFormProps> = ({
  onFileUpload,
  isLoading
}) => {
  const { t } = useTranslation();

  const [migrationType, setMigrationType] = useState<MigrationType>(
    MigrationType.Undefined
  );
  const handleMigrationTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      setMigrationType(e.target.value);
    },
    [setMigrationType]
  );

  const handleMigrationUpload = useCallback(
    (uploadFile: RcFile) => {
      const formData = new FormData();

      if (uploadFile) {
        formData.append("File", uploadFile);
        onFileUpload(migrationType, formData);
      }

      // To prevent upload from ant.design component itself
      return false;
    },
    [onFileUpload, migrationType]
  );

  return (
    <Spin spinning={isLoading}>
      <Card>
        <Space direction="vertical" size="large">
          <Typography.Title level={4}>
            {t("migrations.selectType")}
          </Typography.Title>

          <Radio.Group
            onChange={handleMigrationTypeChange}
            value={migrationType}
          >
            {MIGRATIONS_TYPE_LIST.map(
              (migration) =>
                migration.value !== MigrationType.Undefined && (
                  <Radio value={migration.value}>
                    {t(`migrations.typeCodes.${migration.value}`)}
                  </Radio>
                )
            )}
          </Radio.Group>

          <Space align="start">
            <DownloadFileButton
              disabled={migrationType === MigrationType.Undefined}
              url={`/api/migrations/${migrationType}/template`}
              title={t("migrations.downloadTemplate")}
              type="link"
            />

            <Upload
              fileList={[]}
              style={{ alignItems: "flex-end" }}
              beforeUpload={handleMigrationUpload}
              accept=".xlsx, .xls"
              maxCount={1}
            >
              <Button
                disabled={migrationType === MigrationType.Undefined}
                type="primary"
              >
                {t("migrations.upload")}
              </Button>
            </Upload>
          </Space>
        </Space>
      </Card>
    </Spin>
  );
};
