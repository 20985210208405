import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import type { LinkWithQueryProps } from "./props";

export const LinkWithQuery: FC<LinkWithQueryProps> = ({
  children,
  to,
  style
}) => {
  const { search } = useLocation();

  return (
    <Link to={`${to}${search}`} style={style}>
      {children}
    </Link>
  );
};
