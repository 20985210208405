import { initReactI18next } from "react-i18next";

import i18next from "i18next";

import { enTranslation } from "./en/translation";
import { ruTranslation } from "./ru/translation";

const resources = {
  en: enTranslation,
  ru: ruTranslation
};

const defaultLang = "ru";

const persistedLng = localStorage.getItem("KC_PORTAL_LNG");

if (!persistedLng) {
  localStorage.setItem("KC_PORTAL_LNG", defaultLang);
}

void i18next.use(initReactI18next).init({
  resources,
  lng: persistedLng ?? defaultLang,
  fallbackLng: defaultLang
});

i18next.on("languageChanged", (lng) => {
  localStorage.setItem("KC_PORTAL_LNG", lng);
});

export default i18next;
