import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router";

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

import { Button, Result } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { getTcoPowerBiDashboard } from "@api/powerBiApi";

export const AdminDashboardPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [reportConfig, setReportConfig] =
    useState<models.IReportEmbedConfiguration>({
      type: "report",
      // eslint-disable-next-line no-undefined
      embedUrl: undefined,
      tokenType: models.TokenType.Embed,
      // eslint-disable-next-line no-undefined
      accessToken: undefined,
      // eslint-disable-next-line no-undefined
      settings: undefined
    });

  const powerBiParamsQuery = useQuery({
    queryFn: async () => getTcoPowerBiDashboard(),
    onSuccess: ({ token, ...data }) => {
      setReportConfig({
        ...reportConfig,
        accessToken: token,
        ...data
      });
    }
  });

  const handleBackButtonClick = (): void => {
    history.push("/");
  };

  if (powerBiParamsQuery.isLoading) {
    return <Result icon={<LoadingOutlined />} title={t("powerBi.loading")} />;
  }
  return powerBiParamsQuery.data ? (
    <PowerBIEmbed embedConfig={reportConfig} cssClassName="powerbi-dashboard" />
  ) : (
    <Result
      status="500"
      style={{ height: "100%", minHeight: "100vh" }}
      title="500"
      subTitle={t("powerBi.errorMessage")}
      extra={
        <Button type="primary" onClick={handleBackButtonClick}>
          {t("powerBi.backHome")}
        </Button>
      }
    />
  );
};
