import type { AxiosResponse } from "axios";
import axios from "axios";
import { stringify } from "qs";

import type {
  ContractPaginated,
  FormValues
} from "@components/types/models/Contract";

const getContractsList = async (
  contractorId: number | undefined
): Promise<AxiosResponse> =>
  axios.get(`/api/contract/contractor/${contractorId}`);

const getAllContracts = async (): Promise<AxiosResponse> =>
  axios.get(`/api/contract`);

const getContract = async (id: number): Promise<AxiosResponse> =>
  axios.get(`/api/contract/${id}`);

const getCurrencyList = async (): Promise<AxiosResponse> =>
  axios.get(`/api/currency`);

const getContractAmendment = async (
  contractId: number
): Promise<AxiosResponse> => axios.get(`/api/contract/${contractId}/amendment`);
const deleteContract = async (id: number): Promise<AxiosResponse> =>
  axios.delete(`/api/contract/${id}`);

const getContractAmendmentsList = async (id: number): Promise<AxiosResponse> =>
  axios.get(`/api/contract/${id}/amendment`);

const approveAmendment = async (
  contractId: number,
  amendmentId: number
): Promise<AxiosResponse> =>
  axios.post(`/api/contract/${contractId}/amendment/${amendmentId}/approve`);

const rejectAmendment = async (
  contractId: number,
  amendmentId: number,
  comment?: string
): Promise<AxiosResponse> =>
  axios.post(`/api/contract/${contractId}/amendment/${amendmentId}/reject`, {
    comment
  });

const getAdminForecastsPaginated = async (
  page: number
): Promise<AxiosResponse> =>
  axios.get<ContractPaginated>(`/api/contract/contractor/58`, {
    params: { page, pageSize: 15 },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

const getSubAgreements = async (id: number): Promise<AxiosResponse> =>
  axios.get(`/api/contract/${id}/subagreements`);

const updateContract = async (
  id: number,
  payload: Pick<FormValues, "contractNumber" | "kcCategory">
): Promise<AxiosResponse> =>
  axios.put(`/api/contract/number-and-area/${id}`, payload);

export const contractApi = {
  getContractsList,
  getAllContracts,
  getContract,
  getCurrencyList,
  getContractAmendment,
  deleteContract,
  getContractAmendmentsList,
  approveAmendment,
  rejectAmendment,
  getAdminForecastsPaginated,
  getSubAgreements,
  updateContract
};
