export enum ContractorType {
  Undefined = 0,
  IndividualEntrepreneurship = 1,
  LimitedLiabilityPartnership = 2,
  JointStockCompany = 3
}

export const ContractTypeOptions = [
  {
    value: ContractorType.IndividualEntrepreneurship,
    label: `contractors.contractorTypes.${ContractorType.IndividualEntrepreneurship}`
  },
  {
    value: ContractorType.LimitedLiabilityPartnership,
    label: `contractors.contractorTypes.${ContractorType.LimitedLiabilityPartnership}`
  },
  {
    value: ContractorType.JointStockCompany,
    label: `contractors.contractorTypes.${ContractorType.JointStockCompany}`
  }
];
