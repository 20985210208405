export const enUserguides = {
  user_guide: "User guideline",
  general: "General",
  contracts: "Contracts",
  forecasts: "Forecasts",
  actuals: "Actuals",
  forecasts_general: "General forecasts help",
  forecasts_create: "Forecasts creation",
  forecasts_fillout: "Forecasts fillout",
  g1c: "G1-C Forms",
  contractors: "Contractors",
  dashboards: "Dashboards"
};
