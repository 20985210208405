export const forecasts = [
  {
    title: "Как добавить прогнозы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Руководитель КС утвердил ваш контракт</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите свой контракт в списке Прогнозы и нажмите на кнопку &quot;+&quot; под столбцом Действия</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Результат:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Статус прогноза станет &quot;Черновик&quot; и можно будет начинать процесс заполнения данных для прогнозов</span></span></span></p>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/a-guph9yBcFRwt_papA2V-cMZG_jDBbYWewkws8opi78cMxiqX-nfd0nOYhveWzvJhm4JzljPwMJoatcEaZPL-nIOu2c6pZiXyzdpdsVOx4wfGj2zECnnebUJmh-QMTgFXh5QuzufbKjKxOgHryACN42cccyhPgoOU2D3LGISR7m3IdMTi3ktduskA" style="height:164px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как создать прогнозы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> В системе существует три типа контракта - Отдельное соглашение, Дополнительное соглашение и Генеральное соглашение (Каждый контракт различается секциями для заполнения в прогнозах)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Откройте страницу прогноза &quot;Черновик&quot; нажав на номер контракта или символ &quot;i&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные во всех секциях прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/YcW-RU2eCrgLlfrLD10Z1zLI6t5HR_Mw6_N2kEqf9wc6ABvSjpdvI-Flt7wXzNj58N20v3ze9qc3smKFdIOFDE8ou13kko2oduUjg9Wf_KdkJK_iFScV3KcTlTjRnMmoy9ZK_WnCnDRj6N5HC6k7stDG1WFmaphUvTMW88bxiz415FUE4JjVLSyMWg" style="height:555px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить товары, работы и услуги в прогнозы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку &quot;Прогнозы&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+&quot; расположенной после поля &quot;Описание товара/работ/услуг&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите название товара/работы/услуги в поле &quot;Название&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите добавленный товар/работу/услугу в выпадающем списке &quot;Описание товара/работ/услуг&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+Добавить товар/работы/услуги&quot; если желаете добавить больше</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/glDNQTg6hPDCtWVGevGCP4FKrkB2IPcr_03JyFMWbqY2touIRs5XqrfOPbEngN8gqrd3hOE-69iIKWI1vfVUSs48cJUn2s9kK2bgCzkYvaE7RD4fJCPX6t08q5ZTo7DE_l5LVFIfNHb2_F9c-fNzr9jWh9Zi9SShrhwTo3Hy8fnDVpemAr3ONuwJeA" style="height:530px; width:533px" /></span></span></span></p>
`
  },
  {
    title: "Как удалить добавленные товары, работы и услуги?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Руководитель КС утвердил ваш контракт</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите ранее добавленные товары/работы/услуги и прокрутите вправо секции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;X&quot; чтобы удалить ненужные товары/работы/услуги</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/L-aCBGqza_GRfcwN0L38wShnXKkqIy0YyDRk7IEkUU7O5rgRvjM4Qno0LKAeiOsIp2nsvjOfVLVsV4eEpdCxUxHHYFIsg4h5M9n2aYO0hT05NuiLku7KLEwkoalIuNKwNRTOTG81NhyCDw7SsiJFO2qfn5WyJBlflmihCzSrvE0xPk-RTRglcvYaww" style="height:219px; width:633px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить компанию (субподрядчика) в прогнозы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+&quot; расположенной после поля &quot;Название компании&quot;:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите название Компании</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите страну из выпадающего списка Страна</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите город из выпадающего списка Город</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите БИН (если Казахстан выбран как страна)</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите описание объема работ</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите название компании в выпадающем списке Название Компании</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/T1GnvnvlQlzMy944pAKU3Cnzd15O71Ue3YBMWaN0JoK5j2UWOXiLGNXzIoBV5QnEWSQAuGBmskx1IH-_9lceiqxlpDLLUEW0hGbhKMx9koXX0H9UOCIcXcJyOzebHkojOOxNYmheuSSvUllMpsb6BTQxzK_s6J-XWo2nWgisW_v_NO3zc1oB3ewtsg" style="height:530px; width:541px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить количество сотрудников и национализацию в прогнозы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Данные по количеству сотрудников и национализации могут быть добавлены только в Отдельное и Генеральное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию &quot;Количество сотрудников и национализация&quot; на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить и перейти к следующей секции&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как добавить данные обучений в прогнозы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание: </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Данные по обучениям могут быть добавлены только в Отдельное и Генеральное соглашение</span></span></span></p>
<p>&nbsp;</p><ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию &quot;Обучение&quot; на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить и перейти к следующей секции&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как добавить информацию передачи технологии и наследия?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Данные по передаче технологии и наследия могут быть добавлены только в Отдельное и Генеральное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию &quot;Обучение&quot; и &quot;Наследие&quot; на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить и перейти к следующей секции&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как оставить комментарий в секциях прогнозов?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> В системе существует три типа контракта - Отдельное соглашение, Дополнительное соглашение и Генеральное соглашение (Каждый контракт различается секциями для заполнения в прогнозах)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте необходимую секцию на детальной странице выбранного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Оставить комментарий&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите свой комментарий</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как отправить прогнозы на рассмотрение?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> В системе существует три типа контракта - Отдельное соглашение, Дополнительное соглашение и Генеральное соглашение (Каждый контракт различается секциями для заполнения в прогнозах)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные в секциях прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения прогноза для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как обновить утвержденные прогнозы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Утвержден&quot; из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить&quot; на детальной странице утвержденного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения прогноза для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как восстановить предыдущую версию прогнозов?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Утвержден&quot; и нажмите на кнопку &quot;Обновить&quot; ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Черновик&quot; (версия &gt; 1) из списка прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Восстановить предыдущую версию&quot; на странице прогноза со статусом &quot;Черновик&quot; (версия &gt; 1)</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/rtNdat5Km7X89NdQZrRInyPzA1bJtJAubS6Gde2nz2QDtZvbEGTK0e5JFTpls-VUec_PhEjaP7XRlvO0ibqmH8JK4tqafS_28ZTacPU4p19SzzEtDYiq3pXxcTC5yMWK8mNfTMNwihJ2CeCxMg2dBhCQt1iU8OjF9XLPZGi3_C3OQQy7udHQvvXM9g" style="height:244px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как обновить отклоненные прогнозы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Прогнозы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите прогноз со статусом &quot;Отклонен&quot; из списка Прогнозы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Откройте детальную страницу отклоненного прогноза</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите необходимые изменения базируясь на комментарии оставленные Руководителем КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок для рассмотрения прогноза Руководителем КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
`
  }
];

export default forecasts;
