import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { css } from "@linaria/core";
import moment from "moment";
import tw from "twin.macro";

import { ArrowUpOutlined } from "@ant-design/icons";
import { Row, Table, Tag, Typography } from "antd";
import { LinkWithQuery } from "@components/atoms/LinkWithQuery";

import type { Actual } from "@components/types/models/Actuals";
import { ActualSorting } from "@components/types/models/Actuals";
import {
  defineContractType,
  defineScopeOfWorks
} from "@components/types/models/Contract";
import type { STATUS } from "@components/types/models/Statuses";
import { defineStatus } from "@components/types/models/Statuses";

import { useSort } from "@hooks/useSort";
import { addZero } from "@utils/addZero";

import type { ActualsTableProps } from "./props";

const { Text } = Typography;

const sortList = [
  {
    title: "contractor",
    asc: ActualSorting.ContractorAscending,
    desc: ActualSorting.ContractorDescending
  },
  {
    title: "period",
    asc: ActualSorting.PeriodAscending,
    desc: ActualSorting.PeriodDescending
  }
];

export const ActualsTable: FC<ActualsTableProps> = ({
  actualsList,
  isLoading,
  pagination,
  sort,
  onSetSort
}) => {
  const { t, i18n } = useTranslation();

  const getActualVersion = (version: number): string => ` (v${version})`;

  const { handleSort, rotate } = useSort({ sortList, defaultSortValue: sort });

  const handleChange = useCallback(
    (title: string) => (_: unknown) => {
      const outputSortValue = handleSort(title);
      onSetSort?.(outputSortValue);
    },
    [onSetSort, handleSort]
  );

  const columns = [
    {
      title: function getPeriodTitle() {
        return (
          <Row
            className={css`
              ${tw`cursor-pointer`}
            `}
            justify="space-between"
            align="middle"
            onClick={handleChange("period")}
          >
            <Text>{t("actuals.period")}</Text>
            {sort &&
              [
                ActualSorting.PeriodAscending,
                ActualSorting.PeriodDescending
              ].includes(sort) && (
                <ArrowUpOutlined
                  style={{
                    transition: "transform 0.3s linear",
                    transform: `rotate(${rotate}deg)`
                  }}
                />
              )}
          </Row>
        );
      },
      key: "period",
      render: function renderPeriod(actual: Actual) {
        return (
          <LinkWithQuery to={`/admin/actual/${actual.actualId}`}>
            {moment(`${actual.year}${addZero(actual.month)}`, "YYYYMM")
              .locale(i18n.language)
              .format("MMMM YYYY")}
            {getActualVersion(actual.version)}
          </LinkWithQuery>
        );
      }
    },
    {
      title: t("contracts.contractNumber"),
      key: "contractNumber",
      render: function renderContractNumber(actual: Actual) {
        return <Text>{actual.contractNumber}</Text>;
      }
    },
    {
      title: function getContractorTitle() {
        return (
          <Row
            className={css`
              ${tw`cursor-pointer`}
            `}
            justify="space-between"
            align="middle"
            onClick={handleChange("contractor")}
          >
            <Text>{t("contractor")}</Text>
            {sort &&
              [
                ActualSorting.ContractorAscending,
                ActualSorting.ContractorDescending
              ].includes(sort) && (
                <ArrowUpOutlined
                  style={{
                    transition: "transform 0.3s linear",
                    transform: `rotate(${rotate}deg)`
                  }}
                />
              )}
          </Row>
        );
      },
      key: "contractor",
      render: function renderContractor(actual: Actual) {
        return <Text>{actual.contractor?.name}</Text>;
      }
    },
    {
      title: t("contracts.contractType"),
      key: "contractType",
      render: function renderContractType(actual: Actual) {
        return (
          <Text>
            {i18n.language === "en"
              ? defineContractType(actual.contractType).enName
              : defineContractType(actual.contractType).ruName}
          </Text>
        );
      }
    },
    {
      title: t("contracts.scopeOfWorks"),
      dataIndex: "scopeOfWorks",
      key: "scopeOfWorks",
      render: function getScopeOfWorks(scopeOfWorks: readonly number[]) {
        return scopeOfWorks.length
          ? scopeOfWorks.map((item: number, index) => (
              <Text key={index}>
                {(index ? ", " : "") +
                  (i18n.language === "en"
                    ? defineScopeOfWorks(item).enName
                    : defineScopeOfWorks(item).ruName)}
              </Text>
            ))
          : "-";
      }
    },
    {
      title: t("contracts.kcCategoryAndArea"),
      key: "kcCategory",
      render: function renderKcCategory(actual: Actual) {
        return <Text>{actual.kcCategory?.name}</Text>;
      }
    },
    {
      title: t("contracts.status"),
      dataIndex: "status",
      key: "status",
      render: function renderStatus(status: STATUS) {
        const actualStatus = defineStatus(status);
        return (
          <Text>
            <Tag color={actualStatus.color}>
              {i18n.language === "en"
                ? actualStatus.enName
                : actualStatus.ruName}
            </Tag>
          </Text>
        );
      }
    }
  ];

  return (
    <Table
      locale={{ emptyText: t("noData") }}
      columns={columns}
      loading={isLoading}
      dataSource={actualsList}
      pagination={pagination}
      scroll={{ x: 800 }}
      rowKey="actualId"
    />
  );
};
