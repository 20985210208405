import { FC } from "react";
import { useTranslation } from "react-i18next";

import { css } from "@linaria/core";
import tw from "twin.macro";

import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Row, Col, Button, Typography } from "antd";

import { DetailsOnScopeCardProps } from "./props";

const { Text, Title } = Typography;

export const DetailsOnScopeCard: FC<DetailsOnScopeCardProps> = ({
  isLoading,
  areDetailsVisible,
  setDetailsVisible,
  incomingText,
  amountOfInitialLetters = 25
}) => {
  const [t] = useTranslation();

  return (
    <Card
      loading={isLoading}
      className={css`
        ${tw`mt-6`}
      `}
    >
      <Row>
        <div
          className={css`
            ${tw`m-4! w-full`}
          `}
        >
          <Col span={24}>
            <Row
              className={css`
                ${tw`mb-4 w-full`}
              `}
            >
              <Col span={4}>
                <Text type="secondary">
                  {t("contracts.detailsOnScopeWork")}
                </Text>
              </Col>
              <Col span={20}>
                {incomingText && incomingText.length > amountOfInitialLetters && (
                  <Button
                    size="small"
                    icon={
                      !areDetailsVisible ? (
                        <ArrowDownOutlined />
                      ) : (
                        <ArrowUpOutlined />
                      )
                    }
                    onClick={() => setDetailsVisible(!areDetailsVisible)}
                  >
                    {!areDetailsVisible ? t("showMore") : t("showLess")}
                  </Button>
                )}
              </Col>
            </Row>
            <Row
              className={css`
                ${tw`transition-all animate-bounce`}
              `}
            >
              <Title
                level={5}
                className={css`
                  ${tw`transition-all animate-bounce`}
                `}
              >
                {!areDetailsVisible &&
                incomingText &&
                incomingText.length > amountOfInitialLetters
                  ? incomingText.slice(0, amountOfInitialLetters) + "..."
                  : incomingText ?? "-"}
              </Title>
            </Row>
          </Col>
        </div>
      </Row>
    </Card>
  );
};
