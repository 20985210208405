// eslint-disable-next-line @typescript-eslint/naming-convention

import type { AxiosResponse } from "axios";
import axios from "axios";

import type { City, Country } from "@components/types/models/CountryCity";

const getAllCountries = async (): Promise<AxiosResponse> =>
  axios.get<readonly Country[]>("/api/country", {
    params: { HasCities: true }
  });
const getAllCountriesWithCities = async (): Promise<AxiosResponse> =>
  axios.get<readonly Country[]>("/api/country", {
    params: { OnlyWithCities: true }
  });
const getCountryCities = async (
  countryId: number | undefined
): Promise<AxiosResponse> =>
  axios.get<readonly City[]>("/api/city", { params: { countryId } });
const getAllCities = async (): Promise<AxiosResponse> =>
  axios.get<readonly City[]>("/api/city");

const getReportsCountriesCities = async (): Promise<
  AxiosResponse<readonly Country[]>
> =>
  axios.get("/api/country", {
    params: {
      HasCities: true,
      OnlyWithCities: false
    }
  });

export const countryCityApi = {
  getAllCountries,
  getAllCountriesWithCities,
  getCountryCities,
  getAllCities,
  getReportsCountriesCities
};
