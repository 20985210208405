export enum MemberStatus {
  Invited = 1,
  Active = 2,
  Inactive = 3,
  Deleted = 4
}

export type Member = {
  readonly id: number;
  readonly userId: number;

  readonly kcCategories: ReadonlyArray<{
    readonly id: number;
    readonly name: number;
  }>;
  readonly inviteId: number | null;
  readonly invitedAt: string;
  readonly invitationAccepted: boolean;
  readonly enableAttempted: boolean | null;

  readonly position: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phoneNumber: string;

  readonly status: MemberStatus;

  readonly permissions: ReadonlyArray<{
    readonly userId: number;
    readonly permission: number;
  }>;

  readonly roles: ReadonlyArray<{
    readonly role: number;
    readonly userId: number;
    readonly entityId: number | null;
  }>;

  readonly deletedAt: string | null;
  readonly createdAt: string;
};

export type FormValues = {
  readonly firstName?: string;
  readonly lastName?: string;
  readonly position?: string;
  readonly phoneNumber?: string;
  readonly email?: string;
};
