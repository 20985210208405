export const contracts = [
  {
    title: "How to update a Contract?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> KC Lead created a contract, it appeared in your Contracts list with the status DRAFT</span></span></span></p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on the </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a contract (DRAFT) from the Contracts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update contract&quot; button on the selected contract&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields &amp; Attach KC Plan (optional)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/5dyIBuYWftwx5oCDrMoMiZnwzHGtwF6TW0YjksCC-J4KNkV5cLWc_yny34bqkew5mz3mMlMs35M8rSQ1Pr04FA_Z-QjdBjARm0laSc0t5vf8Rl5LrZcBQsu5mdKScpFYBZt3rmvRZ9fm8LHBZfchTYplO8Zf_rpzt06wVAOL4d7jpxoWIKRqvJOAfQ" style="height:501px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to attach KC Plan?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on the </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a contract (DRAFT) from the Contracts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update contract&quot; button on the selected contract&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on Attach KC Plan button (Refer to the screenshot: &quot;How to update the Contract?&quot;)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select file and open it</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> The format must be .pdf .doc and .docx AND the maximum supported file size must be 8 MB</span></span></span></p>
`
  },
  {
    title: "How to add an Amendment?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on the </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a contract (APPROVED) from the Contracts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add amendment&quot; button on the selected contract&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/CTOqxZlub6Fp6QrFtYTdDXwZWGYGEEWjABZJzUziqzlb7nBLJVo2fBEoB2jHEQl8uYlIo7NJPUyehH_aJRJVyOB_8GA8v24JcVU1CSzwo32o1_UzsEegVSr-zOE0MBYcePgVV-fXebreUfRwi272CvhcATOY5TbeE_e9rhcE2xslAZ5EnVKTuhiAWQ" style="height:337px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to update a Rejected Contract?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on the </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Contracts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a contract (REJECTED) from the Contracts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update and resubmit contract&quot; button on the selected contract&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/nFakQkrwojBQvfGKIqnndgGD-mBT-kKQK9ldvJ2aLHb4LfS2Nqvnzl2ZiQo76ERm-fQ1GtRm3hQgBLNAULb6HZGM0scKqFIJfhNJJTPVSEZY_EuwPlD8BregZHk0QVic4K9x4ljjGOFraKSuU53QCxEHrcOxtD50zyJ9kvE8xvLbolSmsjVSj34yNA" style="height:341px; width:624px" /></span></span></span></p>
`
  }
];
