import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";

import {
  Card,
  Col,
  List,
  notification,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography
} from "antd";
import {
  AimOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import { RelationType } from "@components/types/models/Subcontractor";

import { contractorApi } from "@api/contractorApi";

import { UserContext } from "@contexts/userContext";

const { Title } = Typography;
const LOCALE_PATH = "subcontractors.profile";

const APPROVED_TAG = {
  status: "approved",
  color: "green"
};
const PENDING_TAG = {
  status: "pending",
  color: "orange"
};

const iconsList = [
  { name: "name", icon: <AimOutlined /> },
  { name: "bin", icon: <DatabaseOutlined /> },
  { name: "city", icon: <EnvironmentOutlined /> },
  { name: "country", icon: <EnvironmentOutlined /> },
  { name: "detailOnScopeOfWork", icon: <ContainerOutlined /> },
  { name: "relationType", icon: <ExclamationCircleOutlined /> }
];

export const SubcontractorProfilePage: FC = () => {
  const [t, i18n] = useTranslation();
  const { id, subId } = useParams<{
    readonly id: string;
    readonly subId: string;
  }>();
  const { isAdmin } = useContext(UserContext);

  const [listFields, setListFields] = useState([
    "name",
    "bin",
    "country.nameRu",
    "city.nameRu",
    "detailOnScopeOfWork",
    "contractualRelations.relationType"
  ]);

  const {
    data: subcontractorData,
    isFetching,
    refetch: refetchSubcontractor
  } = useQuery("getSubcontractorProfile", async () =>
    contractorApi
      .getSubcontractorProfile(Number(id), subId)
      .then((res) => res.data)
  );

  const handleSubcontractorReviewStatus = useMutation(
    () =>
      contractorApi
        .changeSubcontractorReviewStatus({
          contractorId: Number(id),
          companyId: subId
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        notification.success({
          message: t(`${LOCALE_PATH}.reviewSuccess`)
        });
        refetchSubcontractor();
      },
      onError: () => {
        notification.error({
          message: t(`${LOCALE_PATH}.reviewError`)
        });
      }
    }
  );

  const reviewedTag = useMemo(
    () => (subcontractorData?.reviewed ? APPROVED_TAG : PENDING_TAG),
    [subcontractorData?.reviewed]
  );

  useEffect(() => {
    if (subcontractorData && !subcontractorData.reviewed && isAdmin) {
      handleSubcontractorReviewStatus.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin, subcontractorData?.reviewed]);

  useEffect(() => {
    setListFields(
      listFields.map((field) => {
        if (field.includes("country") || field.includes("city")) {
          return i18n.language === "ru"
            ? field.replace("nameEn", "nameRu")
            : field.replace("nameRu", "nameEn");
        }

        return field;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const isStringInArray = (keysArr: string[], string: string) =>
    keysArr?.includes(string);

  const getContractorIconByKeysArr = useCallback((keysArr: string[]) => {
    for (const item of iconsList) {
      if (isStringInArray(keysArr, item.name)) {
        return item.icon;
      }
    }
  }, []);

  const getContractorDescriptionByKeysArr = useCallback(
    (keysArr: string[], subcontractor) => {
      return (
        keysArr?.reduce((acc, key) => {
          if (acc && key === "relationType") {
            return t(
              `${LOCALE_PATH}.relationType.${RelationType[acc.relationType]}`
            );
          }

          return acc && acc[key];
        }, subcontractor) || "-"
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getContractorTitleByKeysArr = useCallback(
    (keysArr: string[], key: string) => {
      if (isStringInArray(keysArr, "country")) {
        return t(`${LOCALE_PATH}.country`);
      }

      if (isStringInArray(keysArr, "city")) {
        return t(`${LOCALE_PATH}.city`);
      }

      if (isStringInArray(keysArr, "relationType")) {
        return t(`${LOCALE_PATH}.relation`);
      }

      return t(`${LOCALE_PATH}.${key}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getContractorInfoByKey = useCallback(
    (key: string, subcontractor) => {
      const keysArr = key.split(".");

      if (key === "bin" && !subcontractor.bin) {
        return null;
      }

      const contractorDetails = {
        description: getContractorDescriptionByKeysArr(keysArr, subcontractor),
        title: getContractorTitleByKeysArr(keysArr, key),
        avatar: getContractorIconByKeysArr(keysArr)
      };

      return contractorDetails;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <Row>
      {!isFetching ? (
        <>
          <Col span={24}>
            <Title level={1}>{subcontractorData?.name}</Title>
          </Col>

          <Col span={24}>
            <Card
              title={
                <Space>
                  {t(`${LOCALE_PATH}.title`)}
                  {!handleSubcontractorReviewStatus.isLoading ? (
                    <Tag
                      style={{ marginLeft: "24px" }}
                      color={reviewedTag.color}
                    >
                      {t(`${LOCALE_PATH}.${reviewedTag.status}`)}
                    </Tag>
                  ) : (
                    <Skeleton paragraph={{ rows: 0 }} active />
                  )}
                </Space>
              }
              loading={isFetching}
            >
              <Skeleton title={false} loading={isFetching} active>
                <List
                  dataSource={listFields}
                  itemLayout="horizontal"
                  renderItem={(key) => {
                    const contractorInfo = getContractorInfoByKey(
                      key,
                      subcontractorData
                    );

                    return (
                      contractorInfo && (
                        <List.Item>
                          <List.Item.Meta
                            avatar={contractorInfo.avatar}
                            description={contractorInfo.description}
                            title={contractorInfo.title}
                          />
                        </List.Item>
                      )
                    );
                  }}
                />
              </Skeleton>
            </Card>
          </Col>
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 10 }} />
      )}
    </Row>
  );
};
