export const enProfile = {
  changePassword: "Change password",
  currentPassword: "Current password",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  cancel: "Cancel",
  change: "Change",
  name: "Name",
  accountInfo: "Account info",
  personalInformation: "Personal information",
  requiredField: "The field is required",
  doNotMatch: "The passwords do not match",
  passwordChangeSuccess: "Password updated successfully",
  role: "Role"
};
