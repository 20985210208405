export const gettingStarted = [
  {
    title: "How to access Account Information?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Log in to the system</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>KC PORTAL</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> label on the left corner of a horizontal menu OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on your </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>NAME</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> on the right corner of a horizontal menu</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/j-YIhMyIy-uCOJjt9WsDwppXBVH_6LOK6znEZ8KQnL8ptwoyf7i1s1KyvqgwgGy0eeH5NItBBCdXAROEYzeWfhfQKAv23AePU7AZgMxjhDFuNnzltkMuz_sQ_9vftcf2UvSH3ffhx0qj-en-rR5tRaTtBU-qLJ8I78H4QtDq1c6FVqPK5y4scYgWog" style="height:99px; width:624px" /></span></span></span></p>

`
  },
  {
    title: "How to invite members to the system?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Invite member&quot; button on the Members page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Invite&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/wv494BJrkTxoXqAYFIjchQXS1ERbE_VMEazXHPRiulu-4fcUi0WeZac989hZdy0ffDYVWNUB1leHDlEfy7H2DKHXQ7UW8o1tOiaz0ZwR9B9sepKyqSClXvQZ2S7xhuivwkZSWIVwip716oSZCgJS8kdZCw0yxgzhXln-FJoCZLQ7fR5alujBsaKzbg" style="height:95px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to edit Active member’s Account Information?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a member from Active members list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Edit&quot; button under Actions column on the Members page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/51TUinMQeZvJC8tQwOQKAX0VwqmnDIuR34slJ-7koH0q7KMb6qogGdEO63d_mbDRg50r9eVxU5Kz5k5e-Dt4W4FA4AP8ncoVqvihl5DKMPiIBOgDdKh94W8YaYNOINiCk6KQMcbuQftIyCP0KVRqc12tbKVvD_5r3lZ-yy9lDH2Nzu31BU14s47Tbg" style="height:147px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to activate Inactive members?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a member from Inactive members list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Activate&quot; button under Actions column on the Members page</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/-_89mRVwJ-cYlhDzkVxvcSsIOTGgwwoOZCmiuFJAAOZD2iUMmLG6JYmd0OdhCWSX2zJz6N3XkD5zAVdYVIYQxoGgbat9c_vg18QokyY59FnSEGbM8RMVwTQ8ilkXHbIFn_GBny9lAEDD_h7xIRwGikw25dL3BWF2NJiSeo3CSLf1QGfAWhkY1iZXfg" style="height:88px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to resend invitations to Invited members?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a member from Invited members list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Resend&quot; button under Actions column on the Members page</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/rTHKAzMImtF8KKV4Y8X_WAKqu7mGGSqGw6zlNO-syOstc9Dk74ZN5nX19DRfb9kNvd8n-ruhBve5uB-vkeEhHQyG5vNwkml_jdfoep8GEf57SoA4TXkf9oAgepVnln19WGFMDGOM2FAzu-hFvst09psMVW0KGfTC9x-YHmQjyssbUKSofOzMBXZQBw" style="height:116px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to delete members?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a member from members list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Delete&quot; button under Actions column on the Members page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm the action by clicking on &quot;Yes&quot; button</span></span></span></li>
</ol>

<p><img src="https://lh3.googleusercontent.com/qXFzi8i8lzjCnW3dKeQtaHuKPUsn1AZoMx6Vv8BPg2zFcLcnKW8HmfsZalQGNNorTFanC_vViBkXiMjwULNv_EsTICI3qoE5SFTPuU3pdH8j4ZQa9ryo60KMbHyPWCRWoJ8ruwC4VrTqBT3D5ZtmSg3O6cyNPhRsToP6D0GtPF92jf7d090F3WGLYw" width="654" height="113"></p>
`
  },
  {
    title: "How to Update Exchange Rates?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Settings</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Exchange rates</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update&quot; button on Exchange Rates page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select date of exchange rates update</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Update required rates</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/Pu2mklSd7Gs2TkayWrLeW7wZtp71wii6tZvSTnpwMhZ_CgDdACBK-KiFxsX7KdvBNE2SL97GC3OPLSKAmAC2hcFAev-uxwBwEEs2treDIKb7CGE12TVPCbQDQ1azs8VE-EQuDHvF71tay0c2X3XzQPbC6nma7glVjy6vlllhy__JO1iqJbXqeoRjVA" style="height:552px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Add Currency to Exchange Rates?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Settings</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Exchange rates</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add Currency&quot; button on Exchange Rates page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select currency from Currency drop-down list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/L4n--D0SylbFLRdfriIZjbgOowIj1rRst9MfmXax77U_--Zzhyx66-uKWUPtvSztBuRB-Pd_NgHLM2O7LZoLYpsljrZT83Y4nTxNsxFXPuMk2AVBZdW8d0QJ25gu2C2tzfvRPwRVAkPV8gXAAQImSDPmNMB3tI12NodEjvnhMqE3e8i2hNmMdAcV5A" style="height:328px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to download data migration templates?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Settings</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Migration</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;New migration&quot; button on Migrations page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a data type</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Download template&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/jKT4z7btqgQ5yBmbeBzCFH-jLZ9c6z0L2SJx_r8lxAB15H_xpcLow0kY7DZCpQ0HzpdOtb9Tcc0_ir6HsJSCYzlu-Wn2KUvh77PqQGXZFMQF6vcV-VW6pV-jMXSzOO3HN0ub8pq2HKm7XSCA1ZcW8MNucx0i5cfgIo__bZr8YnyKK444GwjJBAdi7g" style="height:336px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to migrate data?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Settings</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Migration</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;New migration&quot; button on Migrations page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a data type</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Upload&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select a file that you would like to upload</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Open&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong><img src="https://lh6.googleusercontent.com/bVcx4XiVETH7qUNzVoG0bt_mmVEncC1Lt-vL5sgo8F0yo6k6yeJx1_XxT44FvOSNA2qq28DnurtOGqsKGNLbNXivM0TEDB2pMSvti23Eqm5orIl2XNbRlXxaunG2Si48EWSyCWkh8TL6EHA9QrSCKqXBW6FSwW3ha3gK1hYql1XT_5NBaMsR6Z6scA" style="height:429px; width:624px" /></strong></span></span></span></p>
`
  },
  {
    title: "How to add a country to the Countries & Cities list?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Settings</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Countries and cities</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add new country&quot; button on Countries and cities page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/wMpADC5Ff7iawxYAQ09B-4XlWQ6yK8Q5S1qoRnxVgIck4Zd4L35qacg5dslUlG-erbZpsHXWJMj8vUSQCkOzgVBy4GBgvQ_EzQvj7Cw-PYdo3BG4PnnayT3nLkIo6Zdu3i1cmRjJgvcOSndTRMC9BAzN5ePn83m22ylAWi7BGlweV2KM7uL6B54WAA" style="height:331px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to add a city to the Countries & Cities list?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Settings</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Countries and cities</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add new city&quot; button on Countries and cities page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select country from Country drop-down list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Region (if Kazakhstan is selected)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add&quot; button</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/56VYCQEUbmIpYOJhoPeDCwriAg3PV9sNsAjWnVP0crNF2TxCTcUjtaqw-gKbQZ4XJGmpVbetiBBxfGnunHM0K7Lj56_N4veqwrMShLqO7rmFvUAngafDPg8znyLU-hEJt6PQudTCnR_Mq96g32ono_PFG90eJn62Q1pkJJ1x7OarBbFgN2OkYR6JSg" style="height:420px; width:624px" /></span></span></span></p>
`
  }
];
