import type { FC } from "react";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import axios from "axios";
import type { AxiosError } from "axios";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Form, Input, message, Row, Typography } from "antd";
import { CommentCard } from "@components/organisms/CommentCard";

import { SectionType } from "@components/types/models/Forecast";

import type { LegacyTechnologyFormProps } from "./props";

export const LegacyTechnologyForm: FC<LegacyTechnologyFormProps> = ({
  forecastId,
  sectionType,
  placeholder,
  setActiveCollapseItem,
  activeCollapseItem,
  url,
  forecastStatus,
  isEditable
}) => {
  const { TextArea } = Input;
  const { Text } = Typography;
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm();

  const { data, refetch } = useQuery(["getData", forecastId], async () =>
    axios.get(url).then((res) => res.data)
  );

  useEffect(() => {
    setValue("legacyPlan", data?.legacyPlan);
    setValue("techTransferPlan", data?.techTransferPlan);
  }, [setValue, data]);

  const { mutate: mutation, isLoading: isMutationLoading } = useMutation(
    async (values: unknown) => axios.post(url, values).then((res) => res.data),
    {
      onSuccess: () => {
        void message.success(t("saved"));
        setActiveCollapseItem(activeCollapseItem + 1);
        void refetch();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        errData.validationErrors.forEach(
          (error: { readonly description: string }): void => {
            setError("error", { message: error.description });
          }
        );
      }
    }
  );

  const handleTextareaChange = useCallback(
    (name) => (e: React.FormEvent<HTMLTextAreaElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values) => {
      mutation(
        sectionType === SectionType.Legacy
          ? { legacyPlan: values.legacyPlan }
          : { techTransferPlan: values.techTransferPlan }
      );
    })();
  }, [handleSubmit, sectionType, mutation, clearErrors]);

  return (
    <>
      <Form onFinish={handleFormSubmit}>
        <Form.Item
          style={{ width: "100%" }}
          validateStatus={errors.error?.message && "error"}
          help={errors.error?.message}
        >
          {isEditable ? (
            <TextArea
              style={{ width: "100%" }}
              placeholder={placeholder}
              value={watch(
                sectionType === SectionType.Legacy
                  ? "legacyPlan"
                  : "techTransferPlan"
              )}
              maxLength={255}
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={handleTextareaChange(
                sectionType === SectionType.Legacy
                  ? "legacyPlan"
                  : "techTransferPlan"
              )}
            />
          ) : (
            <Text>
              {watch(
                sectionType === SectionType.Legacy
                  ? "legacyPlan"
                  : "techTransferPlan"
              )}
            </Text>
          )}
        </Form.Item>
        <Row
          justify="end"
          className={css`
            ${tw`mt-4`}
          `}
        >
          {isEditable && (
            <Button
              htmlType="submit"
              className="secondary-button"
              loading={isMutationLoading}
            >
              {t("forecasts.saveAndGoToTheNextSection")}
            </Button>
          )}
        </Row>
      </Form>
      <CommentCard
        id={forecastId}
        sectionType={sectionType}
        status={forecastStatus}
      />
    </>
  );
};
