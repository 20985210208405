export const g1c = [
  {
    title: "О G1-C Формах",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C формы создаются Подрядчиками на основе утвержденных фактических показателей Отдельных и Дополнительных соглашений. Фактические показатели за определенный месяц G1-C периода должны быть утверждены, чтобы можно было создать G1-C форму на этот определенный период, без утвержденных фактических показателей создание форм невозможно.</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C формы имеют следующие </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>статусы</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">:</span></span></span></p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Формы, представленные Подрядчиком и еще не рассмотренные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;На рассмотрении&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Формы, представленные Подрядчиком и утвержденные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Утвержден&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Формы, представленные Подрядчиком и отклоненные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Отклонен&quot;</strong></span></span></span></li>
</ol>
`
  },
  {
    title: "Как открыть PDF G1-C формы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;На рассмотрении&quot;ИЛИ &quot;Утвержден&quot;из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Открыть G1-C&quot;на детальной страницы выбранной G1-C формы</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/EBXmyx04dUdpaZ9CjOs8ZLMAyTFmlyeR5KtRqKS9cw5Sut7-siyVzV9D_YPp2imgetVK8zbk4WtWxb7YjtWejomdCr474Kb2FZPnxLbB2KmKAhOkoJkSMSwb5LEqryc7CNyfYTljtSPZBbyYeP64JhUM4Z-ZGpAAIycMh-WwdX3rTKwBwZZu-Gf0tg" style="height:267px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как скачать PDF G1-C формы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;G1-C формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;На рассмотрении&quot;ИЛИ &quot;Утвержден&quot;из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Открыть G1-C&quot;на детальной страницы выбранной G1-C формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Скачать&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/05_aQY0fu_NzPTfoh3fVxaSZY3D2Zz6XezCZnB74hrCzMmeIsJlNZzqAFUt7K_fJrSRVpRQviCxLyOCxKqpdS-wMCdun15fQIHwD8O31vGbgLaKVydAw9-1-bzuVq-1hOMHR-lqfY-OJIH6Zm05Mw-4aCq_q7BEasQANVGfXxnGfRanjeGQ1itQ8MA" style="height:505px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как отклонить G1-C форму?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;На рассмотрении&quot;из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Рассмотрите детали предоставленной G1-C формы по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий нажав на кнопку &quot;Оставить комментарий&quot;(Обязательно: в секции, которую необходимо исправить)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отклонить&quot; на детальной странице выбранной G1-C формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Укажите срок предоставления изменений G1-C формы для подрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите отклонение нажатием на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как утвердить G1-C форму?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;На рассмотрении&quot;из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Рассмотрите детали предоставленной G1-C формы по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий нажав на кнопку &quot;Оставить комментарий&quot;(Необязательно: в секции, которую необходимо исправить)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Утвердить&quot; на детальной странице выбранной G1-C формы</span></span></span></li>
</ol>
`
  }
];
