import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import numeral from "numeral";
import axios from "axios";
import type { AxiosError } from "axios";
import { css } from "@linaria/core";
import tw from "twin.macro";

import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography
} from "antd";
import { useDefaultQuery } from "@hooks";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import type {
  InvoiceGwsItem,
  InvoiceGwsName
} from "@components/types/models/Invoices";

import type { SelectValue } from "antd/es/select";
import { isFloat, isPercentage } from "@utils/validate";

import type { InvoiceGwsFormProps } from "./props";

type FormValues = {
  readonly kcShare?: number;
  readonly gwsId?: number;
  readonly invoicedValue?: string;
  readonly rowIndex: number;
};

export const InvoiceGwsForm: FC<InvoiceGwsFormProps> = ({
  getGwsListUrl,
  currency,
  gwsColumnTitle,
  addRowButtonTitle,
  addGwsMutationUrl,
  submissionSuccessMessage,
  kcShareColumnTitle,
  getGwsNamesListUrl,
  handleProceed,
  invoiceStartDate,
  invoiceEndDate,
  isEditable
}) => {
  const [selectedRow, setSelectedRow] = useState<number>(0);
  const [data, setData] = useState<readonly FormValues[]>([]);
  const { Text } = Typography;
  const percentSymbol = "%";
  const hundredPercent = 100;
  const { Option } = Select;

  const { Column, Summary } = Table;
  const { t } = useTranslation();
  const {
    formState: { errors },
    clearErrors,
    setError
  } = useForm();

  const { data: gwsList, isLoading: isGwsListLoading } = useDefaultQuery(
    ["getGwsList", getGwsListUrl],
    async () => axios.get(getGwsListUrl).then((res) => res.data)
  );

  const { data: gwsNamesList } = useDefaultQuery(
    ["getGwsNamesList", getGwsNamesListUrl],
    async () =>
      axios
        .get(getGwsNamesListUrl, {
          params: {
            start: invoiceStartDate,
            end: invoiceEndDate
          }
        })
        .then((res) => res.data)
  );

  const handleValueChange = (
    value: SelectValue | string,
    fieldName: string,
    rowId: number
  ): void => {
    setData((prevData) =>
      prevData.map((item, index) => {
        if (index === rowId) {
          return { ...prevData[index], [fieldName]: value };
        }
        return item;
      })
    );
  };
  const handleRowChange = (value?: number): void => {
    setSelectedRow(value ?? 0);
  };
  const emptyRow = useMemo(
    () => ({
      kcShare: global.undefined,
      gwsId: global.undefined,
      invoicedValue: global.undefined,
      rowIndex: data.length > 1 ? data.length - 1 : 0
    }),
    [data]
  );

  useEffect(() => {
    if (gwsList?.length > 0) {
      setData(
        gwsList.map((item: InvoiceGwsItem, index: number) => ({
          kcShare: item.kcShare,
          gwsId: item.itemId,
          invoicedValue: item.value,
          rowIndex: index,
          kcAmount: item.kcAmount,
          gwsName: item.item.title
        }))
      );
    } else if (!isEditable && gwsList?.length < 1) {
      setData([]);
    } else {
      setData([emptyRow]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gwsList]);

  const handleAddRowClick = (): void => {
    setData([...data, { ...emptyRow, rowIndex: data.length }]);
  };

  const handleDeleteClick = (index: number): void => {
    setData(data.filter((item) => item.rowIndex !== index));
  };

  const addGwsMutation = useMutation(
    async (values: unknown) => axios.put(addGwsMutationUrl, values),
    {
      onSuccess() {
        void message.success(submissionSuccessMessage);
        handleProceed();
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("message", { message: errData.message });
        }
      }
    }
  );

  const handlePrePopulateClick = (): void => {
    setData(
      gwsNamesList.map((item: InvoiceGwsName, index: number) => ({
        kcShare: item.kcShare,
        gwsId: item.itemId,
        invoicedValue: item.invoicedValue,
        rowIndex: index,
        kcAmount: item.kcValue
      }))
    );
  };

  const handleFormSubmit = (): void => {
    const filteredData = data.map((object) =>
      Object.fromEntries(
        Object.entries(object).filter(
          ([key, v]) => v !== "" && key !== "rowIndex"
        )
      )
    );
    const values = {
      invoiceItems: filteredData.map((item) => ({
        itemId: item.gwsId,
        kcShare: item.kcShare,
        value: item.invoicedValue
      }))
    };
    clearErrors();
    addGwsMutation.mutate(values);
  };

  return isEditable ? (
    <>
      <Button
        type="primary"
        className={css`
          ${tw`mb-4`}
        `}
        onClick={handlePrePopulateClick}
      >
        {t("actuals.prepopulate")}
      </Button>
      <Form onFinish={handleFormSubmit}>
        <Table
          locale={{ emptyText: t("noData") }}
          dataSource={data}
          loading={isGwsListLoading}
          pagination={false}
          summary={() => {
            const totalInvoicedValue = data
              .map((gwsItem) => Number(gwsItem.invoicedValue ?? 0))
              .reduce((prevValue, currentValue) => prevValue + currentValue, 0);

            const totalKcValue = data.reduce(
              (total, currentGwsItem) =>
                total +
                (Number(currentGwsItem.kcShare) / hundredPercent) *
                  Number(currentGwsItem.invoicedValue),
              0
            );

            const totalKcShare =
              (totalKcValue / totalInvoicedValue) * hundredPercent;

            return (
              <Summary>
                <Summary.Row>
                  <Summary.Cell index={0}>{t("total")}</Summary.Cell>
                  <Summary.Cell index={1} />
                  <Summary.Cell index={2}>
                    {totalKcShare
                      ? `${numeral(totalKcShare).format(
                          "0,0.00"
                        )} ${percentSymbol}`
                      : "-"}
                  </Summary.Cell>
                  <Summary.Cell index={3}>
                    {totalInvoicedValue
                      ? `${numeral(totalInvoicedValue).format(
                          "0,0.00"
                        )} ${currency}`
                      : "-"}
                  </Summary.Cell>
                  <Summary.Cell index={4}>
                    {totalKcValue
                      ? `${numeral(totalKcValue).format("0,0.00")} ${currency}`
                      : "-"}
                  </Summary.Cell>
                </Summary.Row>
              </Summary>
            );
          }}
          onRow={(_, rowIndex) => ({
            onClick: () => {
              handleRowChange(rowIndex);
            }
          })}
        >
          <Column
            key="rowIndex"
            width={50}
            dataIndex="rowIndex"
            render={(title) => <Text>{Number(title) + 1}</Text>}
          />
          <Column
            key="kcGoodsId"
            width={300}
            title={gwsColumnTitle}
            render={(item) => (
              <Form.Item
                validateStatus={
                  errors.invoiceItems?.[item?.rowIndex]?.ItemId && "error"
                }
                help={errors.invoiceItems?.[item?.rowIndex]?.ItemId?.message}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  value={item.gwsId}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    const currentGwsItem = gwsNamesList.find(
                      (gwsItem: InvoiceGwsName) => gwsItem.itemId === value
                    );

                    handleValueChange(value, "gwsId", selectedRow);
                    handleValueChange(
                      currentGwsItem.kcShare,
                      "kcShare",
                      selectedRow
                    );
                  }}
                >
                  {gwsNamesList?.map(
                    (gwsItem: InvoiceGwsName, index: number) => {
                      const optionTitle = `${gwsItem.item.title} (${gwsItem.kcShare} %)`;
                      return (
                        <Option key={index} value={gwsItem.itemId}>
                          {optionTitle}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            )}
          />
          <Column
            key="kcShare"
            title={kcShareColumnTitle}
            render={(item) => (
              <Form.Item
                validateStatus={
                  errors.invoiceItems?.[item?.rowIndex]?.KcShare && "error"
                }
                help={errors.invoiceItems?.[item?.rowIndex]?.KcShare?.message}
              >
                <Space align="center">
                  <Input
                    value={item.kcShare}
                    type="number"
                    onChange={(e) => {
                      if (
                        isPercentage(e.currentTarget.value) &&
                        isFloat(e.currentTarget.value)
                      ) {
                        handleValueChange(
                          e.target.value,
                          "kcShare",
                          selectedRow
                        );
                      }
                    }}
                  />
                  <Text>{percentSymbol}</Text>
                </Space>
              </Form.Item>
            )}
          />
          <Column
            key="value"
            title={t("invoices.invoicedValue")}
            render={(item) => (
              <Form.Item
                validateStatus={
                  errors.invoiceItems?.[item?.rowIndex]?.Value && "error"
                }
                help={errors.invoiceItems?.[item?.rowIndex]?.Value?.message}
              >
                <Row gutter={8} align="middle">
                  <Col span={18}>
                    <Input
                      value={item.invoicedValue}
                      type="number"
                      onChange={(e) => {
                        if (isFloat(e.target.value)) {
                          handleValueChange(
                            e.target.value,
                            "invoicedValue",
                            selectedRow
                          );
                        }
                      }}
                    />
                  </Col>
                  <Col span={6}>
                    <Text>{currency}</Text>
                  </Col>
                </Row>
              </Form.Item>
            )}
          />
          <Column
            title={t("invoices.kcValue")}
            render={(item) => {
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              const kcValue = item.invoicedValue * (item.kcShare / 100);
              return (
                <Form.Item>
                  {kcValue
                    ? `${numeral(kcValue).format("0,0.00")} ${currency}`
                    : "-"}
                </Form.Item>
              );
            }}
          />
          <Column
            key="action"
            width={100}
            render={(item) => (
              <Form.Item>
                <Button
                  danger
                  type="text"
                  title={t("delete")}
                  icon={<CloseOutlined />}
                  onClick={() => {
                    handleDeleteClick(item.rowIndex);
                  }}
                />
              </Form.Item>
            )}
          />
        </Table>
        <Row style={{ marginTop: "24px" }} justify="space-between">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddRowClick}
          >
            {addRowButtonTitle}
          </Button>
          <Button
            htmlType="submit"
            className="secondary-button"
            style={{ marginLeft: "auto" }}
            loading={addGwsMutation.isLoading}
          >
            {t("save")}
          </Button>
        </Row>
      </Form>
    </>
  ) : (
    <Table
      locale={{ emptyText: t("noData") }}
      dataSource={data}
      loading={isGwsListLoading}
      pagination={false}
      summary={() => {
        const totalInvoicedValue = data
          .map((gwsItem) => Number(gwsItem.invoicedValue ?? 0))
          .reduce((prevValue, currentValue) => prevValue + currentValue, 0);
        const totalKcValue = data.reduce(
          (total, currentGwsItem) =>
            total +
            (Number(currentGwsItem.kcShare) / hundredPercent) *
              Number(currentGwsItem.invoicedValue),
          0
        );
        const totalKcShare =
          (totalKcValue / totalInvoicedValue) * hundredPercent;
        return (
          <Summary>
            <Summary.Row>
              <Summary.Cell index={0}>{t("total")}</Summary.Cell>
              <Summary.Cell index={1} />
              <Summary.Cell index={2} align="right">
                {totalKcShare
                  ? `${numeral(totalKcShare).format("0,0.00")} ${percentSymbol}`
                  : "-"}
              </Summary.Cell>
              <Summary.Cell index={3} align="right">
                {totalInvoicedValue
                  ? `${numeral(totalInvoicedValue).format(
                      "0,0.00"
                    )} ${currency}`
                  : "-"}{" "}
              </Summary.Cell>
              <Summary.Cell index={4} align="right">
                {totalKcValue
                  ? `${numeral(totalKcValue).format("0,0.00")} ${currency}`
                  : "-"}
              </Summary.Cell>
            </Summary.Row>
          </Summary>
        );
      }}
    >
      <Column
        key="rowIndex"
        fixed
        dataIndex="rowIndex"
        width={50}
        render={(title) => <Text>{Number(title) + 1}</Text>}
      />
      <Column
        key="gwsName"
        title={gwsColumnTitle}
        width={300}
        dataIndex="gwsName"
      />
      <Column
        key="kcShare"
        title={kcShareColumnTitle}
        align="right"
        dataIndex="kcShare"
        render={(kcShare) => (
          <Text>
            {kcShare
              ? `${numeral(kcShare).format("0,0.00")} ${percentSymbol}`
              : "-"}
          </Text>
        )}
      />
      <Column
        key="invoicedValue"
        title={t("invoices.invoicedValue")}
        dataIndex="invoicedValue"
        render={(invoicedValue) => (
          <Text>
            {numeral(invoicedValue).format("0,0.00")} {currency}
          </Text>
        )}
      />
      <Column
        align="right"
        title={t("invoices.kcValue")}
        dataIndex="kcAmount"
        render={(kcAmount) => (
          <Text>
            {numeral(kcAmount).format("0,0.00")} {currency}
          </Text>
        )}
      />
    </Table>
  );
};
