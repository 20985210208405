import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { defineUserRole } from "@contexts/userContext";

export const useUserRole = (): {
  readonly getUserRole: (
    userRoles: ReadonlyArray<{
      readonly role: number;
      readonly userId: number;
      readonly entityId: number | null;
    }>
  ) => string;
} => {
  const { t } = useTranslation();

  const handleDefineUserRoleTranslated = useCallback(
    (role: number | undefined) => {
      const roleDefined = defineUserRole(role);
      switch (roleDefined) {
        case "contractor":
          return t("contractor");
        case "subcontractor":
          return t("subcontractor");
        case "admin":
          return t("kcLead");
        default:
          return t("guest");
      }
    },
    [t]
  );

  const getUserRole = (
    userRoles: ReadonlyArray<{
      readonly role: number;
      readonly userId: number;
      readonly entityId: number | null;
    }>
  ): string =>
    `[${userRoles.reduce(
      (role, ur) =>
        role.length > 0
          ? `${role}, ${handleDefineUserRoleTranslated(ur.role)}`
          : `${handleDefineUserRoleTranslated(ur.role)}`,
      ""
    )}]`;

  return { getUserRole };
};
