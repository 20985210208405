import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { InvoiceButtonsProps } from "@components/molecules/InvoiceButtons/props";

import { STATUS } from "@components/types/models/Statuses";

import { UserRoles } from "@contexts/types/UserRoles";

export const InvoiceButtons: FC<InvoiceButtonsProps> = ({
  userRole,
  invoiceData,
  onPreviewInvoicePdf: handlePreviewInvoicePdf,
  handleEditClick,
  handleRestorePreviousInvoice,
  isPreviousInvoiceMutationLoading,
  isFormOpen,
  handleUpdateApprovedInvoice,
  isUpdateApprovedInvoiceMutationLoading
}) => {
  const { t } = useTranslation();

  return !isFormOpen ? (
    <Space>
      {userRole === UserRoles.Contractor &&
        invoiceData?.status === STATUS.APPROVED &&
        invoiceData.id ===
          invoiceData.versions[invoiceData.versions.length - 1].id && (
          <>
            <Button
              type="primary"
              loading={isUpdateApprovedInvoiceMutationLoading}
              onClick={handleUpdateApprovedInvoice}
            >
              {t("update")}
            </Button>
            <Button onClick={handlePreviewInvoicePdf}>
              {t("invoices.openG1c")}
            </Button>
          </>
        )}
      {userRole === UserRoles.Admin &&
        invoiceData?.status === STATUS.APPROVED && (
          <Button onClick={handlePreviewInvoicePdf}>
            {t("invoices.openG1c")}
          </Button>
        )}
      {invoiceData?.status === STATUS.DRAFT && invoiceData.version > 1 && (
        <Button
          icon={<ReloadOutlined />}
          loading={isPreviousInvoiceMutationLoading}
          onClick={handleRestorePreviousInvoice}
        >
          {t("invoices.restorePreviousVersion")}
        </Button>
      )}
      {invoiceData?.status === STATUS.REJECTED && userRole !== UserRoles.Admin && (
        <>
          <Button type="primary" onClick={handleEditClick}>
            {t("edit")}
          </Button>
        </>
      )}
      {invoiceData?.status === STATUS.DRAFT && userRole !== UserRoles.Admin && (
        <Button type="primary" onClick={handleEditClick}>
          {t("edit")}
        </Button>
      )}
      {invoiceData?.status === STATUS.PENDING && (
        <Button onClick={handlePreviewInvoicePdf}>
          {t("invoices.openG1c")}
        </Button>
      )}
    </Space>
  ) : (
    <></>
  );
};
