import type { FC } from "react";
import { useTranslation } from "react-i18next/";

import { Select } from "antd";

import type { ActualsStatusSelectorProps } from "./props";

export const ActualsStatusSelector: FC<ActualsStatusSelectorProps> = ({
  statuses,
  ...rest
}) => {
  const { i18n } = useTranslation();
  return (
    <Select
      options={statuses?.map((c) => ({
        value: c.id,
        label: i18n.language === "en" ? c.enName : c.ruName
      }))}
      {...rest}
      allowClear
    />
  );
};
