import type { FC } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router";

import numeral from "numeral";
import moment from "moment";
import type { Moment } from "moment";
import axios from "axios";
import type { AxiosError } from "axios";
import { css } from "@linaria/core";
import tw from "twin.macro";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  notification,
  Row,
  Select,
  Space,
  Tag,
  Typography,
  Upload
} from "antd";
import { DeleteOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import { InvoiceButtons } from "@components/molecules/InvoiceButtons";
import { DetailsOnScopeCard } from "@components/molecules/DetailsOnScopeCard";

import { defineStatus, STATUS } from "@components/types/models/Statuses";
import {
  CONTRACT_TYPE,
  defineContractType,
  defineScopeOfWorks
} from "@components/types/models/Contract";

import { invoiceApi } from "@api/invoiceApi";

import type { UploadChangeParam } from "antd/lib/upload";
import { UserContext } from "@contexts/userContext";
import { UserRoles } from "@contexts/types/UserRoles";

import type { InvoiceCardProps } from "./props";

type PageParams = {
  readonly id: string;
};
type FormValues = {
  readonly invoiceNumber: string;
  readonly cwtNumber: string;
  readonly invoiceDate: Moment;
  readonly attachmentId?: number;
};

const { Title, Text, Link } = Typography;
const { Option } = Select;
const { Meta } = Card;
const ADDON_BEFORE_VALUE = "CWT";

export const InvoiceCard: FC<InvoiceCardProps> = ({
  onPreviewInvoicePdf: handlePreviewInvoicePdf,
  invoiceData,
  refetchInvoiceData,
  isInvoiceDataLoading,
  cancelUrl
}) => {
  const { id } = useParams<PageParams>();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userRole } = useContext(UserContext);

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [isCwtEditable, setIsCwtEditable] = useState<boolean>(false);
  const [versionId, setVersionId] = useState<number | undefined>();
  const [attachmentId, setAttachmentId] = useState();
  const [isDeleteAttachment, setIsDeleteAttachment] = useState(false);
  const [areDetailsVisible, setDetailsVisible] = useState(false);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [file, setFile] = useState<any>();
  const {
    setError,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    setValue("invoiceNumber", invoiceData?.identityNumber);
    setValue(
      "invoiceDate",
      invoiceData?.date ? moment(invoiceData?.date) : null
    );
    setValue("cwtNumber", isCWTAddonBefore(invoiceData?.cwtNumber));
    setValue("cwt", isCWTAddonBefore(invoiceData?.cwtNumber));
  }, [invoiceData, setValue]);

  const handleInputChange = useCallback(
    (name) => (event: React.FormEvent<HTMLInputElement>) => {
      setValue(name, event.currentTarget.value);
    },
    [setValue]
  );

  const isCWTAddonBefore = (cwtNumber: string | undefined) => {
    if (cwtNumber && cwtNumber.slice(0, 3) === "CWT") {
      return cwtNumber.substring(3);
    }
    return cwtNumber;
  };

  const invoiceStatus = defineStatus(invoiceData?.status);

  const invoiceLastAttachment = useMemo(
    () => invoiceData?.attachments,
    [invoiceData]
  );

  const handleCancelClick = (): void => {
    history.push(cancelUrl);
  };
  const handleCloseForm = (): void => {
    setIsFormOpen(false);
  };
  const handleEditClick = (): void => {
    setIsFormOpen(true);
  };
  const handleEditCwtClick = (): void => {
    setIsCwtEditable(true);
  };

  const handleDateChange = useCallback(
    (name) => (value: Moment | null) => {
      setValue(name, value);
    },
    [setValue]
  );

  const handleFileChange = (fileList: UploadChangeParam): void => {
    setFile(fileList.fileList[0]?.originFileObj);
    setIsDeleteAttachment(false);
  };

  const handleVersionChange = useCallback(
    (value) => {
      setVersionId(value);
      history.push(`/contractor/payment-application/${value}`);
    },
    [setVersionId, history]
  );

  useEffect(() => {
    setVersionId(invoiceData?.id);
  }, [invoiceData]);

  useEffect(() => {
    refetchInvoiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId]);

  const uploadAttachmentMutation = useMutation(async () => {
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`/api/invoices/${id}/attachment`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then((res) => {
        setAttachmentId(res.data);
      });
  });

  const deleteAttachmentMutation = useMutation(
    async () =>
      axios.delete(
        `api/invoices/${id}/attachment/${
          invoiceLastAttachment ? invoiceLastAttachment.id : attachmentId
        }`
      ),
    {
      onSuccess: () => {
        refetchInvoiceData();
      }
    }
  );
  const handleDeleteAttachmentClick = (): void => {
    setIsDeleteAttachment(true);
    // DeleteAttachmentMutation.mutate();
  };

  useEffect(() => {
    if (file) {
      uploadAttachmentMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const mutation = useMutation(
    async (values: FormValues) =>
      axios.put(`/api/invoices/${id}`, values).then((res) => res.data),
    {
      onSuccess: () => {
        handleCloseForm();
        refetchInvoiceData();
        void message.success(t("invoices.g1cFormUpdated"));
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("error", { message: errData.message });
        }
      }
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    isDeleteAttachment && deleteAttachmentMutation.mutate();
    void handleSubmit((values: FormValues): void => {
      Object.keys(values).forEach((key) => {
        if (values[key as keyof FormValues] === null) {
          delete values[key as keyof FormValues];
        }
      });
      mutation.mutate({
        ...values,
        attachmentId: isDeleteAttachment ? 0 : attachmentId,
        cwtNumber: ADDON_BEFORE_VALUE + values.cwtNumber
      });
    })();
  }, [
    clearErrors,
    handleSubmit,
    mutation,
    attachmentId,
    isDeleteAttachment,
    deleteAttachmentMutation
  ]);

  const updateCwtNumberMutation = useMutation(
    async (values: { readonly cwt: string }) =>
      axios
        .put(`/api/invoices/${id}/cwt`, null, {
          params: {
            cwt: values.cwt
          }
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        setIsCwtEditable(false);
        refetchInvoiceData();
        void message.success(t("invoices.g1cFormUpdated"));
      }
    }
  );

  const handleUpdateCwtNumber = useCallback(() => {
    clearErrors();
    void handleSubmit((values: { cwt: string }): void => {
      values.cwt = ADDON_BEFORE_VALUE + values.cwt;
      updateCwtNumberMutation.mutate(values);
    })();
  }, [clearErrors, handleSubmit, updateCwtNumberMutation]);

  const updateApprovedInvoiceMutation = useMutation(
    async () => invoiceApi.updateApprovedInvoice(invoiceData?.id),
    {
      onSuccess(res) {
        history.replace(`/contractor/payment-application/${res.data.id}`);
        refetchInvoiceData();
      }
    }
  );

  const restorePreviousInvoiceMutation = useMutation(
    async () => invoiceApi.restorePreviousInvoice(invoiceData?.id),
    {
      onSuccess(res) {
        history.replace(`/contractor/payment-application/${res.data.id}`);
        updateApprovedInvoiceMutation.mutate();
        setVersionId(res.data.id);
      }
    }
  );

  const downloadInvoiceAttachmentMutation = useMutation(
    async () =>
      invoiceApi.downloadInvoiceAttachment(
        invoiceData?.id,
        invoiceData?.attachments.id
      ),
    {
      onSuccess(res) {
        saveAs(res.data, invoiceData?.attachments.fileName);
      },
      onError(error: AxiosError) {
        notification.error({ message: error.response?.data.message });
      }
    }
  );

  const handleRestorePreviousInvoice = useCallback(() => {
    setIsFormOpen(false);
    restorePreviousInvoiceMutation.mutate();
  }, [restorePreviousInvoiceMutation]);

  const handleUpdateApprovedInvoice = useCallback(() => {
    updateApprovedInvoiceMutation.mutate();
  }, [updateApprovedInvoiceMutation]);

  const handleDownloadInvoiceAttachment = useCallback(() => {
    downloadInvoiceAttachmentMutation.mutate();
  }, [downloadInvoiceAttachmentMutation]);

  return (
    <Space
      direction="vertical"
      className={css`
        ${tw`w-full`}
      `}
    >
      <Row justify="space-between" align="middle">
        <Col span={6}>
          {!isFormOpen && (
            <Select
              className={css`
                ${tw`w-full mb-2`}
              `}
              value={versionId}
              onChange={handleVersionChange}
            >
              {invoiceData?.versions.map((v) => (
                <Option key={v.id} value={v.id}>
                  <span>
                    {t("invoices.version", {
                      version: v.version
                    })}
                  </span>
                </Option>
              ))}
            </Select>
          )}
        </Col>
        <Col>
          <Button danger icon={<LeftOutlined />} onClick={handleCancelClick}>
            {t("cancel")}
          </Button>
        </Col>
      </Row>
      <Card
        className={css`
          ${tw`w-full`}
        `}
        loading={isInvoiceDataLoading || mutation.isLoading}
        title={
          <Row justify="space-between" align="middle">
            <Title
              level={3}
              className={css`
                ${tw`items-center flex`}
              `}
            >
              {t("invoices.invoiceFor", {
                contract: invoiceData?.contract.contractNumber
              })}
              {!isFormOpen ? (
                <Tag
                  className={css`
                    ${tw`mx-4`}
                  `}
                  color={invoiceStatus.color}
                >
                  {i18n.language === "en"
                    ? invoiceStatus.enName
                    : invoiceStatus.ruName}
                </Tag>
              ) : (
                ""
              )}
            </Title>
            <InvoiceButtons
              userRole={userRole}
              invoiceData={invoiceData}
              handleEditClick={handleEditClick}
              onPreviewInvoicePdf={handlePreviewInvoicePdf}
              handleRestorePreviousInvoice={handleRestorePreviousInvoice}
              isPreviousInvoiceMutationLoading={
                restorePreviousInvoiceMutation.isLoading
              }
              isFormOpen={isFormOpen}
              handleUpdateApprovedInvoice={handleUpdateApprovedInvoice}
              isUpdateApprovedInvoiceMutationLoading={
                updateApprovedInvoiceMutation.isLoading
              }
            />
          </Row>
        }
      >
        <>
          <Row
            justify="space-between"
            align="top"
            className={css`
              ${tw`mb-8`}
            `}
          >
            <Col>
              <Text type="secondary">{t("contracts.contractType")}</Text>
              <br />
              <Title level={5}>
                {i18n.language === "en"
                  ? defineContractType(invoiceData?.contract.contractType)
                      .enName
                  : defineContractType(invoiceData?.contract.contractType)
                      .ruName}
              </Title>
            </Col>
            <Col>
              <Text type="secondary">{t("contracts.contractStartDate")}</Text>
              <br />
              <Title level={5}>
                {invoiceData?.contract.contractEndDate
                  ? moment(invoiceData.contract.contractStartDate).format(
                      "YYYY-MM-DD"
                    )
                  : "-"}
              </Title>
            </Col>
            <Col>
              <Text type="secondary">{t("contracts.contractEndDate")}</Text>
              <br />
              <Title level={5}>
                {invoiceData?.contract.contractEndDate
                  ? moment(invoiceData.contract.contractEndDate).format(
                      "YYYY-MM-DD"
                    )
                  : "-"}
              </Title>
            </Col>
            {userRole === UserRoles.Admin && (
              <Col>
                <Text type="secondary">{t("contracts.kcCategoryAndArea")}</Text>
                <br />
                <Title level={5}>{invoiceData?.contract.kcCategory.name}</Title>
              </Col>
            )}
            <Col>
              <Text type="secondary">{t("contracts.scopeOfWorks")}</Text>
              <br />
              <Title level={5}>
                {invoiceData?.contract.contractType ===
                CONTRACT_TYPE.MASTER_AGREEMENT
                  ? "-"
                  : invoiceData?.contract.scopes.map(
                      (scope, index) =>
                        (index ? ", " : "") +
                        (i18n.language === "en"
                          ? defineScopeOfWorks(scope).enName
                          : defineScopeOfWorks(scope).ruName)
                    )}
              </Title>
            </Col>
            <Col>
              <Text type="secondary">{t("contracts.contractAmount")}</Text>
              <br />
              <Title level={5}>
                {numeral(
                  invoiceData?.contract.lastApprovedAmendment.amount
                ).format("0,0.00")}{" "}
                {invoiceData?.contract.lastApprovedAmendment.currency.code}
              </Title>
            </Col>
          </Row>

          {isFormOpen ? (
            <Form layout="vertical" onFinish={handleFormSubmit}>
              <Row justify="space-between" align="top">
                <Col span={4}>
                  <Form.Item
                    label={t("invoices.invoiceNumber")}
                    validateStatus={errors.invoiceNumber && "error"}
                    help={errors.invoiceNumber?.message}
                  >
                    <Input
                      onChange={handleInputChange("invoiceNumber")}
                      value={watch("invoiceNumber")}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={t("invoices.invoiceDateFrom")}>
                    <DatePicker
                      disabled
                      value={moment(invoiceData?.startDate)}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={t("invoices.invoiceDateTo")}>
                    <DatePicker
                      disabled
                      value={moment(invoiceData?.endDate)}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label={t("invoices.invoiceDate")}
                    validateStatus={errors.invoiceDate && "error"}
                    help={errors.invoiceDate?.message}
                  >
                    <DatePicker
                      format="YYYY-MM-DD"
                      value={watch("invoiceDate")}
                      className={css`
                        ${tw`w-full`}
                      `}
                      onChange={handleDateChange("invoiceDate")}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label={t("invoices.cwtNumber")}
                    validateStatus={errors.cwtNumber && "error"}
                    help={errors.cwtNumber?.message}
                  >
                    <Input
                      addonBefore={ADDON_BEFORE_VALUE}
                      onChange={handleInputChange("cwtNumber")}
                      value={watch("cwtNumber")}
                      placeholder={"12345678"}
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label={
                      <Text
                        type="secondary"
                        className={css`
                          ${tw`text-xs leading-4 block`}
                        `}
                      >
                        {t("invoices.acceptedAttachments")}
                      </Text>
                    }
                    validateStatus={errors.attachmentId && "error"}
                    help={errors.attachmentId?.message}
                  >
                    {(file || invoiceLastAttachment) && !isDeleteAttachment ? (
                      <>
                        <Link onClick={handleDownloadInvoiceAttachment}>
                          {file?.name
                            ? file.name
                            : invoiceLastAttachment?.fileName}
                        </Link>
                        <Button
                          danger
                          loading={deleteAttachmentMutation.isLoading}
                          type="text"
                          icon={<DeleteOutlined />}
                          onClick={handleDeleteAttachmentClick}
                        />
                      </>
                    ) : (
                      <Upload
                        maxCount={1}
                        accept=".pdf,.png, .xlsx, .docx, .jpeg"
                        beforeUpload={() => false}
                        onChange={handleFileChange}
                      >
                        <Button type="primary" htmlType="button">
                          {t("upload")}
                        </Button>
                      </Upload>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Space>
                  <Button type="link" onClick={handleCloseForm}>
                    {t("cancel")}
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {t("save")}
                  </Button>
                </Space>
              </Row>
            </Form>
          ) : (
            <>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text type="secondary">{t("invoices.invoiceNumber")}</Text>
                  <br />
                  <Title level={5}>{invoiceData?.identityNumber ?? "-"}</Title>
                </Col>
                <Col>
                  <Text type="secondary">{t("invoices.invoiceDateFrom")}</Text>
                  <br />
                  <Title level={5}>
                    {moment(invoiceData?.startDate).format("YYYY-MM-DD")}
                  </Title>
                </Col>
                <Col>
                  <Text type="secondary">{t("invoices.invoiceDateTo")}</Text>
                  <br />
                  <Title level={5}>
                    {moment(invoiceData?.endDate).format("YYYY-MM-DD")}
                  </Title>
                </Col>
                <Col>
                  <Text type="secondary">{t("invoices.invoiceDate")}</Text>
                  <br />
                  <Title level={5}>
                    {invoiceData?.date
                      ? moment(invoiceData.date).format("YYYY-MM-DD")
                      : "-"}
                  </Title>
                </Col>
                <Col>
                  <Text type="secondary">{t("invoices.cwtNumber")}</Text>
                  <br />
                  <Row>
                    {isCwtEditable ? (
                      <Form onFinish={handleUpdateCwtNumber}>
                        <Space>
                          <Form.Item
                            className={css`
                              ${tw`mb-0`}
                            `}
                          >
                            <Input
                              addonBefore={ADDON_BEFORE_VALUE}
                              onChange={handleInputChange("cwt")}
                              value={watch("cwt")}
                              placeholder={"12345678"}
                            />
                          </Form.Item>
                          <Button
                            htmlType="submit"
                            loading={updateCwtNumberMutation.isLoading}
                            type="primary"
                          >
                            {t("save")}
                          </Button>
                        </Space>
                      </Form>
                    ) : (
                      <Title level={5}>{invoiceData?.cwtNumber ?? "-"}</Title>
                    )}
                    {invoiceData?.status === STATUS.APPROVED &&
                      !isCwtEditable &&
                      userRole !== UserRoles.Admin && (
                        <Button
                          type="text"
                          icon={<EditOutlined />}
                          onClick={handleEditCwtClick}
                        />
                      )}
                  </Row>
                </Col>
                <Col>
                  <Text type="secondary">{t("invoices.attachment")}</Text>
                  <br />
                  {invoiceLastAttachment ? (
                    <Link onClick={handleDownloadInvoiceAttachment}>
                      {invoiceLastAttachment.fileName}
                    </Link>
                  ) : (
                    "-"
                  )}
                </Col>
              </Row>
              <Divider />
              <Meta
                description={
                  <Row justify="space-between">
                    <Space direction="vertical">
                      <Text>
                        {t("contracts.submittedBy", {
                          name: invoiceData?.submittedBy
                            ? `${invoiceData.submittedBy.firstName} ${invoiceData.submittedBy.lastName}`
                            : "-"
                        })}
                      </Text>
                      <Text>
                        {t("contracts.submissionDate", {
                          date: invoiceData?.submittedAt
                            ? moment(invoiceData.submittedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-"
                        })}
                      </Text>
                    </Space>
                    <Space direction="vertical">
                      <Text>
                        {t(
                          invoiceData?.status === STATUS.REJECTED
                            ? "contracts.rejectedBy"
                            : "contracts.approvedBy",
                          {
                            name: invoiceData?.reviewedBy
                              ? `${invoiceData.reviewedBy.firstName} ${invoiceData.reviewedBy.lastName}`
                              : "-"
                          }
                        )}
                      </Text>
                      <Text>
                        {t(
                          invoiceData?.status === STATUS.REJECTED
                            ? "contracts.rejectionDate"
                            : "contracts.approvalDate",
                          {
                            date: invoiceData?.reviewedAt
                              ? moment(invoiceData.reviewedAt).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : "-"
                          }
                        )}
                      </Text>
                    </Space>
                  </Row>
                }
              />
            </>
          )}
        </>
      </Card>
      <DetailsOnScopeCard
        isLoading={isInvoiceDataLoading}
        areDetailsVisible={areDetailsVisible}
        setDetailsVisible={setDetailsVisible}
        incomingText={
          invoiceData?.contract.lastApprovedAmendment.detailsOnScopeOfWork
        }
      />
    </Space>
  );
};
