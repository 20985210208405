export const forecasts = [
  {
    title: "About Forecasts",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">KC Leads are assigned to specific contracts in the system (based on KC Category and Area), so that when contractors submit contract&rsquo;s forecasts, only assigned KC Leads get notified by email</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Contractors are requested to provide forecasts for every contract they have. One forecast contains plans for the whole contract period, contractors fill out their spending plans for every month/year of a contract. Depending on the type and scope of work of a specific contract, forecasts contain different sections:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Standalone agreement:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Goods/Works/Services; Goods Works, Services summary; Jobs and Nationalization; Training; Technology Transfer; Legacy</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Sub-agreement:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Goods/Works/Services; Goods, Works, Services summary;</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Master agreement:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">&nbsp; Goods Works, Services summary; Jobs and Nationalization; Training; Technology Transfer; Legacy</span></span></span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Contractors can have one forecast for one contract only, that can be updated and have few versions, the latest approved version is considered to be valid and is used in further calculations in the system. Previous versions of a forecast are also kept in the system and can be viewed by switching between versions in the forecast detailed page.</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Forecasts have four statuses in the system:</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Forecasts submitted by Contractor and not yet reviewed by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Pending&quot; </strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Forecasts submitted by Contractor and confirmed by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Approved&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Forecasts submitted by Contractor and rejected by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Rejected&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Contractors which have not submitted their forecasts in 30 days after contract approval are considered to be </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Overdue&quot;</strong></span></span></span></li>
</ol>
`
  },
  {
    title: "How to Reject forecasts?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; forecast from the Forecasts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Review all information provided in the forecast sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (required: in the section which you find to be incorrect)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Reject&quot; button on Pending forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due Date for Contractors</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm your action by clicking on &quot;Yes&quot; button</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/I1nKgTxm47A0nqdlo0HWy-gAA9EIxhOXK9UTcX8w6t4d-hx5wP0xVELIQnxMIFNCtFdmVTjcSzjPbmjJni23WKAOz283kgkl_khTg2Wc6BhElMq7pyOidWnR7tBWCti2GhkiO6Erld8SmRY5ze3LpkAzfrqqkAbVuOnszbEkdVG7LXU1SzGtuwR4Kg" style="height:671px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Approve forecasts?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; forecast from the Forecasts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Review all information provided in the forecast sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (not required: in the sections if needed)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Approve&quot; button on Pending forecast&rsquo;s detailed page</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/bKfxbjfPKB3V8hMVIAmqYSG1cl2zt1OmHLLZNr9TD804c0kYsYmP_Heb8Xn86C2h8By7vQJU_8lIhmXvvI75mKQA1QOpSVCYUvEUxnzyXT9qG1AIKrDPo1fDW48NZYddBSBfxHq4ULXBpLGPGMbWZoy9e-NWofoqSyb8bDkc-5IsBaFKcJeBJdMnxA" style="height:460px; width:624px" /></span></span></span></p>
`
  }
];
