/* eslint-disable @typescript-eslint/naming-convention */

export const ruCommon = {
  logo: "KC PORTAL",
  email: "Email",
  password: "Пароль",
  login: "Войти",
  cancel: "Отменить",
  actions: "Действия",
  edit: "Редактировать",
  submit: "Отправить",
  clear: "Очистить",
  city: "Город",
  country: "Страна",
  workPhoneNumber: "Рабочий телефон",
  bin: "БИН",
  address: "Адрес",
  add: "Добавить",
  search: "Поиск",
  notifications: "Уведомления",
  yes: "Да",
  no: "Нет",
  delete: "Удалить",
  confirmDelete: "Подтвердить удаление",
  accessLevel: "Уровень доступа",
  noData: "Нет данных",
  update: "Обновить",
  successUpdate: "Данные успешно обновлены",
  reports: "Отчеты",
  forecast: "Прогнозы",
  forecasts: "Прогнозы",
  actual: "Фактический показатель",
  actuals: "Фактические показатели",
  forms: "G1-C формы",
  glossary: "Глоссарий",
  admin: "Админ",
  kcLead: "KC Lead",
  contractor: "Подрядчик",
  save: "Сохранить",
  subcontractor: "Субподрядчик",
  guest: "Гость",
  more: "Подробнее",
  reset: "Сбросить",
  downloadError: "Ошибка скачивания",
  all: "Все",
  total: "Итог",
  scopeOfWork: "Объем работ",
  status: "Статус",
  deleted: "Удалено",
  saved: "Сохранено",
  applyFilters: "Применить фильтры",
  requiredField: "Обязательное поле",
  settings: "Настройки",
  monthDataSaved: "Данные за месяц сохранены",
  contract: "Контракт",
  back: "Назад",
  apply: "Применить",
  download: "Скачать",
  approve: "Утвердить",
  reject: "Отклонить",
  create: "Создать",
  by: "от",
  at: "в",
  upload: "Загрузить",
  dateFrom: "Дата с",
  dateTo: "Дата до",
  updatedAt: "Обновлено",
  confirm: "Подтвердить",
  submissionDate: "Дата отправки",
  fileUploadError:
    "{{fileName}} слишком велик. Максимальный поддерживаемый размер файла - {{size}} МБ.",
  sessionExpired: "Срок сессии истек, пожалуйста войдите ещё раз",
  loading: "Загрузка",
  somethingWentWrong: "Что-то пошло не так",
  goToProfile: "К профилю",
  showMore: "Показать больше",
  showLess: "Скрыть"
};
