import type { FC } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import moment from "moment";

import { Button, Col, DatePicker, Form, Input, Row, Skeleton } from "antd";
import { ContractTypeSelector } from "@components/pages/admin/Forecasts/libs/components/ContractTypeSelector";
import { ContractorSelector } from "@components/pages/admin/Forecasts/libs/components/ContractorSelector";
import { CategorySelector } from "@components/pages/admin/Forecasts/libs/components/CategorySelector";
import type { Category } from "@components/organisms/NewContractForm/libs/types/Category";
import { SearchOutlined } from "@ant-design/icons";

import { contractorApi } from "@api/contractorApi";

import { ContractTypes } from "@utils/contractTypes";
import { CATEGORIES } from "@components/constants/AdminCategories";
import { stringArrToNumberArr } from "@utils/helper";

import type { SearchSectionProps } from "./props";

export const SearchSection: FC<SearchSectionProps> = ({
  filters,
  isLoading,
  onSearchQuery
}) => {
  const { t } = useTranslation();

  const { watch, setValue, getValues } = useForm();

  useEffect(() => {
    setValue("contractNumber", filters?.contractNumber);
    setValue("contractors", stringArrToNumberArr(filters?.contractors));
    setValue("categories", stringArrToNumberArr(filters?.categories));
    setValue("contractTypes", stringArrToNumberArr(filters?.contractTypes));
    setValue(
      "period",
      filters?.year && filters?.month
        ? moment(`${filters?.year}-${filters?.month}`)
        : undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnFinish = useCallback(() => {
    const vals = getValues();

    onSearchQuery({
      contractNumber: vals.contractNumber,
      contractors: vals.contractors,
      categories: vals.categories,
      contractTypes: vals.contractTypes,
      year: vals.period ? moment(vals.period).get("year") : undefined,
      month: vals.period ? moment(vals.period).get("month") + 1 : undefined
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contractorsList = useQuery(
    "getContractors",
    async () => contractorApi.getContractors().then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const categoriesList = useMemo<readonly Category[]>(
    () =>
      CATEGORIES.reduce<readonly Category[]>(
        (prev, cat) =>
          cat.children ? prev.concat(cat.children) : [...prev, cat],
        []
      ),
    []
  );

  return (
    <Form layout="vertical" onFinish={handleOnFinish}>
      {contractorsList.isLoading ? (
        <Skeleton active paragraph={{ rows: 1 }} />
      ) : (
        <Row gutter={16}>
          <Col span={3}>
            <Form.Item label={t("contractors.contractNumber")}>
              <Input
                value={watch("contractNumber")}
                onChange={(e) =>
                  setValue("contractNumber", e.currentTarget.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label={t("contractors.contractors")}>
              <ContractorSelector
                contractors={contractorsList.data}
                value={watch("contractors")}
                onChange={(value) => setValue("contractors", value)}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label={t("members.categoryAndArea")}>
              <CategorySelector
                categories={categoriesList}
                value={watch("categories")}
                onChange={(value) => setValue("categories", value)}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label={t("contracts.contractType")}>
              <ContractTypeSelector
                contractTypes={ContractTypes}
                value={watch("contractTypes")}
                onChange={(value) => setValue("contractTypes", value)}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={t("actuals.submissionPeriod")}>
              <DatePicker
                picker="month"
                format="MMMM YYYY"
                style={{ width: "100%" }}
                value={watch("period")}
                onChange={(value) => setValue("period", value)}
              />
            </Form.Item>
          </Col>
          <Col
            span={3}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              loading={isLoading}
            >
              {t("forecasts.applyFilters")}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};
