import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Button, Card, Col, Empty, Row, Typography } from "antd";

export const ExpiredTokenPage: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const history = useHistory();

  const handleButtonClick = (): void => {
    history.push("/reset");
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: `calc(100% - 80px)`, textAlign: "center" }}
    >
      <Col span={12}>
        <Card style={{ display: "flex", justifyContent: "center" }}>
          <Empty description={false} />
          <Title>{t("login.linkIsExpired")}</Title>
          <Button
            type="primary"
            size="large"
            style={{ marginTop: "20px" }}
            onClick={handleButtonClick}
          >
            {t("login.resendLink")}
          </Button>
        </Card>
      </Col>
    </Row>
  );
};
