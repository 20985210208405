/* eslint-disable @typescript-eslint/naming-convention */
import { PERMISSIONS } from "@components/types/models/Permissions";

export const ADMIN_PERMISSION_OPTION_LIST: ReadonlyArray<{
  readonly label?: string;
  readonly values: readonly number[];
  readonly dependsOn?: number;
}> = [
  { values: [PERMISSIONS.MEMBER_VIEW], label: "memberView" },
  {
    values: [PERMISSIONS.MEMBER_EDIT],
    dependsOn: PERMISSIONS.MEMBER_VIEW,
    label: "memberEdit"
  },
  {
    values: [PERMISSIONS.CONTRACTOR_VIEW],
    label: "contractorView"
  },
  {
    values: [PERMISSIONS.CONTRACTOR_EDIT],
    dependsOn: PERMISSIONS.CONTRACTOR_VIEW,
    label: "contractorEdit"
  },
  {
    values: [PERMISSIONS.CONTRACT_VIEW],
    label: "contractView"
  },
  {
    values: [PERMISSIONS.CONTRACT_EDIT],
    dependsOn: PERMISSIONS.CONTRACT_VIEW,
    label: "contractEdit"
  },
  {
    values: [PERMISSIONS.EXCHANGE_RATE_VIEW],
    label: "exchangeRateView"
  },
  {
    values: [PERMISSIONS.EXCHANGE_RATE_EDIT],
    dependsOn: PERMISSIONS.EXCHANGE_RATE_VIEW,
    label: "exchangeRateEdit"
  },
  {
    values: [PERMISSIONS.DASHBOARDS_EDIT],
    label: "dashboardsEdit"
  },
  {
    values: [PERMISSIONS.REPORTS_DOWNLOAD_DUMP],
    label: "reportDownloadDump"
  },
  {
    values: [PERMISSIONS.MIGRATION_EDIT],
    label: "migrationEdit"
  },
  {
    values: [PERMISSIONS.CURRENCY_COUNTRY_CITY_EDIT],
    label: "currencyCountryCityEdit"
  }
];
