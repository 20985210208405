export const g1c = [
  {
    title: "Как создать G1-C формы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C формы могут быть созданы на основе типов контракта Отдельное и Дополнительное соглашение</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: G1-C формы могут быть созданы только для периода контракта с утвержденными фактическими показателями</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+ Создать G1-C форму&quot;:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт из выпадающего списка Контракт</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите сроки для создания G1-C формы</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Создать&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/l9Uq9uTs5-N3b6nRt_2rH-cOPiagnwUZX4uY6OD1rkcS_7QXmGmj9sePYFcTts092RVBR1bN28vi8611HyOuj8Qpe_l8JQNCkA9OQfHDdyqj1BZHLG6vnX4zZDUHmrfIpJOjIaiReEiJZt0DzOVxliI25TR_dPjAJo3ZPLnqscCblldlvvQqmep-AA" style="height:285px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как отредактировать детали G1-C формы?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: G1-C формы могут быть созданы на основе типов контракта Отдельное и Дополнительное соглашение</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C формы могут быть созданы только для периода контракта с утвержденными фактическими показателями</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Редактировать&quot; на детальной странице выбранной формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые поля &amp; Загрузите Счет фактуру (формат файла: xlsx, docx, pdf, png, jpeg*)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/evIVNA-bMor9hI-bKgeLWBKg-u9uTKPP4Pls1k7UpzcuuNSuclcvPrPfVO5iQ4JjuszWl5k4h9ptCR-zE2LNInjphHaYVHtG2SyXRoOORLFgTzDnvL8BtpCJz-jrmvbW1LVozJ2pd9N_zDr35Zjl93_CQT_cO6CeIyxGD7FH_Ibw_5YOAaMVpDvfLg" style="height:448px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить товары, работы и услуги?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C формы могут быть созданы на основе типов контракта Отдельное и Дополнительное соглашение</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C формы могут быть созданы только для периода контракта с утвержденными фактическими показателями</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Откройте секции Товары/Работы/Услуги на детальной странице G1-C формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Заполнить&quot; чтобы добавить все существующие товары, работы, услуги из утвержденных фактических показателей ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите товары, работы, услуги из выпадающего списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить товар/работы/услуги&quot; чтобы добавить дополнительные строки</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;X&quot; чтобы удалить ненужные товары, работы или услуги</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/1gUxevUVnkiRQyn6L5MYXDeXXEbG964nz1SzESfTr_9XYdKhjbZtDrjD5imb9z6axMY-waWy5pXbNSlHEU4jAzvmVx3qajzlDwPRpd_1hN8ATSMIlsu1wpQz7SZEVbOauLsH1oGZuHQL1q2VoFNTne07vvK2LmZIJfIhXgVDcc2yYeiA6fIQWFZdUg" style="height:401px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как оставить комментарий в ТРУ секциях?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Товары/Услуги на детальной странице G1-C формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Оставить комментарий&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите комментарий в текстовое поле</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как отправить на рассмотрение G1-C форму?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые поля в G1-C форме</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Добавьте Товары/Работы/Услуги в G1-C форму</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения формы для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Просмотр&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Подтвердить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/Y1QFvXoYDVB_H9zoscA20veM845IpHRSwgkr6uWdpGpnua_21GT2KF9hc99OWp0RWkVIIU7qOkhVDPCsDxsJtTA2hcmRRW3kthyByepLsIiwePGKcFtaz6cCYytzs6Z8c9hiOgfXyQpFsOPo7a3bycmEBqMjNQTH7msMgTPXitg0NBQWsMjp9wCUrg" style="height:427px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как скачать PDF G1-C формы?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Черновик&quot;, &quot;Отклонен&quot;, &quot;Утвержден&quot; или &quot;На рассмотрении&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Открыть G1-C&quot; на детальной странице G1-C со статусом &quot;Утвержден&quot; или &quot;На рассмотрении&quot;&nbsp; ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Просмотр&quot; на детальной странице G1-C со статусом &quot;Черновик&quot; или&nbsp; &quot;Отклонен&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Скачать&quot; в открытом окне PDF G1-C формы</span></span></span></li>
</ol>
`
  },
  {
    title: "Как обновить утвержденную G1-C форму?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Утвержден&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить&quot; на детальной странице утвержденной G1-C формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите все необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обновите Товары/Работы/Услуги при необходимости</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения формы для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Просмотр&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Подтвердить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как восстановить предыдущую версию?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Утвержден&quot; и нажмите на кнопку &quot;Обновить&quot; ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Черновик&quot; (версия &gt; 1) из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Восстановить предыдущую версию&quot; на странице G1-C формы со статусом Черновик (версия &gt; 1)</span></span></span></li>
</ol>
`
  },
  {
    title: "Как обновить отклоненную G1-C форму?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;G1-C Формы&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите G1-C форму со статусом &quot;Отклонен&quot; из списка G1-C форм</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить&quot; на детальной странице утвержденной G1-C формы</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите все необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обновите Товары/Работы/Услуги при необходимости</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения формы для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Просмотр&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Подтвердить&quot;</span></span></span></li>
</ol>
`
  }
];
