export const g1c = [
  {
    title: "How to create G1-C Form?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C Form can be created only for Standalone and Sub-agreement contract types</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C Form can be created only for the period of Approved Actuals</span></span></span></p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+ Create G1-C form&quot; button:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Contract from Contract drop-down list</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set G1-C form date from &amp; G1-C form date to</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Create&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/6ng7Pk84wq9iycye18VSVKJlBSVZLF6IwAdmgYjhkYmS30sv8louRUkjdAXKLqFKWfeYNUHtPamBnw57ZDZNG4yEOqpukfgPqbmXbEOZEy_kyxfhH2MoWU0IF8XyEta0dxKjcOjp04lZkRoMU4cEfAnv9LzrdV4THcQaBG3ODjbBL0NHEK0lDLjUdw" style="height:456px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to edit G1-C form details?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C Form can be created only for Standalone and Sub-agreement contract types</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C Form can be created only for the period of Approved Actuals</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT/REJECTED G1-C form from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Edit&quot; button on G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out required fields &amp; Upload Invoice (file format: xlsx, docx, pdf, png, jpeg*)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/Zz5KJOUlEhv7c-QZX1IYwUpBe6C_h5O0kWuuCJZQxlR5dRIeKTRDeWn1rmUZjtu2B1qaRPbPZGaV8XryQl2nbV27akP1R0SK84k6wjDefQrPF422aYIOjzPhtr3sqIWhlrdn1kTMv2rJ9O018jHxcor-9utnknJAepNrLya51wTq6Gy3cZt2Qcd9XA" style="height:385px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to fill out the Goods, Works, Services section?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C Form can be created only for Standalone and Sub-agreement contract types</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> G1-C Form can be created only for the period of Approved Actuals</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT/REJECTED G1-C Form from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Open Goods, Works, Services sections on G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Pre-populate&quot; button to add all goods/works/services from approved actuals for selected period OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select goods/works/services from Good/Work/Service name field</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+ Add goods/work/services&quot; button to add more goods/works/services entries</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;X&quot; button to delete not needed goods/works/services entries</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/MNRBy3UygHIgrlguT84h1LScWpuPxhrsVOjIyr-Th7n_XVePVYDgG4isshKphfINDwNjJFk3Tuz20dxHfgZ6yw3JqI0xAlmeq6oEqJ6ihDfv9Nnixvoae9BBxVysPqNrrUeAkWJcJHtBL_NqnmywQTrafHrZ5YjqqLLo0L_U6o4NGlSSU2wZd3DmAQ" style="height:311px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to leave comments in GWS sections?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT/REJECTED G1-C Form from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Open Goods, Works, Services sections on G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Leave comment&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter your comment into the text box</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to submit G1-C Forms?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT/REJECTED G1-C Form from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Add Goods/Works/Services to G1-C Form</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due Date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Preview&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Confirm&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/XXQsvwnPSCot7B205dJC8kQxacB8Nt6W-y385hdynacXwGlh0sXXzSIL2DUNoBkMa0k-tsy_Y9YHwAjXZQRdO_u0eHNcsT6ed9i8siEPcdgsU-k1ZysD6-ykB_RHbIVZiH43pGhjExyXlB5-dg5IuEh48Q8OD-v8Bt5suTTp02FeUPz784WUz0NV7w" style="height:381px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to download PDF of G1-C Form?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select PENDING/APPROVED/REJECTED/DRAFT G1-C form from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Open G1-C&quot; button on PENDING/APPROVED G1-C form&rsquo;s detailed page OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Preview&quot; button on DRAFT/REJECTED G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Download&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to update Approved G1-C Form?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select APPROVED G1-C Form from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update&quot; button on APPROVED G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Edit all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Edit Goods/Works/Services</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due Date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Preview&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Confirm&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to Restore G1-C form’s previous version?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select APPROVED G1-C Form from the list and click on &quot;Update&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT (version &gt; 1) G1-C from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Restore previous version&quot; button on DRAFT G1-C form&rsquo;s detailed page (version &gt;1)</span></span></span></li>
</ol>
`
  },
  {
    title: "How to update Rejected G1-C Form?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select REJECTED G1-C Form from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Edit all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Edit Goods/Works/Services</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due Date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Preview&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Confirm&quot; button</span></span></span></li>
</ol>
`
  }
];
