/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Col, Input, Row, Tooltip } from "antd";
import i18n from "@i18n/i18n";
import type {
  ForecastTrainingData,
  ForecastTrainingDataItem,
  MouCategoryItem,
  MouCategoryList
} from "@components/organisms/ForecastTrainingForm/props";

import { truncateText } from "@utils/truncate";

import type { ForecastTrainingPerMonthProps } from "./props";

const SLICE_COUNT = 20;

export const ForecastTrainingPerMonth: FC<ForecastTrainingPerMonthProps> = ({
  currentPeriod,
  rowsHeaders = [],
  trainingList,
  onSave,
  isEditable
}) => {
  const [periodTrainingData, setPeriodTrainingData] =
    useState<ForecastTrainingData>({});

  const { t } = useTranslation();

  const setTrainingData = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputTrainingData: any = {};

    currentPeriod?.months.map((month: number) => {
      const initialData = trainingList?.find(
        (item: ForecastTrainingDataItem) =>
          item.month === Number(month) && currentPeriod.year === item.year
      );

      outputTrainingData[month] = {
        year: currentPeriod.year,
        month,
        isApproved: initialData?.isApproved ?? false,
        engineering:
          typeof initialData?.engineering === "number"
            ? initialData.engineering
            : "",
        supervisorAndForeman:
          typeof initialData?.supervisorAndForeman === "number"
            ? initialData.supervisorAndForeman
            : "",
        administration:
          typeof initialData?.administration === "number"
            ? initialData.administration
            : "",
        craft: typeof initialData?.craft === "number" ? initialData.craft : "",
        heavyEquipmentOperator:
          typeof initialData?.heavyEquipmentOperator === "number"
            ? initialData.heavyEquipmentOperator
            : ""
      };

      return month;
    });

    setPeriodTrainingData(outputTrainingData);
  };

  const setTrainingValue = ({
    value,
    headerTitle,
    month
  }: {
    readonly value: string;
    readonly headerTitle: string;
    readonly month: number;
  }): void => {
    const updatedList = { ...periodTrainingData };

    updatedList[month] = {
      ...updatedList[month],
      [headerTitle]: value
    };

    setPeriodTrainingData(updatedList);
  };

  const getCategoryTotal = (
    headerTitle: MouCategoryList | "total" | undefined
  ): number =>
    Object.values(periodTrainingData).reduce(
      (total: number, currentValue: ForecastTrainingDataItem) =>
        total + Number(currentValue?.[headerTitle!] ?? 0),
      0
    );

  const getMonthTotal = (monthIndex: number): number => {
    const currentMonth = periodTrainingData[monthIndex];

    const total = rowsHeaders.reduce(
      (sum: number, header: MouCategoryItem) =>
        sum + (Number(currentMonth?.[header.title]) || 0),
      0
    );

    return total;
  };

  const handleOnSave = (payload: ForecastTrainingData) => {
    onSave?.(payload);
  };

  const handleSetTrainingValue = useCallback(
    (payload: { readonly headerTitle: string; readonly month: number }) =>
      (event: React.FormEvent<HTMLInputElement>) => {
        const outputValue = {
          ...payload,
          value: event.currentTarget.value
        };
        setTrainingValue(outputValue);
      },
    [setTrainingValue]
  );

  const saveText = useMemo(() => {
    const hasNextYear = currentPeriod?.years.includes(
      Number(currentPeriod?.year) + 1
    );

    if (!isEditable && hasNextYear) {
      return t("forecasts.proceedAndGoToTheNextYear");
    } else if (!isEditable && !hasNextYear) {
      return t("forecasts.proceedAndGoToTheNextSection");
    }

    if (hasNextYear) {
      return t("forecasts.saveAndGoToTheNextYear");
    }
    return t("forecasts.saveAndGoToTheNextSection");
  }, [currentPeriod?.year, i18n.language]);

  useEffect(() => {
    setPeriodTrainingData({});
    setTrainingData();
  }, [trainingList, currentPeriod]);

  return (
    <Col span={24}>
      <Row>
        <Col span={4} />
        <Col span={20}>
          <Row
            className={css`
              ${tw`space-x-6`}
            `}
          >
            {currentPeriod?.months.map((monthIndex: number) => (
              <div
                key={monthIndex}
                className={css`
                  ${tw`flex-1 flex justify-center font-bold capitalize`}
                  min-width: 50px;
                  max-width: 50px;
                  color: #9e9e9e;
                `}
              >
                {moment()
                  .month(Number(monthIndex) - 1)
                  .locale(i18n.language)
                  .format("MMM")}
              </div>
            ))}
            <div
              className={css`
                ${tw`flex-1 flex justify-center font-bold`}
                min-width: 50px;
                max-width: 50px;
                color: #9e9e9e;
              `}
            >
              {t("forecasts.mouCategory.total")}
            </div>
          </Row>
        </Col>
      </Row>

      <Row
        className={css`
          ${tw`space-y-2`}
        `}
      >
        {rowsHeaders.map((header: MouCategoryItem, index: number) => (
          <React.Fragment key={index}>
            <Col
              span={4}
              className={css`
                ${tw`flex items-center`}
              `}
            >
              <Tooltip title={header.text}>
                {truncateText(header.text ?? "", SLICE_COUNT)}
              </Tooltip>
            </Col>
            <Col span={20}>
              <Row
                className={css`
                  ${tw`space-x-6`}
                `}
              >
                {currentPeriod?.months.map((month: number) => (
                  <div
                    key={month}
                    className={css`
                      ${tw`flex-1 flex justify-center items-center`}
                      min-width: 50px;
                      max-width: 50px;
                    `}
                  >
                    {header.title === "total" ? (
                      <span>{getMonthTotal(month)}</span>
                    ) : (
                      <Input
                        type="number"
                        className={css`
                          ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                          width: 100%;
                          border: 1px solid #e2e2e2;
                        `}
                        value={periodTrainingData[month]?.[header.title]}
                        disabled={
                          !isEditable || periodTrainingData[month]?.isApproved
                        }
                        onChange={handleSetTrainingValue({
                          headerTitle: header.title ?? "",
                          month
                        })}
                      />
                    )}
                  </div>
                ))}
                <div
                  className={css`
                    ${tw`flex-1 flex justify-center items-center`}
                    min-width: 50px;
                    max-width: 50px;
                  `}
                >
                  {header.title === "total"
                    ? ""
                    : getCategoryTotal(header.title)}
                </div>
              </Row>
            </Col>
          </React.Fragment>
        ))}
      </Row>
      <Col
        span={24}
        className={css`
          ${tw`mt-4 flex justify-end`}
        `}
      >
        <Button
          className="secondary-button"
          onClick={() => handleOnSave(periodTrainingData)}
        >
          {saveText}
        </Button>
      </Col>
    </Col>
  );
};
