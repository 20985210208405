import type { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Space, Table, Typography } from "antd";
import type { AppTableProps } from "@components/molecules/AppTable/props";

const TableBase = styled(Table)`
  th {
    &:before {
      ${tw`w-0!`}
    }
  }
`;

export const AppTable: FC<AppTableProps> = ({
  title,
  subtitle,
  children,
  isLoading,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <TableBase
      loading={isLoading}
      title={() =>
        (title || subtitle) && (
          <Space align="center">
            {title && (
              <Typography.Title
                level={5}
                className={css`
                  ${tw`mb-0!`}
                `}
              >
                {title}
              </Typography.Title>
            )}
            {subtitle && (
              <Typography.Text type="secondary">{subtitle}</Typography.Text>
            )}
          </Space>
        )
      }
      locale={{ emptyText: t("noData") }}
      {...rest}
    >
      {children}
    </TableBase>
  );
};
