import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { QueryFunctionContext } from "react-query";
import { useQuery } from "react-query";

import { Row, Typography } from "antd";
import type { SearchQueryType } from "@components/pages/contractor/Invoice/libs/SearchSection/libs/types/SearchQueryType";
import { SearchSection } from "@components/pages/contractor/Invoice/libs/SearchSection";
import { CreateInvoiceForm } from "@components/organisms/CreateInvoiceForm";

import { contractorApi } from "@api/contractorApi";

import { InvoicesTable } from "./libs/InvoicesTable";

const { Title } = Typography;
const PAGE_SIZE = 20;

export const InvoicesList: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  const {
    data: invoicesList,
    isLoading,
    refetch: invoiceListRefetch
  } = useQuery(
    ["getInvoices", { searchQuery }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [string, { readonly searchQuery: SearchQueryType | undefined }]
      >
    ) =>
      contractorApi
        .getContractorInvoices(
          page,
          PAGE_SIZE,
          searchQueryCallback.queryKey[1].searchQuery?.status,
          searchQueryCallback.queryKey[1].searchQuery?.invoiceNumber,
          searchQueryCallback.queryKey[1].searchQuery?.dateStart?.format(
            "YYYY-MM-01"
          ),
          searchQueryCallback.queryKey[1].searchQuery?.dateEnd?.format(
            "YYYY-MM-01"
          )
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  useEffect(() => {
    void invoiceListRefetch();
  }, [page, invoiceListRefetch]);

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    setPage(1);
    setSearchQuery(searchParams);
    invoiceListRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Title>{t("invoices.g1cForms")}</Title>
        <CreateInvoiceForm refetchInvoicesList={invoiceListRefetch} />
      </Row>
      <SearchSection onSearchQuery={handleSearchQuery} />
      <InvoicesTable
        invoicesList={invoicesList?.results ?? []}
        isLoading={isLoading}
        refetchInvoicesList={invoiceListRefetch}
        pagination={{
          showSizeChanger: false,
          onChange: (p: number) => {
            setPage(p);
          },
          total: invoicesList?.totalItems,
          current: invoicesList?.currentPage,
          pageSize: invoicesList?.pageSize
        }}
      />
    </>
  );
};
