export const dashboards = [
  {
    title: "О Дашбордах",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Руководители КС имеют возможность видеть все цифровые показатели КС портала в виде графиков и диаграмм, фильтровать необходимые данные и выводить необходимую статистику. Данные из всех источников КС портала собраны и выведены на странице &quot;Дашборды&quot;.</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">На странице панели мониторинга Руководитель КС может увидеть общие показатели КС в виде различных диаграмм и фильтровать данные по необходимости:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Показатели КС по годам и месяцам</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Плановые и фактические показатели КС</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обзор фактических показателей КС по областям Казахстана</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обзор КС категорий</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Обзор фактических данных по КС категориям</span></span></span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Также, через панель мониторинга, Руководитель КС имеет возможность провалиться в детальные отчеты следующих сущностей и мониторить все относящиеся актуальные данные по ним через графики и диаграммы, фильтровать и выводить нужную детализацию показателей:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Договора и подрядчики</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Субподрядчики</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Товары, работы и услуги</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Трудовые ресурсы</span></span></span></li>
</ul>
`
  }
];
