import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next/";

import { ContractorLink as contractorLink } from "@components/organisms/ContractorsTable/libs/components/ContractorLink";
import type { ContractorsTableProps } from "@components/organisms/ContractorsTable/props";
import { Table } from "antd";

import type { Contractor } from "@components/types/models/Contractor";

import { usePagination } from "@hooks/usePagination";

import { ContractorActions as contractorActions } from "./libs/components/ContractorActions";

export const ContractorsTable: FC<ContractorsTableProps> = ({
  data,
  isLoading,
  pagination,
  onModal
}) => {
  const { t, i18n } = useTranslation();

  const { setPagination } = usePagination();

  const parseData = (
    tableData: ContractorsTableProps["data"]
  ): ReadonlyArray<Record<string, unknown>> =>
    tableData?.map((item) =>
      Object.entries(item).reduce(
        (prev, entry) => ({
          ...prev,
          [entry[0]]:
            (!entry[1] || entry[1] === "") && entry[0] !== "newContractsCount"
              ? "—"
              : entry[1]
        }),
        {}
      )
    ) ?? [];

  const handleShowEditModal = useCallback(
    (item: Contractor) => () => {
      onModal(item);
    },
    [onModal]
  );

  const columns = [
    {
      title: t("contractors.contractorName"),
      key: "name",
      render: ({ id, name, newContractsCount }: Contractor) =>
        contractorLink({
          id,
          name,
          newContractsCount
        })
    },
    {
      title: t("bin"),
      dataIndex: "bin",
      key: "bin"
    },
    {
      title: t("workPhoneNumber"),
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: t("country"),
      dataIndex: ["country", i18n.language === "en" ? "nameEn" : "nameRu"]
    },
    {
      title: t("city"),
      dataIndex: ["city", i18n.language === "en" ? "nameEn" : "nameRu"]
    },
    {
      title: t("actions"),
      key: "actions",
      align: "center" as const,
      render: (item: Contractor) =>
        contractorActions({
          item,
          handleShowEditModal
        })
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={parseData(data)}
      loading={isLoading}
      pagination={pagination}
      onChange={(pagination) => {
        const paginationForQuery = {
          totalItems: pagination.total || 0,
          pageSize: pagination.pageSize || 0,
          currentPage: pagination.current || 0
        };

        setPagination(paginationForQuery);
      }}
      rowKey="id"
    />
  );
};
