import { FC, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import numeral from "numeral";
import moment from "moment";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Card, Col, Row, Tag, Typography } from "antd";
import i18n from "@i18n/i18n";
import { DetailsOnScopeCard } from "@components/molecules/DetailsOnScopeCard";

import {
  CONTRACT_TYPE,
  defineContractType,
  defineScopeOfWorks
} from "@components/types/models/Contract";
import { defineActualStatus } from "@components/types/models/Actuals";

import type { ActualCardProps } from "./props";

const { Text, Title } = Typography;

export const ActualCard: FC<ActualCardProps> = ({
  contract,
  actual,
  isAdmin,
  loading
}) => {
  const { t } = useTranslation();

  const [areDetailsVisible, setDetailsVisible] = useState(false);

  const contractKcTarget = useMemo(
    () => `${contract?.lastApprovedAmendment.kcTarget}%`,
    [contract?.lastApprovedAmendment]
  );
  const contractAmount = useMemo(
    () =>
      `${numeral(contract?.lastApprovedAmendment.amount).format("0,00.00")} ${
        contract?.lastApprovedAmendment.currency.code
      }`,
    [contract?.lastApprovedAmendment]
  );

  const actualStatus = useMemo(
    () => defineActualStatus(actual?.status),
    [actual]
  );

  const getContractPeriod = (startDate?: string, endDate?: string): string => {
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    return t("actuals.fromTo", {
      from: formattedStartDate,
      to: formattedEndDate
    });
  };

  const getActualPeriod = (month?: number, year?: number): string =>
    `${moment()
      .month(Number(month) - 1)
      .locale(i18n.language)
      .format("MMM")} ${year}`;

  const getActualVersion = (version?: number): string => ` (v${version})`;

  return (
    <Col>
      <Card
        loading={loading}
        title={
          isAdmin && (
            <Row align="middle">
              <Text>
                <Text>
                  {i18n.language === "ru" ? t("actuals.actualsForStart") : ""}
                </Text>
                <Link to={`/admin/contract/${actual?.contractId}`}>
                  {actual?.contractNumber}
                </Link>
                <Text>
                  {t("actuals.actualsForEnd", {
                    period: getActualPeriod(actual?.month, actual?.year)
                  })}
                </Text>
                <Text>{getActualVersion(actual?.version)}</Text>
              </Text>
              <Tag style={{ marginLeft: "24px" }} color={actualStatus.color}>
                {i18n.language === "en"
                  ? actualStatus.enName
                  : actualStatus.ruName}
              </Tag>
            </Row>
          )
        }
      >
        <div
          className={css`
            ${tw`m-4!`}
          `}
        >
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={6}>
              <Text type="secondary">{t("contracts.contractPeriod")}</Text>
              <br />
              <Title level={5}>
                {getContractPeriod(
                  contract?.contractStartDate,
                  contract?.contractEndDate
                )}
              </Title>
            </Col>
          </Row>
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            {contract?.contractType !== CONTRACT_TYPE.MASTER_AGREEMENT && (
              <Col span={8}>
                <Text type="secondary">{t("contracts.scopeOfWorks")}</Text>
                <br />
                <Title
                  level={5}
                  className={css`
                    ${tw`mt-0!`}
                  `}
                >
                  {contract?.scopes
                    .map((scope) => {
                      const scopeItem = defineScopeOfWorks(scope);

                      return i18n.language === "en"
                        ? scopeItem.enName
                        : scopeItem.ruName;
                    })
                    .join(", ")}
                </Title>
              </Col>
            )}
            <Col span={6}>
              <Text type="secondary">{t("contracts.contractType")}</Text>
              <br />
              <Title level={5}>
                {i18n.language === "en"
                  ? defineContractType(contract?.contractType).enName
                  : defineContractType(contract?.contractType).ruName}
              </Title>
            </Col>
            {contract?.masterAgreementId && (
              <Col span={7}>
                <Text type="secondary">{t("contracts.parentContract")}</Text>
                <br />
                <Link
                  to={`/${isAdmin ? "admin" : "contractor"}/contract/${
                    contract.masterAgreementId
                  }`}
                >
                  <Text
                    className={css`
                      ${tw`text-base font-medium text-primary`}
                    `}
                  >
                    {contract.masterAgreement?.contractNumber}
                  </Text>
                </Link>
              </Col>
            )}
          </Row>
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            {isAdmin && (
              <Col span={8}>
                <Text type="secondary">{t("contracts.kcCategoryAndArea")}</Text>
                <br />
                <Title level={5}>{contract?.kcCategory.name}</Title>
              </Col>
            )}
            <Col span={6}>
              <Text type="secondary">{t("contracts.amount")}</Text>
              <br />
              <Title level={5}>{contractAmount}</Title>
            </Col>
            <Col span={7}>
              <Text type="secondary">{t("contracts.kcTarget")}</Text>
              <br />
              <Title level={5}>{contractKcTarget}</Title>
            </Col>
          </Row>
        </div>
      </Card>

      <DetailsOnScopeCard
        isLoading={loading}
        areDetailsVisible={areDetailsVisible}
        setDetailsVisible={setDetailsVisible}
        incomingText={contract?.lastApprovedAmendment.detailsOnScopeOfWork}
      />
    </Col>
  );
};
