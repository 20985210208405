import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import tw from "twin.macro";

import { MailOutlined } from "@ant-design/icons";
import { LanguageSelector } from "@components/atoms/LanguageSelector";
import { LoginButton } from "@components/atoms/LoginButton";
import { Logo } from "@components/atoms/Logo";
import { PageMeta } from "@components/atoms/PageMeta";
import { Button, Layout, Space } from "antd";

const TextButton = styled(Button)`
  ${tw`text-white shadow-none border-none! hover:opacity-70 hover:bg-transparent`}
  }
`;
const Header = styled.header`
  ${tw`flex flex-row justify-between items-center p-5 bg-primary`}
`;
const HeaderInner = styled.div`
  ${tw`flex flex-row  justify-between w-full	max-w-screen-xl self-center m-auto`}
`;

const logo = css`
  ${tw`text-white! font-bold text-xl hover:opacity-70! bg-transparent!`}
`;

const langSelect = css`
  ${tw`text-white!`}
  .ant-select-arrow {
    color: white !important;
`;

const langs = [
  {
    title: "English",
    shortTitle: "EN",
    code: "en"
  },
  {
    title: "Русский",
    shortTitle: "RU",
    code: "ru"
  }
];

const { Content } = Layout;

export type PublicLayoutProps = {
  readonly pageName: string;
};

export const PublicLayout: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation();

  const handleLangChange = (l: string): void => {
    void i18n.changeLanguage(l);
  };
  return (
    <Layout
      className="guest-pages-bg"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <PageMeta title={t("meta.main")} />
      <Header>
        <HeaderInner>
          <Logo className={logo} />
          <Space>
            <LanguageSelector
              whiteText
              withoutFlags
              currentLangCode={i18n.language}
              langs={langs}
              className={langSelect}
              onLangSwitch={handleLangChange}
            />
            <a href="mailto:fgpportal@chevron.com">
              <TextButton type="primary">
                <MailOutlined />
                {t("login.contactUs")}
              </TextButton>
            </a>
            <Link to="/login">
              <LoginButton withIcon />
            </Link>
          </Space>
        </HeaderInner>
      </Header>
      <Content> {children} </Content>
    </Layout>
  );
};
