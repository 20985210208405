import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { saveAs } from "file-saver";

import { DownloadOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
// eslint-disable-next-line import/no-deprecated

import type { DownloadFileButtonProps } from "./props";

export const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({
  url,
  disabled,
  title,
  shape,
  isGhost,
  type
}) => {
  const [t] = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = (): boolean => {
    setIsDownloading(true);
    axios
      .get(url, { responseType: "blob" })
      .then((response) => {
        setIsDownloading(false);
        const fileName = response.headers["content-disposition"]
          .split(";")
          .find((n: string) => n.includes("filename="))
          .replace("filename=", "")
          .replaceAll('"', "")
          .replace(" ", "");
        // eslint-disable-next-line import/no-deprecated
        saveAs(new Blob([response.data]), fileName);
      })
      .catch(() => {
        setIsDownloading(false);
        notification.error({
          message: t("downloadError")
        });
      });
    return false;
  };

  return (
    <Button
      type={type ?? "primary"}
      icon={<DownloadOutlined />}
      loading={isDownloading}
      disabled={disabled}
      ghost={isGhost}
      shape={shape}
      onClick={handleDownload}
    >
      {title}
    </Button>
  );
};
