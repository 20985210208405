import { useContext, useEffect } from "react";
import { useHistory } from "react-router";

import { Content } from "antd/lib/layout/layout";
import axios from "axios";

import { Spin } from "antd";

import { UserContext } from "@contexts/userContext";
import { useQueryParams } from "@hooks/useQueryParams";

const SsoCallbackPage: React.FC = () => {
  const queryParams = useQueryParams();
  const redirectTo = queryParams.redirectTo ?? "/profile";
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    if (typeof queryParams.code === "string") {
      localStorage.setItem("KC_PORTAL_USER_TOKEN", queryParams.code);

      void axios.get("/api/auth/me").then((res) => {
        setUser(res.data);
        history.push(String(redirectTo));
      });
    } else {
      history.replace("/login");
    }
  }, [queryParams.code, redirectTo, history, setUser]);

  return (
    <Content
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch"
      }}
    >
      <Spin />
    </Content>
  );
};

export default SsoCallbackPage;
