import { ruMigrations } from "@modules/Migrations/i18n/ru";

import { ruActuals } from "./actuals";
import { ruCommon } from "./common";
import { ruContractors } from "./contractors";
import { ruContracts } from "./contracts";
import { ruCountriesAndCities } from "./countries-and-cities";
import { ruExchangeRates } from "./exchange-rates";
import { ruForecasts } from "./forecasts";
import { ruGlossary } from "./glossary";
import { ruInvoices } from "./invoices";
import { ruLogin } from "./login";
import { ruMembers } from "./members";
import { ruMeta } from "./meta";
import { ruMonths } from "./months";
import { ruPermissions } from "./permissions";
import { ruPowerBi } from "./powerBi";
import { ruProfile } from "./profile";
import { ruReports } from "./reports";
import { ruSubcontractors } from "./subcontractors";
import { ruUserguides } from "./userguides";

export const ruTranslation = {
  translation: {
    ...ruCommon,
    contractors: ruContractors,
    subcontractors: ruSubcontractors,
    login: ruLogin,
    meta: ruMeta,
    members: ruMembers,
    profile: ruProfile,
    permissions: ruPermissions,
    contracts: ruContracts,
    forecasts: ruForecasts,
    actuals: ruActuals,
    months: ruMonths,
    exchangeRates: ruExchangeRates,
    reports: ruReports,
    powerBi: ruPowerBi,
    invoices: ruInvoices,
    glossary: ruGlossary,
    userguides: ruUserguides,
    migrations: ruMigrations,
    countriesAndCities: ruCountriesAndCities
  }
};
