export const ruPermissions = {
  permissions: "Доступы",

  1: "Members view",
  2: "Members edit",

  3: "Contractors view",
  4: "Contractors edit",

  5: "Contracts view",
  6: "Contracts edit",
  7: "Exchange rate view",
  8: "Exchange rate edit",
  9: "Dashboards edit",
  10: "Reports download dumps",
  11: "Migration edit",
  12: "Currency country city edit",

  // Displayed permissions

  memberView: "Сотрудники - просмотр",
  memberEdit: "Сотрудники - редактирование",
  contractorView: "Подрядчики - просмотр",
  contractorEdit: "Подрядчики - редактирование",
  contractView: "Контракты - просмотр",
  contractEdit: "Контракты - редактирование",

  exchangeRateView: "Курс валют - просмотр",
  exchangeRateEdit: "Курс валют - редактирование",
  dashboardsEdit: "Дэшборды - редактирование",
  reportDownloadDump: "Отчеты - скачивание исходных данных",

  migrationEdit: "Миграции - редактирование",
  currencyCountryCityEdit: "Валюта, страна, город - редактирование"
};
