export const enReports = {
  reports: "Reports",
  dashboards: "Dashboards",
  datadump: "Data dump",
  datadumpTitle: "All reports",
  datadumpSubtitle: "Download data dump",
  datadumpName: "File name",
  datadumpDate: "Created date",
  datadumpGenerate: "Generate data dump file",
  datadumpGenerateError:
    "There was an error generating data dump file, please try again",
  status: "Status",
  datadumpSuccess: "Datadump generation might take up to 1 hour",
  datadumpAlert:
    "Please note that only current (reviewed and approved) data is included to the data dump file",
  countriesAndCities: "Countries and cities",
  countryInEng: "Country in ENG",
  countryInRus: "Country in RUS",
  cityInEng: "City in ENG",
  cityInRus: "City in RUS",
  region: "Region"
};
