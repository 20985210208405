import { FC, useEffect } from "react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";

import type { AxiosError } from "axios";

import { Col, Form, Input, Modal, notification, Row, Select } from "antd";
import { CountrySelectorTemp } from "@components/atoms/CountrySelectorTemp";
import { CitySelectorTemp } from "@components/atoms/CitySelectorTemp";

import { City } from "@components/types/models/CountryCity";
import { RelationshipType } from "@components/types/models/Forecast";
import { CONTRACTUAL_RELATIONS } from "@components/types/models/ContractualRelations";

import { countryCityApi } from "@api/countryCityApi";
import { contractorApi } from "@api/contractorApi";

import { CONTRACTUAL_RELATIONS_LIST } from "@components/constants/ContractualRelations";

import type {
  EditSubcontractorFormValues,
  EditSubcontractorModalProps
} from "./props";

const { TextArea } = Input;
const { Option } = Select;
const DEFAULT_RELATION = {
  value: 0,
  label: "-"
};

export const EditSubcontractorModal: FC<EditSubcontractorModalProps> = ({
  isModalOpen,
  onCloseClick,
  selectedSubcontractor,
  refetchSubcontractors,
  contractorId
}) => {
  const [t] = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<number>();

  const handleCloseModal = onCloseClick;

  const {
    formState: { errors },
    setValue,
    setError,
    watch,
    reset,
    getValues,
    clearErrors
  } = useForm();

  useEffect(() => {
    if (selectedSubcontractor) {
      setValue("name", selectedSubcontractor.name || null);
      setValue("countryId", selectedSubcontractor.country?.id || null);
      setValue("cityId", selectedSubcontractor.city?.id || null);
      setValue(
        "relationType",
        selectedSubcontractor.contractualRelations?.relationType || null
      );
      setValue(
        "detailOnScopeOfWork",
        selectedSubcontractor.detailOnScopeOfWork
      );
      setSelectedCountry(selectedSubcontractor.country?.id);
    } else {
      reset();
    }
  }, [selectedSubcontractor, setValue, reset]);

  const countryList = useQuery(
    "getAllCountries",
    async () => countryCityApi.getAllCountries().then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const handleCitySelectorChange = (value: number) => {
    setValue("cityId", value);
  };

  const handleCountrySelectorChange = (value: number) => {
    setSelectedCountry(value);
    setValue("countryId", value);
    setValue("cityId", undefined);
  };

  useEffect(() => {
    if (watch("relationType") === CONTRACTUAL_RELATIONS.CONTRACTOR) {
      setValue("relationType", DEFAULT_RELATION.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setValue(name, e.currentTarget.value);
  };

  const editSubcontractorMutation = useMutation(
    (values: EditSubcontractorFormValues) =>
      contractorApi.editSubcontractor({
        contractorId,
        subcontractorId: selectedSubcontractor?.id,
        payload: values
      }),
    {
      onSuccess: () => {
        refetchSubcontractors();
        handleCloseModal();
        notification.success({
          message: `${t("subcontractors.editSuccess")}`
        });
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        }
      }
    }
  );

  const handleModalOkClick = () => {
    const values = getValues() as EditSubcontractorFormValues;
    clearErrors();
    editSubcontractorMutation.mutate(values);
  };

  return (
    <Modal
      visible={isModalOpen}
      okText={t("update")}
      cancelText={t("cancel")}
      confirmLoading={editSubcontractorMutation.isLoading}
      onOk={handleModalOkClick}
      title={`${t("subcontractors.editSubcontractor")}${
        selectedSubcontractor ? ` - ${selectedSubcontractor.name}` : ""
      }`}
      closable={false}
      maskClosable={false}
      width={800}
      onCancel={handleCloseModal}
    >
      <Form layout="vertical">
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item
              validateStatus={errors.name?.message && "error"}
              help={errors.name?.message}
              label={t("subcontractors.form.companyName")}
            >
              <Input
                value={watch("name")}
                onChange={(evt) => handleInputChange(evt, "name")}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              validateStatus={errors.relationType?.message && "error"}
              help={errors.relationType?.message}
              label={t("subcontractors.form.contractualRelations")}
            >
              <Select
                value={watch("relationType")}
                style={{ width: "100%" }}
                onChange={(value) => {
                  setValue("relationType", value);
                }}
              >
                {CONTRACTUAL_RELATIONS_LIST.map(
                  (relationship: RelationshipType, index: number) =>
                    relationship.value !== CONTRACTUAL_RELATIONS.CONTRACTOR && (
                      <Option key={index} value={relationship.value}>
                        {t(`forecasts.${relationship.label}`)}
                      </Option>
                    )
                )}
                <Option
                  style={{ display: "none" }}
                  value={DEFAULT_RELATION.value}
                >
                  {DEFAULT_RELATION.label}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("subcontractors.form.bin")}>
              <Input value={selectedSubcontractor?.bin ?? "-"} disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              validateStatus={errors.countryId?.message && "error"}
              help={errors.countryId?.message}
              label={t("subcontractors.form.country")}
            >
              <CountrySelectorTemp
                countries={countryList?.data}
                selectedCountry={selectedCountry}
                onChange={handleCountrySelectorChange}
                loading={countryList.isLoading}
              />
            </Form.Item>
          </Col>
          {countryList?.data?.find((c: City) => c.id === selectedCountry)
            ?.cities.length > 0 && (
            <Col md={12}>
              <Form.Item
                validateStatus={errors.cityId?.message && "error"}
                help={errors.cityId?.message}
                label={t("contractors.city")}
                labelCol={{ span: 24 }}
              >
                <CitySelectorTemp
                  cities={
                    countryList.data.find((c: City) => c.id === selectedCountry)
                      ?.cities
                  }
                  selectedCity={watch("cityId")}
                  onChange={handleCitySelectorChange}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item
              validateStatus={errors.detailOnScopeOfWork?.message && "error"}
              help={errors.detailOnScopeOfWork?.message}
              label={t("subcontractors.form.detailOnScopeOfWork")}
              labelCol={{ span: 24 }}
            >
              <TextArea
                rows={4}
                autoSize={{ minRows: 4 }}
                value={watch("detailOnScopeOfWork")}
                onChange={(evt) =>
                  handleInputChange(evt, "detailOnScopeOfWork")
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
