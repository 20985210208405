import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";

import { styled } from "@linaria/react";
import tw from "twin.macro";

import { Menu } from "antd";
import { AppBadge } from "@components/atoms/AppBadge";

import type { NavigationProps, NavItem, NavItemProps } from "./props";

function getCurrentNavItemIds(
  navItems: readonly NavItem[],
  currentPath: string,
  ids: string[] = []
): string[] {
  const currentNavItem = navItems.find(
    (navItem) =>
      navItem.items?.find((item: NavItemProps) => item.path === currentPath) ??
      navItem.path === currentPath
  );
  ids.push(currentNavItem?.id ?? "");

  if (currentNavItem?.items) {
    return getCurrentNavItemIds(currentNavItem.items, currentPath, ids);
  }
  return ids;
}

const Navbar = styled.div`
  ${tw`h-12 bg-white`}
`;

const NavbarMenu = styled(Menu)`
  ${tw`max-w-screen-xl m-auto h-full`}
  border-bottom: none!important
`;

export const Navigation: React.FC<NavigationProps> = ({ navItems }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [defaultNavIds, setDefaultNavIds] = useState<string[]>([]);
  useEffect(() => {
    setDefaultNavIds(getCurrentNavItemIds(navItems, location.pathname));
    // TODO: don't know why `navItems` is not included
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleNavbarMenuClick = useCallback(() => {
    setDefaultNavIds(getCurrentNavItemIds(navItems, window.location.pathname));
  }, [navItems]);

  return (
    <Navbar>
      <NavbarMenu
        selectedKeys={defaultNavIds}
        theme="light"
        mode="horizontal"
        onClick={handleNavbarMenuClick}
      >
        {navItems.map(
          (navItem) =>
            navItem.isNavItem &&
            (navItem.items ? (
              <Menu.SubMenu
                key={navItem.id}
                title={t(navItem.text ?? "")}
                icon={navItem.icon}
              >
                {navItem.items.map((subMenuItem) => (
                  <Menu.Item key={subMenuItem.id}>
                    <Link to={subMenuItem.path ?? "/wrongrouteitem"}>
                      {t(subMenuItem.text ?? "")}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <Menu.Item
                key={navItem.id}
                icon={navItem.icon}
                onClick={() => history.push(navItem.path ?? "/wrongrouteitem")}
              >
                {t(navItem.text ?? "")}
                <AppBadge count={navItem.notice} />
              </Menu.Item>
            ))
        )}
      </NavbarMenu>
    </Navbar>
  );
};
