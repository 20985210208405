/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { FC } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router";

import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import type { Moment } from "moment";
import moment from "moment";
import numeral from "numeral";
import tw from "twin.macro";

import { ForecastGws } from "@components/organisms/ForecastGws";
import { ForecastJobsForm } from "@components/organisms/ForecastJobsForm";
import { ForecastTrainingForm } from "@components/organisms/ForecastTrainingForm/component";
import { LegacyTechnologyForm } from "@components/organisms/LegacyTechnologyForm";
import { MasterAgreementGwsTable } from "@components/organisms/MasterAgreementGwsTable";
import { useDefaultQuery } from "@hooks";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  message,
  notification,
  Row,
  Tag,
  Typography
} from "antd";
import {
  FieldTimeOutlined,
  UndoOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { DetailsOnScopeCard } from "@components/molecules/DetailsOnScopeCard";

import {
  CONTRACT_TYPE,
  defineContractType,
  SCOPE_OF_WORK
} from "@components/types/models/Contract";
import type { ForecastType } from "@components/types/models/Forecast";
import {
  ForecastApprovalStatus,
  SectionType
} from "@components/types/models/Forecast";
import { defineStatus } from "@components/types/models/Statuses";

import { forecastApi } from "@api/forecastApi";

import { UserRoles } from "@contexts/types/UserRoles";
import { UserContext } from "@contexts/userContext";

const { Panel } = Collapse;
const { Text, Title } = Typography;

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_SUBMISSION_DATE = 3;

export const ForecastView: FC = () => {
  const { id } = useParams<{ readonly id?: string }>();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { userRole } = useContext(UserContext);

  const [dueDate, setDueDate] = useState<Moment>(
    moment(new Date(), DEFAULT_DATE_FORMAT).add(DEFAULT_SUBMISSION_DATE, "days")
  );
  const [activeCollapseItem, setActiveCollapseItem] = useState<number>();
  const [areDetailsVisible, setDetailsVisible] = useState(false);

  const scopeOfWorks = useMemo<Record<SCOPE_OF_WORK, string>>(
    () => ({
      [SCOPE_OF_WORK.GOODS]: t("contracts.goods"),
      [SCOPE_OF_WORK.WORKS]: t("contracts.works"),
      [SCOPE_OF_WORK.SERVICES]: t("contracts.services")
    }),
    [t]
  );

  const {
    data: forecast,
    isLoading,
    refetch: refetchForecastDetails
  } = useDefaultQuery<ForecastType>("gerForecast", async () =>
    forecastApi.getForecastDetails(Number(id)).then((res) => res.data)
  );

  const getCommentsBadge = (sectionType: SectionType): string => {
    if (
      forecast?.unresolvedComments.some(
        (item) => item.sectionType === sectionType
      )
    ) {
      return "!";
    }
    return "";
  };

  const forecastStatus = defineStatus(forecast?.approvalStatus);

  const contractPeriod = `${t("forecasts.from")} ${moment(
    forecast?.contractStartDate
  ).format(DEFAULT_DATE_FORMAT)} ${t("forecasts.to")} ${moment(
    forecast?.contractEndDate
  ).format(DEFAULT_DATE_FORMAT)}`;
  const forecastVersion = `v${forecast?.forecastVersion}`;
  const forecastKcTarget = `${forecast?.kcTarget}%`;

  const disableDate = (current: Moment): boolean =>
    current < moment().endOf("day");

  const remainingDaysForReview = useMemo(() => {
    const dueDateTime = moment(forecast?.dueDate);
    const today = moment();

    return dueDateTime.diff(today, "days");
  }, [forecast]);

  const { mutate: submitForecastMutation, isLoading: isSubmitLoading } =
    useMutation(
      async () => forecastApi.submitForecast(Number(id), dueDate.toJSON()),
      {
        onSuccess() {
          void message.success(t("forecasts.successForecastSubmit"));
          void refetchForecastDetails();
          history.push("/contractor/forecasts");
        },
        onError(err: AxiosError) {
          notification.error({ message: err.response?.data?.message });
        }
      }
    );

  useEffect(() => {
    void refetchForecastDetails();
  }, [id, refetchForecastDetails]);

  const handleForecastSubmit = useCallback(() => {
    submitForecastMutation();
  }, [submitForecastMutation]);

  const handleDateChange = useCallback((date) => {
    setDueDate(date);
  }, []);

  const { mutate: updateForecastMutation, isLoading: isUpdateLoading } =
    useMutation(async () => forecastApi.updateForecast(Number(id)), {
      onSuccess(res) {
        history.replace(`/contractor/forecast/${res.data}`);
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    });

  const { mutate: restorePrevForecastMutation, isLoading: isRestoreLoading } =
    useMutation(async () => forecastApi.restorePrevForecast(Number(id)), {
      onSuccess(res) {
        history.replace(`/contractor/forecast/${res.data}`);
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    });

  const handleUpdateForecastClick = useCallback(() => {
    updateForecastMutation();
  }, [updateForecastMutation]);

  const handleRestoreClick = useCallback(() => {
    restorePrevForecastMutation();
  }, [restorePrevForecastMutation]);

  const handleOpenCollapse = useCallback((key) => {
    setActiveCollapseItem(key);
  }, []);

  const isEditable =
    forecast?.approvalStatus !== ForecastApprovalStatus.Pending &&
    forecast?.approvalStatus !== ForecastApprovalStatus.Approved &&
    userRole !== UserRoles.Admin;

  return (
    <>
      <Row
        justify="end"
        className={css`
          ${tw`mb-6`}
        `}
      >
        {forecast &&
          forecast.forecastVersion > 1 &&
          forecast.approvalStatus === ForecastApprovalStatus.Draft && (
            <Button
              size="large"
              type="primary"
              icon={<UndoOutlined />}
              onClick={handleRestoreClick}
            >
              {t("forecasts.restorePrevVersion")}
            </Button>
          )}
        {forecast?.approvalStatus === ForecastApprovalStatus.Approved && (
          <Button
            size="large"
            type="primary"
            onClick={handleUpdateForecastClick}
          >
            {t("update")}
          </Button>
        )}

        {forecast?.approvalStatus === ForecastApprovalStatus.Rejected &&
          (remainingDaysForReview > 0 ? (
            <Col span={24}>
              <Alert
                showIcon
                icon={<FieldTimeOutlined />}
                message={
                  <Translation>
                    {(tr) =>
                      tr("forecasts.timeLeft", {
                        count: remainingDaysForReview
                      })
                    }
                  </Translation>
                }
                type="warning"
              />
            </Col>
          ) : (
            <Col span={24}>
              <Alert
                showIcon
                icon={<WarningOutlined />}
                message={t("actuals.missedDueDate")}
                type="error"
              />
            </Col>
          ))}
      </Row>

      <Card
        loading={
          isLoading || isUpdateLoading || isRestoreLoading || isSubmitLoading
        }
        title={
          <Text>
            {t("forecasts.forecastForContract")} {forecast?.contractNumber}
            <Tag style={{ marginLeft: "24px" }} color={forecastStatus.color}>
              {i18n.language === "en"
                ? forecastStatus.enName
                : forecastStatus.ruName || "-"}
            </Tag>
          </Text>
        }
      >
        <div
          className={css`
            ${tw`m-4!`}
          `}
        >
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={6}>
              <Text type="secondary">{t("forecasts.forecastVersion")}</Text>
              <br />
              <Title level={5}>{forecastVersion || "-"}</Title>
            </Col>
          </Row>
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={8}>
              <Text type="secondary">{t("contracts.contractPeriod")}</Text>
              <br />
              <Title level={5}>{contractPeriod || "-"}</Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t("contracts.contractType")}</Text>
              <br />
              <Title level={5}>
                {i18n.language === "en"
                  ? defineContractType(forecast?.contractType).enName
                  : defineContractType(forecast?.contractType).ruName || "-"}
              </Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t("contracts.masterAgreement")}</Text>
              <br />
              <Title level={5}>{forecast?.masterAgreementNumber ?? "-"}</Title>
            </Col>
          </Row>
          <Row
            className={css`
              ${tw`mb-6`}
            `}
          >
            <Col span={8}>
              <Text type="secondary">{t("contracts.amount")}</Text>
              <br />
              <Title level={5}>
                {numeral(forecast?.contractAmount).format("0,0.00")}{" "}
                {forecast?.currencyDto.code}
              </Title>
            </Col>
            <Col span={6}>
              <Text type="secondary">{t("contracts.kcTarget")}</Text>
              <br />
              <Title level={5}>{forecastKcTarget}</Title>
            </Col>
            {forecast?.contractType !== CONTRACT_TYPE.MASTER_AGREEMENT && (
              <Col span={6}>
                <Text type="secondary">{t("contracts.scopeOfWorks")}</Text>
                <Title
                  level={5}
                  className={css`
                    ${tw`mt-0!`}
                  `}
                >
                  {forecast?.scopes.map(
                    (scope: SCOPE_OF_WORK, index) =>
                      (index ? ", " : "") + scopeOfWorks[scope]
                  ) || "-"}
                </Title>
              </Col>
            )}
          </Row>
        </div>
      </Card>

      <DetailsOnScopeCard
        isLoading={isLoading}
        areDetailsVisible={areDetailsVisible}
        setDetailsVisible={setDetailsVisible}
        incomingText={forecast?.detailsOnScopeOfWork}
      />

      <Collapse
        accordion
        destroyInactivePanel
        activeKey={activeCollapseItem}
        onChange={handleOpenCollapse}
        className={css`
          ${tw`mt-6`}
        `}
      >
        {forecast?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT ? (
          <Panel key="4" header={t("forecasts.goodsWorksServicesSummary")}>
            <MasterAgreementGwsTable forecastId={Number(id)} />
          </Panel>
        ) : (
          <ForecastGws
            scopes={forecast?.scopes}
            startDateString={forecast?.contractStartDate}
            endDateString={forecast?.contractEndDate}
            forecastId={Number(id)}
            contractCurrency={forecast?.currencyDto.code}
            forecastStatus={forecast?.approvalStatus}
            isEditable={isEditable}
            getCommentsBadge={getCommentsBadge}
            forecastCreatedDate={forecast?.createdBy?.createdAt}
          />
        )}
        {forecast?.contractType !== CONTRACT_TYPE.SUB_AGREEMENT && (
          <>
            <Panel
              key="5"
              header={
                <Badge
                  offset={[16, 0]}
                  count={getCommentsBadge(SectionType.JobAndNationalization)}
                >
                  {t("forecasts.jobsAndNationalization")}
                </Badge>
              }
            >
              <ForecastJobsForm
                startDate={forecast?.contractStartDate}
                endDate={forecast?.contractEndDate}
                forecastId={Number(id)}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={5}
                isEditable={isEditable}
                forecastStatus={forecast?.approvalStatus}
                refetchForecast={refetchForecastDetails}
              />
            </Panel>
            <Panel
              key="6"
              header={
                <Badge
                  offset={[16, 0]}
                  count={getCommentsBadge(SectionType.Training)}
                >
                  {t("forecasts.training")}
                </Badge>
              }
            >
              <ForecastTrainingForm
                startDate={forecast?.contractStartDate}
                endDate={forecast?.contractEndDate}
                forecastId={Number(id)}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={6}
                forecastStatus={forecast?.approvalStatus}
                isEditable={isEditable}
              />
            </Panel>
            <Panel
              key="7"
              header={
                <Badge
                  offset={[16, 0]}
                  count={getCommentsBadge(SectionType.TechnologyTransfer)}
                >
                  {t("forecasts.technologyTransfer")}
                </Badge>
              }
            >
              <LegacyTechnologyForm
                forecastId={Number(id)}
                sectionType={SectionType.TechnologyTransfer}
                placeholder={t("forecasts.technologyPlaceholder")}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={7}
                url={`/api/forecasts/techtransfer/${id}`}
                forecastStatus={forecast?.approvalStatus}
                isEditable={isEditable}
              />
            </Panel>
            <Panel
              key="8"
              header={
                <Badge
                  offset={[16, 0]}
                  count={getCommentsBadge(SectionType.Legacy)}
                >
                  {t("forecasts.legacy")}
                </Badge>
              }
            >
              <LegacyTechnologyForm
                forecastId={Number(id)}
                sectionType={SectionType.Legacy}
                placeholder={t("forecasts.legacyPlaceholder")}
                setActiveCollapseItem={setActiveCollapseItem}
                activeCollapseItem={8}
                url={`/api/forecasts/legacy/${id}`}
                forecastStatus={forecast?.approvalStatus}
                isEditable={isEditable}
              />
            </Panel>
          </>
        )}
      </Collapse>
      {(forecast?.approvalStatus === ForecastApprovalStatus.Draft ||
        forecast?.approvalStatus === ForecastApprovalStatus.Rejected) && (
        <Row style={{ marginTop: "24px" }} justify="space-between">
          <Col span={4}>
            <Form.Item>
              <DatePicker
                showNow={false}
                placeholder={t("forecasts.dueDate")}
                size="large"
                value={dueDate}
                style={{ width: "100%" }}
                disabledDate={disableDate}
                format={DEFAULT_DATE_FORMAT}
                onChange={handleDateChange}
              />
            </Form.Item>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                <Alert
                  showIcon
                  message={t("forecasts.submissionWarningMessage")}
                  type="warning"
                />
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  loading={isSubmitLoading}
                  onClick={handleForecastSubmit}
                >
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
