import { FC, useMemo } from "react";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import axios from "axios";
import moment from "moment";
import tw from "twin.macro";

import {
  ClockCircleTwoTone,
  MailOutlined,
  UserOutlined
} from "@ant-design/icons";
import { LanguageSelector } from "@components/atoms/LanguageSelector";
import { Logo } from "@components/atoms/Logo";
import { LogoutButton } from "@components/atoms/LogoutButton";
import { PageMeta } from "@components/atoms/PageMeta";
import { Navigation } from "@components/molecules/Navigation";
import { Button, Divider, Layout, Space, Typography } from "antd";

import { UserContext } from "@contexts/userContext";

const Container = styled.div`
  ${tw`h-auto w-full m-auto  min-h-screen`}
`;
const Content = styled.div`
  ${tw`max-w-screen-xl self-center m-auto p-5`}
`;
const TextButton = styled(Button)`
  ${tw`text-white shadow-none border-none! hover:opacity-70 hover:bg-transparent`}
  }
`;
const Header = styled.header`
  ${tw`flex flex-row justify-between items-center p-5 bg-primary`}
`;
const HeaderInner = styled.div`
  ${tw`flex flex-row  justify-between w-full	max-w-screen-xl self-center m-auto`}
`;

const logo = css`
  ${tw`text-white! font-bold text-xl hover:opacity-70! bg-transparent!`}
`;

const langSelect = css`
  ${tw`text-white!`}
  .ant-select-arrow {
    color: white !important;
`;

const langs = [
  {
    title: "English",
    shortTitle: "EN",
    code: "en"
  },
  {
    title: "Русский",
    shortTitle: "RU",
    code: "ru"
  }
];

type NavItem = {
  readonly id: string;
  readonly text?: string;
  readonly icon?: React.ReactNode;
  readonly path?: string;
  readonly isNavItem?: boolean;
};

export type DefaultLayoutProps = {
  navItems: NavItem[];
  readonly isEmbedContent?: boolean;
};

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  navItems,
  isEmbedContent
}) => {
  const { t, i18n } = useTranslation();
  const { setUser, user, userRole, isAdmin } = useContext(UserContext);
  const { Text } = Typography;
  const userName = `${user?.firstName} ${user?.lastName}(${userRole})`;

  const handleLogoutClick = (): void => {
    setUser(null);
    void axios.post("/api/auth/logout").finally(() => {
      localStorage.removeItem("KC_PORTAL_USER_TOKEN");
    });
  };

  const handleLangSwitch = useCallback(
    (l: string): void => {
      void i18n.changeLanguage(l);
    },
    [i18n]
  );

  const guidelineLink = useMemo(
    () => "/" + (isAdmin ? "admin" : "contractor") + "/user-guide",
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole]
  );

  return (
    <Layout>
      <PageMeta title={t("meta.main")} />
      <Header>
        <HeaderInner>
          <Space>
            <Logo className={logo} />
            <Link to={guidelineLink}>
              <TextButton type="primary">{t("login.userGuide")}</TextButton>
            </Link>
            <a href="mailto:fgpportal@chevron.com">
              <TextButton type="primary">
                <MailOutlined />
                {t("login.contactUs")}
              </TextButton>
            </a>
          </Space>
          <Space>
            <Text style={{ color: "white" }}>
              <ClockCircleTwoTone /> {moment().format("HH:mm")}
            </Text>
            <Divider type="vertical" style={{ backgroundColor: "white" }} />
            <LanguageSelector
              whiteText
              withoutFlags
              currentLangCode={i18n.language}
              langs={langs}
              className={langSelect}
              onLangSwitch={handleLangSwitch}
            />
            <Divider type="vertical" style={{ backgroundColor: "white" }} />
            <Link to="/profile" style={{ color: "white", marginRight: "36px" }}>
              <UserOutlined style={{ marginRight: "8px" }} />
              {userName}
            </Link>
            <Link to="/login">
              <LogoutButton onClick={handleLogoutClick} />
            </Link>
          </Space>
        </HeaderInner>
      </Header>
      <Container>
        <Navigation navItems={navItems} />
        <Content
          style={
            isEmbedContent
              ? {
                  height: "100%",
                  minHeight: "100vh",
                  width: "100%",
                  padding: 0,
                  maxWidth: "100%"
                }
              : {}
          }
        >
          {children}
        </Content>
      </Container>
    </Layout>
  );
};
