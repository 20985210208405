import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import qs from "qs";

import { useQueryParams } from "./useQueryParams";

export type Pagination<FilterParams, SortParams> = {
  totalItems: number;
  pageSize: number;
  currentPage: number;
  filterParams?: FilterParams;
  sortParams?: SortParams;
  tabParams?: string;
};

export const usePagination = <FilterParams = null, SortParams = null>() => {
  const history = useHistory();
  const parsedQueryParams = useQueryParams();

  const [page, setPage] = useState<number>(1);
  const [pagination, setPagination] = useState<
    Pagination<FilterParams, SortParams>
  >({
    totalItems: 0,
    pageSize: 0,
    currentPage: 1
  });

  useEffect(() => {
    // TODO: add type guard
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPagination((prevState: any) => ({
      ...prevState,
      currentPage: parsedQueryParams.page,
      filterParams: parsedQueryParams.filters,
      sortParams: parsedQueryParams.sort,
      tabParams: parsedQueryParams.tab
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      totalItems,
      pageSize,
      currentPage,
      filterParams,
      sortParams,
      tabParams
    } = pagination;

    history.push({
      search: qs.stringify(
        {
          filters: filterParams,
          sort: sortParams,
          page: currentPage,
          tab: tabParams
        },
        { arrayFormat: "indices" }
      )
    });

    // if, for example totalItems = 11, pageSize = 10 and user, located on the 2nd page deletes the very last item of the Array
    // the code below will understand that the 2nd page contains no data and will push user to the 1st page.
    if (
      currentPage &&
      Math.ceil(totalItems / pageSize) < currentPage &&
      page !== 1
    ) {
      setPage(page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  return { page, pagination, setPage, setPagination };
};
