import type { FC } from "react";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next/";

import { styled } from "@linaria/react";
import tw from "twin.macro";

import { AppBadge } from "@components/atoms/AppBadge";
import { ActualsTable } from "@components/pages/admin/Actuals/libs/components/ActualsTable";
import { Tabs } from "antd";

import { ForecastApprovalStatus } from "@components/types/models/Forecast";

import { UserContext } from "@contexts/userContext";

import type { ActualsListTabsProps } from "./props";

const { TabPane } = Tabs;

const TabsBase = styled(Tabs)`
  .ant-tabs-nav {
    &::before {
      ${tw`border-b`}
      border-color: #fafafa;
    }
  }
`;

export const ActualsListTabs: FC<ActualsListTabsProps> = ({
  actualsList,
  isLoading,
  onSetPage,
  sort,
  onSetSort,
  children,
  ...props
}) => {
  const { t } = useTranslation();
  const { notices } = useContext(UserContext);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, react-hooks/exhaustive-deps
  const handleSort = useCallback(onSetSort!, [onSetSort]);

  return (
    <TabsBase {...props}>
      <TabPane key={ForecastApprovalStatus.Undefined} tab={t("all")}>
        <ActualsTable
          actualsList={actualsList?.results}
          isLoading={isLoading}
          pagination={{
            total: actualsList?.totalItems,
            current: actualsList?.currentPage,
            pageSize: actualsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Pending}
        tab={
          <AppBadge count={notices?.actuals}>
            {t("forecasts.opts.status.pending")}
          </AppBadge>
        }
      >
        <ActualsTable
          actualsList={actualsList?.results}
          isLoading={isLoading}
          pagination={{
            total: actualsList?.totalItems,
            current: actualsList?.currentPage,
            pageSize: actualsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Approved}
        tab={t("forecasts.opts.status.approved")}
      >
        <ActualsTable
          actualsList={actualsList?.results}
          isLoading={isLoading}
          pagination={{
            total: actualsList?.totalItems,
            current: actualsList?.currentPage,
            pageSize: actualsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Rejected}
        tab={t("forecasts.opts.status.rejected")}
      >
        <ActualsTable
          actualsList={actualsList?.results}
          isLoading={isLoading}
          pagination={{
            total: actualsList?.totalItems,
            current: actualsList?.currentPage,
            pageSize: actualsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      <TabPane
        key={ForecastApprovalStatus.Overdue}
        tab={t("forecasts.opts.status.overdue")}
      >
        <ActualsTable
          actualsList={actualsList?.results}
          isLoading={isLoading}
          pagination={{
            total: actualsList?.totalItems,
            current: actualsList?.currentPage,
            pageSize: actualsList?.pageSize,
            onChange: (p: number) => {
              onSetPage(p);
            },
            showSizeChanger: false
          }}
          sort={sort}
          onSetSort={handleSort}
        />
      </TabPane>
      {children}
    </TabsBase>
  );
};
