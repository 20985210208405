import { FC, useContext } from "react";
import { useEffect, useMemo, useState } from "react";

import moment from "moment";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";

import { STATUS } from "@components/types/models/Statuses";

import { UserContext } from "@contexts/userContext";

import type { JobsTrainingTimelineProps, YearItem } from "./props";

const { Title } = Typography;

const DATE_FORMAT = "YYYY-M-DD";

export const JobsTrainingTimeline: FC<JobsTrainingTimelineProps> = ({
  startDateString,
  endDateString,
  getCurrentPeriod,
  isCurrentPeriodChanged,
  isGrandTotal,
  status
}) => {
  const presentTime = moment(new Date(), DATE_FORMAT);
  const startDate = moment(startDateString, DATE_FORMAT);
  const endDate = moment(endDateString, DATE_FORMAT);

  const [currentPeriod, setCurrentPeriod] = useState<
    YearItem | null | undefined
  >(null);
  const [monthsYearList, setMonthsYearList] = useState<readonly YearItem[]>([]);

  const { isAdmin } = useContext(UserContext);

  const getPeriodList = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const allMonthsInPeriod: any = {};

    while (startDate.isBefore(endDate)) {
      if (allMonthsInPeriod[startDate.format("YYYY")]) {
        allMonthsInPeriod[startDate.format("YYYY")].push(
          Number(startDate.format("M"))
        );
      } else {
        allMonthsInPeriod[startDate.format("YYYY")] = [
          Number(startDate.format("M"))
        ];
      }
      startDate.add(1, "month");
    }

    const outputPeriods = Object.entries(allMonthsInPeriod).map(
      ([year, months]) => ({
        year: Number(year),
        years: Object.keys(allMonthsInPeriod).map((periodYear: string) =>
          Number(periodYear)
        ),
        months,
        isDetailed: true
      })
    );

    setMonthsYearList(outputPeriods as readonly YearItem[]);
  };

  const handlePrevYearClick = (): void => {
    const prevYearIndex =
      monthsYearList.findIndex(
        (item: YearItem | undefined) => item?.year === currentPeriod?.year
      ) - 1;

    setCurrentPeriod(monthsYearList[prevYearIndex]);
  };

  const handleNextYearClick = (): void => {
    const nextYearIndex = monthsYearList.findIndex(
      (item: YearItem) => item.year === currentPeriod?.year
    );

    setCurrentPeriod(monthsYearList[Number(nextYearIndex) + 1]);
  };

  const isYearIncluded = (year: number | string): boolean =>
    Boolean(monthsYearList.find((item: YearItem) => item.year === year));

  const currentPeriodTitle = useMemo(() => {
    if (!currentPeriod?.isDetailed) {
      const isCurrentYearLast =
        currentPeriod?.year === monthsYearList[monthsYearList.length - 1]?.year;

      return `${currentPeriod?.year}${
        isCurrentYearLast
          ? ""
          : `-${monthsYearList[monthsYearList.length - 1].year}`
      }`;
    }

    return currentPeriod.year || "-";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod]);

  useEffect(() => {
    getPeriodList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getCurrentPeriod && currentPeriod) {
      getCurrentPeriod(currentPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod]);

  useEffect(() => {
    let current;

    if (startDate.isAfter(presentTime, "year")) {
      current = monthsYearList.find(
        (item: YearItem) => Number(item.year) === startDate.year()
      );
    } else if (
      endDate.isBefore(presentTime, "year") ||
      (isAdmin && (status === STATUS.REJECTED || status === STATUS.APPROVED))
    ) {
      current = monthsYearList.find(
        (item: YearItem) => Number(item.year) === endDate.year()
      );
    } else {
      current = monthsYearList.find(
        (item: YearItem) => Number(item.year) === presentTime.year()
      );
    }

    setCurrentPeriod(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthsYearList]);

  useEffect(() => {
    handleNextYearClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentPeriodChanged]);

  return (
    <Row align="middle">
      <Col>
        <Row align="middle" justify="space-between">
          <Button
            type="link"
            icon={<LeftOutlined />}
            disabled={
              !isYearIncluded(Number(currentPeriod?.year) - 1) || isGrandTotal
            }
            onClick={handlePrevYearClick}
          />
          <Title
            style={{
              color: `${isGrandTotal ? "rgba(0, 0, 0, 0.25)" : "#1890ff"}`,
              marginBottom: 0
            }}
            level={4}
          >
            {currentPeriodTitle}
          </Title>
          <Button
            type="link"
            icon={<RightOutlined />}
            disabled={
              !isYearIncluded(Number(currentPeriod?.year) + 1) ||
              !currentPeriod?.isDetailed ||
              isGrandTotal
            }
            onClick={handleNextYearClick}
          />
        </Row>
      </Col>
    </Row>
  );
};
