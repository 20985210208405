/* eslint-disable @typescript-eslint/naming-convention */

export enum STATUS {
  UNDEFINED = 0,
  PENDING = 1,
  REJECTED = 2,
  APPROVED = 3,
  DRAFT = 4
}

export function defineStatus(status: number | undefined): {
  readonly enName: string;
  readonly ruName: string;
  readonly color: string;
} {
  switch (status) {
    case STATUS.PENDING:
      return {
        enName: "PENDING",
        ruName: "НА РАССМОТРЕНИИ",
        color: "orange"
      };
    case STATUS.REJECTED:
      return {
        enName: "REJECTED",
        ruName: "ОТКЛОНЕН",
        color: "red"
      };
    case STATUS.APPROVED:
      return {
        enName: "APPROVED",
        ruName: "УТВЕРЖДЕН",
        color: "green"
      };
    case STATUS.DRAFT:
      return {
        enName: "DRAFT",
        ruName: "ЧЕРНОВИК",
        color: "gray"
      };
    default:
      return {
        enName: "DRAFT",
        ruName: "ЧЕРНОВИК",
        color: "gray"
      };
  }
}
