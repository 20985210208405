export const actuals = [
  {
    title: "О фактических показателях",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Руководителям KC присваиваются определенные контракты в системе (на основе Категории и области KC), так, когда подрядчики отправляют на рассмотрение фактические показатели за определенный период контракта, только назначенные Руководители KC получают уведомление по электронной почте</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подрядчики создают и отправляют на рассмотрение фактические показатели за каждый месяц периода контракта. В зависимости от объема работ по конкретному контракту фактические показатели содержат различные разделы:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Отдельное соглашение</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: Товары/Работы/Услуги; Итого товары, работы, услуги; Количество сотрудников и национализация; Обучение; Передача технологии; Наследие; Истории Успеха</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Дополнительное соглашение:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Товары/Работы/Услуги; Итого товары, работы, услуги;</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Генеральное соглашение:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Итого товары, работы, услуги; Количество сотрудников и национализация; Обучение; Передача технологии; Наследие; Истории Успеха</span></span></span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Фактические показатели имеют </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>четыре статуса</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> в системе:</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Фактические показатели, представленные Подрядчиком и еще не рассмотренные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;На рассмотрении&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Фактические показатели, представленные Подрядчиком и утвержденные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Утвержден&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Фактические показатели, представленные Подрядчиком и отклоненные Руководителем KC, имеют статус </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Отклонен&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подрядчики, которые не представили свои Фактические показатели после 20 числа каждого месяца, считаются</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Просрочен&quot;</strong></span></span></span></li>
</ol>
`
  },
  {
    title: "Как отклонить фактические показатели?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;На рассмотрении&quot;со списка фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Рассмотрите детали предоставленных подрядчиками фактических показателей по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий нажав на кнопку &quot;Оставить комментарий&quot;(Обязательно: в секции, которую необходимо исправить)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отклонить&quot; на детальной странице выбранных фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Укажите срок предоставления изменений фактических показателей для подрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите отклонение нажатием на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как утвердить фактические показатели?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;На рассмотрении&quot;со списка фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Рассмотрите детали предоставленных подрядчиками фактических показателей по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Оставьте комментарий нажав на кнопку &quot;Оставить комментарий&quot;(Обязательно: в секции, которую необходимо исправить)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Утвердить&quot; на детальной странице выбранных фактических показателей</span></span></span></li>
</ol>
`
  }
];
