export const enMembers = {
  members: "Members",
  inviteMember: "Invite member",
  invitedMembers: "Invited members",
  inactiveMembers: "Inactive members",
  firstName: "First name",
  lastName: "Last name",
  jobTitle: "Job title",
  kcProperties: "KC Properties",
  accessLevel: "Access level",
  editMember: "Edit member",
  contractorsView: "Contractors - view",
  contractsView: "Contracts - view",
  notifications: "Notifications",
  categoryAndArea: "KC Category and Area",
  back: "Back",
  invite: "Invite",
  inviteSuccess:
    "was successfully registered. Invitation link was sent to entered email",
  userExport: "Export users",
  activeMembers: "Active members",
  membersSum: "Total members",
  subcontractorsSum: "Total subcontractors",
  phoneNumber: "Phone number",
  position: "Position",
  email: "Email",
  activate: "Activate",
  memberDeleted: "User successfully deleted",
  memberActivated: "Invitation is successfully resent",
  memberInviteDeleted: "Invite was successfully deleted",
  confirmDeleteInvite: "Are you sure you want to delete invite for ",
  confirmContractorMemberDelete:
    "Are you sure you want to delete Contractor member: {{contractorName}}'s user {{memberName}}",
  memberInviteResent: "Invitation is successfully resend",
  invitationDate: "Invitation date",
  previousInviteNotExpired: "Previous invite not expired",
  resendPossible: "Resend is possible",
  memberInformation: "Member information",
  membersData: "Member's data",
  informationAboutCompany: "Information about company",
  successUpdate: "Member is successfully saved ",
  confirmDeleteMember: "Are you sure you want to delete member:"
};
