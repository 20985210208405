/* eslint-disable react-hooks/exhaustive-deps */
import type { FC } from "react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { css } from "@linaria/core";
import moment from "moment";
import tw from "twin.macro";

import { EllipsisOutlined } from "@ant-design/icons";
import type {
  ForecastJobsData,
  ForecastJobsDataItem,
  MouCategorySubCategoryItem
} from "@components/organisms/ForecastJobsForm/props";
import { NationalizationModal } from "@components/organisms/JobsTraining/libs/NationalizationModal";
import type { NationalizeListItem } from "@components/organisms/JobsTraining/types";
import { useDefaultQuery } from "@hooks";
import i18n from "@i18n/i18n";
import { Button, Col, Divider, Input, Row, Tooltip } from "antd";

import { CompanyType } from "@components/types/models/Forecast";

import { countryCityApi } from "@api/countryCityApi";
import { companyApi } from "@api/companyApi";

import { truncateText } from "@utils/truncate";
import { UserContext } from "@contexts/userContext";

import type {
  AnnualTotal,
  ForecastJobsPerMonthProps,
  NationalizeItem
} from "./props";

const defaultValue = {
  engineering: {},
  supervisorAndForeman: {},
  administration: {},
  craft: {},
  heavyEquipmentOperator: {},
  total: {}
};

const JOBS_POSITION_ROW_HEADERS_INDEX = 4;
const GRADUATE_SECTION_ROW_HEADERS_INDEX = -2;

export const ForecastJobsPerMonth: FC<ForecastJobsPerMonthProps> = ({
  currentPeriod,
  mouCategoryRowHeaders,
  initialData,
  currentMouCategory,
  mouCategories,
  onSave,
  addNationalizeDataMutation,
  isEditable,
  refetchJobs
}) => {
  const { t } = useTranslation();
  const [periodJobsData, setPeriodJobsData] =
    useState<ForecastJobsData>(defaultValue);

  const [isNationalizedModalOpen, setNationalizedModalOpen] =
    useState<boolean>(false);
  const [nationalizeItem, setNationalizeItem] = useState<NationalizeItem>({});
  const [annualData, setAnnualData] = useState<AnnualTotal>({});

  const { user } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId;

  const jobsPositionRowHeaders = mouCategoryRowHeaders.slice(
    0,
    JOBS_POSITION_ROW_HEADERS_INDEX
  );
  const graduateSectionRowHeaders = mouCategoryRowHeaders.slice(
    GRADUATE_SECTION_ROW_HEADERS_INDEX
  );

  const isAnnualMouCategory = currentMouCategory === "total";

  const { data: countries } = useDefaultQuery("getAllCountries", async () =>
    countryCityApi.getAllCountries().then((res) => res.data)
  );

  const { data: companiesList, refetch: refetchCompaniesList } = useQuery<
    readonly CompanyType[]
  >(
    "getContractorRelatedCompaniesList",
    async () =>
      companyApi
        .getContractorRelatedCompaniesList(contractorId)
        .then((res) => res.data),
    {
      enabled: false
    }
  );

  const setJobsData = (): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputJobsData: any = {};

    mouCategories?.map((mouCategory) => {
      currentPeriod?.months.map((month: number) => {
        const currentPeriodData = initialData?.find(
          (item: ForecastJobsDataItem) =>
            item.month === Number(month) &&
            currentPeriod.year === item.year &&
            item.mouCategory === mouCategory.value
        );

        outputJobsData[mouCategory.title] = {
          ...outputJobsData[mouCategory.title],
          [month]: {
            year: currentPeriod.year,
            month,
            isApproved: currentPeriodData?.isApproved ?? false,
            kzNationals:
              typeof currentPeriodData?.kzNationals === "number"
                ? currentPeriodData.kzNationals
                : "",
            nonKzNationals:
              typeof currentPeriodData?.nonKzNationals === "number"
                ? currentPeriodData.nonKzNationals
                : "",
            plannedToNationalize:
              typeof currentPeriodData?.plannedToNationalize === "number"
                ? currentPeriodData.plannedToNationalize
                : "",
            newGradInternships:
              typeof currentPeriodData?.newGradInternships === "number"
                ? currentPeriodData.newGradInternships
                : "",
            newGradJobs:
              typeof currentPeriodData?.newGradJobs === "number"
                ? currentPeriodData.newGradJobs
                : "",
            nationalizationPositions:
              currentPeriodData?.nationalizationPositions
                ? currentPeriodData.nationalizationPositions
                : [],
            mouCategory: mouCategory.value
          }
        };
        return month;
      });
      return mouCategory;
    });

    setPeriodJobsData(outputJobsData);
    refetchJobs();
  };

  const setJobsValue = ({
    value,
    headerTitle,
    month
  }: {
    readonly value: string;
    readonly headerTitle: string;
    readonly month: number;
  }): void => {
    const updatedList = { ...periodJobsData };

    updatedList[currentMouCategory] = {
      ...updatedList[currentMouCategory],
      [month]: {
        ...updatedList[currentMouCategory][month],
        [headerTitle]: value
      }
    };

    setPeriodJobsData(updatedList);
  };

  const getTotalPositionsTotal = (month: number): number => {
    const currentMonth = isAnnualMouCategory
      ? annualData[month]
      : periodJobsData[currentMouCategory]?.[month];

    return (
      Number(currentMonth?.kzNationals) +
        Number(currentMonth?.nonKzNationals) || 0
    );
  };

  const setAnnualTotal = (): void => {
    const filteredRows: readonly MouCategorySubCategoryItem[] =
      mouCategoryRowHeaders.filter((item) => item.title !== "total");

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const annualTotal: any = {};

    mouCategories?.map((mouCategory) => {
      currentPeriod?.months.map((month) => {
        if (!annualTotal[month]) {
          annualTotal[month] = {};
        }

        filteredRows.map((itemRow) => {
          annualTotal[month] = {
            ...annualTotal[month],
            [itemRow.title]:
              Number(annualTotal?.[month]?.[itemRow.title] || 0) +
              Number(
                periodJobsData?.[mouCategory.title]?.[month]?.[itemRow.title] ||
                  0
              )
          };
          return itemRow;
        });

        return month;
      });

      return mouCategory;
    });

    setAnnualData(annualTotal);
  };

  const handleOnSave = (jobsData: ForecastJobsData) => {
    onSave(jobsData);
  };

  const handleOpenNationalizedModal =
    (month: number, nationalizedList: readonly NationalizeListItem[] = []) =>
    () => {
      const mouCategoryItem = mouCategories?.find(
        (category) => category.title === currentMouCategory
      );

      setNationalizeItem({
        month,
        year: currentPeriod?.year,
        mouCategory: mouCategoryItem?.value,
        title: mouCategoryItem?.text,
        items: nationalizedList
      });
      setNationalizedModalOpen(true);
    };

  const handleCloseNationalizedModal = useCallback(() => {
    setNationalizeItem({});
    setNationalizedModalOpen(false);
  }, [isNationalizedModalOpen]);

  const handleSetJobsValue = useCallback(
    (payload: { readonly headerTitle: string; readonly month: number }) =>
      (event: React.FormEvent<HTMLInputElement>) => {
        const outputValue = {
          ...payload,
          value: event.currentTarget.value
        };

        setJobsValue(outputValue);
      },
    [setJobsValue]
  );

  const saveText = useMemo(() => {
    const hasNextYear = currentPeriod?.years.includes(
      Number(currentPeriod?.year) + 1
    );

    if (isAnnualMouCategory) {
      if (hasNextYear) {
        return t("forecasts.proceedAndGoToTheNextYear");
      }
      return t("forecasts.proceedAndGoToTheNextSection");
    }

    if (!isEditable) {
      return t("forecasts.proceedAndGoToTheNextCategory");
    }

    return t("forecasts.saveAndGoToTheNextCategory");
  }, [currentMouCategory, i18n.language]);

  const setNationalizationPositions = (
    mouCategory: number,
    positions: readonly NationalizeListItem[],
    month: number,
    year: number
  ): void => {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const outputJobsData: any = JSON.parse(JSON.stringify(periodJobsData));

    const changeMouCategory = mouCategories?.find(
      (item) => item.value === mouCategory
    );

    if (changeMouCategory) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const outputJobsDataValues: any = Object.values(
        outputJobsData[changeMouCategory.title]
      );

      const changeIndex = outputJobsDataValues.findIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: ForecastJobsDataItem | any) =>
          item.month === month &&
          item.year === year &&
          item.mouCategory === mouCategory
      );

      outputJobsData[changeMouCategory.title][
        outputJobsDataValues[changeIndex]?.month
      ] = {
        ...outputJobsData[changeMouCategory.title][
          outputJobsDataValues[changeIndex]?.month
        ],
        nationalizationPositions: positions,
        plannedToNationalize: positions.length
      };

      setPeriodJobsData(outputJobsData);
    }
  };

  useEffect(() => {
    setPeriodJobsData(defaultValue);
    setJobsData();
    setNationalizeItem({
      year: currentPeriod?.year
    });
  }, [initialData, currentPeriod]);

  useEffect(() => {
    setJobsData();
  }, [currentMouCategory]);

  useEffect(() => {
    if (isAnnualMouCategory) {
      setAnnualTotal();
    }
  }, [periodJobsData]);

  useEffect(() => {
    if (isEditable) {
      void refetchCompaniesList();
    }
  }, [isEditable]);

  useEffect(() => {
    if (
      addNationalizeDataMutation?.isSuccess &&
      addNationalizeDataMutation?.variables
    ) {
      const { positions, month, year, mouCategory } =
        addNationalizeDataMutation.variables;

      setNationalizationPositions(mouCategory, positions, month, year);
    }
  }, [addNationalizeDataMutation?.isSuccess]);

  return (
    <Row
      className={css`
        ${tw`space-y-4`}
      `}
    >
      <Col span={24}>
        <Row>
          <Col
            span={4}
            className={css`
              ${tw`font-bold`}
            `}
          >
            {t("forecasts.jobsPositions")}
          </Col>
          <Col span={20}>
            <Row
              className={css`
                ${tw`space-x-6`}
              `}
            >
              {currentPeriod?.months.map((monthIndex: number) => (
                <div
                  key={monthIndex}
                  className={css`
                    ${tw`flex-1 flex justify-center font-bold capitalize`}
                    min-width: 50px;
                    max-width: 50px;
                    color: #9e9e9e;
                  `}
                >
                  {moment()
                    .month(Number(monthIndex) - 1)
                    .locale(i18n.language)
                    .format("MMM")}
                </div>
              ))}
            </Row>
          </Col>
        </Row>

        <Row
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {jobsPositionRowHeaders.map(
            (header: MouCategorySubCategoryItem, index: number) => (
              <React.Fragment key={index}>
                <Col
                  span={4}
                  className={css`
                    ${tw`flex items-center`}
                  `}
                >
                  <Tooltip title={header.text}>
                    {truncateText(header.text ?? "")}
                  </Tooltip>
                </Col>
                <Col span={20}>
                  <Row
                    className={css`
                      ${tw`space-x-6`}
                    `}
                  >
                    {currentPeriod?.months.map((month: number) => (
                      <div
                        key={month}
                        className={css`
                          ${tw`flex-1 flex justify-center items-center`}
                          min-width: 50px;
                          max-width: 50px;
                        `}
                      >
                        {header.title === "plannedToNationalize" ? (
                          <Row align="middle">
                            <span>
                              {isAnnualMouCategory
                                ? annualData?.[month]?.[header.title]
                                : periodJobsData[currentMouCategory]?.[month]?.[
                                    header.title
                                  ] || 0}
                            </span>
                            {!isAnnualMouCategory &&
                              !periodJobsData[currentMouCategory]?.[month]
                                ?.isApproved && (
                                <div
                                  className={css`
                                    ${tw`w-6 h-6 text-center bg-blue-500 ml-1 rounded-full cursor-pointer`}
                                  `}
                                  onClick={handleOpenNationalizedModal(
                                    month,
                                    periodJobsData[currentMouCategory]?.[month]
                                      ?.nationalizationPositions
                                  )}
                                >
                                  <EllipsisOutlined
                                    className={css`
                                      ${tw`text-white text-lg`}
                                    `}
                                  />
                                </div>
                              )}
                          </Row>
                        ) : (
                          <Row>
                            {header.title === "total" ? (
                              <span>{getTotalPositionsTotal(month)}</span>
                            ) : (
                              <Input
                                type="number"
                                className={css`
                                  ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                                  width: 100%;
                                  border: 1px solid #e2e2e2;
                                `}
                                disabled={
                                  isAnnualMouCategory ||
                                  !isEditable ||
                                  periodJobsData[currentMouCategory]?.[month]
                                    ?.isApproved
                                }
                                value={
                                  isAnnualMouCategory
                                    ? annualData?.[month]?.[header.title]
                                    : periodJobsData[currentMouCategory]?.[
                                        month
                                      ]?.[header.title]
                                }
                                onChange={handleSetJobsValue({
                                  headerTitle: header.title ?? "",
                                  month
                                })}
                              />
                            )}
                          </Row>
                        )}
                      </div>
                    ))}
                  </Row>
                </Col>
                <Divider />
              </React.Fragment>
            )
          )}
        </Row>
      </Col>

      {/* Graduate section */}

      <Col span={24}>
        <Row>
          <Col
            span={4}
            className={css`
              ${tw`font-bold`}
            `}
          >
            {t("forecasts.graduateSection")}
          </Col>
          <Col span={20}>
            <Row
              className={css`
                ${tw`space-x-6`}
              `}
            >
              {currentPeriod?.months.map((monthIndex: number) => (
                <div
                  key={monthIndex}
                  className={css`
                    ${tw`flex-1 flex justify-center font-bold capitalize`}
                    min-width: 50px;
                    max-width: 50px;
                    color: #9e9e9e;
                  `}
                >
                  {moment()
                    .month(Number(monthIndex) - 1)
                    .locale(i18n.language)
                    .format("MMM")}
                </div>
              ))}
            </Row>
          </Col>
        </Row>

        <Row
          className={css`
            ${tw`space-y-2`}
          `}
        >
          {graduateSectionRowHeaders.map(
            (header: MouCategorySubCategoryItem, index: number) => (
              <React.Fragment key={index}>
                <Col
                  span={4}
                  className={css`
                    ${tw`flex items-center`}
                  `}
                >
                  <Tooltip title={header.text}>
                    {truncateText(header.text ?? "")}
                  </Tooltip>
                </Col>
                <Col span={20}>
                  <Row
                    className={css`
                      ${tw`space-x-6`}
                    `}
                  >
                    {currentPeriod?.months.map((month: number) => (
                      <div
                        key={month}
                        className={css`
                          ${tw`flex-1 flex justify-center items-center`}
                          min-width: 50px;
                          max-width: 50px;
                        `}
                      >
                        {header.title === "total" ? (
                          <span>{getTotalPositionsTotal(month)}</span>
                        ) : (
                          <Input
                            type="number"
                            className={css`
                              ${tw`rounded bg-white text-center focus:border-blue-400 outline-none`};
                              width: 100%;
                              border: 1px solid #e2e2e2;
                            `}
                            disabled={
                              isAnnualMouCategory ||
                              !isEditable ||
                              periodJobsData[currentMouCategory]?.[month]
                                ?.isApproved
                            }
                            value={
                              isAnnualMouCategory
                                ? annualData?.[month]?.[header.title]
                                : periodJobsData[currentMouCategory]?.[month]?.[
                                    header.title
                                  ]
                            }
                            onChange={handleSetJobsValue({
                              headerTitle: header.title ?? "",
                              month
                            })}
                          />
                        )}
                      </div>
                    ))}
                  </Row>
                </Col>
                <Divider />
              </React.Fragment>
            )
          )}
        </Row>
      </Col>
      <Col
        span={24}
        className={css`
          ${tw`mt-4 flex justify-end`}
        `}
      >
        <Button
          className="secondary-button"
          onClick={() => handleOnSave(periodJobsData)}
        >
          {saveText}
        </Button>
      </Col>

      <NationalizationModal
        isModalOpen={isNationalizedModalOpen}
        nationalizeItem={nationalizeItem}
        countries={countries}
        companiesList={companiesList}
        addNationalizeDataMutation={addNationalizeDataMutation}
        isEditable={isEditable}
        onClose={handleCloseNationalizedModal}
      />
    </Row>
  );
};
