import {
  CheckOutlined,
  CopyOutlined,
  DollarOutlined,
  FileTextOutlined,
  QuestionOutlined
} from "@ant-design/icons";

import { g1c } from "@utils/userguides/contractor/g1c";

import { contracts } from "./contracts";
import { forecasts } from "./forecasts";
import { actuals } from "./actuals";
import { gettingStarted } from "./gettingStarted";

export const contractorUserguides = [
  {
    title: "general",
    data: gettingStarted,
    icon: <QuestionOutlined />
  },
  {
    title: "contracts",
    data: contracts,
    icon: <CopyOutlined />
  },
  {
    title: "forecasts",
    data: forecasts,
    icon: <FileTextOutlined />
  },
  {
    title: "actuals",
    data: actuals,
    icon: <CheckOutlined />
  },
  {
    title: "g1c",
    data: g1c,
    icon: <DollarOutlined />
  }
];
