/* eslint-disable @typescript-eslint/naming-convention */
import type {
  CONTRACT_TYPE,
  SCOPE_OF_WORK
} from "@components/types/models/Contract";
import type { Contractor } from "@components/types/models/Contractor";
import type { User } from "@components/types/models/User";

import { SectionType } from "./Forecast";
import type { STATUS } from "./Statuses";

export enum PERIOD_STATUS {
  UNDEFINED = 0,
  PENDING = 1,
  REJECTED = 2,
  APPROVED = 3,
  DRAFT = 4
}

export enum ActualSorting {
  PeriodAscending = 1,
  PeriodDescending = 2,
  ContractorAscending = 3,
  ContractorDescending = 4
}

export type Actual = {
  readonly id: number;
  readonly actualId?: number;
  readonly contractNumber?: string;
  readonly contractId: number;
  readonly contractor?: Contractor;
  readonly month: number;
  readonly year: number;
  readonly status: STATUS;
  readonly contractType: CONTRACT_TYPE;
  readonly version: number;
  readonly scopeOfWorks: readonly SCOPE_OF_WORK[];
  readonly submissionDate?: string;
  readonly kcCategory?: {
    readonly id: number;
    readonly name?: string;
  };
  readonly sectionTransferTechnology: {
    readonly text: string;
  };
  readonly sectionSuccessStory: {
    readonly text: string;
  };
  readonly sectionLegacy: {
    readonly text: string;
  };
  readonly createdAt: string;
  readonly submittedAt?: string;
  readonly reviewedAt?: string;
  readonly reviewedBy?: User;
  readonly reviewedById?: number;
  readonly submittedBy?: User;
  readonly submittedById?: number;
  readonly reviewLeftDays: number;
  readonly contractStartDate?: string;
  readonly contractEndDate?: string;
  readonly contractAmount?: number;
  readonly kcTarget?: number;
  readonly comments?: string;
  readonly dueDate?: string;
  readonly authorComment?: string;
  readonly currentForecastId?: number;
  readonly reviewer?: {
    readonly id: number;
    readonly firstName: string;
    readonly lastName: string;
  };
};

export type ActualsListResponse = {
  readonly currentPage: number;
  readonly pageSize: number;
  readonly totalItems: number;
  readonly results: readonly Actual[];
};

export enum ActualSortType {
  Ascending = 1,
  Descending = 2
}

export function defineActualStatus(status: number | undefined): {
  readonly enName: string;
  readonly ruName: string;
  readonly color: string;
} {
  switch (status) {
    case PERIOD_STATUS.PENDING:
      return {
        enName: "PENDING",
        ruName: "НА РАССМОТРЕНИИ",
        color: "orange"
      };
    case PERIOD_STATUS.REJECTED:
      return {
        enName: "REJECTED",
        ruName: "ОТКЛОНЕН",
        color: "red"
      };
    case PERIOD_STATUS.APPROVED:
      return {
        enName: "APPROVED",
        ruName: "УТВЕРЖДЕН",
        color: "green"
      };
    case PERIOD_STATUS.DRAFT:
      return {
        enName: "DRAFT",
        ruName: "ЧЕРНОВИК",
        color: "gray"
      };
    default:
      return {
        enName: "NOT SUBMITTED",
        ruName: "НЕ ПРЕДОСТАВЛЕН",
        color: "gray"
      };
  }
}

export const masterAgreementSections = [
  SectionType.JobAndNationalization,
  SectionType.Training,
  SectionType.TechnologyTransfer,
  SectionType.Legacy,
  SectionType.SuccessStories
];

export const subAgreementSections = [
  SectionType.Good,
  SectionType.Work,
  SectionType.Service
];

export const standaloneSections = [
  SectionType.Good,
  SectionType.Work,
  SectionType.Service,
  SectionType.JobAndNationalization,
  SectionType.Training,
  SectionType.TechnologyTransfer,
  SectionType.Legacy,
  SectionType.SuccessStories
];

export function definePeriodStatus(status: number | undefined): {
  readonly enName: string;
  readonly ruName: string;
  readonly color: string;
} {
  switch (status) {
    case PERIOD_STATUS.PENDING:
      return {
        enName: "PENDING",
        ruName: "НА РАССМОТРЕНИИ",
        color: "orange"
      };
    case PERIOD_STATUS.REJECTED:
      return {
        enName: "REJECTED",
        ruName: "ОТКЛОНЕН",
        color: "red"
      };
    case PERIOD_STATUS.APPROVED:
      return {
        enName: "APPROVED",
        ruName: "УТВЕРЖДЕН",
        color: "green"
      };
    case PERIOD_STATUS.DRAFT:
      return {
        enName: "DRAFT",
        ruName: "ЧЕРНОВИК",
        color: "gray"
      };
    default:
      return {
        enName: "NOT SUBMITTED",
        ruName: "НЕ ПРЕДОСТАВЛЕН",
        color: "gray"
      };
  }
}

export const actualSortTypes = [
  {
    enName: "Period (ascending)",
    ruName: "Период (по возрастанию)",
    value: ActualSortType.Ascending
  },
  {
    enName: "Period (descending)",
    ruName: "Период (по убыванию)",
    value: ActualSortType.Descending
  }
];

export type ActualPeriod = {
  readonly approvalStatus?: number;
  readonly month: number;
  readonly year: number;
  readonly period?: string;
  readonly actuals?: readonly Actual[];
};

export type ActualWorksListItem = {
  readonly companyId: number;
  readonly companyTitle: string;
  readonly relationType: number;
  readonly actualValue: number;
  readonly kcShare: number;
  readonly kcActualValue: number;
  readonly kcWorkDto: {
    readonly id: number;
    readonly title: string;
  };
};

export type ActualGoodsListItem = {
  readonly companyId: number;
  readonly companyTitle: string;
  readonly relationType: number;
  readonly actualValue: number;
  readonly kcShare: number;
  readonly kcActualValue: number;
  readonly goodsDto: {
    readonly id: number;
    readonly title: string;
  };
  readonly ctKzCertificateDto: {
    readonly certificateNumber: string;
    readonly fileName: string;
    readonly id: number;
    readonly expirationDate: string;
    readonly ctKzCertificateKcGoodDtos?: readonly [
      {
        readonly kcGood?: {
          readonly id: number;
          readonly title: string;
        };
        readonly kcPercent: number;
      }
    ];
  };
  readonly ctKzCertificateKcGoodDto: {
    readonly kcGood: {
      readonly id: number;
      readonly title: string;
    };
    readonly kcPercent: number;
  };
  readonly unitsOfMeasureDto: {
    readonly unitId: number;
    readonly title: string;
    readonly title_En: string;
    readonly title_Ru: string;
    readonly symbol: string;
    readonly symbol_Ru: string;
    readonly symbol_En: string;
  };
  readonly goodsQuantity: number;
};

export type ActualServiceListItem = {
  readonly companyId: number;
  readonly companyTitle: string;
  readonly relationType: number;
  readonly actualValue: number;
  readonly kcShare: number;
  readonly kcActualValue: number;
  readonly kcServiceDto: {
    readonly id: number;
    readonly title: string;
  };
};

export type ActualMasterGwsSummaryType = {
  readonly actualsGwsSummaryDtos: readonly [
    {
      readonly actualId: number;
      readonly contractId: number;
      readonly subAgreementNumber: string;
      readonly scopeOfWorks: readonly number[];
      readonly status: number;
      readonly actualValueOriginalCurrency: number;
      readonly actualKcValueOriginalCurrency: number;
      readonly actualValueInUsd: number;
      readonly actualKcValueInUsd: number;
      readonly actualKcShare: number;
    }
  ];
  readonly totalActualKcValueInUsd: number;
  readonly totalActualValueInUsd: number;
  readonly totalActualKcShare: number;
};

export type ActualsCommentItemType = {
  readonly commentId: number;
  readonly comment: string;
  readonly leavedByUserId: number;
  readonly leavedByUserFullName: string;
  readonly createdAt: string;
};

export type PrePopulatedGws = ReadonlyArray<{
  readonly kcItemModel: {
    readonly id: number;
  };
  readonly relationType: number;
  readonly companyDto: {
    readonly id: number;
  };
}>;
