export const enLogin = {
  title: "Login",
  subtitle: "Login to continue",
  email: "Email",
  password: "Password",
  loginButton: "Login",
  errorEmail: "Wrong email",
  requiredEmail: "The field is required",
  requiredPassword: "The field is required",
  contactUs: "Contact Us",
  resetPasswordTitle: "Reset password",
  resetPasswordSubtitle: "Enter your email",
  resetPasswordSuccess: "Reset link sent to",
  nextButton: "Next",
  forgotPassword: "Forgot Password?",
  logoutButton: "Logout",
  newPassword: "New password",
  confirmPassword: "Confirm password",
  confirmInvite: "Confirm invite",
  enterNewPassword: "Enter new password",
  loginViaSso: "Login via SSO",
  userGuide: "User guide",
  linkIsExpired: "Link is expired",
  yourPasswordHasExpired: "Your password has expired and must be changed",
  resendLink: "Resend verification link",
  passwordIsSaved: "The password is saved, please login to the Portal",
  passwordIsResetSuccessfully: "Password is reset successfully"
};
