export const contracts = [
  {
    title: "Как обновить контракт?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Руководитель КС создал контракт, он появился в вашем списке контрактов со статусом &quot;Черновик&quot;</span></span></span></p><p>&nbsp;</p><ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Контракты&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт со статусом &quot;Черновик&quot; из списка Контракты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить контракт&quot; на детальной странице выбранного контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните необходимые поля и закрепите КС план (необязательно)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/mmkdbQNcSnPcGGjunJ116oXfJ7T4PC_vM_xNht38U3OqWEYwUCayCj-fFi5N5fXNN72NhH_FrHwOyeA_9s0p23RLPmCS-SP88UGKS5xeh2hSBnTRPzfij4YwcyLlRqpQ8uqEmIdQACwf0xpKGAHVF1VuZGBMOz4VTWzp1LhCSteyrztBHRis_WQbrA" style="height:594px; width:614px" /></span></span></span></p>
`
  },
  {
    title: "Как прикрепить КС план к контракту?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Формат файла должен быть .pdf .doc and .docx И размер файла не должен превышать 8 МБ</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Контракты&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт со статусом &quot;Черновик&quot; из списка Контракты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить контракт&quot; на детальной странице выбранного контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+ Прикрепить КС План&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите файл и нажмите на кнопку &quot;Открыть&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как добавить поправку?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Контракты&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт со статусом &quot;Утвержден&quot; из списка Контракты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить поправку&quot; на странице утвержденного контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Добавьте поправки в условия контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/TMnhkipqL_k0bRQAzA0MMJzVnbzhal1bNeTTrLUToBgnH84yj4PI0givd5lPLoHMlPe8doKHuEHwqYZhXQgtpCMTRPADertkIhf4xpmFZaYDPdQ-fpFRAHuozc4Xld2M84VGlaGfUr85uANVdaTx0Yw82OQyixbJ72Dued2BgEH2yMVXws9nSE14fA" style="height:353px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как обновить отклоненный контракт?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Контракты&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите контракт со статусом &quot;Отклонен&quot; из списка Контракты</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Обновить контракт и отправить снова&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/ZwTzRxwMC7cVBGJtPkW3j2knk7MGyo8-XRE8ykgSP12UTSTnUMX-Tr_khw0jj-nyVhq_AgepJOV71Lx5oh6bymMNVTZtIWbkuXl_HNOSKfICBh34hce5K0p9LUHhf4T-Epx8xSIBVlVpcqHixP0ussM9KFzYoM8wlZcBzPLr43sIdkdsIHQHye5jcg" style="height:397px; width:645px" /></span></span></span></p>
`
  }
];
