import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { Button, Modal, Row, Spin } from "antd";
import { PdfViewer } from "@components/atoms/PdfViewer/component";
import { DownloadFileButton } from "@components/atoms/DownloadFileButton";

import { invoiceApi } from "@api/invoiceApi";

import type { InvoicePreviewModalProps } from "./props";

export const InvoicePreviewModal: FC<InvoicePreviewModalProps> = ({
  invoiceName,
  handleSubmitClick,
  isModalVisible,
  isSubmitLoading,
  handleModalClick,
  invoiceId
}) => {
  const { t } = useTranslation();
  const modalTitle = `${invoiceName}_G1-C.pdf`;

  const invoiceData = useQuery(
    ["previewG1c", invoiceId],
    async () => invoiceApi.downloadG1cForm(invoiceId),
    {
      enabled: false,
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (isModalVisible) {
      void invoiceData.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  return (
    <Modal
      centered
      destroyOnClose
      visible={isModalVisible}
      width={800}
      title={
        <Row justify="space-between">
          {modalTitle}
          <DownloadFileButton
            disabled={false}
            url={`/api/invoices/${invoiceId}/g1c-form`}
            title={t("download")}
          />
        </Row>
      }
      closable={false}
      footer={null}
      onCancel={handleModalClick}
    >
      <Spin spinning={invoiceData.isLoading}>
        {invoiceData.data && (
          <PdfViewer data={invoiceData.data as { readonly data: Blob }} />
        )}

        <Row justify="space-between">
          <Button onClick={handleModalClick}>{t("back")}</Button>
          {handleSubmitClick && (
            <Button
              type="primary"
              loading={isSubmitLoading}
              onClick={handleSubmitClick}
            >
              {t("confirm")}
            </Button>
          )}
        </Row>
      </Spin>
    </Modal>
  );
};
