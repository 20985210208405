export const forecasts = [
  {
    title: "How to add Forecasts?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Your contract was approved by KC Lead</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find your contract and click on Add button (&quot;+&quot;) under Actions column</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Result:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Forecast status is DRAFT and is ready for the process of filling in needed information</span></span></span></p>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/wEsdBL-RfE_lb6s-qDkHugz6AxpQdyMoEq7NLLJZ-aumT32W4mm5T8ucFyD4StU1aHK_PWEoHrAzy0KHTiK-vG7lvq_GYEodWbjr4khIfr_vXA3rxRUzrLyOycBoCtxZ4stwQjZK2weosOl4d2OXhIoOe_g_7l5xZC3ZKCPeMd9lz_Xc4lVfKg0Q3Q" style="height:197px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to create Forecasts?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> There are three types of contracts &ndash; Standalone, Sub-agreement, Master agreement (each with different sections of information to fill in)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT forecast from the Forecasts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Open DRAFT forecast&rsquo;s detailed page by clicking on contract number or &quot;i&quot; symbol</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill in all required information separated by the sections (based on contract type)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/c7NANSmt6_jCn4EimHLpEzSfSLF9KlrPTnfF3bh4drBZl0Ya12nV0KqBgvKGv21auDGnRH0AlASsvwYgOt7J1T3fN_SmNkkhSVve8rtCeR1cMVLC72oCy4tRCdGZw75EKj9ynnAHilPGgqQ5RYMgdx8YQqIipFAglaMWBNEsVrdsPQ0RKEZaH94lNg" style="height:647px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Add Goods, Works, Services?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Forecasts from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services section on Forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+&quot; button next to Goods/Works/Services description field</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter your Good/Work/Service name in the Name field</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Add&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find your good/work/service in the Goods/Works/Services description drop-down list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+Add goods/works/services&quot; button if you need to add more</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/oPnht--9-8Mnqeh6v8iMrnMjuyj5qpUFBpOpjASZBzLdQAMsSv2e7Bnht0a4aQhCIdOdh9ieioyZUK6GuhqTrQHci1hV-_NnP8zSUYob8EoHWZpkNix26Z1Sc_ZJgye9EzGtPvoARyt2XFSZb7R2GyfYXNphJ1AbHsq8X5M0aC-HoTR61MXul0cKFA" style="height:625px; width:612px" /></span></span></span></p>
`
  },
  {
    title: "How to delete Goods/Works/Services?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Pre-condition:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Goods/Works/Services were created</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select and Open DRAFT/REJECTED Forecasts detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services sections on Forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Find created Good/Work/Service and scroll to the right</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;X&quot; button to delete not needed Goods/Works/Services entries</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/KTvdjjWf_8aIHfwc9yk_GdkAe95qs10VnR8fn0y_QwOTcQcMQG6U70vauH0Q_uzmhceRIXhSFo3VkI8NSZmRHb92Le4qenXY3_RgGP55roOLEbBw3Ft4eb1o_N9EUaKWq-PaMs5kHcpzRxm395-_wzZaSZILO6DqhtMltt7o5nmaLNA6JSvH6cvzNg" style="height:169px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Add Company?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> GWS data can be added to Standalone and Sub-agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Forecast from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Goods/Works/Services section on Forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;+&quot; button next to Company name description field:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter Company name</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select Country from drop-down list</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select City from drop-down list</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter BIN (if Kazakhstan is selected as a Country)</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter Details on scope of work</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">&nbsp;&nbsp;Click on &quot;Add&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">&nbsp;&nbsp;Find your Company name in the drop-down list</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/g1QAxlE62ueEpewHer_Sao9QH9eNKWgiw3y_rvmbEOT4E1p1jXzw5yPHzFsLs6aawNbfDiTPOC_N9yBetG_mEnwjCiLXQ1RTJTvuY7fZeCj3g-IraOFtNie2QC523ARP9R_lKI_csg6W1hDMdYfKTC94jItOtRLyBj7Y7rePZOibQKHyls-EHxSVqg" style="height:459px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to add Jobs and Nationalization data?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Jobs and Nationalization data can be added to Standalone and Master agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Forecast from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Jobs and Nationalization section on Forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save and go to the next category&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to add Training data?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Training data can be added to Standalone and Master agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Forecast from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Training section on Forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save and go to the next section&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to add Technology Transfer and Legacy?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Technology Transfer, Legacy can be added to Standalone and Master agreement contract types</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Forecasts from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expand Technology Transfer/Legacy sections on Forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter required information into the text box</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save and go to the next section&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to leave comments in Forecasts Sections?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> There are three types of contracts &ndash; Standalone, Sub-agreement, Master agreement (each with different sections of information to fill in)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Expands Goods, Works, Services, Jobs and Nationalization, Training, Technology Transfer, Legacy sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Leave comment&quot; button</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter your comment into the text box</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Save&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to submit Forecasts?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> There are three types of contracts &ndash; Standalone, Sub-agreement, Master agreement (each with different sections of information to fill in)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT, REJECTED Forecasts from the list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields separated by sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to update Approved Forecast?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select APPROVED forecast from the Forecasts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update&quot; button on APPROVED forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on Submit button</span></span></span></li>
</ol>
`
  },
  {
    title: "How to Restore Forecast’s previous version?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select APPROVED forecast the Forecasts list and click on &quot;Update&quot; button OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select DRAFT (version &gt; 1) from the Forecasts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Restore previous version&quot; button on DRAFT forecast&rsquo;s detailed page (version &gt;1)</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/fWK1FrRrBiLokxju01ef6ONHiIce2TmkC71FXcKUNgFLHP5yfYUIfsknr-a769SDFD1csFKPVyKMwy5acsHG_gEoWN1pjGIZYiyanG5_Bz0bsYQP5INylk9zYS1ye9J_Cp4TTOo0uaORRTcQkUGu_LAj5xgNRUTxG_SSL1-lcRyGRk_CfiJSuZbaYg" style="height:485px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to update Rejected Forecast?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Forecasts</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select REJECTED forecast from the Forecasts list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Open REJECTED forecast&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set due date for KC Lead</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Submit&quot; button</span></span></span></li>
</ol>
`
  }
];

export default forecasts;
