type Params = {
  readonly [key in string]:
    | number
    | string
    | readonly number[]
    | readonly string[]
    | null
    | undefined;
};

export const stripUnusedObjectFields = (obj: Params): Params =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));

export const capitalizeText = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const unCapitalizeText = (text: string): string =>
  text.charAt(0).toLowerCase() + text.slice(1);

const isStringArray = (arr: unknown): arr is string[] => {
  if (!Array.isArray(arr)) return false;

  return arr.every((item: unknown) => typeof item === "string");
};

export const stringArrToNumberArr = (arr?: unknown): number[] =>
  isStringArray(arr)
    ? arr?.map((val: string) => Number(val))
    : (arr as number[]);
