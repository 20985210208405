import type { FC } from "react";

import { AppLinkBadge } from "@components/atoms/AppBadge";
import { LinkWithQuery } from "@components/atoms/LinkWithQuery";

import type { ContractorLinkProps } from "./props";

export const ContractorLink: FC<ContractorLinkProps> = ({
  id,
  name,
  newContractsCount
}) => (
  <AppLinkBadge count={newContractsCount}>
    <LinkWithQuery to={`/admin/contractor/${id}`}>{name}</LinkWithQuery>
  </AppLinkBadge>
);
