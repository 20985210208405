import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { QueryFunctionContext } from "react-query";
import { useQuery } from "react-query";

import { ActualsListTabs } from "@components/pages/admin/Actuals/libs/components/ActualsListTabs";
import { SearchSection } from "@components/pages/admin/Actuals/libs/components/SearchSection";
import type { SearchQueryType } from "@components/pages/admin/Actuals/libs/components/SearchSection/libs/types/SearchQueryType";
import { Typography } from "antd";

import { ActualSorting } from "@components/types/models/Actuals";
import { ForecastApprovalStatus } from "@components/types/models/Forecast";

import { adminApi } from "@api/adminApi";

import { usePagination } from "@hooks/usePagination";

export const Actuals: FC = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const { page, pagination, setPagination, setPage } = usePagination<
    SearchQueryType,
    ActualSorting
  >();

  const pageSize = 15;
  const { data, isLoading, refetch } = useQuery(
    [
      "getActualsPaginated",
      {
        searchQuery: pagination.filterParams,
        sort: pagination.sortParams,
        tabNum: pagination.tabParams ? pagination.tabParams : "",
        page
      }
    ],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [
          string,
          {
            readonly searchQuery: SearchQueryType | undefined;
            readonly sort?: ActualSorting;
            tabNum: string;
            page: number;
          }
        ]
      >
    ) => {
      const outputStatusValue =
        pagination.tabParams === `${ForecastApprovalStatus.Overdue}`
          ? `${ForecastApprovalStatus.Undefined}`
          : pagination.tabParams;

      if (searchQueryCallback.queryKey[1].searchQuery) {
        return adminApi
          .getActualsPaginated(
            page,
            pageSize,
            pagination.sortParams,
            pagination.tabParams === `${ForecastApprovalStatus.Undefined}`
              ? global.undefined
              : outputStatusValue,
            searchQueryCallback.queryKey[1].searchQuery.contractNumber,
            searchQueryCallback.queryKey[1].searchQuery.contractors,
            searchQueryCallback.queryKey[1].searchQuery.categories,
            searchQueryCallback.queryKey[1].searchQuery.contractTypes,
            searchQueryCallback.queryKey[1].searchQuery.year,
            searchQueryCallback.queryKey[1].searchQuery.month
          )
          .then((res) => res.data);
      }

      return adminApi
        .getActualsPaginated(
          page,
          pageSize,
          pagination.sortParams,
          pagination.tabParams === `${ForecastApprovalStatus.Undefined}`
            ? global.undefined
            : outputStatusValue
        )
        .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  useEffect(() => {
    void refetch();
  }, [
    page,
    pagination.tabParams,
    pagination.sortParams,
    pagination.filterParams,
    refetch
  ]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      sortParams: prevState.sortParams || ActualSorting.PeriodAscending,
      tabParams: prevState.tabParams || "0"
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (searchParams?: string) => {
    setPagination((prevState) => ({
      ...prevState,
      tabParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSearchQuery = (searchParams?: SearchQueryType) => {
    setPagination((prevState) => ({
      ...prevState,
      filterParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSort = (searchParams?: ActualSorting) => {
    setPagination((prevState) => ({
      ...prevState,
      sortParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <Title>{t("actuals.actuals")}</Title>
      {/* TODO: year and month are not null when the page is loaded */}
      <SearchSection
        filters={pagination.filterParams}
        onSearchQuery={handleSearchQuery}
        isLoading={isLoading}
      />
      <ActualsListTabs
        actualsList={data}
        isLoading={isLoading}
        sort={Number(pagination.sortParams)}
        onChange={handleTabChange}
        onSetPage={setPage}
        onSetSort={handleSort}
        activeKey={pagination.tabParams}
      />
    </>
  );
};
