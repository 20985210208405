import type { AxiosResponse } from "axios";
import axios from "axios";

import type { ActualJobsDataForm } from "@components/organisms/ActualJobsForm/props";

import type {
  ActualGoodsListItem,
  ActualMasterGwsSummaryType,
  ActualsCommentItemType,
  ActualWorksListItem,
  PrePopulatedGws
} from "@components/types/models/Actuals";
import type { SectionType } from "@components/types/models/Forecast";

const getContractPeriodList = async (
  contractId: number | string | null
): Promise<AxiosResponse> =>
  axios.get(`/api/actual/contract/${contractId}/periods`);

const getActualTraining = async (actualId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/${actualId}/training`);

const getActualJobs = async (actualId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/${actualId}/job`);

const saveActualJobsCities = async (
  actualId: number,
  cityIds: readonly number[]
): Promise<AxiosResponse> =>
  axios.put(`/api/actual/${actualId}/job/city`, { cityIds });

const saveJobsData = async (
  actualId: number,
  cityId: number | null,
  values: ActualJobsDataForm
): Promise<AxiosResponse> =>
  axios.put(`/api/actual/${actualId}/job/city/${cityId}`, values);

const getAttachments = async (actualId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/${actualId}/attachments`);

const uploadAttachment = async (
  actualId: number,
  payload: FormData
): Promise<AxiosResponse> =>
  axios.post(`/api/actual/${actualId}/attachments`, payload);

const getAttachment = async (attachmentId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/attachments/${attachmentId}`, {
    responseType: "blob"
  });

const deleteAttachment = async (attachmentId: number): Promise<AxiosResponse> =>
  axios.delete(`/api/actual/attachments/${attachmentId}`);

const getWorks = async (
  actualId: number
): Promise<AxiosResponse<readonly ActualWorksListItem[]>> =>
  axios.get(`/api/actual/gws/works/${actualId}`);

const getGoods = async (
  actualId: number
): Promise<AxiosResponse<readonly ActualGoodsListItem[]>> =>
  axios.get(`/api/actual/gws/goods/${actualId}`);

const getServices = async (
  actualId: number
): Promise<AxiosResponse<readonly ActualWorksListItem[]>> =>
  axios.get(`/api/actual/gws/services/${actualId}`);

const getMasterGws = async (
  maActualId: number
): Promise<AxiosResponse<ActualMasterGwsSummaryType>> =>
  axios.get(`/api/actual/master/${maActualId}`);

const getActualComments = async (
  actualId: number,
  sectionType: number
): Promise<AxiosResponse<readonly ActualsCommentItemType[]>> =>
  axios.get(`/api/comments/actual/${actualId}`, {
    params: {
      sectionType
    }
  });

const getGwsSummary = async (actualId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/gws/summary/${actualId}`);
const getSuccessStories = async (actualId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/${actualId}/success-stories`);

const getLegacy = async (actualId: number): Promise<AxiosResponse> =>
  axios.get(`/api/actual/${actualId}/legacy`);

const getTechnologyTransfer = async (
  actualId: number
): Promise<AxiosResponse> =>
  axios.get(`/api/actual/${actualId}/technology-transfer`);

const saveTextSection = async (
  actualId: number,
  sectionTitle: string,
  description: string
): Promise<AxiosResponse> =>
  axios.put(`/api/actual/${actualId}/${sectionTitle}`, { description });

const deleteTextSection = async (
  actualId: number,
  sectionTitle: string
): Promise<AxiosResponse> =>
  axios.delete(`/api/actual/${actualId}/${sectionTitle}`);

const submitActual = async (
  actualId?: number,
  dueDate?: string
): Promise<AxiosResponse> =>
  axios.put(`/api/actual/${actualId}/submit`, null, {
    params: {
      dueDate
    }
  });

const approveActual = async (actualId: number): Promise<AxiosResponse> =>
  axios.put(`/api/actual/${actualId}/approve`);

const rejectActual = async (
  actualId: number,
  dueDate: string
): Promise<AxiosResponse> =>
  axios.put(`/api/actual/${actualId}/reject`, null, {
    params: {
      dueDate
    }
  });

const updateApprovedActual = async (
  actualId?: number
): Promise<AxiosResponse> => axios.post(`/api/actual/${actualId}/update`);

const restorePreviousActual = async (
  actualId?: number
): Promise<AxiosResponse> => axios.post(`/api/actual/${actualId}/restore`);

const prePopulateGws = async (
  actualId?: number,
  sectionType?: SectionType
): Promise<AxiosResponse<PrePopulatedGws>> =>
  axios.get(`/api/actual/gws/${actualId}/pre-populate`, {
    params: {
      sectionType
    }
  });

export const actualApi = {
  getContractPeriodList,
  getActualTraining,
  saveActualJobsCities,
  saveJobsData,
  getAttachments,
  getAttachment,
  deleteAttachment,
  uploadAttachment,
  getActualJobs,
  getWorks,
  getServices,
  getMasterGws,
  getActualComments,
  getGwsSummary,
  getSuccessStories,
  getLegacy,
  getTechnologyTransfer,
  saveTextSection,
  deleteTextSection,
  submitActual,
  approveActual,
  rejectActual,
  getGoods,
  updateApprovedActual,
  restorePreviousActual,
  prePopulateGws
};
