import type { FC } from "react";
import { Link } from "react-router-dom";

import type { LinkToContractProps } from "@components/organisms/ContractsTable/libs/components/LinkToContract/props";

export const LinkToContract: FC<LinkToContractProps> = ({
  contractNumber,
  linkToContract,
  id
}) => <Link to={`${linkToContract}${id}`}>{contractNumber}</Link>;
