import { createContext } from "react";

import { USER_ROLES } from "@components/types/models/Roles";

import { UserRoles } from "@contexts/types/UserRoles";

import type { UserContextType } from "./types/UserContextType";

export function defineUserRole(role: number | undefined): UserRoles {
  switch (role) {
    case USER_ROLES.SUBCONTRACTOR_MANAGER:
    case USER_ROLES.SUBCONTRACTOR_OWNER:
      return UserRoles.Subcontractor;
    case USER_ROLES.CONTRACTOR_MANAGER:
    case USER_ROLES.CONTRACTOR_OWNER:
      return UserRoles.Contractor;
    case USER_ROLES.ADMINISTRATOR:
      return UserRoles.Admin;
    default:
      return UserRoles.Guest;
  }
}

export const UserContext = createContext<UserContextType>({
  user: null,
  userRole: UserRoles.Guest,
  userEntityId: 0,
  setUser: () => {
    // Do nothing
  },
  isUserLoggedIn: false,
  userCredentials: null,
  setUserCredentials: () => {
    // Do nothing
  },
  isAdmin: false,
  isContractor: false,
  isSubcontractor: false
});
