import type { FC } from "react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import { SearchOutlined } from "@ant-design/icons";
import type { Category } from "@components/organisms/NewContractForm/libs/types/Category";
import { CategorySelector } from "@components/pages/admin/Forecasts/libs/components/CategorySelector";
import { ContractorSelector } from "@components/pages/admin/Forecasts/libs/components/ContractorSelector";
import { ContractTypeSelector } from "@components/pages/admin/Forecasts/libs/components/ContractTypeSelector";
import { Button, Col, Form, Input, Row, Skeleton } from "antd";

import { contractorApi } from "@api/contractorApi";

import { ContractTypes } from "@utils/contractTypes";
import { CATEGORIES } from "@components/constants/AdminCategories";
import { stringArrToNumberArr } from "@utils/helper";

import type { SearchSectionProps } from "./props";

export const SearchSection: FC<SearchSectionProps> = ({
  filters,
  isLoading,
  onSearchQuery
}) => {
  const { t } = useTranslation();

  const { watch, setValue, getValues } = useForm();

  useEffect(() => {
    setValue("contractNumber", filters?.contractNumber);
    setValue("contractors", stringArrToNumberArr(filters?.contractors));
    setValue("categories", stringArrToNumberArr(filters?.categories));
    setValue("contractTypes", stringArrToNumberArr(filters?.contractTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleOnFinish = useCallback(() => {
    const vals = getValues();

    onSearchQuery({
      contractNumber: vals.contractNumber,
      contractors: vals.contractors,
      categories: vals.categories,
      contractTypes: vals.contractTypes
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchQuery]);

  const contractorsList = useQuery(
    "getContractors",
    async () => contractorApi.getContractors().then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );

  const categoriesList = useMemo<readonly Category[]>(
    () =>
      CATEGORIES.reduce<readonly Category[]>(
        (prev, cat) =>
          cat.children ? prev.concat(cat.children) : [...prev, cat],
        []
      ),
    []
  );

  return (
    <Form layout="vertical" onFinish={handleOnFinish}>
      {contractorsList.isLoading ? (
        <Skeleton active paragraph={{ rows: 1 }} />
      ) : (
        <Row gutter={16}>
          <Col span={3}>
            <Form.Item label={t("contractors.contractNumber")}>
              <Input
                value={watch("contractNumber")}
                onChange={(e) =>
                  setValue("contractNumber", e.currentTarget.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t("contractors.contractors")}>
              <ContractorSelector
                contractors={contractorsList.data}
                onChange={(value) => setValue("contractors", value)}
                value={watch("contractors")}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t("members.categoryAndArea")}>
              <CategorySelector
                categories={categoriesList}
                onChange={(value) => setValue("categories", value)}
                value={watch("categories")}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t("contracts.contractType")}>
              <ContractTypeSelector
                contractTypes={ContractTypes}
                onChange={(value) => setValue("contractTypes", value)}
                value={watch("contractTypes")}
              />
            </Form.Item>
          </Col>
          <Col
            span={3}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              loading={isLoading}
            >
              {t("forecasts.applyFilters")}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};
