export const dashboards = [
  {
    title: "About Dashboards",
    html: `<p><span style="font-weight: 400;">Dashboards allow KC Leads to read and monitor up to date KC data in the form of graphs and diagrams, providing clear visibility and detailed insights. Data from all sources of the KC portal are collected and displayed on the Dashboards page.</span></p>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">On the main Dashboards page, KC Leads can view the overall KC indicators in the form of various charts listed below and filter the data as needed:</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Annual and Monthly KC Performance</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Forecasted and Actual KC Performance</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Review of the Actual and KC Value by regions of Kazakhstan</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">KC Categories Outlook</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Actual KC Performance by Categories</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-weight: 400;">Additionally, KC Leads are able to view the detailed statistics of the following entities on separate pages and monitor all related data, filter them and display accordingly:</span></p>
<p>&nbsp;</p>
<ul>
<li style="font-weight: 400;"><span style="font-weight: 400;">Contracts and Contractors</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Subcontractors</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Goods, works and services</span></li>
<li style="font-weight: 400;"><span style="font-weight: 400;">Manpower</span></li>
</ul>
`
  }
];
