/* eslint-disable @typescript-eslint/naming-convention */

import type { Contractor } from "@components/types/models/Contractor";

import type { STATUS } from "./Statuses";

export enum CONTRACT_TYPE {
  UNDEFINED = 0,
  STANDALONE = 1,
  MASTER_AGREEMENT = 2,
  SUB_AGREEMENT = 3
}

export enum SCOPE_OF_WORK {
  GOODS = 1,
  WORKS = 3,
  SERVICES = 2
}

export type MasterAgreement = {
  readonly contractNumber: string;
  readonly contractEndDate: string;
  readonly contractStartDate: string;
};

export type Contract = {
  readonly id: number;
  readonly contractorId: number;
  readonly contractor: Contractor;
  readonly contractNumber: string;
  readonly capacity: number;
  readonly projectId: number;
  readonly kcCategory: {
    readonly name: string;
  };
  readonly startDate: string;
  readonly endDate: string;
  readonly currencyId: number;
  readonly contractType: CONTRACT_TYPE;
  readonly contractStatus: STATUS;
  readonly version?: number;
  readonly masterAgreement?: MasterAgreement;
  readonly forecastStatus: number;
  readonly forecastVersion: number;
  readonly forecastId: number;
  readonly scopes: readonly SCOPE_OF_WORK[];
  readonly contractEndDate: string;
  readonly contractStartDate: string;
  readonly masterAgreementId: number;
  readonly lastApprovedAmendment: {
    readonly kcTarget: number;
    readonly amount: number;
    readonly currencyId: number;
    readonly detailsOnScopeOfWork: string;
    readonly currency: {
      readonly id: number;
      readonly code: string;
      readonly enabled: false;
    };
  };
};

export type ContractWithContractor = Contract & {
  readonly contractor: Contractor;
  readonly scopes: readonly number[];
};

export type ContractPaginated = {
  readonly currentPage: number;
  readonly pageSize: number;
  readonly totalItems: number;
  readonly results: readonly Contract[];
};

export type FormValues = {
  readonly contractorId?: number;
  readonly contractNumber?: string;
  readonly kcCategory?: number;
  readonly contractType?: number;
  readonly masterAgreementId?: number;
};

export function defineContractType(type: number | undefined): {
  readonly enName: string;
  readonly ruName: string;
} {
  switch (type) {
    case CONTRACT_TYPE.STANDALONE:
      return {
        enName: "Standalone",
        ruName: "Отдельное соглашение"
      };
    case CONTRACT_TYPE.MASTER_AGREEMENT:
      return {
        enName: "Master agreement",
        ruName: "Генеральное соглашение"
      };
    case CONTRACT_TYPE.SUB_AGREEMENT:
      return {
        enName: "Sub-agreement",
        ruName: "Дополнительное соглашение"
      };
    default:
      return {
        enName: "Undefined",
        ruName: "Не найден"
      };
  }
}

export function defineScopeOfWorks(scope: number | undefined): {
  readonly enName: string;
  readonly ruName: string;
} {
  switch (scope) {
    case SCOPE_OF_WORK.GOODS:
      return {
        enName: "Goods",
        ruName: "Товары"
      };
    case SCOPE_OF_WORK.SERVICES:
      return {
        enName: "Services",
        ruName: "Услуги"
      };
    case SCOPE_OF_WORK.WORKS:
      return {
        enName: "Works",
        ruName: "Работы"
      };
    default:
      return {
        enName: "Undefined",
        ruName: "Undefined"
      };
  }
}
