export const ruSubcontractors = {
  subcontractor: "Subcontractor",
  editSubcontractor: "Edit subcontractor",

  profile: {
    title: "Subcontractor profile",
    name: "Name",
    bin: "BIN",
    city: "City",
    detailOnScopeOfWork: "Details On Scope Of Work",
    country: "Country",
    relation: "Contractual Relationship",

    relationType: {
      Undefined: "Undefined",
      Subcontractor: "Subcontractor",
      AffiliatedCompany: "Affiliated Company",
      Supplier: "Supplier",
      PrimeContractor: "Prime Contractor",
      Contractor: "Contractor"
    },

    approved: "APPROVED",
    pending: "PENDING",

    toBeReviewed: "This Subcontractor's details have changed. Review needed",

    reviewSuccess: "The Subcontractor reviewed succesfully",
    reviewError: "Subcontractor review error. Please try again"
  },

  form: {
    companyName: "Company name",
    country: "Country",
    city: "City",
    contractualRelations: "Contractual Relationship",
    bin: "BIN",
    detailOnScopeOfWork: "Details on scope of work"
  },

  editSuccess: "The profile information has been successfully updated",
  detailsTooltip: "Please find the full definition on the detailed page"
};
