export const ruContractors = {
  country: "Страна",
  contractorName: "Название подрядчика",
  workPhoneNumber: "Рабочий телефон",
  city: "Город",
  supervisorEmail: "Email линейного менеджера",
  address: "Адрес",
  companyInformation: "Информация о компании",
  memberInformation: "Информация о сотруднике",
  email: "Email",
  phoneNumber: "Телефон",
  firstName: "Имя сотрудника",
  lastName: "Фамилия сотрудника",
  jobTitle: "Должность сотрудника",
  successRegister: "Подрядчик успешно зарегистрирован",
  registerContractor: "Зарегистрировать подрядчика",
  register: "Зарегистрировать",
  cancel: "Отменить",
  contractors: "Подрядчики",
  contracts: "Контракты",
  subcontractors: "Субподрядчики",
  contractNumber: "Номер контракта",
  version: "Версия",
  type: "Тип",
  masterAgreement: "Генеральное соглашение",
  status: "Статус",
  name: "Название",
  bin: "БИН",
  details: "Описание объема работ",
  relationship: "Договорные отношения",
  addContract: "Добавить контракт",
  newContract: "Новый контракт",
  editContract: "Редактировать контракт",
  kcCategoryAndArea: "Категория и область КС",
  contractorsData: "Данные подрядчика",
  deleteContractorAction: "Удалить подрядчика",
  confirmDelete: "Вы уверены, что хотите удалить подрядчика: {{name}}?",
  contractorDeleted: "Подрядчик успешно удален",
  editContractor: "Редактировать подрядчика",
  successUpdate: "Подрядчик успешно сохранен",
  countryOfResidence: "Страна проживания",

  parentCompanyName: "Название родительской компании",
  contractorType: "Тип подрядчика",

  contractorTypes: {
    0: "Не указан",
    1: "Индивидуальное предпринимательство (ИП)",
    2: "Товарищество с ограниченной ответственностью (ТОО)",
    3: "Акционерное общество (АО)"
  }
};
