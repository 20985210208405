export const g1c = [
  {
    title: "About G1-C Forms",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Forms are created by Contractors based on the Approved Actuals for Standalone and Sub-agreement type of contracts only. Actuals for the specific month of the G1-C period should be Approved, so that G1-C can be created, otherwise, it is impossible to create G1-C forms.</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C forms have following statuses:</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Forms submitted by Contractor and not yet reviewed by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Pending&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Forms submitted by Contractor and confirmed by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Approved&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">G1-C Forms submitted by Contractor and rejected by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Rejected&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
</ol>
`
  },
  {
    title: "How to open PDF of G1-C Form?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; or &quot;Approved&quot; G1-C from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Open G1-C&quot; button</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/gdAT0j9gU8WXxTLNejfP98gQtNkwr2c_JOF-b868d2eBbylIRhmBQi-dTs7DLMoIvAedCaPQ-pX1S_1eEO8j1_BftOk3sxclR8g4OyuMtm3c0Smp3PLCdorKC_7GN-T0OJFLtmXxP2bFN154m7MRQ3sPYhbpNqzFidxyLgtViAMThYKu82WnfgCGZQ" style="height:380px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to download PDF of G1-C Forms?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; or &quot;Approved&quot; G1-C from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Open G1-C&quot; button on G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Download&quot; button</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/p82fOKeG3w-cfi0iWAKwOY4QfW1YCqo3z04CePx6GwsMDs7IQNFhWafpDvEVa3eSFjI3ROCi5qC4v9QtS8YRcBCilpSbbYwxeck-r1GiaeVWyri3Dr_DNRpxO9j9jMKoKm5UNXhlOcAdD87pN81Ot7HOprDIgJD_RvH4wSK_ZA75E4z5DpCjnhjlcw" style="height:507px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Reject G1-C Forms?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; G1-C from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Review all information provided in the sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (required: in the section which you find to be incorrect)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Reject&quot; button on Pending G1-C form&rsquo;s detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due Date for Contractors</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm your action by clicking on &quot;Reject&quot; button</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/9xjqQG13WHz9zCq6tVE7B8GxZyPLTN3I4ApcA71O1PUE23qCPLmVWYw06Lqw55r621MTFmHTZbEGnFibGuX_BJDm3CLGQjSeYofOnOmGr7pI5cExHm6js1Yp6Xvq2XWsGh-OVYxn2D_4BaemnJh59DfyoZuSL2TgJY8ENjL2ibOD6lpwb5ztaTRoHg" style="height:507px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "How to Approve G1-C Forms?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>G1-C Forms</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; G1-C from the G1-C Forms list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Review all information provided in the sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (not required: in the section which you find to be incorrect)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Approve&quot; button on Pending G1-C form&rsquo;s detailed page</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/YOu7HOXk7j0oGJ_-nlgd_jlo-uKV56-BDfvYXEDWguT5eJ_mDmhbdum3_oPTF-_bV0OPyecc_-0tXNFC2o7NJAlKC28XYlfhI3WxxnoxOlQi3iLF9Ga5xdDKQS-lj2Ou0770AFzYy8rZGXxDqLAvu2R1VEC90A0qMqyNPar4X9txxD62NOV5vf14ag" style="height:345px; width:624px" /></span></span></span></p>
`
  }
];
