/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type {
  MouCategorySubCategory,
  MouCategorySubCategoryItem
} from "@components/organisms/ActualJobsForm/libs/ActualJobsEditForm/props";
import type { MouCategoryItem } from "@components/organisms/JobsTraining/types";
import i18n from "@i18n/i18n";

import { MOU_CATEGORY } from "@components/types/models/Forecast";

type ActualHookProps = {
  readonly isTotal?: boolean;
  readonly isActual?: boolean;
};

const MOU_CATEGORY_TOTAL_VALUE = 6;

export const useActualForecast = ({
  isTotal,
  isActual = false
}: ActualHookProps): {
  readonly jobsPositions: readonly MouCategorySubCategoryItem[];
  readonly graduateSection: readonly MouCategorySubCategoryItem[];
  readonly mouCategories: readonly MouCategoryItem[];
} => {
  const { t } = useTranslation();
  const [jobsPositions, setJobsPositions] = useState<
    readonly MouCategorySubCategoryItem[]
  >([]);
  const [graduateSection, setGraduateSection] = useState<
    readonly MouCategorySubCategoryItem[]
  >([]);

  const mouCategories: readonly MouCategoryItem[] = [
    {
      text: t("forecasts.mouCategory.engineering"),
      title: "engineering",
      value: MOU_CATEGORY.ENGINEERING
    },
    {
      text: t("forecasts.mouCategory.supervisorForeman"),
      title: "supervisorAndForeman",
      value: MOU_CATEGORY.SUPERVISOR_AND_FOREMAN
    },
    {
      text: t("forecasts.mouCategory.administration"),
      title: "administration",
      value: MOU_CATEGORY.ADMINISTRATION
    },
    {
      text: t("forecasts.mouCategory.craft"),
      title: "craft",
      value: MOU_CATEGORY.CRAFT
    },
    {
      text: t("forecasts.mouCategory.heavyEquipment"),
      title: "heavyEquipmentOperator",
      value: MOU_CATEGORY.HEAVY_EQUIPMENT_OPERATOR
    },
    {
      text: isActual
        ? t("forecasts.grandTotal")
        : t("forecasts.mouCategory.total"),
      title: "total",
      value: MOU_CATEGORY_TOTAL_VALUE
    }
  ];

  const mouCategoryRowHeaders: MouCategorySubCategory = [
    {
      text: t("actuals.kzNationalsForecast"),
      title: "kzNationals",
      forecast: true
    },
    { text: t("actuals.kzNationals"), title: "kzNationals", forecast: false },
    {
      text: t("actuals.nonKzNationalsForecast"),
      title: "nonKzNationals",
      forecast: true
    },
    {
      text: t("actuals.nonKzNationals"),
      title: "nonKzNationals",
      forecast: false
    },
    {
      text: t("actuals.total"),
      title: "total",
      forecast: false
    },
    {
      text: t("actuals.kzMobilized"),
      title: "kzMobilized",
      forecast: false
    },
    {
      text: t("actuals.nonKzMobilized"),
      title: "nonKzMobilized",
      forecast: false
    },
    {
      text: t("actuals.kzDemobilized"),
      title: "kzDemobilized",
      forecast: false
    },
    {
      text: t("actuals.nonKzDemobilized"),
      title: "nonKzDemobilized",
      forecast: false
    },
    {
      text: t("actuals.plannedToNationalize"),
      title: "plannedToNationalize",
      forecast: true
    },
    {
      text: t("actuals.nationalized"),
      title: "nationalizationPositions",
      forecast: false
    },
    {
      text: t("actuals.newGradInternshipsForecast"),
      title: "newGradInternships",
      forecast: true
    },
    {
      text: t("actuals.newGradInternships"),
      title: "newGradInternships",
      forecast: false
    },
    {
      text: t("actuals.newGradJobsForecast"),
      title: "newGradJobs",
      forecast: true
    },
    { text: t("actuals.newGradJobs"), title: "newGradJobs", forecast: false }
  ];

  useEffect(() => {
    const updatedRowHeaders = isTotal
      ? mouCategoryRowHeaders
      : mouCategoryRowHeaders.filter((title) => title.forecast === isTotal);
    const graduateSectionIndex = updatedRowHeaders.findIndex(
      (category) => category.title === "newGradInternships"
    );

    setJobsPositions(updatedRowHeaders.slice(0, graduateSectionIndex));
    setGraduateSection(updatedRowHeaders.slice(graduateSectionIndex));
  }, [isTotal, i18n.language]);

  return { jobsPositions, graduateSection, mouCategories };
};
