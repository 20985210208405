import type { AxiosResponse } from "axios";
import axios from "axios";

import type { UnitsOfMeasure } from "@components/types/models/UnitsOfMeasure";

const getUnitsOfMeasure = async (): Promise<
  AxiosResponse<readonly UnitsOfMeasure[]>
> => axios.get("/api/units");

export const unitsOfMeasureApi = {
  getUnitsOfMeasure
};
