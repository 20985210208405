import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import axios from "axios";
import type { AxiosError } from "axios";
import { ErrorMessage } from "@hookform/error-message";

import { Button, Card, Col, Form, Input, message, Row, Typography } from "antd";

import { useQueryParams } from "../../hooks/useQueryParams";

type FormValues = {
  readonly password: string;
  readonly passwordConfirmation: string;
};

export const AcceptInvitePage: React.FC = () => {
  const history = useHistory();
  const { token } = useQueryParams();
  const { t } = useTranslation();
  const { Meta } = Card;
  const { Text } = Typography;
  const {
    handleSubmit,
    setError,
    formState: { errors },
    register,
    clearErrors
  } = useForm();

  const mutation = useMutation(
    async (values: FormValues) =>
      axios.post("/api/member/submit", {
        ...values,
        token
      }),
    {
      onSuccess() {
        void message.success({
          content: t("login.passwordIsSaved"),
          style: {
            marginTop: "20vh"
          }
        });
        history.replace("/login");
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("errorMessage", { message: errData.message });
        }
      }
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: FormValues) => {
      mutation.mutate(values);
    })();
  }, [clearErrors, handleSubmit, mutation]);

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col span={12}>
        <Card>
          <Meta
            style={{ marginBottom: "24px" }}
            title={t("login.confirmInvite")}
            description={t("login.enterNewPassword")}
          />
          <Form onFinish={handleFormSubmit}>
            <ErrorMessage
              errors={errors}
              name="errorMessage"
              render={({ message: errorMessage }) => (
                <Text type="danger">{errorMessage}</Text>
              )}
            />
            <Form.Item
              validateStatus={errors.password?.message && "error"}
              help={errors.password?.message}
              label={t("login.newPassword")}
              labelCol={{ span: 24 }}
            >
              <Input.Password {...register("password")} />
            </Form.Item>
            <Form.Item
              validateStatus={errors.passwordConfirmation?.message && "error"}
              help={errors.passwordConfirmation?.message}
              label={t("login.confirmPassword")}
              labelCol={{ span: 24 }}
            >
              <Input.Password {...register("passwordConfirmation")} />
            </Form.Item>
            <Form.Item noStyle>
              <Row justify="end" align="middle">
                <Button type="primary" htmlType="submit">
                  {t("submit")}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
