import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuInfo } from "rc-menu/lib/interface";

import { Collapse, Layout, Menu } from "antd";

const { Sider, Content } = Layout;
const { Panel } = Collapse;

type UserguideTemplateProps = {
  guides: ReadonlyArray<{
    title: string;
    icon: JSX.Element;
    data: ReadonlyArray<{
      title: string;
      html: string;
    }>;
  }>;
};

export const UserguideTemplate: FC<UserguideTemplateProps> = ({ guides }) => {
  const { t } = useTranslation();
  const [activeGuide, setActiveGuide] = useState<number>(0);
  const [defaultActiveKey, setdefaultActiveKey] = useState<string[]>(["0"]);
  const handleClick = (info: MenuInfo) => {
    setActiveGuide(Number(info.key));
    setdefaultActiveKey(["0"]);
  };

  const handleCollapseClick = (key: string | string[]) => {
    setdefaultActiveKey([`${key}`]);
  };

  return (
    <Layout>
      <Sider theme="light" width={256}>
        <Menu
          onClick={handleClick}
          style={{ width: 256 }}
          defaultSelectedKeys={[`${activeGuide}`]}
          mode="inline"
        >
          {guides.map((guide, idx) => (
            <Menu.Item key={idx} icon={guide.icon}>
              {t(`userguides.${guide.title}`)}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Content>
        <Collapse
          defaultActiveKey={defaultActiveKey}
          activeKey={defaultActiveKey}
          onChange={handleCollapseClick}
          accordion={true}
        >
          {guides[activeGuide].data.map((guideItem, idx) => (
            <Panel header={guideItem.title} key={idx}>
              <div dangerouslySetInnerHTML={{ __html: guideItem.html }}></div>
            </Panel>
          ))}
        </Collapse>
      </Content>
    </Layout>
  );
};
