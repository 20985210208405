export const actuals = [
  {
    title: "About Actuals",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">KC Leads are assigned to specific contracts in the system (based on KC Category and Area), so that when contractors submit Actuals for specific contract&rsquo;s period, only assigned KC Leads get notified by email</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Contractors are requested to provide Actuals for every month of the contract period. Depending on the type and scope of work of a specific contract, actuals contain different sections:</span></span></span></p>
<p>&nbsp;</p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Standalone agreement:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Goods/Works/Services; Goods Works, Services summary; Jobs and Nationalization; Training; Technology Transfer; Legacy; Success stories</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Sub-agreement:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Goods/Works/Services; Goods, Works, Services summary;</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Master agreement:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">&nbsp; Goods Works, Services summary; Jobs and Nationalization; Training; Technology Transfer; Legacy; Success stories</span></span></span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Actuals have four statuses in the system:</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Actuals submitted by Contractor and not yet reviewed by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Pending&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Actuals submitted by Contractor and confirmed by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Approved&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Actuals submitted by Contractor and rejected by KC Lead have </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Rejected&quot;</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> status</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Contractors which have not submitted their Actuals after 20th of every month are considered to be </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Overdue&quot;</strong></span></span></span></li>
</ol>
`
  },
  {
    title: "How to Reject actuals?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Review all information provided in the actual sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (required: in the section which you find to be incorrect)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Reject&quot; button on Pending actuals detailed page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Set Due Date for Contractors</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Confirm your action by clicking on &quot;Yes&quot; button</span></span></span></li>
</ol>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/8LHw6fjhOjb4-_PDxcbUilyCNl3c8ZTUXwzgMfV7LBEqjqlCBHuXrRljRQ7oMDysiBL9fRTHLLnS2u3HGWi1RGkH94U21L8hfAXsI7b-XuY3TMcqBJuat8_fAA7KZVswJaYyG-_LY5USRbOPrGJlujVwfThsnAa4bk8BKoXooelrXJOVUIuulSpyXQ" style="height:668px; width:588px" /></span></span></span></p>
`
  },
  {
    title: "How to Approve actuals?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Actuals</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Select &quot;Pending&quot; actuals from the Actuals list</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Review all information provided in the actual sections</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Leave a comment (not required: in the sections if needed)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Approve&quot; button on Pending actuals detailed page</span></span></span></li>
</ol>

<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/GyB8WJST1f_qdpfzQoduEm9OTAAYKr0tSreqltiTysPtUWeCWAw1AI6kreI0925gx6GtxvJ9gFNdO_d3e6EqGWMv4oEKYEUKZ8Cjt1TDsURexjU20PjYeri-Cc42wlvO7UKDvGmhNLPo-NUMnkRAkHufYZyPa_57YkTPq8fPxcaNQi_iepN15bB-pA" style="height:525px; width:624px" /></span></span></span></p>
`
  }
];
