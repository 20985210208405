export const ruMembers = {
  members: "Сотрудники",
  inviteMember: "Пригласить сотрудника",
  firstName: "Имя сотрудника",
  lastName: "Фамилия сотрудника",
  jobTitle: "Должность сотрудника",
  inactiveMembers: "Неактивные сотрудники",
  invitedMembers: "Приглашенные сотрудники",
  kcProperties: "KC опции",
  accessLevel: "Уровень доступа",
  editMember: "Редактировать сотрудника",
  contractorsView: "Подрядчики - просмотр",
  contractsView: "Контракты - просмотр",
  notifications: "Уведомления",
  categoryAndArea: "Категория и область KC",
  back: "Назад",
  invite: "Пригласить",
  inviteSuccess:
    "был зарегистрирован. Ссылка для приглашения была отправлена ​​на указанный адрес электронной почты",
  userExport: "Эспорт сотрудников",
  activeMembers: "Активные сотрудники",
  membersSum: "Всего сотрудников",
  subcontractorsSum: "Всего субподрядчиков",
  phoneNumber: "Рабочий номер",
  position: "Должность",
  memberDeleted: "Пользователь успешно удален",
  memberActivated: "Приглашение успешно  переотправлено",
  memberInviteDeleted: "Приглашение успешно удалено",
  memberInviteResent: "Приглашение успешно переотправлено",
  invitationDate: "Дата приглашения",
  confirmDeleteInvite: "Вы действительно хотите удалить приглашение для",
  confirmDeleteMember: "Вы уверены, что хотите удалить сотрудника:",
  previousInviteNotExpired: "Срок действия предыдущего приглашения не истек",
  resendPossible: "Возможна повторная отправка",
  memberInformation: "Информация о сотруднике",
  confirmContractorMemberDelete:
    "Вы действительно хотите удалить сотрудника подрядчика: {{contractorName}}'s {{memberName}}",
  membersData: "Данные сотрудника",
  informationAboutCompany: "Информация о компании",
  successUpdate: "Сотрудник успешно сохранен"
};
