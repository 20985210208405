import axios, { AxiosResponse } from "axios";

import { MigrationRecord, MigrationType } from "../types/MigrationTypes";

import { PaginatedResponse } from "@api";

const getMigrationList = async (
  page: number,
  pageSize?: number
): Promise<PaginatedResponse<MigrationRecord>> =>
  axios
    .get(`/api/migrations`, {
      params: { page, pageSize }
    })
    .then((response) => response.data);

const postMigration = async (
  type: MigrationType,
  data: FormData
): Promise<AxiosResponse> => axios.post(`/api/migrations/${type}`, data);

export { getMigrationList, postMigration };
