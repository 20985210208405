import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "antd";

import type { LogoProps } from "./props";

export const Logo: React.FC<LogoProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Link to="/">
      <Button type="text" className={className} style={{ height: "100%" }}>
        {t("logo")}
      </Button>
    </Link>
  );
};
