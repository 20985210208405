import type { FC } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { AppTable } from "@components/molecules/AppTable";
import { Table, Typography } from "antd";

import type { AdminMember } from "@components/types/models/AdminMember";

import { defineUserRole, UserContext } from "@contexts/userContext";
import { formatPhoneNumber } from "@utils/phoneNumberFormatter";

import type { TableProps } from "./props";

const { Column } = Table;

export const MemberTable: FC<TableProps> = ({
  members,
  isLoading,
  renderActions,
  ...rest
}) => {
  const { t } = useTranslation();
  const { isAdmin, isContractor } = useContext(UserContext);
  const { Text } = Typography;

  return (
    <AppTable
      isLoading={isLoading}
      dataSource={members}
      {...rest}
      locale={{ emptyText: t("noData") }}
      style={{ marginBottom: "80px" }}
    >
      <Column title={t("members.firstName")} dataIndex="firstName" />
      <Column title={t("members.lastName")} dataIndex="lastName" />
      <Column title={t("members.jobTitle")} dataIndex="position" />
      <Column title={t("email")} dataIndex="email" />
      <Column
        title={t("profile.role")}
        render={(member) =>
          member?.roles.map((item: { readonly role: number }, i: number) => (
            <Text key={i}> {defineUserRole(item.role)} </Text>
          ))
        }
      />
      {isContractor && (
        <Column
          title={t("contractors.phoneNumber")}
          render={(member) => (
            <Text>
              {member.phoneNumber ? formatPhoneNumber(member.phoneNumber) : "-"}{" "}
            </Text>
          )}
        />
      )}
      {renderActions && isAdmin && (
        <Column
          title={t("actions")}
          render={(member: AdminMember) => renderActions(member)}
        />
      )}
    </AppTable>
  );
};
