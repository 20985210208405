import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

import type { CitySelectorProps } from "./props";

export const CitySelectorTemp: React.FC<CitySelectorProps> = ({
  selectedCity,
  onChange,
  cities,
  ...rest
}) => {
  const [, i18n] = useTranslation();
  const { Option } = Select;

  const handleSelectChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      showSearch
      value={selectedCity}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.props.children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      onChange={handleSelectChange}
      {...rest}
    >
      {cities?.map((item, index) => (
        <Option key={index} value={item.id}>
          <span>{i18n.language === "en" ? item.nameEn : item.nameRu}</span>
        </Option>
      ))}
    </Select>
  );
};
