import type { FC } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { css } from "@linaria/core";
import moment from "moment";
import tw from "twin.macro";

import { AppTable } from "@components/molecules/AppTable";
import { Row, Table, Tag, Typography } from "antd";

import { defineScopeOfWorks } from "@components/types/models/Contract";
import { PERMISSIONS } from "@components/types/models/Permissions";
import { defineStatus } from "@components/types/models/Statuses";

import { UserContext } from "@contexts/userContext";
import { isUserHasPermission } from "@utils/permissionHelper";

import type { SubAgreementsTableProps } from "./props";

const { Text } = Typography;
const { Column } = Table;

export const SubAgreementsTable: FC<SubAgreementsTableProps> = ({
  subAgreementsList,
  linkToContract,
  loading
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { user, isAdmin } = useContext(UserContext);

  const hasContractViewPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACT_VIEW
  );

  return (
    <AppTable
      className={css`
        ${tw`mt-8`}
      `}
      dataSource={subAgreementsList}
      subtitle={`${t("contracts.allAdditionalContracts")}${
        subAgreementsList?.length
      }`}
      loading={loading}
    >
      <Column
        title={t("contracts.contractNumber")}
        dataIndex="contractNumber"
        render={(contractNumber: number, record: { readonly id: number }) =>
          hasContractViewPermission ? (
            <Link to={`${linkToContract}${record.id}`}>{contractNumber}</Link>
          ) : (
            <Text>{contractNumber}</Text>
          )
        }
      />
      <Column
        title={t("contracts.version")}
        dataIndex="version"
        // eslint-disable-next-line react/jsx-no-literals
        render={(version: number) => <Text>{`v${version}`}</Text>}
      />
      {isAdmin && (
        <Column
          title={t("contracts.kcCategoryAndArea")}
          dataIndex="kcCategory"
          render={(category) => <Text>{category.name}</Text>}
        />
      )}
      <Column
        title={t("contracts.scopeOfWork")}
        dataIndex="scopes"
        render={(scopes?: readonly number[]) => (
          <Row>
            {scopes?.map((item: number, index) => (
              <Text key={index}>
                {(index ? ", " : "") +
                  (i18n.language === "en"
                    ? defineScopeOfWorks(item).enName
                    : defineScopeOfWorks(item).ruName)}
              </Text>
            ))}
          </Row>
        )}
      />
      <Column
        title={t("contracts.startDate")}
        dataIndex="contractStartDate"
        render={(startDate) => (
          <Text>
            {" "}
            {startDate ? moment(startDate).format("YYYY-MM-DD") : "-"}
          </Text>
        )}
      />
      <Column
        title={t("contracts.endDate")}
        dataIndex="contractEndDate"
        render={(endDate) => (
          <Text>{endDate ? moment(endDate).format("YYYY-MM-DD") : "-"}</Text>
        )}
      />
      <Column
        title={t("contracts.status")}
        dataIndex="contractStatus"
        render={(contractStatus: number) => {
          const status = defineStatus(contractStatus);
          return (
            <Text>
              {contractStatus ? (
                <Tag color={status.color}>
                  {i18n.language === "en" ? status.enName : status.ruName}
                </Tag>
              ) : (
                "-"
              )}
            </Text>
          );
        }}
      />
    </AppTable>
  );
};
