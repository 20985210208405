import {
  CheckOutlined,
  CopyOutlined,
  DollarOutlined,
  FileTextOutlined,
  LineChartOutlined,
  QuestionOutlined,
  UserOutlined
} from "@ant-design/icons";

import { gettingStarted } from "@utils/userguides/ruTco/gettingStarted";
import { contractors } from "@utils/userguides/ruTco/contractors";
import { dashboards } from "@utils/userguides/ruTco/dashboards";
import { contracts } from "@utils/userguides/ruTco/contracts";
import { forecasts } from "@utils/userguides/ruTco/forecasts";
import { actuals } from "@utils/userguides/ruTco/actuals";
import { g1c } from "@utils/userguides/ruTco/g1c";

export const ruTcoUserguides = [
  { title: "general", data: gettingStarted, icon: <QuestionOutlined /> },
  { title: "contractors", data: contractors, icon: <UserOutlined /> },
  {
    title: "contracts",
    data: contracts,
    icon: <CopyOutlined />
  },
  {
    title: "forecasts",
    data: forecasts,
    icon: <FileTextOutlined />
  },
  {
    title: "actuals",
    data: actuals,
    icon: <CheckOutlined />
  },
  {
    title: "g1c",
    data: g1c,
    icon: <DollarOutlined />
  },
  {
    title: "dashboards",
    data: dashboards,
    icon: <LineChartOutlined />
  }
];
