export const ruExchangeRates = {
  exchangeRates: "Код валют",
  date: "Выберите дату",
  updated: "Последнее обновление от {{date}} пользователем {{name}}",
  updateMonth: "Обновить код валют",
  code: "Валюта",
  rate: "Эквивалент в USD",
  rateInput: "Коэффициент курса к USD",
  rateValidationMessage: "Введите курс к валюте",
  addCurrency: "Добавить валюту",
  codePlaceholder: "Выберите коды валют, которые вы хотите добавить",
  codeRequired: "Выберите как минимум одну валюту",
  addCurrencySuccess: "Валюта успешно добавлена",
  addCurrencyError:
    "Во время добавления валюты произошла ошибка, попробуйте позднее",
  updateExchangeRatesSuccess: "Курс валют успешно обновлён",
  updateExchangeRatesError:
    "Во время обновления курса валют произошла ошибка, попробуйте позднее",
  getCurrenciesError:
    "Не удалось получить список валют, попробуйте перезагрузить страницу"
};
