export const ruContracts = {
  contractNumber: "Номер контракта",
  type: "Тип",
  contracts: "Контракты",
  totalContracts: "Всего контрактов",
  masterAgreement: "Генеральное соглашение",
  contractType: "Тип контракта",
  kcCategoryAndArea: "Категория и область КС ",
  kcCategoryAndAreaColon: "Категория и область КС: ",
  contractTypeColon: "Тип контракта: ",
  masterAgreementColon: "Генеральное соглашение: ",
  addSubAgreement: "Добавить доп.соглашение",
  subAgreements: "Дополнительные соглашения",
  additionalContracts: "Дополнительные соглашения",
  allAdditionalContracts: "Всего доп. соглашений: ",
  successCreate: "Контракт успешно создан",
  version: "Версия",
  versionWithParam: "Версия {{version}}",
  updateContract: "Обновить контракт",
  updateAndResubmit: "Обновить контракт и отправить снова",
  startDate: "Дата начала",
  endDate: "Дата окончания",
  currency: "Валюта",
  amount: "Сумма контракта",
  kcTarget: "Целевой показатель KC",
  detailsOnScopeWork: "Описание объема работ",
  comment: "Комментарий",
  comments: "Комментарии",
  attach: "ПРИКРЕПИТЬ KC ПЛАН",
  attachedFile: "Прикрепленный KC План",
  successUpdate: "Контракт успешно обновлен",
  scopeOfWorks: "Объем работ",
  scopeOfWork: "Объем работ",
  status: "Статус",
  contractDeleted: "Контракт удален",
  approve: "Утвердить",
  reject: "Отклонить",
  rejectAmendment: "Отклонить",
  confirmReject: "Вы уверены, что хотите отклонить данные поправки?",
  submittedBy: "Отправлено (кем): {{name}}",
  submissionDate: "Дата отправки: {{date}}",
  approvedBy: "Утверждено (кем): {{name}}",
  approvalDate: "Дата утверждения: {{date}}",
  rejectedBy: "Отклонено (кем): {{name}}",
  rejectionDate: "Дата отклонения: {{date}}",
  compareWithInitial: "Сравнить с изначальной",
  sureWantToReject: "Вы уверены, что хотите отклонить данные поправки?",
  contractPeriod: "Период контракта",
  period: "Период",
  addAmendment: "Добавить поправку",
  newAmendment: "Новые поправки",
  newAmendmentSent: "Запрос на внесение поправок отправлен",
  amendmentResubmitted: "Изменения отправлены на рассмотрение KC специалисту",
  confirmDelete: "Вы уверены, что хотите удалить контракт {{name}}?",
  confirmDeleteSecondLine:
    "Когда вы нажмете ДА, действие не может быть отменено.",
  goods: "Товары",
  works: "Работы",
  services: "Услуги",
  noCommentsYet: "Пока никаких комментариев",
  versionComparison: "Сравнение версий",
  ok: "OK",
  instructionMessage:
    "Пожалуйста укажите текущие данные контракта, опишите изменения в коментариях.",
  instructionExample:
    'Пример: предыдущая сумма контракта составляла 100, сумма увеличилась на 10, текущая сумма составляет 110. В данном случае в поле Сумма контракта укажите 110, а в поле комментарий "Сумма контракта увеличилась на 10".',
  parentContract: "Родительский контракт",
  contractStartDate: "Дата начала контракта",
  contractEndDate: "Дата окончания контракта",
  contractAmount: "Сумма контракта"
};
