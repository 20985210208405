import type { FC } from "react";

import { Select } from "antd";

import type { CategorySelectorProps } from "./props";

export const CategorySelector: FC<CategorySelectorProps> = ({
  categories,
  ...rest
}) => (
  <Select
    options={categories?.map((c) => ({
      value: c.key,
      label: c.title
    }))}
    mode="multiple"
    maxTagCount="responsive"
    filterOption={(input, option) =>
      (option?.label as string).toLowerCase().includes(input.toLowerCase())
    }
    {...rest}
  />
);
