export const contractors = [
  {
    title: "Как зарегистрировать подрядчика?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Зарегистрировать подрядчика&quot;на странице Подрядчики</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Зарегистрировать&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/NbIqHjGSLvBpkBvqIqyxZP0zG9jUG_Psnn0HJicEOScQ7g9vGlZI-0Mgkv72StIN0q565Y7StDpgmskd6y3e45MJj_o0Xjy6PfvizZzkUmrRSlrR5K1DpONf-Hgk5Y0OOIZ4UlsOSamjau8TP2taIAwSO_mC8cSdx_AhlH4-B-u2rq3LAon9vWPJYw" style="height:408px; width:624px" /></span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> На страницу специфического подрядчика Руководитель КС может попасть также через формы &quot;Прогнозы&quot;, &quot;Фактические показатели&quot; и &quot;G1-C Формы&quot;, просто нажав на название компании указанных в виде гиперссылок в этих же формах.</span></span></span></p>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/nTRFCOVis3d_ySfKnmQdtw61w8FWqTYgMoXFkdc-MbdGpUlmIhq6Gswjw9MS3HCh_JTpfRtIrPhlbxJvbrR-DnLXpi_6MljlW3fU2Z0yRovU1D2cZhp1YyixtyDsLBEOW2b6Yj247GVUfByIN5L92QQSJHj3w4-yJAOUrOq7hyZPp3sfPJd7LDmzxA" style="height:153px; width:626px" /></span></span></span></p>
`
  },
  {
    title: "Как отредактировать данные подрядчика?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Редактировать&quot;под столбцом Действия для выбранного подрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/Uqk5APNHGe19WN0glYoLn4qvdxZzkRQVqs3McJLQYCV7YDXzNHEy0qTdCIFGbC6Ogh-zoCh8mmJ-saUtc55dy1ZYozuiVY-TaA6M1BlpQ4VNZHxg-NS1qFzB4gMFDK6vw25ULhsN8SH5oWLbUbfztG1X87WkELKCuqNUhVqriKd1w7KfsMS3EEtYSg" style="height:157px; width:596px" /></span></span></span></p>
`
  },
  {
    title: "Как удалить подрядчиков?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Подрядчики&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите подрядчика со списка Подрядчики</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Пролистайте вниз детальной страницы выбранного подрядчика</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Удалить подрядчика&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите удаление нажав на кнопку &quot;Да&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/qGTV-Y2TnGS5NioFSP0m6IxCLvGpfJiE8sscCZXgyC88WcIDcB2RFycfKhGfVL-tXN_3ka9a6W6l4J_C8CjVYxUL16NStudppFcpHs_46v1143GXD5eRPU_27F-63Qz2XiZswRL-7Um6EKxTL8GXyloUm6HWmyEr1DKHv7Hbz_w6gNv3qXAs_3yG9Q" style="height:339px; width:505px" /></span></span></span></p>
`
  }
];
