import type { FC } from "react";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import axios from "axios";
import type { AxiosError } from "axios";

import { Button, Card, Col, Form, Input, message, Row } from "antd";

import { HttpResponseStatusCodes } from "@components/types/HttpResponseStatusCodes";

export const ResetPassword: FC = () => {
  const { t } = useTranslation();
  const { Meta } = Card;

  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
    clearErrors
  } = useForm();

  const history = useHistory();

  const mutation = useMutation(
    async (values: unknown) =>
      axios.post("/api/auth/password/email", values).then((res) => {
        const token = res.data?.apiToken;
        localStorage.setItem("KC_PORTAL_USER_TOKEN", token);
      }),
    {
      onSuccess: (_, values: { readonly email: string }) => {
        void message.success(
          `${t("login.resetPasswordSuccess")} ${values.email}`
        );
        history.push("/login");
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.status === HttpResponseStatusCodes.BadRequest) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: "email" | "password";
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("errorMessage", { message: errData.message });
        }
      }
    }
  );

  const handleFormSubmit = useCallback(() => {
    clearErrors();
    void handleSubmit((values: { readonly email: string }): void => {
      mutation.mutate(values);
    })();
  }, [handleSubmit, clearErrors, mutation]);

  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col span={8}>
        <Card>
          <Meta
            title={t("login.resetPasswordTitle")}
            description={t("login.resetPasswordSubtitle")}
          />
          <Form onFinish={handleFormSubmit}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={t("email")}
              validateStatus={errors.email?.message && "error"}
              help={errors.email?.message}
            >
              <Input
                size="large"
                placeholder={t("email")}
                {...register("email")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                htmlType="submit"
                type="primary"
                style={{ width: "100%" }}
                loading={mutation.isLoading}
              >
                {t("login.nextButton")}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
