/* eslint-disable @typescript-eslint/naming-convention */

export const enForecasts = {
  forecasts: "Forecasts",
  forecastCreated: "Forecast successfully created",
  forecastForContract: "Forecast for contract",
  forecastVersion: "Forecast version",
  from: "From",
  to: "to",
  name: "Name",
  goods: "Goods",
  newGood: "New good",
  newWork: "New work",
  newService: "New service",
  newCompany: "New company",
  companyName: "Company name",
  goodsDescription: "Goods description",
  contractualRelationship: "Contractual relationship",
  goodQuantity: "Good quantity",
  goodUnitsOfMeasure: "Good units of measure",
  forecastValue: "Forecast value",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CTKZ: "CT-KZ%",
  kcForecastValue: "KC forecast value",
  addGoods: "Add goods",
  subcontractor: "Subcontractor",
  affiliatedCompany: "Affiliated company",
  supplier: "Supplier",
  primeContractor: "Prime contractor",
  contractor: "Contractor",
  subcontractorAdded: "Subcontractor successfully added",
  dataSaved: "Data saved",
  goodAdded: "New good added",
  workAdded: "New work added",
  serviceAdded: "New service added",
  worksDescription: "Works description",
  serviceDescription: "Service description",
  works: "Works",
  addWork: "Add work",
  addService: "Add services",
  staffPercent: "KZ staff share of Payroll fund %",
  comments: "Comments",
  leaveComment: "Leave comment",
  noCommentsYet: "No comments yet",
  commentToTheSection: "Comment to the section",
  services: "Services",
  goodsWorksServicesSummary: "Goods, works, services summary",
  jobsAndNationalization: "Jobs and Nationalization",
  training: "Training",
  mouCategory: {
    engineering: "Engineering",
    supervisorForeman: "Supervisor and Foreman",
    administration: "Administration",
    craft: "Craft",
    heavyEquipment: "Heavy Equipment Operator",
    total: "Total",
    grandTotal: "Grand total"
  },
  nationalization: {
    nationalizationFor: "Nationalization for",
    pleaseProvide:
      "Please provide details for the positions planned to be nationalized",
    position: "Position",
    fwlCategory: "FWL Category",
    foreignerName: "Foreigner name",
    country: "Country",
    companyName: "Company name",
    kzName: "KZ name",
    comment: "Comment",
    category: {
      1: "Category I",
      2: "Category II",
      3: "Category III",
      4: "Category IV"
    },
    categoryInfo: {
      1: "Category I - Chief executives and their deputies",
      2: "Category II - Senior managers of structural subdivisions meeting the qualiﬁcation requirements set out in the occupational skills guide and standard job descriptions",
      3: "Category III - Skilled professionals(specialists) meeting the qualiﬁcation requirements set out in the occupational skills guide and standard job descriptions",
      4: "Category IV - Skilled workers meeting the qualiﬁcation requirements of the Uniﬁed Salary and Wage Rates, Occupations and Skills Guide and standard job descriptions"
    },
    addPosition: "Add position",
    saved: "Nationalization details were successfully saved"
  },
  jobsPositions: "Jobs positions",
  kzNationals: "KZ Nationals",
  nonKzNationals: "Non-KZ Nationals",
  total: "Total positions",
  plannedToNationalize: "Planned to nationalize",
  graduateSection: "Graduate section",
  newGradInternships: "Internship for New-graduates",
  newGradJobs: "Jobs for New-graduates",
  saved: "Saved",
  grandTotal: "Grand total",
  saveAndGoToTheNextYear: "Save and go to the next year",
  saveAndGoToTheNextSection: "Save and go to the next section",
  saveAndGoToTheNextCategory: "Save and go to the next category",
  proceedAndGoToTheNextCategory: "Proceed and go to the next category",
  proceedAndGoToTheNextSection: "Proceed and go to the next section",
  proceedAndGoToTheNextYear: "Proceed and go to the next year",
  technologyTransfer: "Technology transfer",
  legacy: "Legacy",
  successStories: "Success stories",
  saveAndProceed: "Save and proceed",
  proceed: "Proceed",
  section: "Section",
  kc: "KC %",
  all: "ALL",
  opts: {
    status: {
      overdue: "Overdue",
      approved: "Approved",
      rejected: "Rejected",
      pending: "Pending"
    }
  },
  applyFilters: "Apply filters",
  subAgreementNumber: "Subagreement number",
  forecastValueInOriginalCurrency: "Forecast value in original currency",
  forecastValueInUsd: "Forecast value in USD",
  forecastKcValueInOriginalCurrency: "Forecast KC value in  original currency",
  forecastKcValueInUsd: "Forecast KC value in USD",
  forecastKcShare: "Forecast KC share",
  dueDate: "Due date",
  submissionWarningMessage:
    "Once submitted, a new version of forecast will be created and sent to KC Lead for approval",
  successForecastSubmit:
    "Forecast was successfully sent to KC Lead for approval, please wait for the feedback!",
  submissionDate: "Submission date: {{date}}",
  rejectForecast: "Reject forecast",
  setDueDate: "Set a due date for a forecast update",
  forecastApproved: "Forecast has been successfully Approved",
  forecastRejected: "Forecast has been successfully Rejected",
  restorePrevVersion: "Restore previous version",
  legacyPlaceholder: "Please describe legacy and plan below",
  technologyPlaceholder: "Please describe technology transfer and plan below",
  downloadKcPlan: "Download KC plan",
  timeLeftForReview: "Time Left for review: {{count}} day",
  timeLeftForReview_plural: "Time Left for review: {{count}} days",
  timeLeft: "Time left - {{count}} day",
  timeLeft_plural: "Time left - {{count}} days",
  youHaveMissedDueDate: "You have missed due date"
};
