/* eslint-disable @typescript-eslint/naming-convention */
import i18next from "i18next";

import type { AmendmentFormFields } from "@components/types/models/Amendment";

import type { UploadFile } from "antd/es/upload/interface";

export const parseAmendmentFormFields = (
  values: AmendmentFormFields,
  file?: UploadFile
): {
  readonly StartDate?: string;
  readonly EndDate?: string | null;
  readonly Amount?: string;
  readonly CurrencyId: number;
  readonly KcTarget?: string;
  readonly ScopeOfWorks: readonly string[];
  readonly DetailsOnScopeOfWork: string;
  readonly Comment: string;
  readonly KcPlanFile?: UploadFile;
} => ({
  StartDate: values.startDate?.toJSON(),
  EndDate: values.endDate?.toJSON(),
  Amount: values.amount?.toString(),
  CurrencyId: values.currencyId,
  KcTarget: values.kcTarget?.toString(),
  ScopeOfWorks: values.scopeOfWorks,
  DetailsOnScopeOfWork: values.detailsOnScopeWork,
  Comment: values.comment,
  KcPlanFile: file
});
