import type { AxiosResponse } from "axios";
import axios from "axios";

import type { Invoice } from "@components/types/models/Invoices";

const getContractsForInvoices = async (
  contractorId: number
): Promise<AxiosResponse> =>
  axios.get(
    `/api/invoices/contractor/${contractorId}/contracts-for-invoice-draft`
  );

const deleteDraftInvoice = async (invoiceId: number): Promise<AxiosResponse> =>
  axios.delete(`/api/invoices/${invoiceId}/`);

const getInvoiceById = async (id: string): Promise<AxiosResponse<Invoice>> =>
  axios.get(`api/invoices/${id}`);

const submitInvoice = async (
  id: string,
  dueDate: string
): Promise<AxiosResponse> =>
  axios.put(`api/invoices/${id}/submit`, null, {
    params: {
      dueDate
    }
  });

const updateApprovedInvoice = async (
  invoiceId?: number
): Promise<AxiosResponse> => axios.post(`/api/invoices/${invoiceId}/version`);

const restorePreviousInvoice = async (
  invoiceId?: number
): Promise<AxiosResponse> => axios.delete(`/api/invoices/${invoiceId}/version`);

const getInvoiceComments = async (
  invoiceId: number,
  sectionType: number
): Promise<AxiosResponse> =>
  axios.get(`/api/comments/invoice/${invoiceId}`, {
    params: {
      sectionType
    }
  });

const downloadInvoiceAttachment = async (
  invoiceId?: number,
  attachmentId?: number
): Promise<AxiosResponse> =>
  axios.get(`/api/invoices/${invoiceId}/attachment/${attachmentId}`, {
    responseType: "blob"
  });

const downloadG1cForm = async (invoiceId: string) =>
  axios.get(`/api/invoices/${invoiceId}/g1c-form`, {
    responseType: "blob"
  });

export const invoiceApi = {
  deleteDraftInvoice,
  getContractsForInvoices,
  getInvoiceById,
  submitInvoice,
  updateApprovedInvoice,
  restorePreviousInvoice,
  getInvoiceComments,
  downloadInvoiceAttachment,
  downloadG1cForm
};
