export const gettingStarted = [
  {
    title: "How to access Account Information?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Log in to the system.</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>KC PORTAL</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> label on the left corner of a horizontal menu OR</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on your </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>NAME</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> on the right corner of a horizontal menu</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/XYGEH2pdVLDGSccWXp27DJde5X1IsWpW7NZoAq3ZKHUeq8UkqPoHZLwAJM_U7eoYkPxTnq8tGzXeDpLyq7Bx0XXZ-AfY4kcestzUnZdTaOkeTa7HDGL07U9UUtrhE381onEfgUKyxYGBQ62a3RFcMcVMeejqo1Q8JfPgVTNCkgg9_xG6JC8p45tVZw" style="height:122px; width:635px" /></span></span></span></p>
`
  },
  {
    title: "How to change my password?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Log in to the system.</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Open </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Account Information</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Change password&quot; button on your Account Information page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Enter Current password, New password, Confirm new password</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Change&quot; button after filling in all required fields</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/EOa7E71F9u4iwk6p1--qGuKMEEfWb9jFLpahsuDclGvJJQYwn0HbMQz7wI8TKKneeeHmk7Re0DODjzfOcoS7b7PnVQYPXWH04Ro4_0Y2y-XQX83Pm58pDS9lzgTlMQx9nSMndUWrEqhtbOvEly3iigTdc0lOXl_afyBGgQ7blX4ThPcNuOx6SohDBQ" style="height:273px; width:617px" /></span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Note:</strong></span></span></span></p>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Password Length - minimum of 8 characters</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Password Complexity must be composed of a combination of at least three of the following character types:</span></span></span></li>
</ul>
<ul>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Uppercase letters</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Lowercase letters</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Numbers</span></span></span></li>
\t<li style="list-style-type:disc"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Symbols</span></span></span></li>
</ul>
`
  },
  {
    title: "How to access Company Information?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Log in to the system.</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab on the horizontal menu</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/0oskBIAFty6-E7zfRM0mtxlrSdToSdz7YrOcSz048EDI1b_cBMN3zl9o9i3zRKJ2lNWTZq0iyiAtVsyBik7mQsPMaWDTyHO4znMvd9dDdT4JHpMwwFa-_5qcR2XnNy5E7VAptTLJL_ylBMHGiNNrByA35dD1rRy2QVrW441pQMelz4NMEs9Pb13QqA" style="height:167px; width:397px" /></span></span></span></p>
`
  },
  {
    title: "How to invite members to the system?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Invite member&quot; button on the Members page</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Fill out all required fields</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Invite&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/qAyruu74atxDHrf1On4vOhyiQgrlT_fvpgdoQI0daR4JzzBbPrBJnpteHYyB9DoWmGJMLAUU__9hD8aa7gbtCMlW_Mj7vL8ToSaGpOJmaXd5TBbuZ_TElISnRmKZFcm5clFRVMbzWTsgEIJxBwNWhFWNWfbLNhZ8qm6MPW3wVypqmE-tj1BOZKFtRA" style="height:354px; width:554px" /></span></span></span></p>
`
  },
  {
    title: "How to edit Subcontractor’s Profile Information?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Members</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> tab</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Scroll down to the Subcontractor&rsquo;s table</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Edit&quot; button under the Actions column of your selected Subcontractor</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Make required changes</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Click on &quot;Update&quot; button</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/Vd_NX4wWLrhooL5Pa1bKftJ5OJVjAJamleXVbDDh3fF1qSh7Ukio1wKPOMTisXcKM-Ly-XFMiPf0K4YQeN8horFNF8TQunesX48uj7mz5Q6TGjy_jyJtivpOQrRa0B-uZ6C8cF4IE_R8HUbMc4D4kLxONOK2K-X8U94ihVV8wF9SCTVKWD9joIR5zg" style="height:184px; width:624px" /></span></span></span></p>
`
  }
];
