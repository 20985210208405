import { useLocation } from "react-router-dom";

import type { ParsedQs } from "qs";
import { parse } from "qs";

export const useQueryParams = (): ParsedQs => {
  const location = useLocation();
  const q = location.search.slice(1);
  return parse(q, {});
};
