export const forecastStatuses = [
  {
    enName: "-",
    ruName: "-",
    id: 0
  },
  {
    enName: "Pending",
    ruName: "На рассмотрении",
    id: 1
  },
  {
    enName: "Rejected",
    ruName: "Отклонен",
    id: 2
  },
  {
    enName: "Approved",
    ruName: "Утвержден",
    id: 3
  },
  {
    enName: "Draft",
    ruName: "Черновик",
    id: 4
  }
];

export const actualStatuses = [
  {
    enName: "Pending",
    ruName: "На рассмотрении",
    id: 1
  },
  {
    enName: "Rejected",
    ruName: "Отклонен",
    id: 2
  },
  {
    enName: "Approved",
    ruName: "Утвержден",
    id: 3
  },
  {
    enName: "Draft",
    ruName: "Черновик",
    id: 4
  }
];

export const invoiceStatuses = [
  {
    enName: "Pending",
    ruName: "На рассмотрении",
    id: 1
  },
  {
    enName: "Rejected",
    ruName: "Отклонен",
    id: 2
  },
  {
    enName: "Approved",
    ruName: "Утвержден",
    id: 3
  },
  {
    enName: "Draft",
    ruName: "Черновик",
    id: 4
  }
];
