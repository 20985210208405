/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { Table } from "antd";

import type { ColumnsType } from "antd/lib/table";
import type {
  MigrationRecord,
  MigrationStatus,
  MigrationType
} from "../types/MigrationTypes";

import { MigrationStatusBadge } from "./MigrationStatusBadge";

type MigrationsTableProps = {
  readonly data: readonly MigrationRecord[];
  readonly loading: boolean;
  readonly onPageChange: (page: number, pageSize?: number) => void;
  readonly totalItems?: number;
  readonly currentPage?: number;
  readonly pageSize?: number;
};

export const MigrationsTable: React.FC<MigrationsTableProps> = ({
  onPageChange: handlePageChange,
  data,
  loading,
  totalItems,
  currentPage,
  pageSize
}) => {
  const { t, i18n } = useTranslation();

  const columns = useMemo<ColumnsType<MigrationRecord>>(
    () => [
      {
        title: t("migrations.filename"),
        key: "filename",
        dataIndex: "fileName"
      },
      {
        title: t("migrations.dataType"),
        key: "type",
        dataIndex: "type",
        render: (value: MigrationType) => (
          <span>{t(`migrations.typeCodes.${value}`)}</span>
        )
      },
      {
        title: t("migrations.username"),
        key: "username",
        render: (
          _,
          { createdByUser: { firstName, lastName } }: MigrationRecord
        ) => <span>{firstName + lastName}</span>
      },
      {
        title: t("migrations.date"),
        key: "createdAt",
        dataIndex: "createdAt",
        render: (value: string) => (
          <span>{moment(value).format("YYYY.MM.DD HH:mm")}</span>
        )
      },
      {
        title: t("migrations.status"),
        key: "status",
        dataIndex: "status",
        render: (value: MigrationStatus) => (
          <MigrationStatusBadge status={value} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      pagination={{
        showSizeChanger: false,
        onChange: handlePageChange,
        current: currentPage,
        pageSize,
        total: totalItems
      }}
    />
  );
};
