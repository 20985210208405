export const ruInvoices = {
  g1cForms: "G1-C формы",
  invoiceNumber: "Номер счета",
  g1cDateFrom: "Дата от",
  g1cDateTo: "Дата до",
  createInvoice: "Создать G1-C форму",
  newInvoice: "Новая G1-C форма",
  invoiceIsDeleted: "G1-C форма удалена",
  deleteInvoiceConfirmation: "Вы действительно хотите удалить эту G1-C форму?",
  invoiceCreated: "G1-C форма успешно создана",
  invoiceFor: "G1-C форма для {{contract}}",
  restorePreviousVersion: "Восстановить предыдущую версию",
  version: "Версия {{version}}",
  invoiceDateFrom: "Дата от",
  invoiceDateTo: "Дата до",
  cwtNumber: "CWT номер",
  invoiceDate: "Дата выставления счета",
  attachment: "Вложение",
  acceptedAttachments: "Вложение (xlsx, docx, pdf, png, jpeg*)",
  goodName: "Название товара ",
  workName: "Название работы ",
  serviceName: "Название услуги",
  invoicedValue: "Сумма счета",
  kcValue: "Сумма KC",
  goodsSectionUpdated: 'Секция "Товары" обновлена',
  worksSectionUpdated: 'Секция "Работы" обновлена',
  servicesSectionUpdated: 'Секция "Услуги" обнавлена',
  preview: "Просмотр",
  openG1c: "Открыть G1-C",
  g1cFormUpdated: "Данные G1-C формы обновлены",
  setDueDate: "Укажите срок предоставления изменений",
  g1cFormApproved: "G1-C форма подтверждена",
  g1cFormRejected: "G1-C форма отклонена"
};
