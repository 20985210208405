import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "antd";

import type { ContractorTypeSelectorProps } from "./props";

const { Option } = Select;

export const ContractorTypeSelectorTemp: React.FC<
  ContractorTypeSelectorProps
> = ({ selectedContractorType, contractorTypes, onChange, ...rest }) => {
  const [t] = useTranslation();

  const handleSelectChange = useCallback(
    (value) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      showSearch
      value={selectedContractorType}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.props.children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      }
      onChange={handleSelectChange}
      {...rest}
    >
      {contractorTypes.map((item, index) => (
        <Option key={index} value={item.value}>
          <span>{t(item.label)}</span>
        </Option>
      ))}
    </Select>
  );
};
