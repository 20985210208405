import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import numeral from "numeral";

import { useDefaultQuery } from "@hooks";
import { Row, Table, Typography } from "antd";

import { defineScopeOfWorks } from "@components/types/models/Contract";
import type { ForecastSubAgreement } from "@components/types/models/Forecast";
import { defineContractSummaryStatus } from "@components/types/models/Forecast";
import { defineStatus } from "@components/types/models/Statuses";

import { forecastApi } from "@api/forecastApi";
import { actualApi } from "@api/actualApi";

import type { MasterAgreementGwsTableProps } from "./props";

export const MasterAgreementGwsTable: FC<MasterAgreementGwsTableProps> = ({
  forecastId,
  isActual,
  actualId
}) => {
  const { t, i18n } = useTranslation();
  const { Text } = Typography;
  const { Column, Summary } = Table;
  const percentSymbol = "%";
  const usdSymbol = "USD";

  const { data, isLoading, refetch } = useDefaultQuery(
    ["getGwsData", forecastId, actualId],
    async () => {
      if (isActual && actualId) {
        return actualApi.getMasterGws(actualId).then((res) => res.data);
      }
      return (
        forecastId &&
        forecastApi.getMasterGws(forecastId).then((res) => res.data)
      );
    }
  );

  useEffect(() => {
    refetch();
  }, [forecastId, actualId]);

  return isActual ? (
    <Table
      dataSource={data?.actualsGwsSummaryDtos}
      pagination={false}
      scroll={{ x: 2000 }}
      loading={isLoading}
      summary={() => (
        <Summary>
          <Summary.Row>
            <Summary.Cell index={0}>{t("total")}</Summary.Cell>
            <Summary.Cell index={1} />
            <Summary.Cell index={2} />
            <Summary.Cell index={3} />
            <Summary.Cell index={4}>
              {numeral(data?.totalActualValueInUsd).format("0,0.00")}{" "}
              {usdSymbol}
            </Summary.Cell>
            <Summary.Cell index={5} />
            <Summary.Cell index={6}>
              {numeral(data?.totalActualKcValueInUsd).format("0,0.00")}{" "}
              {usdSymbol}
            </Summary.Cell>
            <Summary.Cell index={7}>
              {data?.totalActualKcShare} {percentSymbol}
            </Summary.Cell>
          </Summary.Row>
        </Summary>
      )}
    >
      <Column
        key="contractNumber"
        title={t("forecasts.subAgreementNumber")}
        dataIndex="subAgreementNumber"
      />
      <Column
        key="contractScopes"
        title={t("scopeOfWork")}
        dataIndex="scopeOfWorks"
        render={(scope?: readonly number[]) => (
          <Row>
            {scope?.map((item: number, index) => (
              <Text key={index}>
                {(index ? ", " : "") +
                  (i18n.language === "en"
                    ? defineScopeOfWorks(item).enName
                    : defineScopeOfWorks(item).ruName)}
              </Text>
            ))}
          </Row>
        )}
      />
      <Column
        key="contractStatus"
        title={t("status")}
        dataIndex="status"
        render={(status: number) => (
          <Text>
            {i18n.language === "en"
              ? defineStatus(status).enName
              : defineStatus(status).ruName}
          </Text>
        )}
      />
      <Column
        key="contractStatus"
        title={t("actuals.actualValueInOriginalCurrency")}
        render={(item) => (
          <Text>
            {item?.actualValueOriginalCurrency
              ? `${numeral(item?.actualValueOriginalCurrency).format(
                  "0,0.00"
                )} ${item?.originalCurrency?.code}`
              : "-"}
          </Text>
        )}
      />
      <Column
        title={t("actuals.actualValueInUsd")}
        dataIndex="actualValueInUsd"
        render={(value) => (
          <Text>
            {value ? `${numeral(value).format("0,0.00")} ${usdSymbol}` : "-"}
          </Text>
        )}
      />
      <Column
        title={t("actuals.actualsKcValueInOriginalCurrency")}
        render={(item) => (
          <Text>
            {item?.actualKcValueOriginalCurrency
              ? `${numeral(item?.actualKcValueOriginalCurrency).format(
                  "0,0.00"
                )}
            ${item?.originalCurrency?.code}`
              : "-"}
          </Text>
        )}
      />
      <Column
        title={t("actuals.actualKcValueInUsd")}
        dataIndex="actualKcValueInUsd"
        render={(value: number) => (
          <Text>
            {value ? `${numeral(value).format("0,0.00")} ${usdSymbol}` : "-"}
          </Text>
        )}
      />
      <Column
        title={t("actuals.actualKcShare")}
        dataIndex="actualKcShare"
        render={(value: number) => (
          <Text>{value ? `${value} ${percentSymbol}` : "-"}</Text>
        )}
      />
    </Table>
  ) : (
    <Table
      dataSource={data?.subAgreements}
      pagination={false}
      scroll={{ x: 2000 }}
      loading={isLoading}
      summary={() => (
        <Summary>
          <Summary.Row>
            <Summary.Cell index={0}>{t("total")}</Summary.Cell>
            <Summary.Cell index={1} />
            <Summary.Cell index={2} />
            <Summary.Cell index={3} />
            <Summary.Cell index={4}>
              {numeral(data?.totalForecastInUsd).format("0,0.00")} {usdSymbol}
            </Summary.Cell>
            <Summary.Cell index={5} />
            <Summary.Cell index={6}>
              {numeral(data?.totalForecastKcValueInUsd).format("0,0.00")}{" "}
              {usdSymbol}
            </Summary.Cell>
            <Summary.Cell index={7}>
              {numeral(data?.totalKcShare).format("0,0.00")} {percentSymbol}
            </Summary.Cell>
          </Summary.Row>
        </Summary>
      )}
    >
      <Column
        key="sectionName"
        title={t("forecasts.subAgreementNumber")}
        dataIndex="subAgreementNumber"
      />
      <Column
        key="forecastValue"
        title={t("scopeOfWork")}
        dataIndex="scopeOfWork"
        render={(scope: readonly number[]) => (
          <Row>
            {scope.length > 0
              ? scope.map((item: number, index) => (
                  <Text key={index}>
                    {(index ? ", " : "") +
                      (i18n.language === "en"
                        ? defineScopeOfWorks(item).enName
                        : defineScopeOfWorks(item).ruName)}
                  </Text>
                ))
              : "-"}
          </Row>
        )}
      />
      <Column
        key="kcForecastValue"
        title={t("status")}
        dataIndex="status"
        render={(status: number) => (
          <Text>
            {i18n.language === "en"
              ? defineContractSummaryStatus(status).enName
              : defineContractSummaryStatus(status).ruName}
          </Text>
        )}
      />
      <Column
        title={t("forecasts.forecastValueInOriginalCurrency")}
        render={(item?: ForecastSubAgreement) => (
          <Text>
            {item?.forecastValueOriginCurrency
              ? `${numeral(item.forecastValueOriginCurrency).format(
                  "0,0.00"
                )} ${item.currencyDto?.code}`
              : "-"}
          </Text>
        )}
      />
      <Column
        title={t("forecasts.forecastValueInUsd")}
        dataIndex="forecastValueInUsd"
        render={(value: number) => (
          <Text>
            {value ? `${numeral(value).format("0,0.00")} ${usdSymbol}` : "-"}
          </Text>
        )}
      />
      <Column
        title={t("forecasts.forecastKcValueInOriginalCurrency")}
        render={(item?: ForecastSubAgreement) => (
          <Text>
            {item?.forecastValueOriginCurrency
              ? `${numeral(item.forecastKcValueOriginCurrency).format(
                  "0,0.00"
                )} ${item.currencyDto?.code}`
              : "-"}
          </Text>
        )}
      />
      <Column
        title={t("forecasts.forecastKcValueInUsd")}
        dataIndex="forecastKcValueInUsd"
        render={(value: number) => (
          <Text>
            {value ? `${numeral(value).format("0,0.00")} ${usdSymbol}` : "-"}
          </Text>
        )}
      />
      <Column
        title={t("forecasts.forecastKcShare")}
        dataIndex="forecastKcShare"
        render={(value: number) => (
          <Text>
            {value
              ? `${numeral(value).format("0,0.00")} ${percentSymbol}`
              : "-"}
          </Text>
        )}
      />
    </Table>
  );
};
