import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ColumnType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";

import { Space, Table } from "antd";

import { glossaryData } from "@utils/glossaryData";

export const GlossaryPage: FC = () => {
  const { t, i18n } = useTranslation();

  const columns = useMemo<ColumnType<object>[]>(() => {
    return [
      {
        title: t("glossary.glossary"),
        dataIndex: "title"
      },
      {
        title: t("glossary.descriptionColumn"),
        dataIndex: "description"
      }
    ];
  }, [i18n.language, t]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Title>{t("glossary.glossary")}</Title>
      <Table columns={columns} dataSource={glossaryData} pagination={false} />
    </Space>
  );
};
