import { FC, useContext } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import axios from "axios";
import tw from "twin.macro";
import moment from "moment";

import { CommentCard } from "@components/organisms/CommentCard";
import type { MouCategoryList } from "@components/organisms/ForecastTrainingForm/props";
import { ForecastJobsPerMonth } from "@components/organisms/JobsTraining/libs/ForecastJobsPerMonth";
import { ForecastJobsPerYear } from "@components/organisms/JobsTraining/libs/ForecastJobsPerYear";
import { JobsTrainingTimeline } from "@components/organisms/JobsTraining/libs/JobsTrainingTimeline";
import type { YearItem } from "@components/organisms/JobsTraining/libs/JobsTrainingTimeline/props";
import type { NationalizeListItem } from "@components/organisms/JobsTraining/types";
import { useDefaultQuery } from "@hooks";
import { Button, Col, Divider, notification, Row } from "antd";

import { STATUS } from "@components/types/models/Statuses";
import { SectionType } from "@components/types/models/Forecast";

import { forecastApi } from "@api/forecastApi";

import { useActualForecast } from "@hooks/useActualForecast";
import { UserContext } from "@contexts/userContext";

import type {
  ForecastJobsData,
  ForecastJobsDataItem,
  ForecastJobsFormProps,
  MouCategoryItem,
  MouCategorySubCategory
} from "./props";

const TOTAL_MOU_CATEGORY = "total";
const DEFAULT_MOU_CATEGORY = "heavyEquipmentOperator";
const DEFAULT_CONTRACTOR_MOU_CATEGORY = "engineering";

export const ForecastJobsForm: FC<ForecastJobsFormProps> = ({
  startDate,
  endDate,
  forecastId,
  setActiveCollapseItem,
  activeCollapseItem,
  forecastStatus,
  isEditable
}) => {
  const { t } = useTranslation();
  const { mouCategories } = useActualForecast({});

  const { isAdmin, isContractor } = useContext(UserContext);

  const [isAnnual, setIsAnnual] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState<YearItem>({
    year: 0,
    years: [],
    months: [],
    isDetailed: false
  });
  const [currentMouCategory, setCurrentMouCategory] = useState<
    MouCategoryList | typeof TOTAL_MOU_CATEGORY
  >(TOTAL_MOU_CATEGORY);
  const [isCurrentPeriodChanged, setCurrentPeriodChanged] =
    useState<boolean>(false);
  const isLastCategory = currentPeriod?.isDetailed
    ? currentMouCategory === TOTAL_MOU_CATEGORY
    : currentMouCategory === DEFAULT_MOU_CATEGORY;

  useEffect(() => {
    if (
      !currentPeriod?.isDetailed &&
      forecastStatus === STATUS.PENDING &&
      isContractor
    ) {
      setIsAnnual(true);
    } else {
      setIsAnnual(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod]);

  useEffect(() => {
    if (isContractor) {
      setCurrentMouCategory(DEFAULT_CONTRACTOR_MOU_CATEGORY);
    }

    if (isAdmin) {
      setCurrentMouCategory(TOTAL_MOU_CATEGORY);
    }
  }, [isContractor, isAdmin]);

  const mouCategoryRowHeaders: MouCategorySubCategory = [
    { text: t("forecasts.kzNationals"), title: "kzNationals" },
    {
      text: t("forecasts.nonKzNationals"),
      title: "nonKzNationals"
    },
    {
      text: t("forecasts.total"),
      title: "total"
    },
    {
      text: t("forecasts.plannedToNationalize"),
      title: "plannedToNationalize"
    },
    {
      text: t("forecasts.newGradInternships"),
      title: "newGradInternships"
    },
    { text: t("forecasts.newGradJobs"), title: "newGradJobs" }
  ];

  const { data: initialData, refetch: refetchJobList } = useDefaultQuery<
    readonly ForecastJobsDataItem[]
  >(["getJobsList", forecastId], async () =>
    forecastApi.getForecastJobs(forecastId).then((res) => res.data)
  );

  const switchMouCategory = (): void => {
    const nextCategoryIndex =
      mouCategories.findIndex(
        (category) => category.title === currentMouCategory
      ) + 1;

    setCurrentMouCategory(mouCategories[nextCategoryIndex].title);
  };

  const handleJobTransition = (): void => {
    const hasNextYear = currentPeriod?.years.includes(
      Number(currentPeriod.year) + 1
    );
    let canGetJobs = true;

    if (currentPeriod?.isDetailed) {
      if (hasNextYear && isLastCategory) {
        setCurrentPeriodChanged(!isCurrentPeriodChanged);
      } else if (!hasNextYear && isLastCategory) {
        canGetJobs = false;
        setActiveCollapseItem(activeCollapseItem + 1);
      } else {
        switchMouCategory();
      }
    } else if (isLastCategory) {
      canGetJobs = false;
      setActiveCollapseItem(activeCollapseItem + 1);
    } else {
      switchMouCategory();
    }

    if (canGetJobs) {
      void refetchJobList();
    }
  };

  const addJobsDataMutation = useMutation(
    async (values: ForecastJobsData) => {
      return axios.post(`api/forecasts/job/${forecastId}`, values);
    },
    {
      onSuccess() {
        notification.success({ message: t("saved") });
        handleJobTransition();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const addNationalizeDataMutation = useMutation(
    async (values: unknown | readonly NationalizeListItem[]) =>
      axios.post(`api/forecasts/job/${forecastId}/nationalization`, values),
    {
      onSuccess() {
        notification.success({ message: t("saved") });
      }
    }
  );

  const handleSelectMouCategory = useCallback(
    (category: MouCategoryList | typeof TOTAL_MOU_CATEGORY) => () => {
      void refetchJobList();
      setCurrentMouCategory(category);
    },
    [refetchJobList]
  );

  const getCategoriesList = (): readonly MouCategoryItem[] =>
    currentPeriod?.isDetailed
      ? mouCategories
      : mouCategories.filter(
          (category) => category.title !== TOTAL_MOU_CATEGORY
        );

  const handleSave = (payload: ForecastJobsData): void => {
    if (!isEditable || currentMouCategory === TOTAL_MOU_CATEGORY) {
      handleJobTransition();
      return;
    }

    const categoryJobsData = payload[currentMouCategory];

    const outputPayload = JSON.parse(
      JSON.stringify(Object.values(categoryJobsData))
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ).map((item: any) => {
      mouCategoryRowHeaders.map((category) => {
        if (category.title === TOTAL_MOU_CATEGORY) {
          return false;
        }

        if (item[category.title] === "") {
          item[category.title] = null;
        } else {
          item[category.title] = Number(item[category.title]);
        }
        return category;
      });
      return item;
    });

    addJobsDataMutation.mutate(outputPayload);
  };

  return (
    <Col span={24}>
      <Col
        span={24}
        className={css`
          ${tw`flex`}
        `}
      >
        <JobsTrainingTimeline
          startDateString={startDate}
          endDateString={endDate}
          getCurrentPeriod={(payload): void => {
            setCurrentPeriod(payload);
          }}
          isCurrentPeriodChanged={isCurrentPeriodChanged}
        />
        <Col>
          {currentPeriod?.year && currentPeriod.year > moment().year() && (
            <Row
              gutter={6}
              className={css`
                ${tw`mb-2`}
              `}
            >
              <Col>
                <Button
                  type={isAnnual ? "default" : "primary"}
                  onClick={() => setIsAnnual(false)}
                >
                  {t("months.monthly")}
                </Button>
              </Col>
              <Col>
                <Button
                  type={isAnnual ? "primary" : "default"}
                  onClick={() => setIsAnnual(true)}
                >
                  {t("months.annual")}
                </Button>
              </Col>
            </Row>
          )}

          <Row gutter={6}>
            {getCategoriesList().map((category) => (
              <Col
                key={category.title}
                className={css`
                  ${tw`mb-2`}
                `}
              >
                <Button
                  type={
                    currentMouCategory === category.title
                      ? "primary"
                      : "default"
                  }
                  onClick={handleSelectMouCategory(category.title)}
                >
                  {category.text}
                </Button>
              </Col>
            ))}
          </Row>
        </Col>
      </Col>
      <Divider />

      {currentPeriod?.isDetailed && !isAnnual ? (
        <ForecastJobsPerMonth
          mouCategories={[...mouCategories]}
          initialData={initialData}
          currentPeriod={currentPeriod}
          currentMouCategory={currentMouCategory}
          mouCategoryRowHeaders={mouCategoryRowHeaders}
          addNationalizeDataMutation={addNationalizeDataMutation}
          isEditable={isEditable}
          onSave={handleSave}
          refetchJobs={refetchJobList}
        />
      ) : (
        <ForecastJobsPerYear
          mouCategories={[...mouCategories]}
          initialData={initialData}
          currentPeriod={currentPeriod}
          currentMouCategory={currentMouCategory}
          mouCategoryRowHeaders={mouCategoryRowHeaders}
          isEditable={isEditable && currentMouCategory !== TOTAL_MOU_CATEGORY}
          onSave={handleSave}
          refetchJobs={refetchJobList}
        />
      )}

      <CommentCard
        id={forecastId}
        sectionType={SectionType.JobAndNationalization}
        status={forecastStatus}
      />
    </Col>
  );
};
