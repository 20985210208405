export const isUserHasPermission = (
  userPermissions: readonly number[] | undefined,
  permission: number
): boolean | undefined => userPermissions?.includes(permission);

export const isUserHasPermissions = (
  userPermissions: readonly number[] | undefined,
  permissions: readonly number[]
): boolean | undefined =>
  permissions.every((p) => userPermissions?.includes(p));
