import type { FC } from "react";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Space, Tag } from "antd";

import { defineStatus } from "@components/types/models/Statuses";

import type { ContractStatusProps } from "./props";

export const ContractStatus: FC<ContractStatusProps> = ({ statusNumber }) => {
  const [, i18n] = useTranslation();

  const status = defineStatus(statusNumber);

  return (
    <Space size="middle">
      <Tag color={status.color}>
        {i18n.language === "en" ? status.enName : status.ruName}
      </Tag>
    </Space>
  );
};
