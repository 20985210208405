import React, { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { stringify } from "qs";

import { AcceptInvitePage } from "@components/pages/AcceptInvitePage";
import { ExpiredPasswordPage } from "@components/pages/ExpiredPasswordPage";
import { ExpiredTokenPage } from "@components/pages/ExpiredTokenPage";
import { Home } from "@components/pages/Home";
import { LoginPage } from "@components/pages/LoginPage";
import { ResetPassword } from "@components/pages/ResetPassword";
import { ResetPasswordTokenPage } from "@components/pages/ResetPasswordTokenPage";
import SsoCallbackPage from "@components/pages/SsoCallbackPage";
import { PublicLayout } from "@components/templates/PublicLayout";

import { UserContext } from "../contexts/userContext";

const guestRoutes = [
  {
    id: "index",
    path: "/",
    component: Home
  },
  {
    id: "login",
    path: "/login",
    component: LoginPage
  },
  {
    id: "resetPassword",
    path: "/reset",
    component: ResetPassword
  },
  {
    id: "invite",
    path: "/invite",
    component: AcceptInvitePage
  },
  {
    id: "sso-callback",
    path: "/sso-callback",
    component: SsoCallbackPage
  },
  {
    id: "resetPasswordToken",
    path: "/auth/password/reset/:token",
    component: ResetPasswordTokenPage
  },
  {
    id: "expiredToken",
    path: "/expired-token",
    component: ExpiredTokenPage
  },
  {
    id: "expiredPassword",
    path: "/expired-password",
    component: ExpiredPasswordPage
  }
];

const GuestRoutes: React.FC = () => {
  const { isUserLoggedIn } = useContext(UserContext);
  const location = useLocation();
  const redirectTo = location.pathname;

  return (
    <PublicLayout>
      <Switch>
        {guestRoutes.map((route, i) => (
          <Route key={i} exact path={route.path} component={route.component} />
        ))}
        {!isUserLoggedIn && (
          <Route
            path="/"
            render={() => (
              <Redirect
                to={{
                  pathname: "/login",
                  search: stringify({ redirectTo })
                }}
              />
            )}
          />
        )}
      </Switch>
    </PublicLayout>
  );
};

export default GuestRoutes;
