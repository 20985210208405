export const actuals = [
  {
    title: "Как создать фактические показатели?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие:</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"> Руководитель КС утвердил ваш контракт</span></span></span></p>
<p>&nbsp;</p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: В системе существует три типа контракта - Отдельное соглашение, Дополнительное соглашение и Генеральное соглашение (Каждый контракт различается секциями для заполнения в прогнозах)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите утвержденный контракт с выпадающего списка &quot;Контракт&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите месяц сдачи фактических показателей для выбранного контракта</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные во всех секциях фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/3FhRzFBhhkh-Nk9ZcW07SdtQfw7Ufzfaw3a5u87Sk3OaOjKyi1zVEDZGT7AV96WyokckTSCR-cBdZSvm9F_dZ4kcMWb7pfTpxrHtxECrVgF0QS6a-xr31YL1z3vqIAwTaU2X7RvAogNgsfv6FRV5FA6c5RGVCWpemi1rTH97dt_LgPoX1jWfz1GgOg" style="height:487px; width:559px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить товары, работы и услуги в фактические показатели?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги на детальной странице фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+&quot; расположенной после поля &quot;Описание товара/работ/услуг&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите название товара/работы/услуги в поле &quot;Название&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите добавленный товар/работу/услугу в выпадающем списке &quot;Описание товара/работ/услуг&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+Добавить товар/работы/услуги&quot; если желаете добавить больше</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/hwcU_sy5eNMrsDgq3IO6Tt3djatEcNYFjDzEvpSUcyMTW-opY5ABhgDM4iqJcvnJwXLzBevAmoiCjv1y-Ne1mcNdhcD0i-39YSObVrNKK4Kh7CFFqLD-m_W2ARaGTnmUOQzon7Lv3V5EO9b2A8E4oZfKAZPQP6_v_uAzRUMEAq5kpsLiTNLXmimvgg" style="height:480px; width:496px" /></span></span></span></p>
`
  },
  {
    title: "Как удалить добавленные товары, работы и услуги?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Предварительное условие</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: Руководитель КС утвердил ваш контракт</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка&nbsp;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги на детальной странице фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите ранее добавленные товары/работы/услуги и прокрутите вправо секции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;X&quot; чтобы удалить ненужные товары/работы/услуги</span></span></span></li>
</ol>
<p>&nbsp;</p>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/Ek9lKCEiOHToHzNgFPrFxhwQouseLQpe36Wn3szPCKXTe0ooFbaHH04mJ1UEgDpCo6v4pxgsnJr5FK24RgACXuLB3OKcXeRU_Uf3mrwRCaL9P3A73qTMFdTzCjlFVhcvvCD5eZLoS1SersXhnV_LuI0ZuQVgfF1U3avyG2H6Gou4iw8gz1IEn8QViw" style="height:201px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить компанию (субподрядчика) в фактические показатели?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка&nbsp;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги на детальной странице фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+&quot; расположенной после поля &quot;Название компании&quot;:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите название Компании</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите страну из выпадающего списка Страна</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите город из выпадающего списка Город</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите БИН (если Казахстан выбран как страна)</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите описание объема работ</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Добавить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите название компании в выпадающем списке Название Компании</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh5.googleusercontent.com/9P30m8uvGm5-ledxvzusoiKEMKDna-e-3hVuI0JgVHYk_6S34OtxpKWcBO4IyEA4rTWgOyuambpVpfQdSCUnLUMDbHponMPjF4O9SCey-Y1ugS7mTm0RxV9rh2Wz6-PFmAmIJ1X-DGm6D6I0arqud3l0-7JkB40JDt-1iwryuvZXPNXczD1VMZFSeA" style="height:473px; width:499px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить CT-KZ сертификат в фактические показатели?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: ТРУ данные могут быть добавлены только в Отдельное и Дополнительное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка&nbsp;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию Товары на детальной странице фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+&quot; расположенной после поля &quot;CT-KZ сертификат #&quot;:</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите Номер сертификата</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите Дату окончания срока сертификата</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Добавьте сертификат (формат файла должен быть .pdf .jpg .jpeg .png)</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Подтвердите Подлинность сертификата</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите товар из выпадающего списка Название товара для сертификата ИЛИ</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Добавьте товар нажатием на кнопку &quot;+&quot; расположенной после поля Название товара</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите CT-KZ% для добавленного товара</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;+ Добавить позицию&quot; чтобы добавить больше товаров</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите добавленный сертификат в выпадающем списке CT-KZ сертификат #</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/oVVjEXrv6U32TZbR2EOQNPrW-Dlz2CGZwmUAYtCdM82SFigyU0CeY_Sj3_cJOPmS_s5Ijn2WP4fRzUtu5U0IC10zGWS955rVYIQHltWY0_fARAxDCJZm7pgEPI84dtq7fkMJNzvPmDxKyWctQySdwnZkkqm0EaR6SpHscpU_1gDBDCQ5TH503FWnhw" style="height:396px; width:476px" /></span></span></span></p>
`
  },
  {
    title: "Как скачать CT-KZ сертификат?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: CT-KZ сертификат был добавлен в систему</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot;, &quot;Отклонен&quot;, &quot;Утвержден&quot; или &quot;На рассмотрении&quot; из списка&nbsp;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию Товары на детальной странице фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Найдите необходимый товар и прокрутите вправо пока не увидите поле CT-KZ сертификат #</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Скачать&quot; расположенная перед номером загруженного сертификата</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/8awSvpW0Hn9Cj31I6oUmnEVrYxxNBNttecwxS1FgWpOkJVC3rTHhocSsIE_EBywdEDAePYjSBPp-L0zSDVH4m5CGeybsE1KUw3z8b_IKxfd7_73tc0MiPGP94dUuZ0bg1VSy17KpgmEUkPkA5omYUitmZScz7ivDx1NyIbR4Xrih263_ATqc0nb4jA" style="height:176px; width:624px" /></span></span></span></p>
`
  },
  {
    title: "Как заполнить данные предыдущими?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: Фактические показатели за предыдущие месяца должны быть утверждены</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Работы/Услуги/Количество сотрудников и национализация</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Заполнить&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/tGhdQmcHagUebajDbUDcmNAR9Cqal-70fBxENoIK_qtWvYihmYpsS4UlZesQ3Do3zGsDcDN13nenroPwQI8GhaZ9u8MiUNtesIN-SD2xkFNmhpjS872KMmEmbp9ay_cf0fO8nkh9pybL9HKR7PXrL0npD_1jlhsByxdfOGJrPa_iIEn5ULFoDBd21g" style="height:192px; width:589px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить количество сотрудников и национализацию?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: Данные количество сотрудников и национализация могут быть добавлены только в Отдельное и Генеральное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию Количество сотрудников и национализация на детальной странице</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Редактировать города&quot; (можно выбрать больше одного города):</span></span></span>
\t<ol>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите страну из выпадающего списка &quot;Страна&quot;</span></span></span></li>
\t\t<li style="list-style-type:lower-alpha"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите город из выпадающего списка &quot;Город&quot;</span></span></span></li>
\t</ol>
\t</li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Применить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Прикрепите файл при необходимости нажав на кнопку &quot;Прикрепить файл&quot; (Не больше 3 файлов)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить и перейти к итогу&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh4.googleusercontent.com/LhGucNy6JiorJk5kY4i5sKXNI97azGDwUtz20hyacDOz1KwBjJN1pE8bKM7U6AqGkZI1qc4a2WkUlg7sZ8JBQ3nmveV9bTiLmYMgeN80uh9jhYaIgOEcbXaSpPIFsmrT7iLGEV_BZ72fbh3hWRjd2Jdc1N66upICSMjG8-fnmwKy_6MDcfsy3Q4-OA" style="height:472px; width:406px" /></span></span></span></p>
`
  },
  {
    title: "Как добавить данные обучений в фактические показатели?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: Данные обучений могут быть добавлены только в Отдельное и Генеральное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секцию Обучение на детальной странице</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые поля</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить и перейти к следующей секции&quot;</span></span></span></li>
</ol>
`
  },
  {
    title:
      "Как добавить информацию передачи технологии, наследия и истории успеха?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: Данные передачи технологии, наследия и истории успеха могут быть добавлены только в Отдельное и Генеральное соглашение</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Добавьте секции Передача технологии/Наследие/История успеха на детальной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте добавленные секции</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите необходимую информацию в текстовое поле</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Прикрепите файл при необходимости нажав на кнопку &quot;Прикрепить файл&quot; (Не больше 3 файлов)</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Удалите секции при необходимости нажатием на кнопку &quot;Удалить секцию&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить и перейти к следующей секции&quot;</span></span></span></li>
</ol>
<p style="text-align:center"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh3.googleusercontent.com/fqz8mQSrbFpULSMzz6Z2usJm4WC7EQiGjSzZbnTFBfKkuRJlSxLhCkpi5MPiZCrg0uA5WAYvTsIIUFQ6y7uhjGK1l87UCqOsR7Kp4L7XOMnC_GNbo2cAL0JqEdLcFiTMhFPNR2CKiOb9iQtVJ4GQQkN-Qf97Xw8Izg7PZVhxT6o2X_MU9fnjXfb_3g" style="height:247px; width:561px" /></span></span></span></p>
`
  },
  {
    title: "Как оставить комментарий в секциях фактических показателей?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: В системе существует три типа контракта - Отдельное соглашение, Дополнительное соглашение и Генеральное соглашение (Каждый контракт различается секциями для заполнения в прогнозах)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Раскройте секции Товары/Товары/Услуги/Количество сотрудников и национализация/Обучение/Передача технологии/Наследие/История успеха</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Оставить комментарий&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите комментарий в текстовое поле</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Сохранить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как отправить фактические показатели на рассмотрение?",
    html: `<p><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Примечание</strong></span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">: В системе существует три типа контракта - Отдельное соглашение, Дополнительное соглашение и Генеральное соглашение (Каждый контракт различается секциями для заполнения в прогнозах)</span></span></span></p>
<p>&nbsp;</p>
<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить новые фактические показатели&quot; на главной странице ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; или &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Заполните все необходимые данные по секциям</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения фактических показателей Руководителем КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как обновить утвержденные фактические показатели?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Утвержден&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Откройте детальную страницу и нажмите на кнопку &quot;Обновить&quot;</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Введите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения для Руководителя КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
`
  },
  {
    title: "Как восстановить предыдущую версию?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку</span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong> &quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Утвержден&quot; и нажмите на кнопку &quot;Обновить&quot; ИЛИ</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Черновик&quot; (версия &gt; 1) из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Восстановить предыдущую версию&quot; на странице фактических показателей со статусом Черновик (версия &gt; 1)</span></span></span></li>
</ol>
`
  },
  {
    title: "Как обновить отклоненные фактические показатели?",
    html: `<ol>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Щелкните вкладку </span></span></span><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000"><strong>&quot;Фактические показатели&quot;</strong></span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Выберите фактические показатели со статусом &quot;Отклонен&quot; из списка</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Откройте детальную страницу отклоненных фактических показателей</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Внесите необходимые изменения</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Установите срок рассмотрения фактических показателей Руководителем КС</span></span></span></li>
\t<li style="list-style-type:decimal"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:#000000">Нажмите на кнопку &quot;Отправить&quot;</span></span></span></li>
</ol>
`
  }
];

export default actuals;
