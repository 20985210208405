import type { FC } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import { Badge, Collapse } from "antd";
import { useDefaultQuery } from "@hooks";

import type { UnitsOfMeasure } from "@components/types/models/UnitsOfMeasure";
import { SectionType } from "@components/types/models/Forecast";
import type { CompanyType } from "@components/types/models/Forecast";
import { SCOPE_OF_WORK } from "@components/types/models/Contract";
import { CommentCard } from "../CommentCard";
import { GoodsForm } from "../GoodsForm";
import { GwsSummaryTable } from "../GwsSummaryTable";
import { ServiceForm } from "../ServicesForm";
import { WorksForm } from "../WorksForm";

import { unitsOfMeasureApi } from "@api/unitsOfMeasureApi";
import { companyApi } from "@api/companyApi";

import { UserContext } from "@contexts/userContext";

import { GwsTotalTable } from "./libs/GwsTotalTable";
import { Timeline } from "./libs/Timeline";
import type { ForecastGwsProps } from "./props";

const UNSET_MONTH = 0;

export const ForecastGws: FC<ForecastGwsProps> = ({
  scopes,
  startDateString,
  endDateString,
  forecastId,
  contractCurrency,
  forecastStatus,
  isEditable,
  getCommentsBadge,
  activeCollapseItem,
  ...rest
}) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const badgeMargin = 16;
  const [period, setPeriod] = useState<Record<string, readonly number[]>>();
  const [selectedYear, setSelectedYear] = useState<number>(
    moment(startDateString).year()
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    moment(startDateString).month() + 1
  );

  const { user } = useContext(UserContext);

  const startDate = moment(startDateString, "YYYY-M-DD");
  const endDate = moment(endDateString, "YYYY-M-DD");

  const lastMonthNumOfYear = useMemo(() => {
    const lastIndex = period?.[`${selectedYear}`]?.length ?? 0;
    return lastIndex === 0
      ? global.undefined
      : period?.[`${selectedYear}`]?.[lastIndex - 1];
  }, [selectedYear, period]);

  const lastYear = moment(endDateString).year();
  const firstYear = moment(startDateString).year();

  const onProceedClick = useCallback(() => {
    if (selectedMonth !== UNSET_MONTH && selectedMonth !== lastMonthNumOfYear) {
      setSelectedMonth(selectedMonth && selectedMonth + 1);
    } else if (selectedMonth === lastMonthNumOfYear) {
      setSelectedMonth(UNSET_MONTH);
    } else if (
      selectedMonth === UNSET_MONTH &&
      selectedYear !== Number(lastYear)
    ) {
      setSelectedYear(selectedYear + 1);
    } else if (selectedYear === Number(lastYear)) {
      setSelectedYear(UNSET_MONTH);
    }
  }, [selectedMonth, selectedYear, lastMonthNumOfYear, lastYear]);

  useEffect(() => {
    const allMonthsInPeriod: Record<string, number[]> = {};
    while (startDate.isBefore(endDate)) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (allMonthsInPeriod[startDate.get("year")]) {
        allMonthsInPeriod[startDate.get("year")].push(
          parseInt(startDate.format("M"))
        );
      } else {
        allMonthsInPeriod[startDate.get("year")] = [
          parseInt(startDate.format("M"))
        ];
      }
      startDate.add(1, "month");
    }
    setPeriod(allMonthsInPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateString, endDateString]);

  useEffect(() => {
    setSelectedYear(moment(startDateString).year());
    setSelectedMonth(moment(startDateString).month() + 1);
  }, [activeCollapseItem, startDateString]);

  const { data: unitsOfMeasure } = useDefaultQuery<readonly UnitsOfMeasure[]>(
    "getUnitsOfMeasure",
    async () => unitsOfMeasureApi.getUnitsOfMeasure().then((res) => res.data)
  );

  const { data: companiesList, refetch: refetchCompaniesList } =
    useDefaultQuery<readonly CompanyType[]>(
      "getCompaniesList",
      async () =>
        user?.userRoles[0].entityId &&
        companyApi
          .getContractorCompaniesList(user.userRoles[0].entityId)
          .then((res) => res.data)
    );

  const handleResetTime = (): void => {
    setSelectedYear(moment(startDateString).year());
    setSelectedMonth(moment(startDateString).month() + 1);
  };

  return (
    <Collapse {...rest} onChange={handleResetTime}>
      {scopes?.includes(SCOPE_OF_WORK.GOODS) && (
        <Panel
          key="1"
          header={
            <Badge
              offset={[badgeMargin, 0]}
              count={getCommentsBadge(SectionType.Good)}
            >
              {t("forecasts.goods")}
            </Badge>
          }
        >
          <Timeline
            forecastStatus={forecastStatus}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            period={period}
            lastYear={lastYear}
            firstYear={firstYear}
          />
          {selectedYear === 0 ? (
            <GwsTotalTable
              forecastId={forecastId}
              sectionType="goods"
              currency={contractCurrency}
            />
          ) : (
            <GoodsForm
              forecastId={forecastId}
              currency={contractCurrency}
              isEditable={isEditable}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              handleProceed={onProceedClick}
              companiesList={companiesList}
              refetchCompaniesList={refetchCompaniesList}
              unitsOfMeasure={unitsOfMeasure}
            />
          )}
          <CommentCard
            id={forecastId}
            sectionType={SectionType.Good}
            status={forecastStatus}
          />
        </Panel>
      )}
      {scopes?.includes(SCOPE_OF_WORK.WORKS) && (
        <Panel
          key="2"
          header={
            <Badge
              offset={[badgeMargin, 0]}
              count={getCommentsBadge(SectionType.Work)}
            >
              {t("forecasts.works")}
            </Badge>
          }
        >
          <Timeline
            forecastStatus={forecastStatus}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            period={period}
            lastYear={lastYear}
            firstYear={firstYear}
          />
          {selectedYear === 0 ? (
            <GwsTotalTable
              forecastId={forecastId}
              sectionType="works"
              currency={contractCurrency}
            />
          ) : (
            <WorksForm
              forecastId={forecastId}
              currency={contractCurrency}
              isEditable={isEditable}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              handleProceed={onProceedClick}
              companiesList={companiesList}
              refetchCompaniesList={refetchCompaniesList}
            />
          )}
          <CommentCard
            id={forecastId}
            sectionType={SectionType.Work}
            status={forecastStatus}
          />
        </Panel>
      )}
      {scopes?.includes(SCOPE_OF_WORK.SERVICES) && (
        <Panel
          key="3"
          header={
            <Badge
              offset={[badgeMargin, 0]}
              count={getCommentsBadge(SectionType.Service)}
            >
              {t("forecasts.services")}
            </Badge>
          }
        >
          <Timeline
            forecastStatus={forecastStatus}
            lastYear={lastYear}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            period={period}
            firstYear={firstYear}
          />
          {selectedYear === 0 ? (
            <GwsTotalTable
              forecastId={forecastId}
              sectionType="services"
              currency={contractCurrency}
            />
          ) : (
            <ServiceForm
              forecastId={forecastId}
              currency={contractCurrency}
              isEditable={isEditable}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              handleProceed={onProceedClick}
              companiesList={companiesList}
              refetchCompaniesList={refetchCompaniesList}
            />
          )}
          <CommentCard
            id={forecastId}
            sectionType={SectionType.Service}
            status={forecastStatus}
          />
        </Panel>
      )}
      <Panel key="4" header={t("forecasts.goodsWorksServicesSummary")}>
        <Timeline
          forecastStatus={forecastStatus}
          lastYear={lastYear}
          firstYear={firstYear}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
          period={period}
        />
        <GwsSummaryTable
          forecastId={forecastId}
          currency={contractCurrency}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      </Panel>
    </Collapse>
  );
};
