import type { AxiosResponse } from "axios";
import axios from "axios";
import { stringify } from "qs";

import { EditSubcontractorFormValues } from "@components/organisms/EditSubcontractorModal/props";

import type { ActualsListResponse } from "@components/types/models/Actuals";
import type { ContractorCertificate } from "@components/types/models/Certificate";
import type {
  Contractor,
  ContractorEdit,
  ContractorPaginated
} from "@components/types/models/Contractor";
import type { InvoicesListResponse } from "@components/types/models/Invoices";
import type { Member } from "@components/types/models/Member";
import { Subcontractor } from "@components/types/models/Subcontractor";

const getContractorsPaginated = async (
  page: number,
  bin?: string,
  name?: string,
  categories?: readonly number[]
): Promise<AxiosResponse> =>
  axios.get<ContractorPaginated>(`/api/contractor/paginated`, {
    params: { page, bin, name, kcCategories: categories },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

const getContractors = async (): Promise<
  AxiosResponse<readonly Contractor[]>
> => axios.get<readonly Contractor[]>(`/api/contractor`);

const getContractorMembers = async (
  contractorId?: number | null
): Promise<AxiosResponse<readonly Member[] | undefined>> =>
  axios.get<readonly Member[]>(`/api/contractor/${contractorId}/member`);

const getContractorSubcontractors = async (
  page: number,
  pageSize: number,
  contractorId?: number | null,
  bin?: string,
  name?: string
): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/subcontractor/paginated`, {
    params: {
      Page: page,
      PageSize: pageSize,
      Bin: bin,
      Name: name
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

const createContractor = async (): Promise<AxiosResponse> =>
  axios.post("/api/contractor/");

const deleteContractor = async (id: number): Promise<AxiosResponse> =>
  axios.delete(`/api/contractor/${id}`);

const activateContractorMember = async (
  contractorId: number,
  memberId: number
): Promise<AxiosResponse> =>
  axios.get(`api/admin/member/${memberId}/contractor/${contractorId}/activate`);

const editContractor = async ({
  id,
  payload
}: {
  readonly id: number;
  readonly payload: ContractorEdit;
}): Promise<AxiosResponse<unknown>> =>
  axios.put(`/api/contractor/${id}`, payload);

const editSubcontractor = async ({
  contractorId,
  subcontractorId,
  payload
}: {
  readonly contractorId?: number;
  readonly subcontractorId?: number;
  readonly payload: EditSubcontractorFormValues;
}): Promise<AxiosResponse<unknown>> =>
  axios.put(
    `/api/contractor/${contractorId}/subcontractor/${subcontractorId}`,
    payload
  );

const getContractorData = async (
  id: number | null | undefined
): Promise<AxiosResponse> => axios.get(`/api/contractor/${id}`);

const deleteContractorMember = async (
  contractorId: number,
  memberId: number
): Promise<AxiosResponse> =>
  axios.delete(`/api/contractor/${contractorId}/member/${memberId}`);

const resendInviteMember = async (
  contractorId: number,
  email: string
): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/member/invite/resend`, {
    params: {
      email
    }
  });

const deleteInviteMember = async (
  contractorId: number,
  email: string
): Promise<AxiosResponse> =>
  axios.delete(`/api/contractor/${contractorId}/member/invite/delete`, {
    params: {
      email
    }
  });

const getCategories = async (): Promise<AxiosResponse> =>
  axios.get(`/api/static-data/kc/categories`);

const getContractorSingleMember = async (
  contractorId: number,
  userId: number | null
): Promise<AxiosResponse> =>
  axios.get(`/api/contractor/${contractorId}/member/${userId}`);

const getForecastsPaginated = async (
  page: number,
  pageSize: number,
  contractNumber?: number | string,
  forecastStatus?: number
): Promise<AxiosResponse> =>
  axios.get(`/api/contract/contractor/approved`, {
    params: {
      ContractNumber: contractNumber,
      ApprovalStatus: forecastStatus,
      Page: page,
      PageSize: pageSize,
      SortBy: 12
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

const getActualsPaginated = async (
  page: number,
  pageSize: number,
  sortType: number,
  status?: number,
  year?: number,
  month?: number
): Promise<
  AxiosResponse<ActualsListResponse>
  // eslint-disable-next-line max-params
> =>
  axios.get(`/api/actual`, {
    params: {
      Status: status,
      Page: page,
      PageSize: pageSize,
      Year: year,
      Month: month,
      OrderingVector: sortType
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

const getContractorCertificates = async (
  contractorId: number
): Promise<AxiosResponse<readonly ContractorCertificate[]>> =>
  axios.get<readonly ContractorCertificate[]>(
    `/api/certificate/contractor/${contractorId}`
  );

const getContractorInvoices = async (
  page: number,
  pageSize: number,
  status?: readonly number[],
  invoiceNumber?: string,
  dateStart?: string,
  dateEnd?: string
): Promise<AxiosResponse<InvoicesListResponse>> =>
  axios.get(`/api/invoices`, {
    params: {
      Statuses: status,
      Page: page,
      PageSize: pageSize,
      DateStart: dateStart,
      DateEnd: dateEnd,
      InvoiceNumberOrdNull: invoiceNumber
    },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

const getSubcontractorProfile = async (
  contractorId: number,
  companyId: string
): Promise<AxiosResponse<Subcontractor>> =>
  axios.get<Subcontractor>(
    `/api/contractor/${contractorId}/subcontractor/${companyId}`
  );

const changeSubcontractorReviewStatus = async ({
  contractorId,
  companyId
}: {
  contractorId: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  axios.put(
    `/api/contractor/${contractorId}/subcontractor/${companyId}/review`
  );

export const contractorApi = {
  getContractorsPaginated,
  getContractors,
  deleteContractor,
  editContractor,
  editSubcontractor,
  getContractorData,
  getContractorMembers,
  deleteContractorMember,
  resendInviteMember,
  deleteInviteMember,
  getCategories,
  getContractorSingleMember,
  createContractor,
  activateContractorMember,
  getForecastsPaginated,
  getContractorSubcontractors,
  getActualsPaginated,
  getContractorCertificates,
  getContractorInvoices,
  getSubcontractorProfile,
  changeSubcontractorReviewStatus
};
