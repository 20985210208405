import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { Moment } from "moment";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { invoiceStatuses } from "@utils/statuses";

import { InvoicesStatusSelector } from "./InvoicesStatusSelector";
import type { SearchQueryType } from "./libs/types/SearchQueryType";
import type { SearchSectionProps } from "./props";

export const SearchSection: FC<SearchSectionProps> = ({ onSearchQuery }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  useEffect(() => {
    onSearchQuery(searchQuery);
  }, [onSearchQuery, searchQuery]);

  const handleOnFinish = useCallback(
    (vals: {
      readonly invoicesStatus: readonly number[];
      readonly invoiceNumber: string;
      readonly dateStart: Moment;
      readonly dateEnd: Moment;
    }) => {
      setSearchQuery({
        status: vals.invoicesStatus,
        invoiceNumber: vals.invoiceNumber,
        dateStart: vals.dateStart,
        dateEnd: vals.dateEnd
      });
    },
    []
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item name="dateStart" label={t("invoices.g1cDateFrom")}>
            <DatePicker
              picker="month"
              format="YYYY-MM-01"
              className={css`
                ${tw`w-full`}
              `}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="dateEnd" label={t("invoices.g1cDateTo")}>
            <DatePicker
              picker="month"
              format="YYYY-MM-01"
              className={css`
                ${tw`w-full`}
              `}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="invoiceNumber" label={t("invoices.invoiceNumber")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="invoicesStatus" label={t("contracts.status")}>
            <InvoicesStatusSelector statuses={invoiceStatuses} />
          </Form.Item>
        </Col>
        <Col span={3} style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {t("forecasts.applyFilters")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
