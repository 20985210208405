import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import axios from "axios";
import type { AxiosError } from "axios";
import { css } from "@linaria/core";
import tw from "twin.macro";

import { Button, Col, Input, message, notification, Row, Spin } from "antd";
import i18n from "@i18n/i18n";
import type { ForecastTrainingDataItem } from "@components/organisms/ForecastTrainingForm/props";
import type { MouCategoryItem } from "@components/organisms/ForecastJobsForm/props";
import { CommentCard } from "@components/organisms/CommentCard";
import { grey, red } from "@ant-design/colors";

import { SectionType } from "@components/types/models/Forecast";

import { actualApi } from "@api/actualApi";

import type { ActualTrainingFormProps, ActualTrainingFormState } from "./props";

export const ActualTrainingForm: FC<ActualTrainingFormProps> = ({
  isEditable,
  forecastData = [],
  period,
  actualId,
  refetchPeriodList,
  activeCollapseItem,
  setActiveCollapseItem
}) => {
  const { t } = useTranslation();
  const [periodForecastData, setPeriodForecastData] = useState<
    ForecastTrainingDataItem | null | undefined
  >(null);
  const [trainingForm, setTrainingForm] = useState<ActualTrainingFormState>({
    engineering: "",
    supervisorAndForeman: "",
    administration: "",
    craft: "",
    heavyEquipmentOperator: ""
  });
  const [errors, setErrors] = useState<readonly string[]>([]);
  const greyIndex = 10;

  const mouCategories: readonly MouCategoryItem[] = [
    {
      text: t("forecasts.mouCategory.engineering"),
      title: "engineering",
      value: 0
    },
    {
      text: t("forecasts.mouCategory.supervisorForeman"),
      title: "supervisorAndForeman",
      value: 1
    },
    {
      text: t("forecasts.mouCategory.administration"),
      title: "administration",
      value: 2
    },
    { text: t("forecasts.mouCategory.craft"), title: "craft", value: 3 },
    {
      text: t("forecasts.mouCategory.heavyEquipment"),
      title: "heavyEquipmentOperator",
      value: 4
    },
    { text: t("forecasts.mouCategory.total"), title: "total", value: 5 }
  ];

  const {
    data: initialTrainingData,
    refetch: refetchInitialTrainingData,
    isFetching: isInitialTrainingDataLoading,
    isSuccess: isInitialTrainingDataSuccess
    // IsError: hasInitialTrainingDataError
  } = useQuery(
    "getActualTrainingData",
    async () => actualApi.getActualTraining(actualId).then((res) => res.data),
    {
      enabled: false
    }
  );

  const addTrainingDataMutation = useMutation(
    async (values: ActualTrainingFormState) =>
      axios.put(`api/actual/${actualId}/training`, values),
    {
      onSuccess() {
        notification.success({ message: t("saved") });
        if (!period?.approvalStatus) {
          void refetchPeriodList?.();
        }
      },
      onError(error: AxiosError) {
        const validationErrors: ReadonlyArray<{ readonly name: string }> =
          error.response?.data?.validationErrors ?? [];

        if (validationErrors.length) {
          const [firstError] = validationErrors;
          const errorMouCategory = mouCategories.find(
            (mouCategory) => mouCategory.title === firstError.name
          );

          void message.error(
            t("actuals.trainingError", {
              mouCategory: errorMouCategory?.text
            })
          );

          setErrors(validationErrors.map((err) => err.name));
        } else {
          notification.error({ message: error.response?.data?.message });
        }
      }
    }
  );

  const forecastTotal = useMemo(
    () =>
      mouCategories.reduce(
        (sum, item) => sum + Number(periodForecastData?.[item.title] ?? 0),
        0
      ),
    [periodForecastData]
  );

  const actualTotal = useMemo(
    () =>
      mouCategories.reduce(
        (sum, item) => sum + Number(trainingForm[item.title] ?? 0),
        0
      ),
    [trainingForm]
  );

  const saveText = useMemo(
    () =>
      isEditable
        ? t("actuals.saveAndGoToTheNextSection")
        : t("actuals.proceedAndGoToTheNextSection"),
    [i18n.language]
  );

  const setTrainingValue = (title: string, value: number | string): void => {
    const outputPayload = {
      ...trainingForm,
      [title]: value
    };

    setTrainingForm(outputPayload);
  };

  const handleChange = useCallback(
    (title: string) => (event: React.FormEvent<HTMLInputElement>) => {
      setTrainingValue(title, event.currentTarget.value);
    },
    [setTrainingValue]
  );

  const handleSave = useCallback(() => {
    if (!isEditable) {
      setActiveCollapseItem?.((activeCollapseItem ?? 0) + 1);
      return;
    }

    const outputPayload: ActualTrainingFormState = {
      administration:
        trainingForm.administration === ""
          ? null
          : Number(trainingForm.administration),
      craft: trainingForm.craft === "" ? null : Number(trainingForm.craft),
      engineering:
        trainingForm.engineering === ""
          ? null
          : Number(trainingForm.engineering),
      heavyEquipmentOperator:
        trainingForm.heavyEquipmentOperator === ""
          ? null
          : Number(trainingForm.heavyEquipmentOperator),
      supervisorAndForeman:
        trainingForm.supervisorAndForeman === ""
          ? null
          : Number(trainingForm.supervisorAndForeman)
    };

    addTrainingDataMutation.mutate(outputPayload);
  }, [trainingForm]);

  useEffect(() => {
    if (forecastData.length) {
      const currentPeriodData = forecastData.find(
        (item: ForecastTrainingDataItem) =>
          item.year === period?.year && item.month === period?.month
      );
      setPeriodForecastData(currentPeriodData);
    }
  }, [forecastData, period]);

  useEffect(() => {
    void refetchInitialTrainingData();
  }, []);

  useEffect(() => {
    if (initialTrainingData) {
      setTrainingForm(initialTrainingData);
    }
  }, [initialTrainingData]);

  useEffect(() => {
    if (addTrainingDataMutation.isSuccess && activeCollapseItem) {
      setActiveCollapseItem?.(activeCollapseItem + 1);
    }
  }, [addTrainingDataMutation.isSuccess]);

  return (
    <>
      {isInitialTrainingDataLoading && (
        <Col span={24}>
          <Row justify="center">
            <Spin />
          </Row>
        </Col>
      )}
      {isInitialTrainingDataSuccess && (
        <>
          <Col span={24}>
            <Row
              justify="space-between"
              align="middle"
              className={css`
                ${tw`py-4 text-center border-b`}
              `}
            >
              <Col span={3} />
              {mouCategories.map((mouCategory) => (
                <Col key={mouCategory.title} span={3}>
                  {mouCategory.text}
                </Col>
              ))}
            </Row>
            <Row
              justify="space-between"
              className={css`
                ${tw`py-4 text-center`}
              `}
            >
              <Col span={3}>{t("forecast")}</Col>
              {mouCategories.map((mouCategory) => (
                <Col key={mouCategory.title} span={3}>
                  {mouCategory.title === "total"
                    ? forecastTotal
                    : periodForecastData?.[mouCategory.title] ?? 0}
                </Col>
              ))}
            </Row>

            <Row
              justify="space-between"
              className={css`
                ${tw`py-4 text-center`}
              `}
            >
              <Col span={3}>{t("actual")}</Col>
              {mouCategories.map((mouCategory) => (
                <Col key={mouCategory.title} span={3}>
                  {mouCategory.title === "total" ? (
                    actualTotal
                  ) : (
                    <Input
                      style={{
                        borderColor: errors.includes(mouCategory.title)
                          ? red.primary
                          : grey[greyIndex]
                      }}
                      type="number"
                      className={css`
                        ${tw`text-center`}
                      `}
                      value={trainingForm[mouCategory.title] ?? ""}
                      disabled={!isEditable}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={handleChange(mouCategory.title)}
                    />
                  )}
                </Col>
              ))}
            </Row>
          </Col>
          <Row justify="end">
            <Button
              className="secondary-button"
              loading={addTrainingDataMutation.isLoading}
              onClick={handleSave}
            >
              {saveText}
            </Button>
          </Row>
        </>
      )}

      <Col span={24}>
        <CommentCard
          isActual
          id={actualId}
          status={period?.approvalStatus}
          sectionType={SectionType.Training}
        />
      </Col>
    </>
  );
};
