import type { FC } from "react";

import type { ContractsSelectorProps } from "@components/pages/admin/Invoice/libs/components/ContractsSelector/props";
import { Select } from "antd";

export const ContractsSelector: FC<ContractsSelectorProps> = ({
  contracts,
  ...rest
}) => (
  <Select
    options={contracts?.map((c) => ({
      value: c.id,
      label: c.contractNumber
    }))}
    mode="multiple"
    maxTagCount="responsive"
    filterOption={(input, option) =>
      (option?.label as string).toLowerCase().includes(input.toLowerCase())
    }
    {...rest}
  />
);
