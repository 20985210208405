/* eslint-disable @typescript-eslint/naming-convention */

export const enCommon = {
  logo: "KC PORTAL",
  email: "Email",
  password: "Password",
  login: "Login",
  actions: "Actions",
  cancel: "Cancel",
  edit: "Edit",
  city: "City",
  country: "Country",
  workPhoneNumber: "Work phone number",
  bin: "BIN",
  address: "Address",
  add: "Add",
  search: "Search",
  submit: "Submit",
  clear: "Clear",
  notifications: "Notifications",
  yes: "Yes",
  confirmDelete: "Confirm delete",
  no: "No",
  noData: "No data",
  delete: "Delete",
  accessLevel: "Access level",
  update: "Update",
  successUpdate: "The information were updated successfully",
  reports: "Reports",
  forecast: "Forecast",
  forecasts: "Forecasts",
  actual: "Actual",
  actuals: "Actuals",
  forms: "G1-C forms",
  glossary: "Glossary",
  admin: "Admin",
  kcLead: "KC Lead",
  contractor: "Contractor",
  subcontractor: "Subcontractor",
  guest: "Guest",
  save: "Save",
  more: "More",
  reset: "Reset",
  downloadError: "Download error",
  all: "All",
  total: "Total",
  scopeOfWork: "Scope of works",
  status: "Status",
  deleted: "Deleted",
  saved: "Saved",
  applyFilters: "Apply filters",
  requiredField: "The field is required",
  settings: "Settings",
  monthDataSaved: "Month data saved",
  contract: "Contract",
  back: "Back",
  apply: "Apply",
  download: "Download",
  approve: "Approve",
  reject: "Reject",
  create: "Create",
  by: "by",
  at: "at",
  upload: "Upload",
  dateFrom: "Date from",
  dateTo: "Date to",
  updatedAt: "Updated at",
  confirm: "Confirm",
  submissionDate: "Submission date",
  fileUploadError:
    "The {{fileName}} is too large, the maximum supported file size is {{size}} MB",
  sessionExpired: "The current session has expired, please login once again.",
  loading: "Loading",
  somethingWentWrong: "Something went wrong",
  goToProfile: "To Profile",
  showMore: "Show more",
  showLess: "Show less"
};
