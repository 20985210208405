import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";

import { FileOutlined, WarningOutlined } from "@ant-design/icons";
import { DatadumpReportTable } from "@components/organisms/DatadumpReportTable";
import { Alert, Button, notification, PageHeader, Space } from "antd";

import {
  generateAdminDump,
  generateContractorDump,
  getContractorDumps
} from "@api/datadumpApi";

import { UserContext } from "@contexts/userContext";

export const ContractorDatadumpListPage: FC = () => {
  const [t] = useTranslation();
  const userContext = useContext(UserContext);

  const dataDumps = useQuery(
    ["getContractorDump"],
    async () =>
      getContractorDumps(userContext.userEntityId).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0
    }
  );

  const generateDump = useMutation(
    async () => generateContractorDump(userContext.userEntityId),
    {
      onSuccess: (): void => {
        void dataDumps.refetch();
        notification.success({ message: t("reports.datadumpSuccess") });
      },
      onError: (): void => {
        notification.error({ message: t("reports.datadumpGenerateError") });
      }
    }
  );
  const handleGenerateClick = (): void => {
    generateDump.mutate();
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <PageHeader
        title={t("reports.datadump")}
        extra={
          <Button
            icon={<FileOutlined />}
            type="primary"
            loading={generateDump.isLoading}
            onClick={handleGenerateClick}
          >
            {t("reports.datadumpGenerate")}
          </Button>
        }
      />

      <Alert
        icon={<WarningOutlined />}
        type="warning"
        message={t("reports.datadumpAlert")}
      />

      <DatadumpReportTable
        reports={dataDumps.data ?? []}
        isLoading={dataDumps.isLoading}
        getDownloadLink={(report) =>
          `/api/reports/actual-metrics/dumps/${report.id}/download`
        }
      />
    </Space>
  );
};

export default ContractorDatadumpListPage;
