import type { FC, PropsWithChildren, ReactNode } from "react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import moment from "moment";
import tw from "twin.macro";

import { VersionSelector } from "@components/atoms/VersionSelector";
import { Amendment } from "@components/organisms/Amendment";
import { NewContractForm } from "@components/organisms/NewContractForm";
import { SubAgreementsTable } from "@components/organisms/SubAgreementsTable";
import { useDefaultQuery } from "@hooks";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Skeleton,
  Typography
} from "antd";

import type { Amendment as AmendmentType } from "@components/types/models/Amendment";
import type { Contract as ContractType } from "@components/types/models/Contract";
import {
  CONTRACT_TYPE,
  SCOPE_OF_WORK
} from "@components/types/models/Contract";

import { contractApi } from "@api/contractApi";

import { UserContext } from "@contexts/userContext";
import { formatAmount } from "@utils/formatAmount";

import { ComparisonTable } from "./libs/ComparisonTable";

const { Title, Text } = Typography;

type AmendmentMutation = {
  readonly contractId?: number;
  readonly amendmentId?: number;
  readonly comment?: string;
};

const { TextArea } = Input;
const versionComparisonAmendmentsLength = 2;

export const ContractView: FC = () => {
  const { id } = useParams<{ readonly id?: string }>();
  const { t, i18n } = useTranslation();
  const { refetchNotices } = useContext(UserContext);

  const {
    data: contractData,
    refetch: refetchContractData,
    isFetching: isContractFetching
  } = useDefaultQuery<ContractType>("getContract", async () =>
    contractApi.getContract(Number(id)).then((res) => res.data)
  );

  const [amendmentVersions, setAmendmentVersions] = useState<
    ReadonlyArray<{
      readonly period?: string;
      readonly scopes?: string;
      readonly detailsOnScopeOfWork?: string;
      readonly amount?: string;
      readonly currency_code?: string;
      readonly kc_target?: string;
    }>
  >([]);

  const contractTypes: Record<CONTRACT_TYPE, string> = {
    [CONTRACT_TYPE.MASTER_AGREEMENT]: "Master Agreement",
    [CONTRACT_TYPE.STANDALONE]: "Standalone",
    [CONTRACT_TYPE.SUB_AGREEMENT]: "Sub agreement",
    [CONTRACT_TYPE.UNDEFINED]: "Undefined"
  };

  const [rejectAmendmentModalOpen, setRejectAmendmentModalOpen] =
    useState(false);
  const [compareVersionsModalOpen, setCompareVersionsModalOpen] =
    useState(false);

  const [amendmentVersion, setAmendmentVersion] = useState<
    number | null | undefined
  >(null);

  const { data: amendments, refetch: refetchAmendments } = useDefaultQuery<
    readonly AmendmentType[]
  >("getContractAmendment", async () =>
    contractApi.getContractAmendment(Number(id)).then((res) => res.data)
  );

  const {
    data: subAgreements,
    refetch: refetchSubAgreements,
    isFetching: isSubAgreementsFetching
  } = useDefaultQuery("getSubAgreements", async () =>
    contractApi.getSubAgreements(Number(id)).then((res) => res.data)
  );

  const [lastAmendment, setLastAmendment] = useState<AmendmentType>();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    setAmendmentVersion(contractData?.version);
  }, [contractData]);

  useEffect(() => {
    void refetchContractData();
    void refetchAmendments();
  }, [id, refetchAmendments, refetchContractData]);

  useEffect(() => {
    if (amendments && amendments.length > 0 && amendmentVersion) {
      setLastAmendment(amendments[amendmentVersion - 1]);
    }
  }, [amendments, amendmentVersion]);

  const approveAmendmentMutation = useMutation(
    async ({ contractId, amendmentId }: AmendmentMutation) => {
      if (contractId && amendmentId) {
        await contractApi.approveAmendment(contractId, amendmentId);
      }
    },
    {
      onSuccess() {
        void refetchContractData();
        void refetchAmendments();
        void refetchNotices?.();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const rejectAmendmentMutation = useMutation(
    async ({ contractId, amendmentId, comment }: AmendmentMutation) => {
      if (contractId && amendmentId) {
        await contractApi.rejectAmendment(contractId, amendmentId, comment);
      }
    },
    {
      onSuccess() {
        setRejectAmendmentModalOpen(false);
        void refetchContractData();
        void refetchAmendments();
        void refetchNotices?.();
      },
      onError(err: AxiosError) {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              readonly name: any;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        }
        if (errData.message) {
          notification.error({ message: err.response?.data?.message });
        }
      }
    }
  );

  const handleApproveAmendment = useCallback(
    (amendmentId?: number) => () => {
      approveAmendmentMutation.mutate({
        contractId: contractData?.id,
        amendmentId
      });
    },
    [approveAmendmentMutation, contractData]
  );

  const handleOpenRejectAmendmentModal = useCallback(() => {
    setRejectAmendmentModalOpen(true);
  }, []);

  const handleVersionSelectChange = (value: number): void => {
    setAmendmentVersion(value);
  };

  const onFormSubmit = useCallback(
    (values) => {
      clearErrors();
      rejectAmendmentMutation.mutate({
        contractId: contractData?.id,
        amendmentId: lastAmendment?.id,
        comment: values.comment
      });
    },
    [contractData, lastAmendment, rejectAmendmentMutation, clearErrors]
  );

  const handleRejectModalOkClick = useCallback(() => {
    void handleSubmit(onFormSubmit)();
  }, [handleSubmit, onFormSubmit]);
  const handleCloseRejectModal = useCallback(() => {
    setRejectAmendmentModalOpen(false);
  }, []);

  const handleInputChange = useCallback(
    (name) => (e: React.FormEvent<HTMLTextAreaElement>) => {
      setValue(name, e.currentTarget.value);
    },
    [setValue]
  );

  const handleCompareVersionsOkButtonPress = useCallback(() => {
    setCompareVersionsModalOpen(false);
  }, []);

  const handleClickCompareWithInitialButton = useCallback(() => {
    setCompareVersionsModalOpen(true);
  }, []);

  // Do not make this array readonly, it conflicts with antd Table columns prop
  const [versionColumns, setVersionColumns] = useState<
    Array<{
      readonly title: string;
      readonly key: string;
      readonly dataIndex: string;
      readonly render?: (
        text: string,
        row: Record<string, string>
      ) =>
        | ReactNode
        | {
            readonly props: PropsWithChildren<unknown>;
            readonly children: ReactNode;
          };
    }>
  >([
    {
      title: "",
      key: "title",
      dataIndex: "title"
    }
  ]);

  const [versionCompareDatasource, setVersionCompareDataSource] =
    useState<ReadonlyArray<Record<string, string>>>();

  useEffect(() => {
    setAmendmentVersions(
      amendments && lastAmendment
        ? [
            {
              period: t("contracts.period"),
              scopes: t("contracts.scopeOfWork"),
              detailsOnScopeOfWork: t("contracts.detailsOnScopeWork"),
              amount: t("contracts.amount"),
              currency_code: t("contracts.currency"),
              kc_target: t("contracts.kcTarget")
            },
            ...[lastAmendment, amendments[0]].map(
              (a) =>
                a && {
                  period:
                    a.startDate && a.endDate
                      ? `${moment(a.startDate).format("YYYY-MM-DD")} - ${moment(
                          a.endDate
                        ).format("YYYY-MM-DD")}`
                      : "-",
                  scopes:
                    a.scopes
                      ?.map(
                        (s) =>
                          (s === SCOPE_OF_WORK.GOODS && t("contracts.goods")) ||
                          (s === SCOPE_OF_WORK.WORKS && t("contracts.works")) ||
                          (s === SCOPE_OF_WORK.SERVICES &&
                            t("contracts.services"))
                      )
                      .join(", ") || "-",
                  detailsOnScopeOfWork: a.detailsOnScopeOfWork,
                  amount: `${formatAmount(a.amount)}`,
                  currency_code: a.currency?.code,
                  kc_target: `${a.kcTarget} %`
                }
            )
          ]
        : []
    );

    setVersionCompareDataSource([
      versionColumns
        .map((col) => ({
          title: col.title,
          key: col.key,
          dataIndex: col.dataIndex
        }))
        .reduce((prev, col) => ({
          ...prev,
          [col.key]: col.title
        })),
      {}
    ]);
  }, [amendments, lastAmendment, versionColumns, t]);

  useEffect(() => {
    const transpose = (
      m: ReadonlyArray<readonly string[]>
    ): ReadonlyArray<readonly string[]> =>
      m.length > 0 ? m[0].map((_, i) => m.map((y) => y[i])) : [];

    const transposedAmendmentVersions = transpose(
      amendmentVersions.length > 0
        ? amendmentVersions?.map((av) => (av ? Object.values(av) : []))
        : []
    );

    transposedAmendmentVersions.length > 0
      ? setVersionCompareDataSource(
          transposedAmendmentVersions.map((tv) =>
            versionColumns.reduce(
              (prev, col) =>
                col.key !== "key" && col.key !== "dataIndex"
                  ? {
                      ...prev,
                      [col.key]: tv[Object.keys(prev).length]
                    }
                  : prev,
              {}
            )
          )
        )
      : setVersionCompareDataSource([]);
  }, [amendmentVersions, versionColumns]);

  useEffect(() => {
    if (
      amendments?.length === versionComparisonAmendmentsLength &&
      lastAmendment
    ) {
      setVersionColumns([
        {
          title: "",
          key: "title",
          dataIndex: "title"
        },
        {
          title: `${i18n.language === "en" ? "Version" : "Версия"} ${
            lastAmendment.version
          }`,
          key: `version_second`,
          dataIndex: `version_${lastAmendment.version}`
        },
        {
          title: `${i18n.language === "en" ? "Version" : "Версия"} ${
            amendments[0].version
          }`,
          key: `version_first`,
          dataIndex: `version_${amendments[0].version}`
        }
      ]);
    }
    if (amendments) {
      setVersionColumns(
        amendments.length >= versionComparisonAmendmentsLength && lastAmendment
          ? [
              {
                title: "",
                key: "title",
                dataIndex: "title"
              },
              {
                title: `${i18n.language === "en" ? "Version" : "Версия"} ${
                  lastAmendment.version
                }`,
                key: `version_second`,
                dataIndex: `version_${lastAmendment.version}`
              },
              {
                title: `${i18n.language === "en" ? "Version" : "Версия"} ${
                  amendments[0].version
                }`,
                key: `version_first`,
                dataIndex: `version_${amendments[0].version}`
              }
            ]
          : [
              {
                title: "",
                key: "title",
                dataIndex: "title"
              }
            ]
      );
    }
  }, [amendments, lastAmendment, i18n.language]);

  return (
    <div>
      {contractData && !isContractFetching ? (
        <>
          <Title level={2}>{contractData.contractNumber}</Title>
          <div>
            <Text
              className={css`
                ${tw`inline text-xl`}
              `}
            >
              {t("contracts.contractTypeColon")}
            </Text>
            <Title
              level={3}
              className={css`
                ${tw`inline`}
              `}
            >
              {contractTypes[contractData.contractType]}
            </Title>
          </div>
          <div>
            <Text
              className={css`
                ${tw`inline text-xl`}
              `}
            >
              {t("contracts.kcCategoryAndAreaColon")}
            </Text>
            <Title
              level={3}
              className={css`
                ${tw`inline`}
              `}
            >
              {contractData.kcCategory.name}
            </Title>
          </div>
          {contractData.contractType === CONTRACT_TYPE.SUB_AGREEMENT && (
            <div>
              <Text
                className={css`
                  ${tw`inline text-xl`}
                `}
              >
                {t("contracts.masterAgreementColon")}
              </Text>
              <Title
                level={3}
                className={css`
                  ${tw`inline`}
                `}
              >
                {contractData.masterAgreement?.contractNumber}
              </Title>
            </div>
          )}
        </>
      ) : (
        <Skeleton active />
      )}
      {amendmentVersion && (
        <Row
          className={css`
            ${tw`my-8`}
          `}
        >
          <Col span={12}>
            <VersionSelector
              contractData={contractData}
              amendments={amendments}
              onVersionSelectChange={handleVersionSelectChange}
            />
          </Col>
        </Row>
      )}

      {amendments && amendments.length > 0 && amendmentVersion && (
        <>
          <Modal
            visible={rejectAmendmentModalOpen}
            title={t("contracts.reject")}
            cancelText={t("cancel")}
            okText={t("yes")}
            confirmLoading={rejectAmendmentMutation.isLoading}
            closable={false}
            onOk={handleRejectModalOkClick}
            onCancel={handleCloseRejectModal}
          >
            {t("contracts.sureWantToReject")}
            <Form>
              <Form.Item
                validateStatus={errors.comment?.message && "error"}
                help={errors.comment?.message}
              >
                <TextArea
                  {...register("comment")}
                  onChange={handleInputChange("comment")}
                />
              </Form.Item>
            </Form>
          </Modal>
          {amendments.length > 0 && !!amendmentVersion && (
            <Amendment
              isAdmin
              amendment={amendments[amendmentVersion - 1]}
              contractStatus={contractData?.contractStatus}
              headerRightSide={
                amendments.length > 1 ? (
                  <Button onClick={handleClickCompareWithInitialButton}>
                    {t("contracts.compareWithInitial")}
                  </Button>
                ) : (
                  global.undefined
                )
              }
              onOpenRejectAmendmentModal={handleOpenRejectAmendmentModal}
              onApproveAmendment={handleApproveAmendment(lastAmendment?.id)}
            />
          )}

          <Modal
            visible={compareVersionsModalOpen}
            title={t("contracts.versionComparison")}
            closable={false}
            okText={t("")}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={handleCompareVersionsOkButtonPress}
              >
                {t("contracts.ok")}
              </Button>
            ]}
            onCancel={handleCompareVersionsOkButtonPress}
          >
            <ComparisonTable
              dataSource={versionCompareDatasource}
              columns={versionColumns}
            />
          </Modal>
        </>
      )}
      {contractData?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT && (
        <>
          <Row justify="space-between" align="middle">
            <Title level={3}>{t("contracts.additionalContracts")}</Title>
            <NewContractForm
              contractType={3}
              refetchContractsList={refetchSubAgreements}
              addButtonTitle={t("contracts.addSubAgreement")}
              contractorId={contractData.contractorId}
              masterAgreementId={contractData.id}
            />
          </Row>
          <SubAgreementsTable
            subAgreementsList={subAgreements}
            linkToContract="/admin/contract/"
            loading={isSubAgreementsFetching}
          />
        </>
      )}
    </div>
  );
};
