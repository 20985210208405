import qs from "querystring";
import type { FC } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Translation, useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import type { Moment } from "moment";
import moment from "moment";
import tw from "twin.macro";

import {
  FieldTimeOutlined,
  LeftOutlined,
  ReloadOutlined,
  WarningOutlined
} from "@ant-design/icons";
import { ActualCard } from "@components/organisms/ActualCard";
import { ActualJobsForm } from "@components/organisms/ActualJobsForm";
import { ActualsGws } from "@components/organisms/ActualsGws";
import { ActualStoriesLegacyTechnologyForm } from "@components/organisms/ActualStoriesLegacyTechnologyForm";
import type { ActualStoriesLegacyTechnologyData } from "@components/organisms/ActualStoriesLegacyTechnologyForm/props";
import { ActualTrainingForm } from "@components/organisms/ActualTrainingForm";
import { MasterAgreementGwsTable } from "@components/organisms/MasterAgreementGwsTable";
import { useDefaultQuery } from "@hooks";
import i18n from "@i18n/i18n";
import {
  Alert,
  Button,
  Col,
  Collapse,
  DatePicker,
  message,
  notification,
  Row,
  Select,
  Tag,
  Typography
} from "antd";

import type { ActualPeriod } from "@components/types/models/Actuals";
import {
  definePeriodStatus,
  PERIOD_STATUS
} from "@components/types/models/Actuals";
import type { Contract as ContractType } from "@components/types/models/Contract";
import { CONTRACT_TYPE } from "@components/types/models/Contract";
import type { Country } from "@components/types/models/CountryCity";
import { SectionType } from "@components/types/models/Forecast";
import { STATUS } from "@components/types/models/Statuses";

import { forecastApi } from "@api/forecastApi";
import { countryCityApi } from "@api/countryCityApi";
import { contractApi } from "@api/contractApi";
import { actualApi } from "@api/actualApi";

import { UserRoles } from "@contexts/types/UserRoles";
import { UserContext } from "@contexts/userContext";
import { useActualComments } from "@hooks/useActualComments";
import { stripUnusedObjectFields } from "@utils/helper";

const { Text } = Typography;
const { Panel } = Collapse;

const previousActualIndex = 2;

export const ActualView: FC = () => {
  const { t } = useTranslation();
  const { user, userRole } = useContext(UserContext);
  const contractorId = user?.userRoles[0].entityId;
  const history = useHistory();
  const pageParams = qs.parse(history.location.search.slice(1));

  const {
    setError,
    formState: { errors }
  } = useForm();

  const [actualSearchParams, setActualSearchParams] = useState<{
    readonly contractId: number | string | null;
    readonly period: number | string | null;
  }>({ contractId: null, period: null });

  const [currentPeriod, setCurrentPeriod] = useState<ActualPeriod>();
  const [currentContract, setCurrentContract] = useState<ContractType>();
  const [activeCollapseItem, setActiveCollapseItem] = useState<number | null>(
    null
  );
  const [activeTextComponents, setActiveTextComponents] = useState<
    readonly number[]
  >([]);

  const actualId = useMemo(() => {
    const actualList = currentPeriod?.actuals;
    const lastVersion = actualList?.[actualList.length - 1];

    return lastVersion?.actualId;
  }, [currentPeriod]);

  const currentActual = useMemo(
    () => currentPeriod?.actuals?.[currentPeriod.actuals.length - 1],
    [currentPeriod]
  );

  const { freshCommentedSections } = useActualComments({
    actualId,
    contractorId,
    status: currentPeriod?.approvalStatus,
    submittedAt: currentActual?.submissionDate,
    contractType: currentContract?.contractType
  });

  const previousActual = useMemo(
    () =>
      currentPeriod?.actuals?.[
        currentPeriod.actuals.length - previousActualIndex
      ],
    [currentPeriod]
  );

  const isEditable =
    currentPeriod?.approvalStatus !== PERIOD_STATUS.PENDING &&
    currentPeriod?.approvalStatus !== PERIOD_STATUS.APPROVED &&
    userRole !== UserRoles.Admin;

  const defaultSubmissionDate = 3;
  const [dueDate, setDueDate] = useState<Moment>(
    moment(new Date(), "YYYY-MM-DD").add(defaultSubmissionDate, "days")
  );

  const disableDate = (current: Moment): boolean =>
    current < moment().endOf("day");

  const handleDueDateChange = useCallback((date) => {
    setDueDate(date);
  }, []);

  const { data: contractsList = [], isFetching: isContractsListLoading } =
    useDefaultQuery<readonly ContractType[]>("getContractsList", async () =>
      contractApi
        .getContractsList(Number(user?.userRoles[0]?.entityId))
        .then((res) => res.data)
    );

  const {
    data: contractPeriodList = [],
    refetch: refetchContractPeriodList,
    isFetching: isContractPeriodLoading
  } = useQuery<readonly ActualPeriod[]>(
    "getContactPeriodList",
    async () =>
      actualApi
        .getContractPeriodList(actualSearchParams.contractId)
        .then((res) => res.data),
    {
      enabled: false
    }
  );

  const { data: forecastTrainingData, refetch: refetchForefastTrainingData } =
    useQuery(
      "getTrainingData",
      async () =>
        forecastApi
          .getForecastTraining(currentContract!.forecastId)
          .then((res) => res.data),
      {
        enabled: false
      }
    );

  const { data: forecastJobsData, refetch: refetchForecastJobsData } = useQuery(
    "getJobsData",
    async () =>
      forecastApi
        .getForecastJobs(currentContract!.forecastId)
        .then((res) => res.data),
    {
      enabled: false
    }
  );

  const { data: successStoriesData, refetch: refetchSuccessStoriesData } =
    useQuery<ActualStoriesLegacyTechnologyData>(
      ["getSuccessStoriesData", actualId],
      async () =>
        actualId &&
        actualApi.getSuccessStories(actualId).then((res) => res.data),
      {
        enabled: false,
        onSuccess(response) {
          if (response.description) {
            setActiveTextComponents((prevState) => [
              ...prevState,
              SectionType.SuccessStories
            ]);
          }
        }
      }
    );

  const { data: legacyData, refetch: refetchLegacyData } =
    useQuery<ActualStoriesLegacyTechnologyData>(
      ["getLegacyData", actualId],
      async () =>
        actualId && actualApi.getLegacy(actualId).then((res) => res.data),
      {
        enabled: false,
        onSuccess(response) {
          if (response.description) {
            setActiveTextComponents((prevState) => [
              ...prevState,
              SectionType.Legacy
            ]);
          }
        }
      }
    );

  const {
    data: technologyTransferData,
    refetch: refetchTechnologyTransferData
  } = useQuery<ActualStoriesLegacyTechnologyData>(
    ["getTechnologyTransferData", actualId],
    async () =>
      actualId &&
      actualApi.getTechnologyTransfer(actualId).then((res) => res.data),
    {
      enabled: false,
      onSuccess(response) {
        if (response.description) {
          setActiveTextComponents((prevState) => [
            ...prevState,
            SectionType.TechnologyTransfer
          ]);
        }
      }
    }
  );

  const saveTextSectionMutation = useMutation(
    async ({
      sectionTitle,
      description
    }: {
      readonly sectionTitle: string;
      readonly description: string;
    }) =>
      actualId && actualApi.saveTextSection(actualId, sectionTitle, description)
  );

  const removeTextSectionMutation = useMutation(
    async ({
      sectionTitle
    }: {
      readonly sectionTitle: string;
      readonly sectionId: number;
    }) => actualId && actualApi.deleteTextSection(actualId, sectionTitle),
    {
      onSuccess(_, values) {
        setActiveTextComponents((prevState) =>
          prevState.filter((id) => id !== values.sectionId)
        );
        notification.success({ message: t("deleted") });

        switch (values.sectionId) {
          case SectionType.TechnologyTransfer:
            void refetchTechnologyTransferData();
            break;
          case SectionType.Legacy:
            void refetchLegacyData();
            break;
          case SectionType.SuccessStories:
            void refetchSuccessStoriesData();
            break;
          default:
            break;
        }
      }
    }
  );

  const { mutate: submitActualMutation, isLoading: isSubmitLoading } =
    useMutation(
      async () =>
        actualApi.submitActual(currentActual?.actualId, dueDate.toJSON()),
      {
        onSuccess() {
          void message.success(t("actuals.successActualSubmit"));
          void refetchContractPeriodList();
        },
        onError(err: AxiosError) {
          void message.error(t("actuals.submissionError"));
          const errData = err.response?.data;
          Object.keys(errData).forEach((key) => {
            setError(key, { message: errData[key] });
          });
        }
      }
    );

  const updateApprovedActualMutation = useMutation(
    async () => actualApi.updateApprovedActual(currentActual?.actualId),
    {
      onSuccess() {
        void refetchContractPeriodList();
        setActiveCollapseItem(null);
      }
    }
  );

  const restorePreviousActualMutation = useMutation(
    async () => actualApi.restorePreviousActual(currentActual?.actualId),
    {
      onSuccess() {
        void refetchContractPeriodList();
        setActiveCollapseItem(null);
      }
    }
  );

  const handleAddTextSection = useCallback(
    (newSection: number) => () => {
      setActiveTextComponents((prevState) => [...prevState, newSection]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTextComponents]
  );

  const handleDeleteTextSection = useCallback(
    (sectionTitle: string, sectionId: number) =>
      (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation();
        removeTextSectionMutation.mutate({ sectionTitle, sectionId });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTextComponents]
  );

  const { data: countryList = [] } = useDefaultQuery<readonly Country[]>(
    "getAllCountriesWithCities",
    async () =>
      countryCityApi.getAllCountriesWithCities().then((res) => res.data)
  );

  const updatedContractPeriodList = useMemo(
    () =>
      contractPeriodList.map((period: ActualPeriod) => ({
        ...period,
        period: `${period.year}-${period.month}`
      })),
    [contractPeriodList]
  );

  const handleContractChange = useCallback(
    (field: string) => (value: number | string) => {
      setCurrentPeriod(global.undefined);

      setActualSearchParams((prevState) => ({
        ...prevState,
        period: null,
        [field]: value
      }));
      setActiveCollapseItem(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actualSearchParams, currentPeriod]
  );

  const handleSelectChange = useCallback(
    (field: string) => (value: number | string) => {
      setActualSearchParams((prevState) => ({
        ...prevState,
        [field]: value
      }));
      setActiveCollapseItem(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actualSearchParams]
  );

  const handleOpenCollapse = useCallback((key) => {
    setActiveCollapseItem(key);
  }, []);

  const getPeriodTitle = (period: ActualPeriod): string => {
    const version = period.actuals?.[period.actuals.length - 1]?.version;

    return `${moment()
      .month(Number(period.month) - 1)
      .locale(i18n.language)
      .format("MMMM")} ${version ? `(v${version})` : ""} ${period.year}`;
  };

  const updateQuery = (): void => {
    const payload = stripUnusedObjectFields(actualSearchParams);

    history.push({
      pathname: "/contractor/actual/details",
      search: qs.stringify(payload)
    });
  };

  const handleActualSubmit = useCallback(() => {
    submitActualMutation();
  }, [submitActualMutation]);

  const handleUpdateApprovedActual = useCallback(() => {
    updateApprovedActualMutation.mutate();
  }, []);

  const handleRestorePreviousActual = useCallback(() => {
    restorePreviousActualMutation.mutate();
  }, []);

  useEffect(() => {
    if (actualSearchParams.contractId && contractsList.length) {
      const activeContract = contractsList.find(
        (item: ContractType) =>
          item.id === Number(actualSearchParams.contractId)
      );

      setCurrentContract(activeContract);
      void refetchContractPeriodList();
      updateQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSearchParams.contractId, contractsList]);

  useEffect(() => {
    if (actualSearchParams.period && contractPeriodList.length) {
      const month = moment(actualSearchParams.period).month() + 1;
      const year = moment(actualSearchParams.period).year();

      const activePeriod = contractPeriodList.find(
        (period: ActualPeriod) => period.month === month && period.year === year
      );

      setCurrentPeriod(activePeriod);
      updateQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSearchParams.period, contractPeriodList]);

  useEffect(() => {
    if (currentContract?.forecastId) {
      void refetchForefastTrainingData();
      void refetchForecastJobsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContract]);

  useEffect(() => {
    const keyParams: readonly string[] = Object.keys(pageParams);

    if (keyParams.length) {
      keyParams.map((key: string) => {
        const value = pageParams[key];
        const outputValue = key === "contractId" ? Number(value) : value;
        setActualSearchParams((prevState) => ({
          ...prevState,
          [key]: outputValue
        }));
        return key;
      });
    }
  }, []);

  useEffect(() => {
    setActiveTextComponents([]);

    if (actualId) {
      void refetchSuccessStoriesData();
      void refetchLegacyData();
      void refetchTechnologyTransferData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualId]);

  return (
    <Col
      className={css`
        ${tw`space-y-6`}
      `}
    >
      <Col span={24}>
        <Row justify="space-between">
          <Col span={18}>
            <Row
              className={css`
                ${tw`space-x-4`}
              `}
            >
              <Col span={8}>
                <Select
                  placeholder={t("contract")}
                  value={actualSearchParams.contractId!}
                  className={css`
                    ${tw`w-full`}
                  `}
                  loading={isContractsListLoading}
                  onChange={handleContractChange("contractId")}
                >
                  {contractsList
                    .filter((contractItem) =>
                      Boolean(contractItem.lastApprovedAmendment)
                    )
                    .map((contract) => (
                      <Select.Option key={contract.id} value={contract.id}>
                        {contract.contractNumber}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
              <Col span={8}>
                <Select
                  placeholder={t("actuals.submissionPeriod")}
                  className={css`
                    ${tw`w-full`}
                  `}
                  value={actualSearchParams.period!}
                  disabled={
                    !actualSearchParams.contractId || isContractPeriodLoading
                  }
                  loading={isContractPeriodLoading}
                  onChange={handleSelectChange("period")}
                >
                  {updatedContractPeriodList.map((period) => {
                    const status = definePeriodStatus(period.approvalStatus);

                    return (
                      <Select.Option
                        key={`${period.year} ${period.month}`}
                        value={period.period}
                      >
                        <Col
                          className={css`
                            ${tw`space-x-2`}
                          `}
                        >
                          <Text>{getPeriodTitle(period)}</Text>
                          <Tag color={status.color}>
                            {i18n.language === "en"
                              ? status.enName
                              : status.ruName}
                          </Tag>
                        </Col>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Col>

          <Col
            className={css`
              ${tw`space-x-2`}
            `}
          >
            {currentActual?.status === STATUS.APPROVED && (
              <Button
                type="primary"
                disabled={!currentActual}
                loading={updateApprovedActualMutation.isLoading}
                icon={<ReloadOutlined />}
                onClick={handleUpdateApprovedActual}
              >
                {t("update")}
              </Button>
            )}
            {previousActual?.status === STATUS.APPROVED &&
              currentActual?.status === STATUS.DRAFT && (
                <Button
                  type="primary"
                  disabled={!currentActual}
                  loading={restorePreviousActualMutation.isLoading}
                  icon={<ReloadOutlined />}
                  onClick={handleRestorePreviousActual}
                >
                  {t("actuals.restorePreviousVersion")}
                </Button>
              )}
            <Link to="/contractor/actuals">
              <Button danger icon={<LeftOutlined />}>
                {t("back")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>

      {currentActual?.status === STATUS.REJECTED &&
        (currentActual.reviewLeftDays && currentActual.reviewLeftDays > 0 ? (
          <Col span={24}>
            <Alert
              showIcon
              icon={<FieldTimeOutlined />}
              message={
                <Translation>
                  {(tr) =>
                    tr("forecasts.timeLeft", {
                      count: currentActual.reviewLeftDays
                    })
                  }
                </Translation>
              }
              type="warning"
            />
          </Col>
        ) : (
          <Col span={24}>
            <Alert
              showIcon
              icon={<WarningOutlined />}
              message={t("actuals.missedDueDate")}
              type="error"
            />
          </Col>
        ))}

      {currentContract && <ActualCard contract={currentContract} />}

      {currentPeriod && actualId && (
        <>
          <Collapse
            accordion
            destroyInactivePanel
            activeKey={activeCollapseItem!}
            onChange={handleOpenCollapse}
          >
            {currentContract?.contractType ===
            CONTRACT_TYPE.MASTER_AGREEMENT ? (
              <Panel key="4" header={t("forecasts.goodsWorksServicesSummary")}>
                <MasterAgreementGwsTable
                  isActual
                  actualId={currentActual?.actualId}
                />
              </Panel>
            ) : (
              <ActualsGws
                period={currentPeriod}
                scopes={currentContract?.scopes}
                contractCurrency={
                  currentContract?.lastApprovedAmendment.currency.code
                }
                errors={errors}
                actualId={currentActual?.actualId}
                isEditable={isEditable}
                periodList={contractPeriodList}
                freshCommentedSections={freshCommentedSections}
              />
            )}

            {currentContract?.contractType !== CONTRACT_TYPE.SUB_AGREEMENT && (
              <>
                <Panel
                  key="5"
                  header={
                    <Text>
                      {t("forecasts.jobsAndNationalization")}
                      {isEditable && errors.JobAndNationalization && (
                        <Tag style={{ marginLeft: "8px" }} color="error">
                          {t("actuals.sectionIsRequired")}
                        </Tag>
                      )}
                      {freshCommentedSections[
                        SectionType.JobAndNationalization
                      ] &&
                        currentActual?.status === STATUS.REJECTED && (
                          <WarningOutlined
                            className={css`
                              ${tw`ml-2 text-lg text-red-600`}
                            `}
                          />
                        )}
                    </Text>
                  }
                >
                  <ActualJobsForm
                    forecastData={forecastJobsData}
                    period={currentPeriod}
                    countries={countryList}
                    contractId={currentContract?.id}
                    periodList={contractPeriodList}
                    refetchPeriodList={refetchContractPeriodList}
                    activeCollapseItem={5}
                    setActiveCollapseItem={setActiveCollapseItem}
                    isEditable={isEditable}
                    actualId={actualId}
                  />
                </Panel>
                <Panel
                  key="6"
                  header={
                    <Text>
                      {t("forecasts.training")}
                      {isEditable && errors.Training && (
                        <Tag style={{ marginLeft: "8px" }} color="error">
                          {t("actuals.sectionIsRequired")}
                        </Tag>
                      )}
                      {freshCommentedSections[SectionType.Training] &&
                        currentActual?.status === STATUS.REJECTED && (
                          <WarningOutlined
                            className={css`
                              ${tw`ml-2 text-lg text-red-600`}
                            `}
                          />
                        )}
                    </Text>
                  }
                >
                  <ActualTrainingForm
                    forecastData={forecastTrainingData}
                    period={currentPeriod}
                    actualId={actualId}
                    refetchPeriodList={refetchContractPeriodList}
                    activeCollapseItem={6}
                    setActiveCollapseItem={setActiveCollapseItem}
                    isEditable={isEditable}
                  />
                </Panel>
              </>
            )}
            {activeTextComponents.includes(SectionType.TechnologyTransfer) && (
              <Panel
                key="7"
                header={
                  <Text>
                    {t("forecasts.technologyTransfer")}
                    {freshCommentedSections[SectionType.TechnologyTransfer] &&
                      currentActual?.status === STATUS.REJECTED && (
                        <WarningOutlined
                          className={css`
                            ${tw`ml-2 text-lg text-red-600`}
                          `}
                        />
                      )}
                  </Text>
                }
                extra={
                  isEditable && (
                    <Button
                      danger
                      size="small"
                      className={css`
                        ${tw`z-10`}
                      `}
                      onClick={handleDeleteTextSection(
                        "technology-transfer",
                        SectionType.TechnologyTransfer
                      )}
                    >
                      {t("actuals.deleteSection")}
                    </Button>
                  )
                }
              >
                <ActualStoriesLegacyTechnologyForm
                  sectionType={SectionType.TechnologyTransfer}
                  activeCollapseItem={7}
                  setActiveCollapseItem={setActiveCollapseItem}
                  initialDescription={technologyTransferData?.description}
                  saveTextSectionMutation={saveTextSectionMutation}
                  refetchData={refetchTechnologyTransferData}
                  actualId={actualId}
                  isEditable={isEditable}
                />
              </Panel>
            )}
            {activeTextComponents.includes(SectionType.Legacy) && (
              <Panel
                key="8"
                header={
                  <Text>
                    {t("forecasts.legacy")}
                    {freshCommentedSections[SectionType.Legacy] &&
                      currentActual?.status === STATUS.REJECTED && (
                        <WarningOutlined
                          className={css`
                            ${tw`ml-2 text-lg text-red-600`}
                          `}
                        />
                      )}
                  </Text>
                }
                extra={
                  isEditable && (
                    <Button
                      danger
                      size="small"
                      onClick={handleDeleteTextSection(
                        "legacy",
                        SectionType.Legacy
                      )}
                    >
                      {t("actuals.deleteSection")}
                    </Button>
                  )
                }
              >
                <ActualStoriesLegacyTechnologyForm
                  sectionType={SectionType.Legacy}
                  activeCollapseItem={8}
                  setActiveCollapseItem={setActiveCollapseItem}
                  initialDescription={legacyData?.description}
                  saveTextSectionMutation={saveTextSectionMutation}
                  refetchData={refetchLegacyData}
                  actualId={actualId}
                  isEditable={isEditable}
                />
              </Panel>
            )}

            {activeTextComponents.includes(SectionType.SuccessStories) && (
              <Panel
                key="9"
                header={
                  <Text>
                    {t("forecasts.successStories")}
                    {freshCommentedSections[SectionType.SuccessStories] &&
                      currentActual?.status === STATUS.REJECTED && (
                        <WarningOutlined
                          className={css`
                            ${tw`ml-2 text-lg text-red-600`}
                          `}
                        />
                      )}
                  </Text>
                }
                extra={
                  isEditable && (
                    <Button
                      danger
                      size="small"
                      onClick={handleDeleteTextSection(
                        "success-stories",
                        SectionType.SuccessStories
                      )}
                    >
                      {t("actuals.deleteSection")}
                    </Button>
                  )
                }
              >
                <ActualStoriesLegacyTechnologyForm
                  sectionType={SectionType.SuccessStories}
                  activeCollapseItem={9}
                  setActiveCollapseItem={setActiveCollapseItem}
                  initialDescription={successStoriesData?.description}
                  saveTextSectionMutation={saveTextSectionMutation}
                  refetchData={refetchSuccessStoriesData}
                  actualId={actualId}
                  isEditable={isEditable}
                />
              </Panel>
            )}
          </Collapse>

          {(currentContract?.contractType === CONTRACT_TYPE.STANDALONE ||
            currentContract?.contractType === CONTRACT_TYPE.MASTER_AGREEMENT) &&
            isEditable && (
              <Col span={24}>
                <Row
                  className={css`
                    ${tw`space-x-2`}
                  `}
                >
                  {!activeTextComponents.includes(
                    SectionType.TechnologyTransfer
                  ) && (
                    <Button
                      onClick={handleAddTextSection(
                        SectionType.TechnologyTransfer
                      )}
                    >
                      {t("actuals.addTechnologyTransfer")}
                    </Button>
                  )}
                  {!activeTextComponents.includes(SectionType.Legacy) && (
                    <Button onClick={handleAddTextSection(SectionType.Legacy)}>
                      {t("actuals.addLegacy")}
                    </Button>
                  )}
                  {!activeTextComponents.includes(
                    SectionType.SuccessStories
                  ) && (
                    <Button
                      onClick={handleAddTextSection(SectionType.SuccessStories)}
                    >
                      {t("actuals.addSuccessStories")}
                    </Button>
                  )}
                </Row>
              </Col>
            )}

          {(currentActual?.status === STATUS.DRAFT ||
            currentActual?.status === STATUS.REJECTED ||
            currentActual?.status === STATUS.UNDEFINED) && (
            <Row style={{ marginTop: "24px" }} justify="space-between">
              <Col span={4}>
                <DatePicker
                  showNow={false}
                  placeholder={t("forecasts.dueDate")}
                  size="large"
                  value={dueDate}
                  style={{ width: "100%" }}
                  disabledDate={disableDate}
                  format="YYYY-MM-DD"
                  onChange={handleDueDateChange}
                />
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="large"
                  loading={isSubmitLoading}
                  onClick={handleActualSubmit}
                >
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </Col>
  );
};
