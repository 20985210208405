export const ruSubcontractors = {
  subcontractor: "Субподрядчик",
  editSubcontractor: "Редактировать субподрядчика",

  profile: {
    title: "Профиль субподрядчика",
    name: "Имя",
    bin: "БИН",
    city: "Город",
    detailOnScopeOfWork: "Детали по объему работы",
    country: "Страна",
    relation: "Отношения в рамках контракта",

    relationType: {
      Undefined: "Неизвестно",
      Subcontractor: "Субподрядчик",
      AffiliatedCompany: "Аффилированная компания",
      Supplier: "Поставщик",
      PrimeContractor: "Главный подрядчик",
      Contractor: "Подрядчик"
    },

    approved: "УТВЕРЖДЕН",
    pending: "НА РАССМОТРЕНИИ",

    toBeReviewed: "Субподрядчик изменил данные, необходимо подтверждение",

    reviewSuccess: "Изменения данных субподрядчика успешно подтверждены",
    reviewError:
      "Ошибка в подтверждении изменений субподрядчика, попробуйте снова"
  },

  form: {
    companyName: "Название компании",
    country: "Страна",
    city: "Город",
    contractualRelations: "Контрактные отношения",
    bin: "БИН",
    detailOnScopeOfWork: "Детали по объему работы"
  },

  editSuccess: "Субподрядчик успешно отредактирован",
  detailsTooltip:
    "Более подробное описание деталей находится на странице субподрядчика"
};
