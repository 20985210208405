import type { FC } from "react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { ErrorMessage } from "@hookform/error-message";
import type { AxiosError } from "axios";
import axios from "axios";

import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Typography
} from "antd";

import type { FormValues } from "@components/types/models/Contract";
import { PERMISSIONS } from "@components/types/models/Permissions";

import { CATEGORIES } from "@components/constants/AdminCategories";
import { UserContext } from "@contexts/userContext";
import { ContractTypes } from "@utils/contractTypes";
import { isUserHasPermission } from "@utils/permissionHelper";

import type { Category } from "./libs/types/Category";
import type { NewContractFormProps } from "./props";

const { Option } = Select;
const { Text } = Typography;

const approvedContractStatus = 3;
const subAgreementId = 3;
const ADDON_BEFORE_VALUE = "C";

export const NewContractForm: FC<NewContractFormProps> = ({
  contractorId,
  masterAgreementsList,
  refetchContractsList,
  addButtonTitle,
  masterAgreementId,
  contractType
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const [, i18n] = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const isDisable = !isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACT_EDIT
  );

  const {
    formState: { errors },
    setError,
    reset,
    clearErrors,
    getValues,
    setValue,
    watch
  } = useForm();

  const handleContractTypeChange = (value: number): void => {
    setValue("contractType", value);
  };

  useEffect(() => {
    if (contractType) {
      setValue("contractType", contractType);
      setValue("masterAgreementId", masterAgreementId);
    } else {
      reset();
    }
  }, [setValue, reset, contractType, masterAgreementId]);

  const handleOpenModal = (): void => {
    setIsModalVisible(true);
  };
  const handleCloseModal = (): void => {
    setIsModalVisible(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckboxChange = (e: any): void => {
    setValue("kcCategory", e.target.value);
  };
  const handleMasterAgreementChange = (value: number): void => {
    setValue("masterAgreementId", value);
  };
  const handleInputChange = (
    e: React.FormEvent<HTMLInputElement>,
    name: "contractNumber"
  ) => {
    setValue(name, e.currentTarget.value);
  };

  const mutation = useMutation(
    async (values: FormValues) => axios.post("/api/contract", values),
    {
      onSuccess() {
        notification.success({
          message: t("contracts.successCreate")
        });
        handleCloseModal();
        refetchContractsList();
      },
      onError: (err: AxiosError) => {
        const errData = err.response?.data;
        if (errData.validationErrors) {
          errData.validationErrors.forEach(
            (error: {
              readonly name: string;
              readonly description: string;
            }): void => {
              setError(error.name, { message: error.description });
            }
          );
        } else {
          setError("error", { message: errData.message });
        }
      }
    }
  );

  const handleModalOkClick = () => {
    clearErrors();
    const values = getValues();
    const mutatedValues = {
      ...values,
      contractNumber: ADDON_BEFORE_VALUE + values.contractNumber,
      contractorId
    };
    mutation.mutate(mutatedValues);
  };

  return (
    <>
      {addButtonTitle && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          disabled={isDisable}
          onClick={handleOpenModal}
        >
          {addButtonTitle}
        </Button>
      )}
      <Modal
        destroyOnClose
        visible={isModalVisible}
        title={t("contractors.newContract")}
        cancelText={t("cancel")}
        okText={t("save")}
        width={1000}
        confirmLoading={mutation.isLoading}
        closable={false}
        onOk={handleModalOkClick}
        onCancel={handleCloseModal}
      >
        <Form layout="vertical">
          <ErrorMessage
            errors={errors}
            name="error"
            render={({ message }) => <Text type="danger">{message}</Text>}
          />
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                label={t("contracts.contractNumber")}
                labelCol={{ span: 24 }}
                validateStatus={errors.contractNumber?.message && "error"}
                help={errors.contractNumber?.message}
              >
                <Input
                  addonBefore={ADDON_BEFORE_VALUE}
                  placeholder={"1234567"}
                  onChange={(evt: React.FormEvent<HTMLInputElement>) =>
                    handleInputChange(evt, "contractNumber")
                  }
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label={t("contracts.type")}
                labelCol={{ span: 24 }}
                validateStatus={errors.contractType?.message && "error"}
                help={errors.contractType?.message}
              >
                <Select
                  placeholder={t("contracts.type")}
                  style={{ width: "100%" }}
                  value={watch("contractType")}
                  disabled={Boolean(contractType)}
                  onChange={handleContractTypeChange}
                >
                  {ContractTypes.map((contract, index) => (
                    <Option key={index} value={contract.id}>
                      {i18n.language === "en"
                        ? contract.enName
                        : contract.ruName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8}>
              {watch("contractType") === subAgreementId && !contractType && (
                <Form.Item
                  validateStatus={errors.masterAgreementId?.message && "error"}
                  help={errors.masterAgreementId?.message}
                  label={t("contracts.masterAgreement")}
                  labelCol={{ span: 24 }}
                >
                  <Select
                    style={{ width: "100%" }}
                    placeholder={t("contracts.masterAgreement")}
                    value={watch("masterAgreementId")}
                    onChange={handleMasterAgreementChange}
                  >
                    {masterAgreementsList
                      ?.filter(
                        (item) => item.contractStatus === approvedContractStatus
                      )
                      .map((contract, index) => (
                        <Option key={index} value={contract.id}>
                          {contract.contractNumber}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Text type="danger">{errors.kcCategory?.message}</Text>
          <Form.Item label={t("members.categoryAndArea")}>
            <Radio.Group
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                height: "250px"
              }}
              onChange={handleCheckboxChange}
            >
              {CATEGORIES.map((category: Category) => (
                <Radio
                  key={category.key}
                  style={{ marginBottom: "8px" }}
                  value={category.key}
                  disabled={category.key === 0}
                >
                  {category.title}
                </Radio>
              ))}
              {CATEGORIES.map(
                (category: { readonly children?: readonly Category[] }) =>
                  category.children?.map(
                    (child: {
                      readonly title: string;
                      readonly key: number;
                    }) => (
                      <Radio
                        key={child.key}
                        style={{ marginBottom: "8px", marginLeft: "24px" }}
                        value={child.key}
                      >
                        {child.title}
                      </Radio>
                    )
                  )
              )}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
