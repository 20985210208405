type GlossaryItem = {
  readonly title: string;
  readonly description: string;
};

export const glossaryData: readonly GlossaryItem[] = [
  {
    title: "Actuals",
    description:
      "Values of actually paid invoices for the various goods, works and services"
  },
  {
    title: "Amendment",
    description: "Official change made to a Contact"
  },
  {
    title: "Contractor",
    description:
      "The legal entity that provide some works and services and has contractual obligations with TCO"
  },
  {
    title: "CT-KZ",
    description: "Certificate of Kazakhstani Origin"
  },
  {
    title: "Exhibit G1",
    description:
      "Company requirements for Contractor implementation of the Project KC program"
  },
  {
    title: "Forecast",
    description:
      "Accumulated historical data to predict financial outcomes for future months or years"
  },
  {
    title: "G1-A",
    description: "Kazakhstani Content Plan Template"
  },
  {
    title: "G1-B",
    description: "Kazakhstani content reporting metrics"
  },
  {
    title: "G1-C",
    description:
      "Kazakhstani content reporting form (to be attached to invoice)"
  },
  {
    title: "G1-D",
    description: "Kazakhstani content instructions"
  },
  {
    title: "Goods",
    description: "Materials purchased for direct use in subsoil operations"
  },
  {
    title: "GWS",
    description: "Goods, Works and Services"
  },
  {
    title: "KC",
    description: "Kazakhstani Content"
  },
  {
    title: "KC Lead",
    description: "TCO Responsible person for Contractors’ KC performance"
  },
  {
    title: "KC Metrics",
    description:
      "Tool to forecast KC for the effectiveness of the Contract and monitor development and compliance progress of Contractor"
  },
  {
    title: "KCP",
    description: "Kazakhstani Content Plan"
  },
  {
    title: "Legacy",
    description: "Benefits to the local community"
  },
  {
    title: "Master agreement",
    description:
      "is a contract reached between parties, in which the parties agree to most of the terms that will govern future transactions or future agreements"
  },
  {
    title: "Minimum viable product, MVP",
    description:
      "Values of actually paid invoices for the various goods, works and services"
  },
  {
    title: "Nationalization",
    description:
      "Phased transfer of jobs from non-KZ nationals to KZ national staff"
  },
  {
    title: "Payroll Fund",
    description:
      "Share (%) of Kazakhstani Staff Payroll Fund in total Payroll Fund of Company"
  },
  {
    title: "RoK",
    description: "Republic of Kazakhstan"
  },
  {
    title: "Services",
    description:
      "Activities required for direct use in subsoil operations, not aimed to create(produce) goods or other material objects"
  },
  {
    title: "SOW",
    description: "Scope of work"
  },
  {
    title: "Stand-alone agreement",
    description:
      "The hierarchical type of a contract that cannot be associated with a parent agreement or subagreements within a contract hierarchy"
  },
  {
    title: "Sub-agreement",
    description: "Legal document issued under the Master agreement"
  },
  {
    title: "Subcontractor",
    description:
      "The legal entity that provide some goods, works or/and services and has contractual obligations with Contractor"
  },
  {
    title: "System",
    description: "Web-based information Kazakhstani Content tool"
  },
  {
    title: "TCO",
    description: "Tengizchevroil LLP"
  },
  {
    title: "Version",
    description:
      "Variation of a contract or forecast which is caused by a change in contract/forecast"
  },
  {
    title: "Works",
    description:
      "Activities on creating(producing) goods, equipment assembly, construction of facilities"
  }
];
