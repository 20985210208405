import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import type { QueryFunctionContext } from "react-query";
import { useQuery } from "react-query";

import { Typography } from "antd";
import type { SearchQueryType } from "@components/pages/admin/Forecasts/libs/components/SearchSection/libs/types/SearchQueryType";
import { SearchSection } from "@components/pages/admin/Forecasts/libs/components/SearchSection";

import {
  ForecastApprovalStatus,
  ForecastSorting
} from "@components/types/models/Forecast";

import { adminApi } from "@api/adminApi";

import { usePagination } from "@hooks/usePagination";

import { ForecastsListTabs } from "./libs/components/ForecastsListTabs";

const { Title } = Typography;

const PAGE_SIZE = 15;

export const Forecasts: FC = () => {
  const { t } = useTranslation();

  const { page, pagination, setPagination, setPage } = usePagination<
    SearchQueryType,
    ForecastSorting
  >();

  const {
    data: forecastsList,
    isLoading,
    refetch
  } = useQuery(
    [
      "getForecastsPaginated",
      { searchQuery: pagination.filterParams, sort: pagination.sortParams }
    ],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [
          string,
          {
            readonly searchQuery: SearchQueryType | undefined;
            readonly sort?: ForecastSorting;
          }
        ]
      >
    ) => {
      if (searchQueryCallback.queryKey[1].searchQuery) {
        return pagination.tabParams === `${ForecastApprovalStatus.Overdue}`
          ? adminApi
              .getOverdueForecastsPaginated(
                page,
                PAGE_SIZE,
                pagination.sortParams,
                searchQueryCallback.queryKey[1].searchQuery.contractNumber,
                searchQueryCallback.queryKey[1].searchQuery.contractors,
                searchQueryCallback.queryKey[1].searchQuery.categories
              )
              .then((res) => res.data)
          : adminApi
              .getForecastsPaginated(
                page,
                PAGE_SIZE,
                pagination.sortParams,
                pagination.tabParams === `${ForecastApprovalStatus.Undefined}`
                  ? global.undefined
                  : pagination.tabParams,
                searchQueryCallback.queryKey[1].searchQuery.contractNumber,
                searchQueryCallback.queryKey[1].searchQuery.contractors,
                searchQueryCallback.queryKey[1].searchQuery.categories,
                searchQueryCallback.queryKey[1].searchQuery.contractTypes
              )
              .then((res) => res.data);
      }
      return pagination.tabParams === `${ForecastApprovalStatus.Overdue}`
        ? adminApi
            .getOverdueForecastsPaginated(
              page,
              PAGE_SIZE,
              pagination.sortParams
            )
            .then((res) => res.data)
        : adminApi
            .getForecastsPaginated(
              page,
              PAGE_SIZE,
              pagination.sortParams,
              pagination.tabParams === `${ForecastApprovalStatus.Undefined}`
                ? global.undefined
                : pagination.tabParams
            )
            .then((res) => res.data);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  useEffect(() => {
    void refetch();
  }, [
    page,
    pagination.tabParams,
    pagination.sortParams,
    pagination.filterParams,
    refetch
  ]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      sortParams: prevState.sortParams || ForecastSorting.ByContractorNameAsc,
      tabParams: prevState.tabParams || "0"
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (searchParams?: string) => {
    setPagination((prevState) => ({
      ...prevState,
      tabParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSearchQuery = (searchParams?: SearchQueryType) => {
    setPagination((prevState) => ({
      ...prevState,
      filterParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSort = (searchParams?: ForecastSorting) => {
    setPagination((prevState) => ({
      ...prevState,
      sortParams: searchParams
    }));
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <>
      <Title>{t("forecasts.forecasts")}</Title>
      <SearchSection
        filters={pagination.filterParams}
        isLoading={isLoading}
        onSearchQuery={handleSearchQuery}
      />
      <ForecastsListTabs
        forecastsList={forecastsList}
        isLoading={isLoading}
        sort={Number(pagination.sortParams)}
        onChange={handleTabChange}
        onSetPage={setPage}
        onSetSort={handleSort}
        activeKey={pagination.tabParams}
      />
    </>
  );
};
