import type { FC } from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import { css } from "@linaria/core";
import type { AxiosError } from "axios";
import tw from "twin.macro";

import { ContractorMembersTable } from "@components/organisms/ContractorMembersTable";
import { ContractsTable } from "@components/organisms/ContractsTable";
import { EditContractorMemberForm } from "@components/organisms/EditContractorMemberForm";
import { InviteContractorMemberForm } from "@components/organisms/InviteContractorMemberForm";
import { NewContractForm } from "@components/organisms/NewContractForm";
import { SubcontractorsTable } from "@components/organisms/SubcontractorsTable";
import { useDefaultQuery } from "@hooks";
import {
  Button,
  Card,
  Col,
  List,
  Modal,
  notification,
  Row,
  Typography
} from "antd";
import {
  DeleteOutlined,
  EnvironmentOutlined,
  FlagOutlined,
  FormOutlined,
  HomeOutlined,
  PhoneOutlined
} from "@ant-design/icons";
import { FilterSubcontractorForm } from "@components/organisms/FilterSubcontractorsForm";

import type { Contract } from "@components/types/models/Contract";
import { CONTRACT_TYPE } from "@components/types/models/Contract";
import type { Member } from "@components/types/models/Member";
import { PERMISSIONS } from "@components/types/models/Permissions";
import { STATUS } from "@components/types/models/Statuses";

import { contractorApi } from "@api/contractorApi";
import { contractApi } from "@api/contractApi";

import { UserContext } from "@contexts/userContext";
import { isUserHasPermission } from "@utils/permissionHelper";

const { Title } = Typography;
const PAGE_SIZE = 10;

export const Contractor: FC = () => {
  const { id } = useParams<{ readonly id?: string }>();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [editableMember, setEditableMember] = useState<Member>();
  const [filters, setFilters] = useState<{
    bin?: string;
    name?: string;
  } | null>(null);

  const hasContractorEditPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACTOR_EDIT
  );
  const hasContractViewPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACT_VIEW
  );

  const contractsList = useQuery(
    "getContractsList",
    async () =>
      contractApi.getContractsList(Number(id)).then((res) => res.data),
    {
      enabled: hasContractViewPermission,
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const handleEditClick = (item?: Member) => {
    setEditableMember(item);
    setIsEditModalOpen((i) => !i);
  };

  const handleCloseEditModal = (): void => {
    setIsEditModalOpen(false);
  };

  const { isLoading: isContractorLoading, data: contractorData } =
    useDefaultQuery("getContractorData", async () =>
      contractorApi.getContractorData(Number(id)).then((res) => res.data)
    );
  const members = useDefaultQuery("getContractorMembers", async () =>
    contractorApi.getContractorMembers(Number(id)).then((res) => res.data)
  );

  const {
    data: subcontractors,
    isLoading: isSubcontractorsFetching,
    refetch: refetchSubcontractors
  } = useQuery(
    "getSubcontractors",
    async () =>
      contractorApi
        .getContractorSubcontractors(
          page,
          PAGE_SIZE,
          Number(id),
          filters?.bin,
          filters?.name
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  useEffect(() => {
    void refetchSubcontractors();
  }, [page, filters, refetchSubcontractors]);

  const masterAgreementsList = contractsList.data?.filter(
    (contract: Contract) =>
      contract.contractStatus === STATUS.APPROVED &&
      contract.contractType === CONTRACT_TYPE.MASTER_AGREEMENT
  );

  const deleteMemberMutation = useMutation(
    async (memberId: number) =>
      contractorApi.deleteContractorMember(Number(id), memberId),
    {
      onSuccess() {
        notification.success({ message: t("members.memberDeleted") });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const deleteContractorMutation = useMutation(
    async (contractorId: number) =>
      contractorApi.deleteContractor(contractorId),
    {
      onSuccess() {
        notification.success({
          message: t("contractors.contractorDeleted")
        });
        history.push("/admin/contractor");
      }
    }
  );

  const resendInviteMutation = useMutation(
    async (email: string) =>
      contractorApi.resendInviteMember(Number(id), email),
    {
      onSuccess() {
        notification.success({ message: t("members.memberInviteResent") });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const activateMemberMutation = useMutation(
    async (memberId: number) =>
      contractorApi.activateContractorMember(Number(id), memberId),
    {
      onSuccess() {
        notification.success({ message: t("members.memberActivated") });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const deleteMemberInviteMutation = useMutation(
    async (email: string) =>
      contractorApi.deleteInviteMember(Number(id), email),
    {
      onSuccess() {
        notification.success({ message: t("members.memberInviteDeleted") });

        void members.refetch();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const handleCloseDeleteModal = (): void => {
    setDeleteOpen(false);
  };

  const handleOpenDeleteModal = (): void => {
    setDeleteOpen(true);
  };

  const handleDeleteMutation = (): void => {
    deleteContractorMutation.mutate(Number(id));
  };

  const handleFilters = ({ bin, name }: { bin?: string; name?: string }) => {
    setFilters({
      bin,
      name
    });
  };

  useEffect(() => {
    refetchSubcontractors();
  }, [page, refetchSubcontractors]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      refetchSubcontractors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <Card loading={isContractorLoading} title={contractorData?.name}>
        <List>
          <List.Item>
            <List.Item.Meta
              avatar={<FlagOutlined />}
              title={contractorData?.country?.nameEn ?? "-"}
              description={t("contractors.country")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<HomeOutlined />}
              title={contractorData?.city?.nameEn ?? "-"}
              description={t("contractors.city")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<EnvironmentOutlined />}
              title={contractorData?.address ?? "-"}
              description={t("contractors.address")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<PhoneOutlined />}
              title={contractorData?.phone ?? "-"}
              description={t("contractors.phoneNumber")}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<FormOutlined />}
              title={t(
                `contractors.contractorTypes.${contractorData?.contractorType}`
              )}
              description={t(`contractors.contractorType`)}
            />
          </List.Item>
          <List.Item>
            <List.Item.Meta
              avatar={<FormOutlined />}
              title={contractorData?.parentCompanyName ?? "-"}
              description={t("contractors.parentCompanyName")}
            />
          </List.Item>
        </List>
      </Card>
      {hasContractViewPermission && (
        <>
          <Row
            className={css`
              ${tw`my-6`}
            `}
          >
            <Col
              span={12}
              className={css`
                ${tw`text-left`}
              `}
            >
              <Title level={3}>{t("contractors.contracts")}</Title>
            </Col>
            <Col
              span={12}
              className={css`
                ${tw`text-right`}
              `}
            >
              <NewContractForm
                contractorId={Number(id)}
                masterAgreementsList={masterAgreementsList || []}
                refetchContractsList={contractsList.refetch}
                addButtonTitle={t("contractors.addContract")}
              />
            </Col>
          </Row>
          <ContractsTable
            isLoading={contractsList.isLoading}
            contractsList={contractsList.data || []}
            refetch={contractsList.refetch}
            linkToContract="/admin/contract/"
          />
        </>
      )}

      <Row
        className={css`
          ${tw`my-6`}
        `}
      >
        <Col
          span={12}
          className={css`
            ${tw`text-left`}
          `}
        >
          <Title level={3}>{t("members.members")}</Title>
        </Col>
        <Col
          span={12}
          className={css`
            ${tw`text-right`}
          `}
        >
          <InviteContractorMemberForm
            contractorId={Number(id)}
            refetchMembersList={members.refetch}
            isDisable={!hasContractorEditPermission}
          />
          <EditContractorMemberForm
            contractorId={Number(id)}
            member={editableMember}
            isModalOpen={isEditModalOpen}
            refetchMembersList={members.refetch}
            onCloseClick={handleCloseEditModal}
          />
        </Col>
      </Row>
      <ContractorMembersTable
        membersList={members.data ?? []}
        isMembersListLoading={members.isLoading}
        deleteMemberMutation={deleteMemberMutation}
        resendInviteMutation={resendInviteMutation}
        deleteMemberInviteMutation={deleteMemberInviteMutation}
        disableActionButton={!hasContractorEditPermission}
        activateMemberMutation={activateMemberMutation}
        contractorName={contractorData?.name}
        onEditClick={handleEditClick}
      />
      <Title
        className={css`
          ${tw`my-6`}
        `}
        level={3}
      >
        {t("contractors.subcontractors")}
      </Title>
      <FilterSubcontractorForm
        handleFilters={handleFilters}
        clearFilters={() => setFilters(null)}
        isLoading={isSubcontractorsFetching}
      />
      <SubcontractorsTable
        isLoading={isSubcontractorsFetching}
        total={subcontractors?.totalItems}
        subcontractorsList={subcontractors?.results ?? []}
        pagination={{
          showSizeChanger: false,
          onChange: (p: number) => {
            setPage(p);
          },
          total: subcontractors?.totalItems,
          current: subcontractors?.currentPage,
          pageSize: subcontractors?.pageSize
        }}
        contractorId={Number(id)}
      />
      <Row style={{ marginTop: "24px" }} justify="end">
        <Button
          danger
          type="primary"
          disabled={deleteContractorMutation.isLoading}
          loading={deleteContractorMutation.isLoading}
          icon={<DeleteOutlined />}
          onClick={handleOpenDeleteModal}
        >
          {t("contractors.deleteContractorAction")}
        </Button>
      </Row>
      <Modal
        destroyOnClose
        visible={isDeleteOpen}
        maskClosable={false}
        closable={false}
        title={t("contractors.deleteContractorAction")}
        footer={null}
        width={400}
        onCancel={handleCloseDeleteModal}
      >
        <Row>
          {t("contractors.confirmDelete", {
            name: contractorData?.name
          })}
        </Row>
        <Row justify="space-between" style={{ marginTop: "24px" }}>
          <Button
            loading={deleteContractorMutation.isLoading}
            onClick={handleCloseDeleteModal}
          >
            {t("cancel")}
          </Button>

          <Button
            danger
            type="primary"
            loading={deleteContractorMutation.isLoading}
            onClick={handleDeleteMutation}
          >
            {t("yes")}
          </Button>
        </Row>
      </Modal>
    </>
  );
};
