import { styled } from "@linaria/react";
import tw from "twin.macro";

import { Badge } from "antd";

export const AppBadge = styled(Badge)`
  .ant-badge-count {
    top: -5px;
    width: 15px;
    ${tw`-right-2 px-0`};
  }
`;

export const AppLinkBadge = styled(Badge)`
  .ant-badge-count {
    top: -2px;
    width: 15px;
    ${tw`-right-3 px-0`};
  }
`;
