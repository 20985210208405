import type { AxiosResponse } from "axios";
import axios from "axios";
import { stringify } from "qs";

import type {
  ExchangeRateListType,
  UpdateExchangeRatePayloadType
} from "@components/types/models/ExchangeRates";
import type { Currency } from "@components/types/models/Currency";

export const getExchangeRates = async (
  ratePeriod: string
): Promise<AxiosResponse<ExchangeRateListType>> =>
  axios.get(`/api/ex-rates`, {
    params: { ratePeriod },
    paramsSerializer: (params) => stringify(params, { arrayFormat: "repeat" })
  });

export const updateExchangeRates = async (
  payload: UpdateExchangeRatePayloadType
): Promise<AxiosResponse> => axios.post(`/api/ex-rates`, payload);

const getCurrencyList = async (
  isOnlyEnabled = false
): Promise<AxiosResponse<readonly Currency[]>> =>
  axios.get(`/api/currency`, { params: { isOnlyEnabled } });
const addCurrency = async (codes: readonly string[]): Promise<AxiosResponse> =>
  axios.post(`/api/currency`, { codes });

export const exchangeRateApi = {
  getExchangeRates,
  updateExchangeRates,
  getCurrencyList,
  addCurrency
};
