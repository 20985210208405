import type { FC } from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { QueryFunctionContext } from "react-query";
import { useQuery } from "react-query";

import { css } from "@linaria/core";
import tw from "twin.macro";

import { ContractorsTable } from "@components/organisms/ContractorsTable";
import { SearchSection } from "@components/organisms/ContractorsTable/libs/components/SearchSection";
import type { SearchQueryType } from "@components/organisms/ContractorsTable/libs/types/SearchQueryType";
import { EditContractorForm } from "@components/organisms/EditContractorForm";
import { NewContractorForm } from "@components/organisms/NewContractorForm";
import { Col, Modal, Row, Space, Typography } from "antd";

import type {
  Contractor,
  ContractorPaginated
} from "@components/types/models/Contractor";
import { PERMISSIONS } from "@components/types/models/Permissions";

import { countryCityApi } from "@api/countryCityApi";
import { contractorApi } from "@api/contractorApi";

import { UserContext } from "@contexts/userContext";
import { objectParsers } from "@utils/objectParsers";
import { isUserHasPermission } from "@utils/permissionHelper";
import { usePagination } from "@hooks/usePagination";

const { Title } = Typography;

export const ContractorsPage: FC = () => {
  const { t } = useTranslation();
  const { user, notices } = useContext(UserContext);
  const [editableContractor, setEditableContractor] = useState<Contractor>();
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const hasContractorEditPermission = isUserHasPermission(
    user?.permissions,
    PERMISSIONS.CONTRACTOR_EDIT
  );
  const { page, pagination, setPagination, setPage } =
    usePagination<SearchQueryType>();

  const fetchContractors = async (
    pageNum: number,
    bin?: string,
    name?: string,
    categories?: readonly number[]
  ): Promise<ContractorPaginated> =>
    contractorApi
      .getContractorsPaginated(pageNum, bin, name, categories)
      .then((res) => res.data);

  const handleToggleEditModal = useCallback((item?: Contractor): void => {
    setEditableContractor(item);
    setIsEditModalShown((i) => !i);
  }, []);

  const handleCloseModal = (): void => {
    setIsEditModalShown(false);
  };

  const { data, refetch, isLoading } = useQuery(
    ["getContractorsPaginated", { searchQuery: pagination.filterParams }],
    async (
      searchQueryCallback: QueryFunctionContext<
        readonly [string, { readonly searchQuery: SearchQueryType | undefined }]
      >
    ) =>
      searchQueryCallback.queryKey[1].searchQuery
        ? fetchContractors(
            page,
            searchQueryCallback.queryKey[1].searchQuery.bin,
            searchQueryCallback.queryKey[1].searchQuery.name,
            searchQueryCallback.queryKey[1].searchQuery.categories
          )
        : fetchContractors(page),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );

  const contractorsList = useMemo<readonly Contractor[]>(
    () =>
      data?.results.map((res) =>
        notices
          ? {
              ...res,
              newContractsCount:
                notices.contractors.find((c) => c.contractorId === res.id)
                  ?.amendments ?? 0
            }
          : res
      ) ?? [],
    [notices, data]
  );

  const categories = useQuery(
    "getCategories",
    async () =>
      contractorApi
        .getCategories()
        .then((res) => objectParsers.parseCategories(res.data)),
    {
      refetchOnWindowFocus: false
    }
  );

  const countries = useQuery(
    "getAllCountries",
    async () => countryCityApi.getAllCountries().then((res) => res.data),
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    void refetch();
  }, [
    page,
    pagination.tabParams,
    pagination.sortParams,
    pagination.filterParams,
    refetch
  ]);

  const handleSearchQuery = useCallback((searchParams?: SearchQueryType) => {
    const paginationForQuery = {
      totalItems: pagination.totalItems,
      pageSize: pagination.pageSize,
      currentPage: pagination.currentPage,
      filterParams: searchParams
    };

    setPagination(paginationForQuery);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditContractorFormSubmitted = useCallback(() => {
    void refetch();
  }, [refetch]);

  return (
    <Row>
      <Col
        span={12}
        className={css`
          ${tw`text-left`}
        `}
      >
        <Title level={1}>{t("contractors.contractors")}</Title>
      </Col>
      <Col
        span={12}
        className={css`
          ${tw`text-right`}
        `}
      >
        <Space>
          <NewContractorForm
            countries={countries.data || []}
            refetchList={refetch}
            isDisable={!hasContractorEditPermission}
          />
        </Space>
      </Col>
      <Col
        span={24}
        className={css`
          ${tw`text-left`}
        `}
      >
        <SearchSection
          categories={categories.data}
          filters={pagination.filterParams}
          isLoading={isLoading}
          onSearchQuery={handleSearchQuery}
        />
        <ContractorsTable
          data={contractorsList}
          setEditedContractor={setEditableContractor}
          isLoading={isLoading}
          pagination={{
            showSizeChanger: false,
            total: data?.totalItems,
            current: data?.currentPage,
            pageSize: data?.pageSize,
            onChange: (p) => {
              setPage(p);
            }
          }}
          onModal={handleToggleEditModal}
        />
        <Modal
          destroyOnClose
          visible={isEditModalShown}
          maskClosable={false}
          title={t("contractors.editContractor")}
          footer={null}
          width={800}
          closable={false}
          onCancel={handleCloseModal}
        >
          <EditContractorForm
            contractorId={editableContractor?.id}
            countries={countries.data || []}
            handleCloseModal={handleCloseModal}
            onFormSubmitted={handleEditContractorFormSubmitted}
          />
        </Modal>
      </Col>
    </Row>
  );
};
