import { User } from "@components/types/models/User";

export type MigrationRecord = {
  readonly id: number;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly type: MigrationType;
  readonly status: MigrationStatus;
  readonly fileName: string;
  readonly createdByUser: User;
};

export type MigrationUploadVariables = {
  readonly migrationType: MigrationType;
  readonly formData: FormData;
};

export enum MigrationStatus {
  Undefined = 0,
  InProgress = 1,
  Completed = 2,
  Pending = 3,
  Failed = 4
}

export enum MigrationType {
  Undefined = 0,
  Metrics = 1,
  Contracts = 2,
  Contractors = 3,
  JobsAndNationalization = 4,
  Training = 5
}
