import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import numeral from "numeral";
import { styled } from "@linaria/react";

import { Table } from "antd";
import { useDefaultQuery } from "@hooks";

import { forecastApi } from "@api/forecastApi";
import { actualApi } from "@api/actualApi";

import type { GwsSummaryTableProps } from "./props";

export const GwsSummaryTable: FC<GwsSummaryTableProps> = ({
  forecastId,
  currency,
  selectedYear,
  selectedMonth,
  isActualForm,
  actualId
}) => {
  const { Summary } = Table;
  const { t } = useTranslation();
  const percentSymbol = "%";

  const { data, isLoading, refetch } = useDefaultQuery(
    ["getGwsData", selectedYear, selectedMonth],
    async () => {
      if (isActualForm && actualId) {
        return actualApi.getGwsSummary(actualId).then((res) => res.data);
      }
      return (
        forecastId &&
        forecastApi
          .getGwsSummary(forecastId, selectedYear, selectedMonth)
          .then((res) => res.data)
      );
    }
  );

  useEffect(() => {
    void refetch();
  }, [selectedMonth, selectedYear, refetch]);

  const forecastColumns = [
    {
      title: t("forecasts.section")
    },
    {
      title: t("forecasts.forecastValue")
    },
    {
      title: t("forecasts.kcForecastValue")
    },
    {
      title: t("forecasts.kc")
    }
  ];

  const actualColumns = [
    {
      title: t("actuals.section")
    },
    {
      title: t("actuals.actualValue")
    },
    {
      title: t("actuals.kcActualValue")
    },
    {
      title: t("actuals.kc")
    }
  ];

  const HiddenBodyTable = styled(Table)`
    tbody {
      display: none;
    }
  `;

  return (
    <HiddenBodyTable
      loading={isLoading}
      columns={isActualForm ? actualColumns : forecastColumns}
      summary={() => (
        <Summary>
          <Summary.Row>
            <Summary.Cell index={0}>{t("forecasts.goods")}</Summary.Cell>
            <Summary.Cell index={1}>
              {data
                ? `${numeral(data?.totalGoods.totalValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={2}>
              {data
                ? `${numeral(data?.totalGoods.totalKcValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={3}>
              {data ? `${data?.totalGoods.totalKcShare} ${percentSymbol}` : "-"}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row>
            <Summary.Cell index={0}>{t("forecasts.works")}</Summary.Cell>
            <Summary.Cell index={1}>
              {data
                ? `${numeral(data?.totalWorks.totalValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={2}>
              {data
                ? `${numeral(data?.totalWorks.totalKcValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={3}>
              {data ? `${data?.totalWorks.totalKcShare} ${percentSymbol}` : "-"}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row>
            <Summary.Cell index={0}>{t("forecasts.services")}</Summary.Cell>
            <Summary.Cell index={1}>
              {data
                ? `${numeral(data?.totalServices.totalValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={2}>
              {data
                ? `${numeral(data?.totalServices.totalKcValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={3}>
              {data
                ? `${data?.totalServices.totalKcShare} ${percentSymbol}`
                : "-"}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row>
            <Summary.Cell index={0}>{t("total")}</Summary.Cell>
            <Summary.Cell index={1}>
              {data
                ? `${numeral(data?.totalGWSValue).format("0,0.00")} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={2}>
              {data
                ? `${numeral(data?.totalGWSKcValue).format(
                    "0,0.00"
                  )} ${currency}`
                : "-"}
            </Summary.Cell>
            <Summary.Cell index={3}>
              {data ? `${data?.totalGWSKcShare} ${percentSymbol}` : "-"}
            </Summary.Cell>
          </Summary.Row>
        </Summary>
      )}
    />
  );
};
