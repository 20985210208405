/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import { compareAsc, format } from "date-fns";

import {
  masterAgreementSections,
  PERIOD_STATUS,
  standaloneSections,
  subAgreementSections
} from "@components/types/models/Actuals";
import { CONTRACT_TYPE } from "@components/types/models/Contract";
import { SectionType } from "@components/types/models/Forecast";

import { actualApi } from "@api/actualApi";

type ActualCommentsProps = {
  readonly actualId?: number;
  readonly status?: number;
  readonly contractorId?: number | null;
  readonly submittedAt?: string;
  readonly contractType?: number;
};

type FreshComments = {
  readonly [key in SectionType]?: boolean;
};

export const useActualComments = ({
  actualId,
  contractorId,
  status,
  submittedAt,
  contractType
}: ActualCommentsProps): {
  readonly freshCommentedSections: FreshComments;
} => {
  const [freshCommentedSections, setFreshCommentedSections] =
    useState<FreshComments>({
      [SectionType.Good]: false,
      [SectionType.Work]: false,
      [SectionType.Service]: false,
      [SectionType.JobAndNationalization]: false,
      [SectionType.Training]: false,
      [SectionType.TechnologyTransfer]: false,
      [SectionType.Legacy]: false,
      [SectionType.SuccessStories]: false
    });

  const sectionList = useMemo(() => {
    switch (contractType) {
      case CONTRACT_TYPE.MASTER_AGREEMENT:
        return masterAgreementSections;
      case CONTRACT_TYPE.STANDALONE:
        return standaloneSections;
      case CONTRACT_TYPE.SUB_AGREEMENT:
        return subAgreementSections;
      default:
        return [];
    }
  }, [contractType]);

  const commonDateFormat = "yyyy-MM-dd HH:mm:ss";
  const afterSubmitIndex = -1;

  const getCommentsMutation = useMutation(
    async (sectionType: number) =>
      actualApi.getActualComments(actualId!, sectionType),
    {
      onSuccess(res, sectionType): void {
        const sectionComments = res.data;

        sectionComments.map((comment) => {
          const isAdminComment = comment.leavedByUserId !== contractorId;

          const isFreshComment =
            sectionComments.findIndex(
              (comm) =>
                compareAsc(
                  new Date(format(new Date(comm.createdAt), commonDateFormat)),
                  new Date(format(new Date(submittedAt!), commonDateFormat))
                ) === 1
            ) > afterSubmitIndex && isAdminComment;

          setFreshCommentedSections((prevState) => ({
            ...prevState,
            [sectionType]: isFreshComment
          }));

          return comment;
        });
      }
    }
  );

  useEffect(() => {
    if (contractorId && status === PERIOD_STATUS.REJECTED) {
      sectionList.forEach((section) => {
        getCommentsMutation.mutate(section);

        return section;
      });
    }
  }, [status, contractorId]);

  return { freshCommentedSections };
};
