import { FC, useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import axios from "axios";
import type { AxiosError } from "axios";
import { css } from "@linaria/core";
import tw from "twin.macro";
import moment from "moment";

import { Button, Col, Divider, notification, Row } from "antd";
import { useDefaultQuery } from "@hooks";
import { ForecastTrainingPerYear } from "@components/organisms/JobsTraining/libs/ForecastTrainingPerYear";
import { ForecastTrainingPerMonth } from "@components/organisms/JobsTraining/libs/ForecastTrainingPerMonth";
import { CommentCard } from "@components/organisms/CommentCard";

import { STATUS } from "@components/types/models/Statuses";
import { SectionType } from "@components/types/models/Forecast";
import { JobsTrainingTimeline } from "../JobsTraining/libs/JobsTrainingTimeline";

import { forecastApi } from "@api/forecastApi";

import type { YearItem } from "../JobsTraining/libs/JobsTrainingTimeline/props";
import { UserContext } from "@contexts/userContext";

import type {
  ForecastTrainingData,
  ForecastTrainingFormProps,
  MouCategoryItem
} from "./props";

const TOTAL_MOU_CATEGORY = "total";

export const ForecastTrainingForm: FC<ForecastTrainingFormProps> = ({
  startDate,
  endDate,
  forecastId,
  setActiveCollapseItem,
  activeCollapseItem,
  forecastStatus,
  isEditable
}) => {
  const { t } = useTranslation();

  const [isAnnual, setIsAnnual] = useState(false);
  const [currentPeriod, setCurrentPeriod] = useState<YearItem>({
    year: 0,
    years: [],
    months: [],
    isDetailed: false
  });
  const [isGrandTotal, setGrandTotalMode] = useState<boolean>(false);
  const [isCurrentPeriodChanged, setCurrentPeriodChanged] =
    useState<boolean>(false);

  const { isAdmin } = useContext(UserContext);

  useEffect(() => {
    if (
      (forecastStatus === STATUS.PENDING && !isAdmin) ||
      forecastStatus === STATUS.APPROVED ||
      (forecastStatus !== STATUS.PENDING && isAdmin)
    ) {
      setGrandTotalMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentPeriod?.isDetailed) {
      setIsAnnual(true);
    } else {
      setIsAnnual(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod]);

  const rowsHeaders: readonly MouCategoryItem[] = [
    { text: t("forecasts.mouCategory.engineering"), title: "engineering" },
    {
      text: t("forecasts.mouCategory.supervisorForeman"),
      title: "supervisorAndForeman"
    },
    {
      text: t("forecasts.mouCategory.administration"),
      title: "administration"
    },
    { text: t("forecasts.mouCategory.craft"), title: "craft" },
    {
      text: t("forecasts.mouCategory.heavyEquipment"),
      title: "heavyEquipmentOperator"
    },
    { text: t("forecasts.mouCategory.total"), title: "total" }
  ];

  const { data: trainingList, refetch: refetchTrainingList } = useDefaultQuery(
    ["getTrainingList", forecastId],
    async () =>
      forecastApi.getForecastTraining(forecastId).then((res) => res.data)
  );

  const handleTrainingTransition = (): void => {
    const hasNextYear = currentPeriod?.years.includes(
      Number(currentPeriod.year) + 1
    );

    if (currentPeriod?.isDetailed && hasNextYear) {
      setCurrentPeriodChanged(!isCurrentPeriodChanged);
      void refetchTrainingList();
    } else {
      setActiveCollapseItem(activeCollapseItem + 1);
    }
  };

  const addTrainingDataMutation = useMutation(
    async (values: ForecastTrainingData) =>
      axios.post(`api/forecasts/training/${forecastId}`, values),
    {
      onSuccess() {
        notification.success({ message: t("forecasts.saved") });

        handleTrainingTransition();
      },
      onError(err: AxiosError) {
        notification.error({ message: err.response?.data?.message });
      }
    }
  );

  const handleSetGrandTotal = (payload: boolean) => {
    setGrandTotalMode(payload);
  };

  const handleSave = (payload: ForecastTrainingData): void => {
    if (isGrandTotal) {
      setActiveCollapseItem(activeCollapseItem + 1);
      return;
    } else if (!isEditable) {
      handleTrainingTransition();
      return;
    }

    const outputPayload = JSON.parse(
      JSON.stringify(Object.values(payload))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ).map((item: any) => {
      rowsHeaders.map((header) => {
        if (header.title === TOTAL_MOU_CATEGORY) {
          return false;
        }

        if (item[header.title] === "") {
          item[header.title] = null;
        } else {
          item[header.title] = Number(item[header.title]);
        }
        return header;
      });
      return item;
    });

    addTrainingDataMutation.mutate(outputPayload);
  };

  return (
    <Col span={24}>
      <Col
        span={24}
        className={css`
          ${tw`flex space-x-4`}
        `}
      >
        <JobsTrainingTimeline
          startDateString={startDate}
          endDateString={endDate}
          getCurrentPeriod={(payload): void => {
            setCurrentPeriod(payload);
          }}
          isCurrentPeriodChanged={isCurrentPeriodChanged}
          isGrandTotal={isGrandTotal}
          status={forecastStatus}
        />

        <Col>
          {currentPeriod?.year && currentPeriod.year > moment().year() && (
            <Row
              gutter={6}
              className={css`
                ${tw`mb-2`}
              `}
            >
              <Col>
                <Button
                  type={isAnnual ? "default" : "primary"}
                  onClick={() => setIsAnnual(false)}
                >
                  {t("months.monthly")}
                </Button>
              </Col>
              <Col>
                <Button
                  type={isAnnual ? "primary" : "default"}
                  onClick={() => setIsAnnual(true)}
                >
                  {t("months.annual")}
                </Button>
              </Col>
            </Row>
          )}

          <Button
            type={isGrandTotal ? "primary" : "default"}
            onClick={() => handleSetGrandTotal(!isGrandTotal)}
          >
            {t("forecasts.grandTotal")}
          </Button>
        </Col>
      </Col>
      <Divider />

      {currentPeriod?.isDetailed && !isGrandTotal && !isAnnual ? (
        <ForecastTrainingPerMonth
          rowsHeaders={[...rowsHeaders]}
          trainingList={trainingList}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentPeriod={currentPeriod!}
          isEditable={isEditable}
          onSave={handleSave}
        />
      ) : (
        <ForecastTrainingPerYear
          rowsHeaders={[...rowsHeaders]}
          trainingList={trainingList}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          currentPeriod={currentPeriod!}
          isGrandTotal={isGrandTotal}
          isEditable={isEditable}
          onSave={handleSave}
        />
      )}
      <CommentCard
        id={forecastId}
        sectionType={SectionType.Training}
        status={forecastStatus}
      />
    </Col>
  );
};
