import {
  CheckOutlined,
  CopyOutlined,
  DollarOutlined,
  FileTextOutlined,
  LineChartOutlined,
  QuestionOutlined,
  UserOutlined
} from "@ant-design/icons";

import { gettingStarted } from "@utils/userguides/tco/gettingStarted";
import { contractors } from "@utils/userguides/tco/contractors";
import { dashboards } from "@utils/userguides/tco/dashboards";

import { contracts } from "./contracts";
import { forecasts } from "./forecasts";
import { actuals } from "./actuals";
import { g1c } from "./g1c";

export const tcoUserguides = [
  { title: "general", data: gettingStarted, icon: <QuestionOutlined /> },
  { title: "contractors", data: contractors, icon: <UserOutlined /> },
  {
    title: "contracts",
    data: contracts,
    icon: <CopyOutlined />
  },
  {
    title: "forecasts",
    data: forecasts,
    icon: <FileTextOutlined />
  },
  {
    title: "actuals",
    data: actuals,
    icon: <CheckOutlined />
  },
  {
    title: "g1c",
    data: g1c,
    icon: <DollarOutlined />
  },
  {
    title: "dashboards",
    data: dashboards,
    icon: <LineChartOutlined />
  }
];
