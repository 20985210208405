import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Content } from "antd/lib/layout/layout";

import { Button, Col, Row, Space, Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Content
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
        height: "70vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch"
      }}
    >
      <Row gutter={32}>
        <Col>
          <WarningOutlined
            style={{
              color: "rgba(234, 105, 73, 0.8)",
              fontSize: 150
            }}
          />
        </Col>
        <Col>
          <Space direction="vertical">
            <Typography.Title level={1} style={{ marginBottom: 0 }}>
              {t("Error")}
            </Typography.Title>
            <Typography.Title level={3} type="secondary">
              {t("somethingWentWrong")}
            </Typography.Title>
            <Link to={"/profile"}>
              <Button type="primary">{t("goToProfile")}</Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Content>
  );
};
