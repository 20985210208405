import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Typography } from "antd";
import { UserguideTemplate } from "@components/templates/UserguideTemplate";

import { tcoUserguides } from "@utils/userguides/tco";
import { ruTcoUserguides } from "@utils/userguides/ruTco";

export const UserGuide: FC = () => {
  const { t, i18n } = useTranslation();
  const { Title } = Typography;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Title>{t("login.userGuide")}</Title>
      <UserguideTemplate
        guides={i18n.language === "en" ? tcoUserguides : ruTcoUserguides}
      />
    </Space>
  );
};
