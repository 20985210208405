export const enExchangeRates = {
  exchangeRates: "Exchange rates",
  date: "Select date",
  updated: "Last updated at {{date}} by {{name}}",
  updateMonth: "Update exchange rates",
  code: "Currency",
  rate: "USD equivalent",
  rateInput: "USD Coefficient Rate",
  rateValidationMessage: "Enter exchange rate",
  addCurrency: "Add currency",
  codePlaceholder: "Select codes for currencies that you want to add",
  codeRequired: "Select at least one currency",
  addCurrencySuccess: "Currency successfully added",
  addCurrencyError: "There was an error adding currency, please try later",
  updateExchangeRatesSuccess:
    "The exchange rates for given period were updated successfully",
  updateExchangeRatesError:
    "There was an error updating exchange rates, please try later",
  getCurrenciesError: "Failed to fetch currencies list, try reloading the page"
};
