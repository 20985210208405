import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import moment, { locale } from "moment";

import { Button, message, Popconfirm, Table, Tag } from "antd";
import { DeleteFilled, InfoCircleOutlined } from "@ant-design/icons";

import { defineStatus, STATUS } from "@components/types/models/Statuses";
import type { Invoice } from "@components/types/models/Invoices";
import { defineContractType } from "@components/types/models/Contract";
import type { Contract } from "@components/types/models/Contract";

import { invoiceApi } from "@api/invoiceApi";

import type { InvoicesTableProps } from "./props";

const { Column } = Table;

export const InvoicesTable: FC<InvoicesTableProps> = ({
  invoicesList,
  isLoading,
  pagination,
  refetchInvoicesList
}) => {
  const { t, i18n } = useTranslation();

  locale(i18n.language === "en" ? "en" : "ru");

  const deleteInvoiceMutation = useMutation(
    async (invoiceId: number) => invoiceApi.deleteDraftInvoice(invoiceId),
    {
      onSuccess() {
        void message.success(t("invoices.invoiceIsDeleted"));
        refetchInvoicesList();
      }
    }
  );

  const handleDeleteClick = useCallback(
    (id: number) => () => {
      deleteInvoiceMutation.mutate(id);
    },
    [deleteInvoiceMutation]
  );

  return (
    <Table
      locale={{ emptyText: t("noData") }}
      loading={isLoading}
      dataSource={invoicesList}
      pagination={pagination}
      scroll={{ x: 800 }}
      rowKey="contractNumber"
    >
      <Column
        key="period"
        width={250}
        title={t("invoices.invoiceNumber")}
        render={(invoice: Invoice) => (
          <Link to={`/contractor/payment-application/${invoice.id}`}>
            {invoice.identityNumber ?? "-"}
          </Link>
        )}
      />
      <Column
        key="contractNumber"
        title={t("contracts.contractNumber")}
        dataIndex="contract"
        render={(contract: Contract) => contract.contractNumber}
      />

      <Column
        key="contractType"
        title={t("contracts.contractType")}
        dataIndex="contract"
        render={(contract: Contract) =>
          i18n.language === "en"
            ? defineContractType(contract.contractType).enName
            : defineContractType(contract.contractType).ruName
        }
      />
      <Column
        key="startDate"
        title={t("invoices.g1cDateFrom")}
        dataIndex="startDate"
        render={(startDate?: string) =>
          startDate ? moment(startDate).format("YYYY-MM-DD") : "-"
        }
      />
      <Column
        key="endDate"
        title={t("invoices.g1cDateTo")}
        dataIndex="endDate"
        render={(endDate?: string) =>
          endDate ? moment(endDate).format("YYYY-MM-DD") : "-"
        }
      />
      <Column
        key="submissionDate"
        title={t("actuals.submissionDate")}
        dataIndex="submittedAt"
        render={(submittedAt?: string) =>
          submittedAt ? moment(submittedAt).format("YYYY-MM-DD") : "-"
        }
      />
      <Column
        key="status"
        title={t("contracts.status")}
        dataIndex="status"
        render={(status: STATUS) => (
          <Tag color={defineStatus(status).color}>
            {i18n.language === "en"
              ? defineStatus(status).enName
              : defineStatus(status).ruName}
          </Tag>
        )}
      />
      <Column
        render={(invoice: Invoice) => (
          <Link to={`/contractor/payment-application/${invoice.id}`}>
            <Button size="large" type="link" icon={<InfoCircleOutlined />} />
          </Link>
        )}
      />
      <Column
        render={(invoice: Invoice) =>
          invoice.status === STATUS.DRAFT && (
            <Popconfirm
              title={t("invoices.deleteInvoiceConfirmation")}
              cancelText={t("cancel")}
              okText={t("yes")}
              onConfirm={handleDeleteClick(invoice.id)}
            >
              <Button
                danger
                size="large"
                type="text"
                icon={<DeleteFilled color="red" />}
                loading={deleteInvoiceMutation.isLoading}
              />
            </Popconfirm>
          )
        }
      />
    </Table>
  );
};
