import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Col, Form, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { ForecastStatusSelector } from "../ForecastStatusSelector";

import { forecastStatuses } from "@utils/statuses";

import type { SearchQueryType } from "./libs/types/SearchQueryType";
import type { SearchSectionProps } from "./props";

export const SearchSection: FC<SearchSectionProps> = ({ onSearchQuery }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState<SearchQueryType>();

  useEffect(() => {
    onSearchQuery(searchQuery);
  }, [onSearchQuery, searchQuery]);

  const handleOnFinish = useCallback(
    (vals: {
      readonly contractNumber: string;
      readonly forecastStatus: number;
    }) => {
      setSearchQuery({
        contractNumber: vals.contractNumber,
        forecastStatus: vals.forecastStatus
      });
    },
    []
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            name="contractNumber"
            label={t("contractors.contractNumber")}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="forecastStatus" label={t("contracts.status")}>
            <ForecastStatusSelector forecastStatuses={forecastStatuses} />
          </Form.Item>
        </Col>
        <Col span={3} style={{ display: "flex", alignItems: "center" }}>
          <Button type="primary" htmlType="submit">
            <SearchOutlined />
            {t("forecasts.applyFilters")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
